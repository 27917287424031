import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ChevronIconURL from '../../assets/images/icon-chevron.svg';

interface AccordionProps {
  title: string;
  content: React.ReactNode;
  isOpenInitially: boolean;
  index: number;
  setAccordionIndexes: Function;
}

interface AccordionIconProps {
  isOpen: boolean;
}

const AccordionContainer = styled.div`
  margin-bottom: 1rem;
`;

const AccordionTitle = styled.button`
  background-color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  justify-content: space-between;
  width: 100%;
  outline: none;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey-200, #e2e8f0);
  top: 0;
  position: sticky;
  margin-bottom: 0;
`;

const AccordionIcon = styled.span<AccordionIconProps>`
  width: 24px;
  height: 24px;
  background-image: url(${ChevronIconURL});
  background-size: cover;
  margin-right: 10px;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.3s ease;
`;

const AccordionContent = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;

  &.open {
    max-height: 50vh;
    overflow-y: auto;
  }
`;

const Accordion: React.FC<AccordionProps> = ({
  title,
  content,
  isOpenInitially,
  index,
  setAccordionIndexes
}) => {
  const [isOpen, setIsOpen] = useState(isOpenInitially);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setAccordionIndexes((prevState: { [key: number]: boolean }) => {
      prevState[index] = !prevState[index];
      return { ...prevState };
    })
  };

  return (
    <AccordionContainer>
      <AccordionTitle onClick={toggleAccordion} aria-expanded={isOpen}>
        {title}
        <AccordionIcon isOpen={isOpen} />
      </AccordionTitle>
      <AccordionContent ref={contentRef} className={isOpen ? 'open' : ''}>
        {content}
      </AccordionContent>
    </AccordionContainer>
  );
};

export default Accordion;
