import React, { useState, useEffect, useRef } from 'react';
import DataTable, { Api } from 'datatables.net';
import {
  SlotsInfo,
  Zephyr,
  Loading,
  ReduxState,
  ZephyrTypes,
} from '../../utils/interface';
import '../../assets/css/elements/table.css';
import {
  csvHeaders,
  FleetManagementAvailableSpecies,
  gaseousSlots,
} from '../../utils/consts';
import utilsRequest from '../../utils/request';
import { setZephyrs } from '../../actions/zephyrs';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { tableSubRowGenerator } from '../../utils/functions/tableSubRowGenerator';
import moment from 'moment';
import FleetManagementFilter from '../view_components/FleetManagementFilter';
import FleetManagementGenerator from '../view_components/FleetManagementGenerator';
import FleetManagementHeaders from '../view_components/FleetManagementHeaders';
import { useLocation } from 'react-router';

interface FleetManagementProps {
  zephyrs: Zephyr[];
  setZephyrs: Function;
  loading: Loading;
  bearerToken: string | null;
}

interface ZephyrType {
  type: string;
  label: string;
  checked: boolean;
  zType: number;
}

interface InitState {
    tableData: any[];
    fleetTable: Api<any> | null;
    tableDataToUse: any[] | null;
    availableZephyrTypes: ZephyrType[] | any[];
    defaultAllZephyrs: boolean;
    csvOption: boolean | null;
};

const initState: InitState = {
  tableData: [],
  fleetTable: null,
  tableDataToUse: null,
  availableZephyrTypes: [],
  defaultAllZephyrs: true,
  csvOption: null,
};

let isExpandButtonClicked = false;

const FleetManagement = ({
  zephyrs,
  setZephyrs,
  loading,
  bearerToken,
}: FleetManagementProps) => {
  // States
  const [tableData, setTableData] = useState(initState.tableData);
  const [fleetTable, setFleetTable] = useState(initState.fleetTable);
  const [tableDataToUse, setTableDataToUse] = useState(
    initState.tableDataToUse,
  );
  const [availableZephyrTypes, setAvailableZephyrTypes] = useState(
    initState.availableZephyrTypes,
  );
  const [defaultAllZephyrs, setDefaultAllZephyrs] = useState(
    initState.defaultAllZephyrs,
  );
  const [csvOption, setCsvOption] = useState(initState.csvOption);

  // Refs
  const sampIntervalRef = useRef(null);
  const slotsRef = useRef(null);
  const batteryPercentageRef = useRef(null);
  const lastConnectedRef = useRef(null);
  const table = useRef(null);

  // Consts
  const location = useLocation();

  // Effects

  useEffect(() => {
    if (location.pathname.includes('fleetManagement') && isExpandButtonClicked)
      isExpandButtonClicked = false;
  }, [location.pathname]);

  useEffect(() => {
    const filteredZephyrs = omitHistoricMappings();
    const zephyrsData = filteredZephyrs
      .filter((z: Zephyr) => z.type === 0)
      .map((z: Zephyr) => {
        const zNumber = z.zNumber ? z.zNumber : '-';
        const alias = z.alias ? z.alias : '-';
        const lat = z.latitude ? parseFloat(z.latitude).toFixed(4) : '-';
        const lon = z.longitude ? parseFloat(z.longitude).toFixed(4) : '-';
        const transmissionInterval =
          'transmissionInterval' in z && z.transmissionInterval
            ? z.transmissionInterval
            : '-';
        const sampPeriod = z.SampPeriod ? z.SampPeriod : 'Not Sampling';
        const slotAName = z.slotA_SN ? z.slotA_SN : 'Empty';
        const slotBName = z.slotB_SN ? z.slotB_SN : 'Empty';
        const batteryCharge =
          z.batteryCharge && typeof z.batteryCharge === 'number'
            ? z.batteryCharge
            : 'No Information';
        const lastConnected = z.lastConnectionDT
          ? moment(z.lastConnectionDT, 'YYYY-MM-DDZHH:mm:ss').format(
              'YYYY-MM-DD HH:mm',
            )
          : 'No Information';
        return [
          null,
          zNumber,
          alias,
          `${lat}, ${lon}`,
          // transmissionInterval,
          sampPeriod,
          `Slot A: ${slotAName} | Slot B: ${slotBName}`,
          batteryCharge,
          lastConnected,
          { type: z.type },
        ];
      });
    if (!isExpandButtonClicked) setTableData(zephyrsData);
  }, [zephyrs]);

  useEffect(() => {
    const table: Api<any> = new DataTable('#table_id', {
      columns: [
        {
          className: 'dt-control',
          orderable: false,
          data: null,
          defaultContent: '',
        },
        { title: csvHeaders[0] },
        { title: csvHeaders[1] },
        { title: csvHeaders[2] },
        { title: csvHeaders[3] },
        { title: csvHeaders[4] },
        { title: csvHeaders[5] },
        { title: csvHeaders[6] },
      ],
      data: tableDataToUse === null ? tableData : tableDataToUse,
      ordering: true,
      info: true,
      stateSave: true,
      order: [[1, 'asc']],
    });
    setFleetTable(table);
    // Extra step to do extra clean-up.
    return () => {
      table.destroy();
    };
  }, [tableData, tableDataToUse]);

  const filterTableData = () => {
    const sampIntervalChecked = (sampIntervalRef.current as any).checked;
    const slotsChecked = (slotsRef.current as any).checked;
    const batteryPercentageChecked = (batteryPercentageRef.current as any)
      .checked;
    const lastConnected = (lastConnectedRef.current as any).checked;
    if (
      !sampIntervalChecked &&
      !slotsChecked &&
      !batteryPercentageChecked &&
      !lastConnected &&
      defaultAvailableZephyrType()
    ) {
      setTableDataToUse(null);
      return;
    }
    const filteredData = getFilteredTableData(
      sampIntervalChecked,
      slotsChecked,
      batteryPercentageChecked,
      lastConnected,
    );
    filteredData && filteredData.length !== tableData.length
      ? setTableDataToUse(filteredData)
      : setTableDataToUse(null);
  };

  useEffect(() => {
    if (!loading.aurnLastHour && !loading.initApp && !loading.zephyrLastHour) {
      if (fleetTable) {
        const fleetManagementTable = document.getElementById('table_id');
        fleetManagementTable?.addEventListener('click', toggleRow);
        getAvailableZephyrTypes();
        return () => {
          fleetManagementTable?.removeEventListener('click', toggleRow);
        };
      }
    }
  }, [loading, fleetTable]);

  useEffect(() => {
    const defaultZephyrType = defaultAvailableZephyrType();
    setDefaultAllZephyrs(defaultZephyrType);
  }, [availableZephyrTypes]);

  const omitHistoricMappings = () => {
    const filteredZephyrsArray = zephyrs.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.zNumber === value.zNumber),
    );
    return filteredZephyrsArray;
  };

  const getAvailableZephyrTypes = () => {
    const filteredZephyrTypes = FleetManagementAvailableSpecies.speciesMap.filter(
      (sm) =>
        !(
          (sm.type === 'zephyr' &&
            !zephyrs.filter((z) => z.type === 0).length) ||
          (sm.type === 'aurn' && !zephyrs.filter((z) => z.type === 1).length) ||
          (sm.type === 'virtual' &&
            !zephyrs.filter((z) => z.type === ZephyrTypes.virtual).length)
        ),
    );
    setAvailableZephyrTypes(filteredZephyrTypes);
  };

  const extractAvailableZephyrTypes = () => {
    return (availableZephyrTypes as any[]).map((t, idx) => (
      <label htmlFor={t.type} key={idx}>
        <input
          type="checkbox"
          id={t.type}
          name={t.type}
          value={t.label}
          checked={t.checked}
          onChange={() => {
            filterByZephyrType(idx);
          }}
        />
        <span>{t.label}</span>
      </label>
    ));
  };

  const setAllZephyrsOption = () => {
    if (!defaultAvailableZephyrType()) {
      const copyAvailableZephyrTypes = [...availableZephyrTypes];
      copyAvailableZephyrTypes.forEach((t) => {
        if (t.checked) t.checked = false;
      });
      setAvailableZephyrTypes(copyAvailableZephyrTypes);
    }
    filterTableData();
  };

  const filterByZephyrType = (idx: number) => {
    const copyAvailableZephyrTypes = [...availableZephyrTypes];
    copyAvailableZephyrTypes[idx].checked = !copyAvailableZephyrTypes[idx]
      .checked;
    setAvailableZephyrTypes(copyAvailableZephyrTypes);
    filterTableData();
  };

  const defaultAvailableZephyrType = () => {
    if (!availableZephyrTypes) return true;
    const checkedOptionsArr = availableZephyrTypes.filter(
      (t) => t.checked === true,
    );
    if (checkedOptionsArr.length > 0) return false;
    return true;
  };

  const validateCheckedButtons = (obj: any, data: any[]) => {
    if (obj.checked && obj.idx === 7)
      return data[obj.idx] <= 25 || data[obj.idx] === obj.emptyValue;
    if (obj.checked && obj.idx === 8) {
      const hours =
        data[obj.idx] !== 'No Information'
          ? moment().diff(moment(data[obj.idx]), 'hours')
          : null;
      return hours !== null ? hours >= 24 : data[obj.idx] === obj.emptyValue;
    }
    if (obj.checked) return data[obj.idx] === obj.emptyValue;
    return true;
  };

  const getFilteredTableData = (
    sampIntervalChecked: boolean,
    slotsChecked: boolean,
    batteryPercentageChecked: boolean,
    lastConnected: boolean,
  ) => {
    const tableDataCopy = [...tableData];
    const checkedButtons = {
      sampInterval: {
        idx: 4,
        checked: sampIntervalChecked,
        emptyValue: 'Not Sampling',
      },
      slots: {
        idx: 5,
        checked: slotsChecked,
        emptyValue: 'Slot A: Empty | Slot B: Empty',
      },
      batteryCharge: {
        idx: 6,
        checked: batteryPercentageChecked,
        emptyValue: 'No Information',
      },
      lastTimeConnected: {
        idx: 7,
        checked: lastConnected,
        emptyValue: 'No Information',
      },
    };
    const filteredDataByZephyr = getFiltredTableDataByZephyr(tableDataCopy);
    const filteredData =
      defaultAvailableZephyrType() ||
      filteredDataByZephyr?.length === 0 ||
      filteredDataByZephyr?.length === tableDataCopy.length
        ? tableDataCopy.filter((data: any[]) => {
            return (
              validateCheckedButtons(checkedButtons.sampInterval, data) &&
              validateCheckedButtons(checkedButtons.slots, data) &&
              validateCheckedButtons(checkedButtons.batteryCharge, data) &&
              validateCheckedButtons(checkedButtons.lastTimeConnected, data)
            );
          })
        : filteredDataByZephyr?.filter((data: any[]) => {
            return (
              validateCheckedButtons(checkedButtons.sampInterval, data) &&
              validateCheckedButtons(checkedButtons.slots, data) &&
              validateCheckedButtons(checkedButtons.batteryCharge, data) &&
              validateCheckedButtons(checkedButtons.lastTimeConnected, data)
            );
          });
    return filteredData;
  };

  const getZephyrCheckBoxState = (zephyrType: number) => {
    const zephyrExist = availableZephyrTypes.some(
      (z) => z.zType === zephyrType,
    );
    if (!zephyrExist) return true;
    const indexOfZephyrObj = availableZephyrTypes.findIndex(
      (z) => z.zType === zephyrType,
    );
    if (!availableZephyrTypes[indexOfZephyrObj].checked) return false;
    return availableZephyrTypes[indexOfZephyrObj].zType;
  };

  const getFiltredTableDataByZephyr = (tableDataCopy: any[]) => {
    if (!availableZephyrTypes) return;
    const filteredData = tableDataCopy.filter((data: any[]) => {
      const actualZephyr = getZephyrCheckBoxState(0);
      const vZephyr = getZephyrCheckBoxState(100);
      const aurn = getZephyrCheckBoxState(1);
      return (
        data[6].type === actualZephyr ||
        data[6].type === vZephyr ||
        data[6].type === aurn
      );
    });
    return filteredData;
  };

  const getCurrentZephyr = (tr: HTMLElement) => {
    const zephyrZNumber = parseInt(tr.children[1].innerHTML);
    return zephyrs.find((z: Zephyr) => z.zNumber === zephyrZNumber);
  };

  const setUnitCartridgeData = async (unit: Zephyr) => {
    if (!unit.slotsInfo) {
      const packagedSlotsInfo: SlotsInfo = {
        slotA: null,
        slotB: null,
      };
      if (!unit.userEndTimeDate) {
        for await (const gS of gaseousSlots) {
          const slotSerial = unit[`${gS.label}_SN`];
          if (slotSerial) {
            const slotInfo = await utilsRequest.getCartridge(
              slotSerial,
              bearerToken,
            );
            if (slotInfo) {
              packagedSlotsInfo[gS.label] = slotInfo;
            }
          }
        }
      }
      const copyZephyrsList = [...zephyrs];
      const newData = copyZephyrsList.find((z) => z.id_pod === unit.id_pod);
      if (newData) newData.slotsInfo = packagedSlotsInfo;
      setZephyrs(copyZephyrsList);
    }
  };

  const updateCartridgeData = async (z: Zephyr) => {
    if (z && z.type === 0 && !z.slotsInfo) {
      isExpandButtonClicked = true;
      await setUnitCartridgeData(z);
    }
  };

  const toggleRow = async (event: any) => {
    if (
      event.target.className.includes('dt-control') &&
      !event.target.className.includes('sorting_disabled') &&
      fleetTable
    ) {
      const toggleRowButton = event.target;
      const tr = toggleRowButton.closest('tr');
      if (!tr) return;
      const row = (fleetTable as any).row(tr);
      if (row.child.isShown() || tr.className.includes('dt-hasChild')) {
        row.child.hide();
        if (isExpandButtonClicked) isExpandButtonClicked = false;
        return;
      }
      const zephyr = getCurrentZephyr(tr);
      await updateCartridgeData(zephyr!);
      row.child(tableSubRowGenerator(zephyr!)).show();
    }
  };

  return (
    <section>
      <div className="table-filter-options">
        {
          <FleetManagementFilter
            sampIntervalRef={sampIntervalRef}
            slotsRef={slotsRef}
            batteryPercentageRef={batteryPercentageRef}
            lastConnectedRef={lastConnectedRef}
            filterTableData={filterTableData}
          />
        }
        {
          <FleetManagementGenerator
            tableData={tableData}
            table={table}
            csvOption={csvOption}
            setCsvOption={setCsvOption}
          />
        }
      </div>
      {<FleetManagementHeaders table={table} />}
    </section>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  loading: state.loading,
  bearerToken: state.auth.bearerToken,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setZephyrs,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(FleetManagement);
