// Package Imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

// @ts-ignore
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

// Component Imports
import Modal from '../shared_components/Modal';
import AppIntroModal from './AppIntroModal';
import AgreementModal from './AgreementModal';
import VersionLatestModal from './VersionLatestModal';

// Request Imports
import utilsRequest from '../../utils/request';

// Action Import
import { setAlertsOn, setAlertsOff } from '../../actions/alerts';

// Type Imports
import {
  AppIntro,
  Agreement,
  ReduxState,
  UserConfig,
  Version,
} from '../../utils/interface';

// Component Interfaces
interface AlertsProps {
  appIntro: AppIntro | null;
  agreements: Agreement[] | any [];
  internalUser: boolean;
  isPublic: boolean;
  on: boolean;
  setAlertsOn: Function;
  setAlertsOff: Function;
  userConfig: UserConfig;
  versions: Version[];
  bearerToken: string | null;
}

interface InitState {
  appIntro: AppIntro | null;
  agreements: Agreement[];
  version: Version | null;
}

// Component
const Alerts = ({
  agreements,
  appIntro,
  internalUser,
  isPublic,
  on,
  setAlertsOn,
  setAlertsOff,
  userConfig,
  versions,
  bearerToken,
}: AlertsProps) => {
  // State
  const initState: InitState = {
    appIntro: null,
    agreements: [],
    version: null,
  };

  const [appIntroInternal, setAppIntroInternal] = useState(initState.appIntro);
  const [agreementsInternal, setAgreementsInternal] = useState(
    initState.agreements,
  );
  const [versionInternal, setVersionInternal] = useState(initState.version);
  const [agreementModalOn, setAgreementModalOn] = useState(true);
  // Effects
  useEffect(() => {
    if (agreements && agreements.length && !internalUser) {
      setAgreementsInternal(agreements);
    }
  }, [agreements]);

  useEffect(() => {
    setAppIntroInternal(appIntro);
  }, [appIntro]);

  useEffect(() => {
    if (
      userConfig &&
      versions &&
      userConfig.latestVersionViewed &&
      versions.length &&
      !isPublic &&
      !internalUser
    ) {
      const latestVersion = versions[versions.length - 1];
      if (userConfig.latestVersionViewed !== latestVersion.versionId) {
        setVersionInternal(latestVersion);
      }
    }
  }, [userConfig, versions]);

  useEffect(() => {
    agreementsInternal.length > 0 || appIntroInternal || versionInternal
      ? setAlertsOn()
      : setAlertsOff();
  }, [agreementsInternal, appIntroInternal, versionInternal]);

  useEffect(() => {
    // && appIntroInternal.on
    if (appIntroInternal !== null) {
      const x = document.getElementsByTagName('BODY')[0];
      disableBodyScroll(x);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [appIntroInternal]);

  // Functions
  const signAgreement = async (mua_id: number) => {
    const response = await utilsRequest.signUserAgreement(mua_id, bearerToken);
    if (response) {
      const updatedAgreements = agreementsInternal.filter(
        (a) => a.id !== mua_id,
      );
      setAgreementsInternal(updatedAgreements);
    }
  };

  const updateLatestVersionViewed = async (versionId: number) => {
    // Close modal regardless of failed/sucessful request
    await utilsRequest.updateUserLatestVersionViewed(versionId, bearerToken);
    setVersionInternal(null);
  };

  const completeAppIntro = () => {
    setAppIntroInternal(null);
  };

  return (
    <>
      {appIntro ? (
        <Modal
          on={appIntroInternal !== null && appIntroInternal.on}
          modalComponent={
            <AppIntroModal
              appIntro={appIntro}
              completeAppIntro={completeAppIntro}
            />
          }
        />
      ) : (
        <></>
      )}
      {agreementsInternal.length > 0 ? (
        <Modal
          on={agreementModalOn}
          modalComponent={
            <AgreementModal
              agreement={agreementsInternal[0]}
              buttonText="Agree"
              signAgreement={signAgreement}
              setAgreementModalOn={setAgreementModalOn}
            />
          }
        />
      ) : (
        <></>
      )}
      {versionInternal && agreementsInternal.length === 0 ? (
        <Modal
          on={versionInternal !== null}
          modalComponent={
            <VersionLatestModal
              version={versionInternal}
              updateLatestVersionViewed={updateLatestVersionViewed}
            />
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  agreements: state.alerts.agreements,
  appIntro: state.auth.userInfo && state.auth.userInfo.appIntro,
  internalUser: state.auth.userInfo && state.auth.userInfo.isInternal,
  isPublic: state.auth.userInfo && state.auth.userInfo.isPublic,
  on: state.alerts.on,
  userConfig: state.setUserConfig,
  versions: state.setVersions,
  bearerToken: state.auth.bearerToken,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setAlertsOn,
      setAlertsOff,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
