// Package Imports
import React, { useState, useEffect, useRef } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import TagManager from 'react-gtm-module';
// Action Imports
import {
  loadingInitAppDataOff,
  loadingInitAppDataOn,
  loadingInitAppThirdPartyDataOff,
  loadingInitAppThirdPartyDataOn,
  loadingInitAppOff,
  loadingInitAppOn,
  logout,
  setAlerts,
  setAveragingPeriods,
  setDataConfig,
  setDisplayConfig,
  setFailedLogin,
  setFeedback,
  setPublicAdvice,
  setOverlays,
  setStylegroups,
  setThreshold,
  setTimePeriods,
  setTodayData,
  setUserConfig,
  setUserInfo,
  setVersions,
  setMasterAuthBearerToken,
} from '../actions/auth';
import { queryStringParams } from '../actions/queryStringParams';
import { mappAirFilters, setSelectedFilters } from '../actions/mappAirFilters';
import { changeOverlayLayer, showOverlayLayer } from '../actions/layers';
import { setFeedbackOn } from '../actions/feedback';
import { setUnitLocation } from '../actions/locations';
import { setAurns, setZephyr, setZephyrs } from '../actions/zephyrs';
import { setAirAlerts } from '../actions/airAlerts';

// Components
import AirAlerts from './views/AirAlerts';
import Alerts from './view_components/Alerts';
import Analytics from './views/Analytics';
import Data from './views/Data';
import Location from './views/Location';
import Header from './shared_components/Header';
import Login from './views/Login';
import Spinner from './shared_components/Spinner';
import Today from './views/Today';
import UserTour from './view_components/UserTour';
import FleetManagement from './views/FleetManagement';

// Consts Imports
import {
  defaultLocation,
  defaultSelectedFilters,
  gaseousSlots,
  gtmEventIdentifiers,
  routePaths,
  slots,
  defaultVZephyrsConfig,
} from '../utils/consts';

// Util Imports
import analyticsEventFirer from '../utils/functions/analyticsEventFirer';
import { queryStringParser } from '../utils/functions/queryStringParser';
import utilsRequest from '../utils/request';
import { todayDataPackager } from '../utils/functions/todayDataPackager';
import { wideScreenFinder } from '../utils/functions/wideScreenFinder';
import {
  zephyrMetDataEnricher,
  zephyrMetRequestPackager,
} from '../utils/functions/zephyrMetDataEnricher';
import { sortAnArrayOfZephyrsByName, zephyrSorter } from '../utils/functions/zephyrSorter';
import { tallScreenFinder } from '../utils/functions/tallScreenFinder';
import {
  current,
  subtractTimeFromNow,
  utcConvertor,
} from '../utils/functions/dateFinder';
import aqiCalculator from '../utils/functions/aqiCalculator';
import { airAlertsApiPackager } from '../utils/functions/airAlertsInterface';
import { pointDataPackager } from '../utils/functions/saDataPackager';

// Type Imports
import {
  AuthProps,
  DataConfig,
  DisplayConfig,
  Feedback,
  LocationCoordinates,
  Overlay,
  ReduxState,
  SlotsInfo,
  SpeciesDataIdentifiers,
  UrlQueryStringParams,
  UserInfo,
  Zephyr,
  ZephyrLastHourInd,
  VirtualZephyr,
  VirtualZephyrData,
  VZephyrsConfig,
  ZephyrTypes,
} from '../utils/interface';
import { ModularFeatures } from '../reducers/modularFeatures';
import SmsGroupAlerts from './views/SmsGroupAlerts';
import DataAnalyticsContainer from './views/DataAnalyticsContainer';
import { compress, decompress } from 'lz-string';
import { setNewModularFeaturesState } from '../actions/modularFeaturesActions';
import { setIsCartridgeDataLoading } from '../actions/dataAnalytics';

// Component Interfaces
interface InitState {
  auth: null | AuthProps;
  parsedQueryString: null | UrlQueryStringParams;
  vZephyrsConfig: VZephyrsConfig;
  isDSEEnabled: boolean;
}

interface AppContainerProps {
  basename: string;
  dataConfig: DataConfig;
  displayConfig: DisplayConfig;
  feedback: Feedback;
  isAuthenticated: boolean;
  loading: boolean;
  loginFailed: boolean;
  loadingInitAppDataOff: Function;
  loadingInitAppDataOn: Function;
  loadingInitAppThirdPartyDataOff: Function;
  loadingInitAppThirdPartyDataOn: Function;
  loadingInitAppOff: Function;
  loadingInitAppOn: Function;
  logout: Function;
  mappAirFilters: Function;
  overlays: Overlay[];
  queryStringParams: Function;
  setAirAlerts: Function;
  setAlerts: Function;
  setAurns: Function;
  setAveragingPeriods: Function;
  setDataConfig: Function;
  setDisplayConfig: Function;
  setFailedLogin: Function;
  setFeedback: Function;
  setFeedbackOn: Function;
  setOverlays: Function;
  setPublicAdvice: Function;
  setSelectedFilters: Function;
  setStylegroups: Function;
  setThreshold: Function;
  setTimePeriods: Function;
  setTodayData: Function;
  setUnitLocation: Function;
  setUserConfig: Function;
  setUserInfo: Function;
  setVersions: Function;
  setZephyr: Function;
  setZephyrs: Function;
  showOverlayLayer: Function;
  changeOverlayLayer: Function;
  userInfo: UserInfo;
  userLoading: boolean;
  userLocation: LocationCoordinates;
  zephyrs: Zephyr[];
  zephyrSet: Boolean;
  modularFeatures: ModularFeatures;
  setMasterAuthBearerToken: Function;
  bearerToken: string | null;
  setNewModularFeaturesState: Function;
  isCartridgeDataLoading: boolean | null;
  setIsCartridgeDataLoading: Function;
}
// Init App
const AppContainer = ({
  basename,
  dataConfig,
  displayConfig,
  isAuthenticated,
  feedback,
  loading,
  loadingInitAppDataOff,
  loadingInitAppDataOn,
  loadingInitAppThirdPartyDataOff,
  loadingInitAppThirdPartyDataOn,
  loadingInitAppOff,
  loadingInitAppOn,
  loginFailed,
  logout,
  mappAirFilters,
  overlays,
  queryStringParams,
  setAirAlerts,
  setAlerts,
  setAurns,
  setAveragingPeriods,
  setDataConfig,
  setDisplayConfig,
  setFailedLogin,
  setFeedbackOn,
  setFeedback,
  setOverlays,
  setPublicAdvice,
  setSelectedFilters,
  setStylegroups,
  setThreshold,
  setTimePeriods,
  setTodayData,
  setUnitLocation,
  setUserInfo,
  setUserConfig,
  setVersions,
  setZephyr,
  setZephyrs,
  showOverlayLayer,
  changeOverlayLayer,
  userInfo,
  userLocation,
  userLoading,
  zephyrs,
  zephyrSet,
  modularFeatures,
  setMasterAuthBearerToken,
  bearerToken,
  setNewModularFeaturesState,
  isCartridgeDataLoading,
  setIsCartridgeDataLoading
}: AppContainerProps) => {
  const initState: InitState = {
    auth: null,
    parsedQueryString: null,
    vZephyrsConfig: defaultVZephyrsConfig,
    isDSEEnabled: false,
  };
  const startDate = new Date('2023-01-23T15:00:00');
  const endDate = new Date('2023-02-06T00:00:00');
  const [isBannerShowing, setIsBannerShowing] = useState(false);
  const [bannerValue, setBannerValue] = useState<string[] | null>(null);
  const [isModalOpened, setIsModalOpened] = useState(isBannerShowing);
  const [isDSEEnabled, setIsDSEEnabled] = useState<boolean>(
    initState.isDSEEnabled,
  );
  const [tableData, setTableData] = useState<any>(null);
  const [csvData, setCsvData] = useState<any>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [aqiBands, setAqiBands] = useState<any>(null);

  // Consts
  const history = useHistory();
  const location = useLocation();
  const bannerText = [
    'Hello from Earthsense Data Quality,',
    'As part of our ongoing continuous improvements, we have made some adjustments to our calibration factors which will be applied to raw PM2.5 measurements. These improvements were recommended by our recent iMCERTs accreditation process. The adjustments will ensure that any Zephyr® which is currently in service and has the correct hardware version, now produces indicative MCERTS compliant PM2.5 measurements without further data processing. The adjustment will also show an improvement on data quality for Zephyrs that do not have iMCERTS compliant hardware.',
    'The rollout for the improvements is scheduled to happen on February 1st 2023 and will be applied to all historic data.',
    'If you’re unsure whether your hardware version is iMCERTs compliant, please get in touch with our sales team who will be happy to inform you.',
    'Email: sales@earthsense.co.uk or; Phone: 0116 2967460 option 2',
    'You may opt out of the scheduled improvements by emailing us at support@earthsense.co.uk. If we do not receive any correspondence informing us of the decision to opt out, we will assume that the improvements may be implemented with your data. Any questions please feel free to get in touch.',
    'This popup message will expire on the 6th Feb 2023.',
  ];

  // Timers
  let timeout: ReturnType<typeof setTimeout>;
  let interval: ReturnType<typeof setInterval>;

  // State
  const [authReq, setAuthReq] = useState(initState.auth);
  const [vZephyrsConfig, setVZephyrsConfig] = useState(
    initState.vZephyrsConfig,
  );

  // Refs
  const dataConfigRef = useRef<DataConfig | null>(null);
  dataConfigRef.current = dataConfig;

  const displayConfigRef = useRef<DisplayConfig | null>(null);
  displayConfigRef.current = displayConfig;

  const isAuthenticatedRef = useRef<boolean | null>(null);
  isAuthenticatedRef.current = isAuthenticated;

  const oneTimeFeedbackAutomation = useRef<boolean | null>(null);
  oneTimeFeedbackAutomation.current = feedback.oneTimeAutomation;

  const userLocationRef = useRef<LocationCoordinates | null>(null);
  userLocationRef.current = userLocation;

  const zephyrsRef = useRef<Zephyr[]>([]);
  zephyrsRef.current = zephyrs;

  useEffect(() => {
    // const {modularFeatures} = await utilsRequest.getModularFeaturesConfig(userID: number);
    if (process.env.REACT_APP_SERVER === 'production') {
      // setNewModularFeaturesState(modularFeatures)
    } else if (process.env.REACT_APP_SERVER === 'beta') {
      // setNewModularFeaturesState(modularFeatures)
    }
  }, []);

  useEffect(() => {
    // update global master auth token state with IIFY
    if (!bearerToken && authReq && authReq.username && authReq.password) {
      (async () => {
        const masterAuthBearerToken = await utilsRequest.getMasterAuthToken(
          authReq.username,
          authReq.password,
        );
        setMasterAuthBearerToken(masterAuthBearerToken);
      })();
    }
  }, [authReq]);

  useEffect(() => {
    const rawQueryString = location.search;
    const parsedQueryString = rawQueryString
      ? queryStringParser(rawQueryString)
      : initState.parsedQueryString;
    if (rawQueryString) {
      queryStringParams(parsedQueryString);
    }
    if (!location.pathname.includes('login') && userLoading) {
      const auth = {
        internal: false,
        isAuthenticated: !!localStorage.getItem('token'),
        password: localStorage.getItem('key') || '',
        queryString: parsedQueryString,
        username: localStorage.getItem('user') || '',
      };
      if (basename === 'internal') {
        auth.internal = true;
      } else if (basename) {
        auth.isAuthenticated = false;
        auth.username = basename;
        auth.password = basename;
      }

      if (!wideScreenFinder(800) || !tallScreenFinder(500)) {
        mappAirFilters();
        showOverlayLayer();
      }
      if (
        ((!basename || basename === 'internal') &&
          location.pathname === '/' &&
          localStorage.getItem('isPublic') === 'true') ||
        !auth.username ||
        !auth.password
      ) {
        logout();
      } else {
        const { password, username } = auth;
        if (password && username) {
          setAuthReq(auth);
        }
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    // Note the nesting of functions here in the useEffect enables us to declare requests dirtied
    // See the ignore property
    const setUserInfoInternal = async (authIsInternal: boolean) => {
      const updatedUserInfo = await utilsRequest.getUserInfo(bearerToken);
      if ('aqiBands' in updatedUserInfo && updatedUserInfo.aqiBands) {
        const {
          aqiBands: { AQISpecies },
        } = updatedUserInfo;
        setAqiBands(AQISpecies);
      }
      updatedUserInfo.isInternal = authIsInternal;
      await setUserInfo({
        user: localStorage.getItem('user'),
        key: localStorage.getItem('key'),
        userInfo: updatedUserInfo,
      });
      if (updatedUserInfo.isPublic) {
        setUnitLocation(
          updatedUserInfo.startingLng,
          updatedUserInfo.startingLat,
        );
        if (updatedUserInfo.today && updatedUserInfo.todaySpecies) {
          const todayData = await todayDataPackager(
            updatedUserInfo.todaySpecies,
            [updatedUserInfo.startingLat, updatedUserInfo.startingLng],
            bearerToken,
          );
          setTodayData(todayData);
        }
      }
      return updatedUserInfo;
    };

    const authenticate = async () => {
      if (authReq && bearerToken) {
        // Always clear cached data on reauth
        localStorage.removeItem('data');
        let loginState: boolean = false;
        if (!authReq.isAuthenticated) {
          // get/set token
          const token = await utilsRequest.getFetchToken(
            authReq.username,
            authReq.password,
            bearerToken,
          );
          localStorage.setItem('token', token);
          localStorage.setItem('user', authReq.username);
          localStorage.setItem('key', authReq.password);
        }
        const validToken = await utilsRequest.checkValidToken(bearerToken);
        // do correct actions if the token is not valid
        if (!validToken) {
          setAuthReq(null);
          setFailedLogin();
        } else {
          // initalise user analyticsager.initialize(gtmConfig);
          const user = authReq.username;
          const userId = user
            .split('')
            .map((c: string) => c.charCodeAt(0))
            .join('-');
          const tagManagerArgs = {
            dataLayer: {
              userId,
            },
          };
          TagManager.dataLayer(tagManagerArgs);
          // trigger initial App loading phase on
          await loadingInitAppOn();
          // get/set User Info
          const updatedUserInfo = await setUserInfoInternal(authReq.internal);
          localStorage.setItem('isPublic', updatedUserInfo.isPublic);
          loginState = true;
          // fire analytics
          const labelEnrichment = loginState ? 'Successful' : 'Unsuccessful';
          analyticsEventFirer(gtmEventIdentifiers.login, labelEnrichment);
        }
      }
    };
    authenticate();
  }, [authReq, bearerToken]);

  useEffect(() => {
    if (
      userInfo &&
      userInfo.today &&
      (!wideScreenFinder(800) || !tallScreenFinder(500))
    ) {
      history.push(routePaths.today);
    }
  }, [userInfo]);

  const isFeedbackDue = () => {
    let isDue = false;
    const user = localStorage.getItem('user');

    const random = (min: number, max: number) =>
      Math.floor(Math.random() * (max - min)) + min;

    const feedbackDueDateStr = localStorage.getItem('feedbackDueDate');
    const dateObject = new Date();
    const currentDate = dateObject.getDate();
    const currentMonth = dateObject.getMonth() + 1; // 👈️ months are 0-based
    const dueMonth = currentMonth === 12 ? 1 : currentMonth;

    // first time user
    if (!feedbackDueDateStr) {
      const dueDate = random(1, 28);

      const objectTosave = {
        user,
        dueDate,
        dueMonth: dueMonth + 1 > 12 ? 1 : dueMonth + 1,
        feedbackShown: false,
      };
      localStorage.setItem('feedbackDueDate', JSON.stringify(objectTosave));

      if (currentDate === dueDate) isDue = true;
    } else {
      const savedObj = JSON.parse(feedbackDueDateStr);
      const {
        user: savedUser,
        dueDate,
        dueMonth: savedCurrentMonth,
        feedbackShown: savedFeedbackShown,
      } = savedObj;

      // match due date
      if (
        currentMonth === savedCurrentMonth &&
        currentDate === dueDate &&
        savedUser === user &&
        !savedFeedbackShown
      ) {
        const objectTosave = {
          user,
          dueDate,
          dueMonth,
          feedbackShown: savedFeedbackShown,
        };
        localStorage.setItem('feedbackDueDate', JSON.stringify(objectTosave));
        isDue = true;
      }

      if (savedCurrentMonth < currentMonth) {
        // renew Due date
        const objectTosave = {
          user,
          dueDate: currentDate,
          dueMonth: dueMonth + 1 > 12 ? 1 : dueMonth + 1,
          feedbackShown: false,
        };
        localStorage.setItem('feedbackDueDate', JSON.stringify(objectTosave));
      }
    }

    return isDue;
  };

  useEffect(() => {
    if (
      isAuthenticated &&
      userInfo &&
      !userInfo.isPublic &&
      feedback.questions.length > 0 &&
      isFeedbackDue()
    ) {
      timeout = setTimeout(() => {
        if (oneTimeFeedbackAutomation.current && isAuthenticatedRef.current) {
          setFeedbackOn();
        }
      }, 30000);
    }
  }, [isAuthenticated, feedback, userInfo]);

  useEffect(() => {
    if (!isAuthenticated) {
      clearTimeout(timeout);
      clearInterval(interval);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Stale request state
    let ignore = false;
    let aurnsList: any = [];
    // Launch sequence
    const launch = async () => {
      if (userInfo.isPublic) {
        const publicAdvice = await utilsRequest.getPublicAdvice(bearerToken);
        await setPublicAdvice(publicAdvice);
      }

      setSelectedFilters(defaultSelectedFilters);

      // get/set Display Config
      const displayConfig = await utilsRequest.getDisplayConfig(bearerToken);
      await setDisplayConfig(displayConfig);
      // get/set Mappair Overlays
      const updatedOverlays = await utilsRequest.getOverlays(
        bearerToken,
        userInfo.isPublic,
      );
      await setOverlays(updatedOverlays);

      // get/set Alerts
      const agreements = await utilsRequest.getUserAgreements(bearerToken);
      if (agreements.length > 0) {
        await setAlerts(agreements);
      }
      // get/set User Config
      // if (!userInfo.isPublic && process.env.REACT_APP_FEEDBACK_ENABLED) {
      if (!userInfo.isPublic) {
        const feedbackQuestions = await utilsRequest.getUserFeedbackQuestions(
          bearerToken,
        );
        await setFeedback(feedbackQuestions);
      }

      if (
        !vZephyrsConfig.active ||
        !vZephyrsConfig.max ||
        !vZephyrsConfig.new30Days ||
        !vZephyrsConfig.new30DaysMax
      ) {
        const {
          active_vzephyrs: active,
          active_vzephyrs_max: max,
          new_vzephyrs_30days: new30Days,
          new_vzephyrs_30days_max: new30DaysMax,
        } = await utilsRequest.getUserVZephyrConfig(bearerToken);
        setVZephyrsConfig({
          active,
          max,
          new30Days,
          new30DaysMax,
        });
      }
      const userConfig = await utilsRequest.getUserConfig(bearerToken);
      await setUserConfig(userConfig);
      // get styleGroups
      const stylegroups = await utilsRequest.getStylegroups(bearerToken);
      await setStylegroups(stylegroups);
      // set threshold
      if (stylegroups.length) {
        const threshold = stylegroups[0].name;
        await setThreshold(threshold);
      }

      // Update modular features
      // const newModularFeatures = await utilsRequest.getModularFeaturesConfig(userID: number);
      // if (newModularFeatures !== modularFeatures) {
      //   setNewModularFeaturesState(newModularFeatures);
      // }

      // get/set versions
      // const versions = await utilsRequest.getVersions();
      // await setVersions(versions);

      // get/set Time Periods (data)
      const timePeriods = await utilsRequest.getTimePeriods(bearerToken);
      await setTimePeriods(timePeriods);
      // get/set Averaging Periods (data)
      const averagingPeriods = await utilsRequest.getAveragingPeriods(
        bearerToken,
      );
      await setAveragingPeriods(averagingPeriods);
      // get/set Data Config
      const dataConfigInternal = await utilsRequest.getDataConfig(bearerToken);
      await setDataConfig(dataConfigInternal);
      // get/set zephyrs and aurns with last hour data and met data
      await setUnits(false, userInfo.isPublic);
      // trigger initial App loading phase off for Public

      // set public default filter
      if (userInfo.isPublic) {
        await mappAirFilters();
        await showOverlayLayer();

        if (updatedOverlays) {
          const foundOneCompatibleOverlayWithPollution = updatedOverlays.find(
            (updatedOverlay: Overlay) =>
              updatedOverlay.accessGranted && updatedOverlay.isMappairOverlay,
          );
          if (foundOneCompatibleOverlayWithPollution) {
            const {
              name: overlayName,
            } = foundOneCompatibleOverlayWithPollution;
            await changeOverlayLayer(overlayName);
          }
        }
      }
      await loadingInitAppOff();
      // Set polling
      if (!userInfo.isPublic) {
        interval = setInterval(() => {
          if (isAuthenticatedRef.current && zephyrsRef.current.length > 0) {
            if (!isCartridgeDataLoading) setIsCartridgeDataLoading(true);
            setUnits(true, userInfo.isPublic);
          }
        }, 600000);
      }
    };

    const getAvailableSpeciesLabels = () => {
      if (displayConfigRef.current) {
        const availableSpecies: string[] = [];
        displayConfigRef.current.tabs.forEach((t) =>
          t.species.forEach((tS) => availableSpecies.push(tS.label)),
        );
        return availableSpecies;
      }
      return [];
    };

    const getAvailableSpeciesIdentifiers = () => {
      if (displayConfigRef.current) {
        const availableSpecies: SpeciesDataIdentifiers[] = [];
        displayConfigRef.current.tabs.forEach((t) =>
          t.species.forEach((tS) =>
            tS.dataIdentifiers.forEach((tSD) => availableSpecies.push(tSD)),
          ),
        );
        return availableSpecies;
      }
      return [];
    };

    const getSpeciesLabelFromSpeciesIdentifier = (
      speciesIdentifier: SpeciesDataIdentifiers,
    ) => {
      let speciesLabel = '';
      if (displayConfigRef.current) {
        displayConfigRef.current.tabs.forEach((t) =>
          t.species.forEach((tS) => {
            if (tS.dataIdentifiers.includes(speciesIdentifier)) {
              speciesLabel = tS.label;
            }
          }),
        );
      }
      return speciesLabel;
    };

    const getUsersAurns = async () => {
      const userAurnList = await utilsRequest.getAurns(bearerToken);
      const availableSpecies = getAvailableSpeciesLabels();
      const enrichedUserAurnList = userAurnList.map((userAurn: Zephyr) => {
        const enrichedAurn: any = {
          ...userAurn,
          name: userAurn.location,
          averageOfLastHourOfData: null,
          slotsInfo: null,
        };
        availableSpecies.forEach((aS) => {
          if (aS in enrichedAurn) {
            if (enrichedAurn.averageOfLastHourOfData === null) {
              enrichedAurn.averageOfLastHourOfData = {};
            }
            enrichedAurn.averageOfLastHourOfData[aS] = enrichedAurn[aS]
              ? Math.round(enrichedAurn[aS])
              : null;
            delete enrichedAurn[aS];
          }
        });
        const {
          averageOfLastHourOfData: { NO2, PM2p5 },
        } = enrichedAurn;
        const AQIMax = aqiCalculator(NO2, PM2p5, aqiBands);
        enrichedAurn.AQI = AQIMax;
        enrichedAurn.averageOfLastHourOfData = {
          ...enrichedAurn.averageOfLastHourOfData,
          AQI: AQIMax,
        };
        return enrichedAurn;
      });
      return enrichedUserAurnList;
    };

    const addMetInfoToUsersZephyrs = async (userZephyrs: Zephyr[]) => {
      const packagedMetRequest = zephyrMetRequestPackager(userZephyrs);
      const externalMetData = await utilsRequest.getLatestMetDataForUser(
        packagedMetRequest as any,
      );
      const enrichedZephyrs = zephyrMetDataEnricher(
        externalMetData,
        userZephyrs,
      );
      return enrichedZephyrs;
    };

    const getUsersZephyrs = async () => {
      const userZephyrList = await utilsRequest.getZephyrs(bearerToken);
      const virtualZephyrList = await getUserVirtualZephyrs();
      const list = [...userZephyrList, ...sortAnArrayOfZephyrsByName(virtualZephyrList)];
      return list;
    };

    const getUsersZephyrsData = async (userZephyrList: any) => {
      // return [];
      let aurnsCalled: boolean = false;
      if (dataConfigRef.current) {
        const currentDT = utcConvertor(current());
        const backstopDT = utcConvertor(subtractTimeFromNow(2, 'hour'));
        const twoHourDt = utcConvertor(subtractTimeFromNow(2, 'hour'));
        const TwoHourDataConfig = {
          ...dataConfigRef.current,
          averagingChain: 1,
        };
        const availableSpecies = getAvailableSpeciesIdentifiers();
        if (userZephyrList.length === 0 && aurnsCalled === false) {
          aurnsCalled = true;
          aurnsList = await getUsersAurns();
        }
        const enrichedUserZephyrList: Zephyr[] = userZephyrList.map(
          async (userZephyr: Zephyr) => {
            let enrichedZephyr = {
              ...userZephyr,
              averageOfLastHourOfData: null,
            };
            const lastDataDT = userZephyr.lastDataDT
              ? utcConvertor(userZephyr.lastDataDT)
              : null;
            //If we have a connection and if the last connection is less than an hour old
            const cleanData =
              lastDataDT && utcConvertor(userZephyr.lastDataDT) < backstopDT;

            if (
              !userZephyr.userEndTimeDate ||
              userZephyr.type === ZephyrTypes.virtual
            ) {
              if (aurnsCalled === false) {
                aurnsCalled = true;
                aurnsList = await getUsersAurns();
              }
              //used to be cleanData variable, but I think the logic might be flawed?
              if (true) {
                let userZephyrLastHour = null;

                if (userZephyr.type === ZephyrTypes.virtual) {
                  const { latitude, longitude } = userZephyr;
                  const currentDate = new Date();
                  const dateStart = currentDate.setHours(
                    currentDate.getHours() - 1,
                  ); // lasthour

                  userZephyrLastHour = await utilsRequest.getDataAtCoords(
                    latitude,
                    longitude,
                    new Date(dateStart).toISOString(),
                    currentDate.toISOString(),
                    bearerToken,
                    'NO2,PM2p5,windspeed',
                  );
                  if (userZephyrLastHour.results) {
                    const { results } = userZephyrLastHour;
                    const averageOfLastHourOfData: any = {};

                    //only grab data from results if call was successful
                    if (results) {
                      results.map((spec: any) => {
                        averageOfLastHourOfData[spec.species] =
                          spec.values_coordinates[0].values_timestamps[0].value;
                      });
                    }
                    const { NO2, PM2p5 } = averageOfLastHourOfData;
                    const AQIMax = aqiCalculator(NO2, PM2p5, aqiBands);
                    averageOfLastHourOfData.AQI = AQIMax;
                    enrichedZephyr = {
                      ...userZephyr,
                      averageOfLastHourOfData,
                    };
                    return enrichedZephyr;
                  }
                }
                const zephyrLastConnectionTime: any = utcConvertor(
                  userZephyr.lastConnectionDT,
                );
                const slotsData = userZephyr.slotA_SN ?? userZephyr.slotB_SN;
                const isCurData =
                  zephyrLastConnectionTime.slice(0, 8) == currentDT.slice(0, 8);
                const isDifLessThanTwoHours =
                  parseInt(currentDT.slice(8, 10)) -
                  parseInt(zephyrLastConnectionTime.slice(8, 10)) <
                  3;
                if (slotsData && isCurData && isDifLessThanTwoHours) {
                  userZephyrLastHour = await utilsRequest.getZephyrMeasurementData(
                    userZephyr,
                    twoHourDt,
                    currentDT,
                    TwoHourDataConfig,
                    bearerToken,
                    location.pathname === '/',
                  );
                  const userZephyrLastHourData = userZephyrLastHour.data;
                  const packagedUserZephyrLastHourData:
                    | ZephyrLastHourInd
                    | any = {};
                  if (
                    userZephyrLastHourData &&
                    userZephyrLastHourData['Hourly average on the hour']
                  ) {
                    availableSpecies.forEach((aS) => {
                      let aSValues: number[] = [];
                      slots.forEach((s) => {
                        const slotValue =
                          userZephyrLastHourData['Hourly average on the hour'][
                            s.label
                          ] &&
                            userZephyrLastHourData['Hourly average on the hour'][
                            s.label
                            ][aS] &&
                            userZephyrLastHourData['Hourly average on the hour'][
                              s.label
                            ][aS].data
                            ? userZephyrLastHourData[
                              'Hourly average on the hour'
                            ][s.label][aS].data
                            : null;
                        if (slotValue) {
                          aSValues = slotValue;
                        }
                      });
                      const averagedASValues = aSValues.length
                        ? Math.round(
                          aSValues.reduce((a, b) => a + b) / aSValues.length,
                        )
                        : null;
                      const speciesLabel = getSpeciesLabelFromSpeciesIdentifier(
                        aS,
                      );
                      if (!(speciesLabel in packagedUserZephyrLastHourData)) {
                        packagedUserZephyrLastHourData[
                          speciesLabel
                        ] = averagedASValues;
                      }
                    });
                    const AQIMax = aqiCalculator(
                      packagedUserZephyrLastHourData.NO2,
                      packagedUserZephyrLastHourData.Pm2p5,
                      aqiBands,
                    );
                    packagedUserZephyrLastHourData.AQI = AQIMax;
                    enrichedZephyr = {
                      ...enrichedZephyr,
                      averageOfLastHourOfData: packagedUserZephyrLastHourData,
                    };
                  }
                }
                return enrichedZephyr;
              }
              return enrichedZephyr;
            }
            return enrichedZephyr;
          },
        );

        return Promise.all(enrichedUserZephyrList);
      }
      return [];
    };

    const getInitZephyrList = async (isPublic: boolean) => {
      // Get users zephyrs list
      const userZephyrsList = await getUsersZephyrs();
      // Add names
      const userZephyrsListWithNames = userZephyrsList.map((sUN) => {
        return {
          ...sUN,
          slotsInfo: null,
          name: sUN.alias || sUN.name || sUN.zNumber?.toString(),
        };
      });

      // Sort Zephyrs
      const sortedUserZephyrsList = zephyrSorter(
        userZephyrsListWithNames,
        isPublic,
      );
      return sortedUserZephyrsList;
    };

    const getUnits = async (userZephyrList: Zephyr[]) => {
      const userZephyrsWithData = await getUsersZephyrsData(userZephyrList);

      // Combine Zephyrs and AURNs
      const userZephyrs = [
        ...userZephyrsWithData,
        ...aurnsList,
        // ...virtualZephyrs,
      ];
      // Sort the combined list
      const sortedUnits = zephyrSorter(userZephyrs, userInfo.isPublic);
      return sortedUnits;
    };

    const addUnitList = async (isPolling: boolean, unitList: Zephyr[]) => {
      if (!ignore && isAuthenticated && unitList.length) {
        setZephyrs(unitList);
        if (!isPolling) {
          const curZephyr = unitList[0];
          setZephyr(curZephyr);
          if (
            defaultLocation === userLocationRef.current &&
            !curZephyr.userEndTimeDate
          ) {
            setUnitLocation(curZephyr.longitude, curZephyr.latitude);
          }
        }
      }
    };

    const setUnits = async (isPolling: boolean, isPublic: boolean) => {
      const initZephyrList = await getInitZephyrList(isPublic);
      if (!isPolling && !isPublic && initZephyrList.length !== 0) {
        await addUnitList(isPolling, initZephyrList);
        await loadingInitAppDataOn();
      }
      const userUnits = await getUnits(initZephyrList);
      if (userUnits.length !== 0) {
        await addUnitList(isPolling, userUnits);
      } else if (
        overlays.length &&
        overlays[0].name &&
        overlays.filter((ol: Overlay) => ol.isMappairOverlay).length
      ) {
        // turn on MappAir
        await mappAirFilters();
        await showOverlayLayer();
      } else {
        //If we have no units and we aren't told to automatically be in mappair overlay
        //We set an empty array of zephyrs so the user can continue
        setZephyrs([]);
      }
      await loadingInitAppDataOff();
    };

    if (isAuthenticated) {
      launch();
    }

    return () => {
      ignore = true;
    };
  }, [isAuthenticated]);

  // remove versions from local storage after reloading the page

  useEffect(() => {
    window.addEventListener('beforeunload', updateStatesAfterReloading);
    return () => {
      window.removeEventListener('beforeunload', updateStatesAfterReloading);
    };
  }, []);

  const removeVersionsFromLocalStorage = () => {
    if (localStorage.getItem('Versions')) localStorage.removeItem('Versions');
  };

  const removeAlertsFromLocalStorage = () => {
    if (localStorage.getItem('alerts')) localStorage.removeItem('alerts');
  };

  const updateStatesAfterReloading = () => {
    removeVersionsFromLocalStorage();
    removeAlertsFromLocalStorage();
  };

  const getVZephyrWithDefaultData = (vzCreated: any) => {
    // if end date is null add 1 month to prevent ui break
    const currentDate = new Date();
    const userEndTimeDate =
      vzCreated.endDt ||
      vzCreated.end_dt ||
      new Date(currentDate.setMonth(currentDate.getMonth() + 1)).toISOString();
    const { species_keywords } = vzCreated;
    const speciesList: string[] = species_keywords.map((el: { species: string, keyword: string | null }) => {
      const { species } = el;
      if (species.includes('pm')) return 'PM';
      return species.toUpperCase();
    });
    const item: Zephyr = {
      id_pod: vzCreated.id,
      name: vzCreated.name,
      type: 100,
      userEndTimeDate,
      userStartTimeDate: vzCreated.startdt || vzCreated.start_dt,
      latitude: vzCreated.latlon?.latitude,
      longitude: vzCreated.latlon?.longitude,
      zNumber: vzCreated.id,
      HasCertificate: null,
      averageOfLastHourOfData: null,
      SampPeriod: vzCreated.sampleFreqMins || vzCreated.sample_freq_mins,
      alias: vzCreated.name,
      backLogCount: null,
      batteryCharge: null,
      batteryVoltage: null,
      externalMetData: null,
      externalVoltage: null,
      firmwareVersion: 'Virtual',
      id_location: null,
      lastConnectionDT: null,
      lastDataDT: null,
      latLngAltered: null,
      location: '',
      locationStartTimeDate: null,
      locationType: null,
      measurementCount: null,
      mobileLocation: null,
      serialNumber: 'virtual',
      slotA_SN: null,
      slotA_start: null,
      slotB_SN: null,
      slotB_start: null,
      slotsInfo: { slotA: null, slotB: null, key: null },
      species: speciesList.includes('PM') ? Array.from(new Set(speciesList)) : speciesList,
    };

    return item;
  };

  const getUserVirtualZephyrs = async () => {
    let virtualZephyrList:
      | VirtualZephyr[]
      | any = await utilsRequest.getVZephyrs(bearerToken);
    // use only subscribed one
    virtualZephyrList = Array.isArray(virtualZephyrList)
      ? virtualZephyrList.filter(
        (vz) => vz.end_dt === null || new Date(vz.end_dt) > new Date(),
      )
      : 'vzephyrs' in virtualZephyrList
        ? virtualZephyrList.vzephyrs?.filter(
          (vz: VirtualZephyr) =>
            vz.end_dt === null || new Date(vz.end_dt) > new Date(),
        )
        : [];

    const vZephyrDataList = Array.isArray(virtualZephyrList)
      ? virtualZephyrList.map((vz) => {
        const item = getVZephyrWithDefaultData(vz);
        return item;
      })
      : virtualZephyrList.vzephyrs.map((vz: any) => {
        const item = getVZephyrWithDefaultData(vz);
        return item;
      });

    return Promise.all(vZephyrDataList);
  };

  const setBannerWithExpiryTime = (key: string, value: string[]) => {
    const item = {
      value: value,
    };
    setBannerValue(value);
    localStorage.setItem(key, JSON.stringify(item));
  };

  const verifyBannerExpiryTime = (key: string, value: string[]) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) return;
    setBannerValue(value);
    setIsBannerShowing(true);
    setIsModalOpened(true);
  };

  const addBannerToLocalStorage = () => {
    const isBannerShowingToString = (!isBannerShowing).toString();
    localStorage.setItem('toShowBanner', isBannerShowingToString);
    setBannerWithExpiryTime('banner', bannerText);
    setIsBannerShowing(true);
    setIsModalOpened(true);
  };

  const updateBannerStatus = () => {
    verifyBannerExpiryTime('banner', bannerText);
    const isBannerExpired = (!!localStorage.getItem('banner')).toString();
    localStorage.setItem('toShowBanner', isBannerExpired!);
  };

  const freeLocalStorageFromBannerProperties = () => {
    if (localStorage.getItem('toShowBanner'))
      localStorage.removeItem('toShowBanner');
    if (localStorage.getItem('banner')) localStorage.removeItem('banner');
  };

  useEffect(() => {
    if (isAuthenticated && !userInfo.isPublic) {
      const curDate = new Date();
      if (curDate >= startDate && curDate <= endDate) {
        if (!localStorage.getItem('toShowBanner')) {
          addBannerToLocalStorage();
          return;
        }
        if (
          localStorage.getItem('toShowBanner') &&
          localStorage.getItem('toShowBanner') === 'true'
        )
          updateBannerStatus();
      } else {
        freeLocalStorageFromBannerProperties();
      }
    }
  }, [isAuthenticated]);

  return (
    <div className="App app-container">
      <Spinner
        additionalClass="see-through initial-load"
        on={!location.pathname.includes('login') && loading}
      />
      {userLoading && !location.pathname.includes('login') ? (
        <></>
      ) : (
        <>
          {!location.pathname.includes('login') ? (
            <>
              {/* <CookiePermissionBanner /> */}
              <Header vZephyrsConfig={vZephyrsConfig} />
            </>
          ) : (
            <></>
          )}
          <Alerts />
          <Switch>
            <Route
              exact
              path={routePaths.login}
              render={(routeProps) =>
                isAuthenticated ? (
                  <Redirect to={routePaths.app} />
                ) : (
                  <Login
                    basename={basename}
                    setAuthReq={setAuthReq}
                    {...routeProps}
                  />
                )
              }
            />
            <Route
              exact
              path={routePaths.app}
              render={(routeProps) =>
                basename && basename !== 'internal' ? (
                  userLocation !== defaultLocation ? (
                    <Location
                      {...routeProps}
                      vZephyrsConfig={vZephyrsConfig}
                      getUserVirtualZephyrs={getUserVirtualZephyrs}
                      isBannerShowing={true}
                      isModalOpened={isModalOpened}
                      setIsModalOpened={setIsModalOpened}
                      bannerValue={bannerValue}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  ) : (
                    <></>
                  )
                ) : !localStorage.getItem('token') ||
                  localStorage.getItem('isPublic') === 'true' ? (
                  <Redirect to={routePaths.login} />
                ) : (
                  <Location
                    {...routeProps}
                    vZephyrsConfig={vZephyrsConfig}
                    getUserVirtualZephyrs={getUserVirtualZephyrs}
                    isBannerShowing={isBannerShowing}
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                    bannerValue={bannerValue}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )
              }
            />

            <Route
              path={routePaths.data}
              render={(routeProps) =>
                zephyrSet ||
                  routeProps.location.search.includes('annualavg') ? (
                  <Data
                    {...routeProps}
                    isDSEEnabled={isDSEEnabled}
                    setIsDSEEnabled={setIsDSEEnabled}
                  />
                ) : (
                  <Redirect to={routePaths.app} />
                )
              }
            />

            <Route
              path={routePaths.analytics}
              render={(routeProps) =>
                zephyrSet ? (
                  // <Analytics {...routeProps} />
                  <Analytics />
                ) : (
                  <Redirect to={routePaths.app} />
                )
              }
            />
            <Route
              path={routePaths.today}
              render={(routeProps) =>
                wideScreenFinder(800) && tallScreenFinder(500) ? (
                  <Redirect to={routePaths.app} />
                ) : (
                  <Today {...routeProps} />
                )
              }
            />
            <Route
              path={routePaths.alerts}
              render={(routeProps) => 
                isAuthenticated ? (
                <AirAlerts {...routeProps} />
              ) : (
                <Redirect to={routePaths.login} />
              )}  
            />
            <Route
              path={routePaths.fleetManagement}
              render={(routeProps) => 
                isAuthenticated ? (
                  <FleetManagement zephyrs={zephyrs} {...routeProps} />
                ) : (
                  <Redirect to={routePaths.login} />
              )}
            />
            {(localStorage.getItem('user') === 'BP_Alerting' ||
              localStorage.getItem('user') === 'BP_Alerting_Alternative') && (
                <Route
                  path={routePaths.smsAlerts}
                  render={(routeProps) => (
                    <SmsGroupAlerts
                      {...routeProps}
                      tableData={tableData}
                      setTableData={setTableData}
                      csvData={csvData}
                      setCsvData={setCsvData}
                      fileName={fileName}
                      setFileName={setFileName}
                      bearerToken={bearerToken}
                    />
                  )}
                />
              )}
            {/* <Route
              path={routePaths.dataAnalytics}
              render={(routeProps) =>
                zephyrSet ? (
                  <DataAnalyticsContainer {...routeProps} />
                ) : (
                  <Redirect to={routePaths.app} />
                )
              }
            /> */}
            <Route
              path={routePaths.dataAnalytics}
              render={(routeProps) => 
                isAuthenticated ? (
                <DataAnalyticsContainer {...routeProps} />
              ) : (
                <Redirect to={routePaths.login} />
              )}
            />
          </Switch>
          <UserTour />
        </>
      )}
    </div>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  dataConfig: state.dataConfig,
  displayConfig: state.setDisplayConfig,
  isAuthenticated: state.auth.isAuthenticated,
  feedback: state.feedback,
  loading: state.getZephyrs.loading,
  loginFailed: state.auth.loginFailed,
  overlays: state.setOverlays,
  userInfo: state.auth.userInfo,
  userLocation: state.setLocation.location,
  userLoading: state.auth.loading,
  zephyrs: state.getZephyrs.zephyrs,
  zephyrSet: state.setZephyr.set,
  modularFeatures: state.modularFeatures,
  bearerToken: state.auth.bearerToken,
  isCartridgeDataLoading: state.unitHistoriesOptions.isCartridgeDataLoading
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      loadingInitAppDataOff,
      loadingInitAppDataOn,
      loadingInitAppThirdPartyDataOff,
      loadingInitAppThirdPartyDataOn,
      loadingInitAppOff,
      loadingInitAppOn,
      logout,
      mappAirFilters,
      queryStringParams,
      setAirAlerts,
      setAlerts,
      setAurns,
      setAveragingPeriods,
      setDataConfig,
      setDisplayConfig,
      setFailedLogin,
      setFeedback,
      setFeedbackOn,
      setPublicAdvice,
      setSelectedFilters,
      setOverlays,
      setStylegroups,
      setThreshold,
      setTimePeriods,
      setTodayData,
      setUnitLocation,
      setUserConfig,
      setUserInfo,
      setVersions,
      setZephyr,
      setZephyrs,
      showOverlayLayer,
      changeOverlayLayer,
      setMasterAuthBearerToken,
      setNewModularFeaturesState,
      setIsCartridgeDataLoading,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
