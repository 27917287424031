// Types Imports
import {
  SET_CONTACT_MODAL_ON,
  SET_DATA_MODAL_ON,
  SET_MAPPAIR_QUOTE_MODAL_ON,
  SET_MAPPAIR_TIMESERIES_MODAL_ON,
  SET_MODALS_OFF,
  SET_ZEPHYR_QUOTE_MODAL_ON,
} from '../actions/types';

// Type Imports
import { Modal } from '../utils/interface';

// Type Safety
interface ModalReducer {
  type:
    | typeof SET_CONTACT_MODAL_ON
    | typeof SET_DATA_MODAL_ON
    | typeof SET_MAPPAIR_QUOTE_MODAL_ON
    | typeof SET_MAPPAIR_TIMESERIES_MODAL_ON
    | typeof SET_MODALS_OFF
    | typeof SET_ZEPHYR_QUOTE_MODAL_ON;
}
// State
const initState: Modal = {
  contact: false,
  data: false,
  mappairQuote: false,
  mappairTimeseries: false,
  zephyrQuote: false,
};

// Reducer
export default function (state = initState, action: ModalReducer) {
  const { type } = action;

  switch (type) {
    case SET_CONTACT_MODAL_ON:
      return {
        ...initState,
        contact: true,
      };

    case SET_DATA_MODAL_ON:
      return {
        ...initState,
        data: true,
      };

    case SET_MAPPAIR_QUOTE_MODAL_ON:
      return {
        ...initState,
        mappairQuote: true,
      };

    case SET_MAPPAIR_TIMESERIES_MODAL_ON:
      return {
        ...initState,
        mappairTimeseries: true,
      };

    case SET_MODALS_OFF:
      return initState;

    case SET_ZEPHYR_QUOTE_MODAL_ON:
      return {
        ...initState,
        zephyrQuote: true,
      };

    default:
      return state;
  }
}
