// Type Imports
import { SET_TIME_PERIODS } from '../actions/types';

// Types Imports
import { TimePeriods } from '../utils/interface';

// Type Safety
interface SetTimePeriods {
  type: typeof SET_TIME_PERIODS;
  payload: TimePeriods;
}

// State
const initState: TimePeriods = { periods: [] };

// Reducer
export default function (state = initState, action: SetTimePeriods) {
  const { type, payload } = action;

  switch (type) {
    case SET_TIME_PERIODS:
      return payload;

    default:
      return state;
  }
}
