// Package Imports
import React from 'react';

// TypeImports
import { useLocation } from 'react-router-dom';
import { Zephyr } from '../../utils/interface';

// Hooks

// Component Interfaces
interface PublicAsideUnitProps {
  dataLoading: boolean;
  mappairPointMode: boolean;
  prevUnit: Zephyr | null;
  selectUnit: Function;
  selectedUnit: number;
  setColour: Function;
  unit: Zephyr;
}

// Component
const PublicAsideUnit = ({
  dataLoading,
  mappairPointMode,
  prevUnit,
  selectUnit,
  selectedUnit,
  setColour,
  unit,
}: PublicAsideUnitProps) => {
  // Consts
  const location = useLocation();

  return (
    <>
      {prevUnit && prevUnit.type !== unit.type ? (
        <div className="unit-divider" />
      ) : (
        <></>
      )}
      <li
        key={unit.zNumber}
        className={`public-unit-card ${
          selectedUnit === unit.zNumber ? 'active' : ''
        }`}
      >
        <div
          className={
            selectedUnit === unit.zNumber &&
            (!mappairPointMode || location.pathname.includes('data'))
              ? unit.type === 0 && !unit.userEndTimeDate
                ? 'beacon-button active'
                : 'beacon-button aurn-active'
              : unit.type === 0 && !unit.userEndTimeDate
              ? 'beacon-button'
              : 'beacon-button no-toggle'
          }
          style={{
            boxShadow: `inset 5px 0px 0px 0px ${setColour(unit)}`,
          }}
          onClick={() => {
            if (!dataLoading && selectedUnit !== unit.zNumber) {
              selectUnit(unit);
            }
          }}
        >
          <header>
            <h4>{unit.name}</h4>
          </header>
        </div>
      </li>
    </>
  );
};

export default PublicAsideUnit;
