// Package Imports
import React from 'react';
import YouTube from 'react-youtube';

// Const Imports
import { headerTutorialModalContent } from '../../utils/consts';

// Component Interfaces
interface HeaderTutorialModalProps {
  handleTutorialDialog: Function;
}

// Component
const HeaderTutorialModal = ({
	handleTutorialDialog,
}: HeaderTutorialModalProps) => (
	<div className="tutorial-modal-outter">
		<h1 className="heading">Tutorials</h1>
		<div className="d-flex" style={{ gap: 32 }}>
			<div style={{ flex: 1 }}>
				<p className="video-title">Beginners Guide</p>
				<p className="video-description">MyAir® Beginners Tutorial</p>
				<YouTube
					videoId="-rlbofEylz0"
					opts={{
						height: "240",
						width: "427",
						playerVars: {
							autoplay: 0,
						},
					}}
					onEnd={() => handleTutorialDialog(false)}
				/>
			</div>

			<div className="d-flex flex-column" style={{ flex: 1 }}>
				<p className="video-title">Data Analytics Guide</p>
				<p className="video-description">
					Get the most out of your Air Quality data
				</p>
				<YouTube
					videoId="QLoR-QteSVY"
					opts={{
						height: "240",
						width: "427",
						playerVars: {
							autoplay: 0,
						},
					}}
					onEnd={() => handleTutorialDialog(false)}
				/>
			</div>
		</div>

		<button
			className="button close close-modal"
			onClick={() => handleTutorialDialog(false)}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="13"
				height="13"
				viewBox="0 0 24 24"
				fill="#000"
			>
				<path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
			</svg>
		</button>
	</div>
);

export default HeaderTutorialModal;
