// Types Imports
import {
  SET_FEEDBACK,
  SET_FEEDBACK_IDX,
  SET_FEEDBACK_OFF,
  SET_FEEDBACK_ON,
} from '../actions/types';
import { Feedback, FeedbackQuestion } from '../utils/interface';

// Type Safety
interface SetAlerts {
  type: typeof SET_FEEDBACK;
  payload: FeedbackQuestion[];
}

// State
const initState: Feedback = {
  idx: 0,
  on: false,
  oneTimeAutomation: true,
  questions: [],
};

// Reducer
export default function (state = initState, action: SetAlerts) {
  const { type, payload } = action;

  switch (type) {
    case SET_FEEDBACK_IDX:
      return {
        ...state,
        idx: payload,
      };

    case SET_FEEDBACK_OFF:
      return {
        ...state,
        on: false,
        oneTimeAutomation: false,
      };

    case SET_FEEDBACK_ON:
      return {
        ...state,
        on: true,
        oneTimeAutomation: false,
      };

    case SET_FEEDBACK:
      return {
        ...state,
        questions: payload,
      };

    default:
      return state;
  }
}
