// Types Imports
import { Dispatch } from 'redux';
import {
  SET_FEEDBACK,
  SET_FEEDBACK_IDX,
  SET_FEEDBACK_OFF,
  SET_FEEDBACK_ON,
} from './types';

// Type Imports
import { FeedbackQuestion } from '../utils/interface';

// Action Creators
export const setFeedback = (feedback: FeedbackQuestion[]) => (
  dispatch: Dispatch,
) => {
  dispatch({
    type: SET_FEEDBACK,
    payload: feedback,
  });
};

export const setFeedbackIdx = (idx: number) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_FEEDBACK_IDX,
    payload: idx,
  });
};

export const setFeedbackOff = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_FEEDBACK_OFF,
  });
};

export const setFeedbackOn = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_FEEDBACK_ON,
  });
};
