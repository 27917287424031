import React from 'react';
import { csvHeaders } from '../../utils/consts';


interface FleetManagementHeadersProps {
    table: React.MutableRefObject<any>
}

const FleetManagementHeaders = ({ table }: FleetManagementHeadersProps) => {

    const extractBottomHeaders = () => {
        return csvHeaders.map((csvHeader: string, idx: number) => <th key={idx}>{csvHeader}</th>);
    };

    return (
        <table id="table_id" className="cell-border compact hover order-column row-border stripe" ref={table}>
            <tfoot>
                <tr>
                    <th></th>
                    {extractBottomHeaders()}
                </tr>
            </tfoot>
        </table>
    )
};

export default FleetManagementHeaders;