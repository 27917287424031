import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import { TRANSLATIONS_EN } from './en/translations';
import { TRANSLATIONS_ES } from './es/translations';
import { TRANSLATIONS_ZH } from './zh/translations';

// http://localhost:3000/login/?lng=es
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      // order and from where user language should be detected
      order: [
        'localStorage',
        'querystring',
        'cookie',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      fallbackLng: 'en', // https://www.i18next.com/principles/fallback#fallback-to-different-languages:~:text=Copied!-,Fallback%20to%20different%20languages,-If%20you%20can
      interpolation: {
        format: function (value, format, lng) {
          if (value instanceof Date) return moment(value).format(format);
          if (typeof value === 'number')
            return new Intl.NumberFormat().format(value);
          return value;
        },
      },
      resources: {
        en: {
          translation: TRANSLATIONS_EN,
        },
        es: {
          translation: TRANSLATIONS_ES,
        },
        zh: {
          translation: TRANSLATIONS_ZH,
        },
      },
    },
    (err, t) => {
      if (err)
        return console.error('something went wrong loading translator', err);
      t('key'); // -> same as i18next.t
    },
  );

// i18n.changeLanguage('en');

export { i18n };
