// Package Imports
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Component Imports
import Confirmation from '../shared_components/Confirmation';
import Dropdown from '../shared_components/Dropdown';

// Async Imports
import utilsRequest from '../../utils/request';

// Const Imports
import {
  defaultZephyrForm,
  detailsContactFormLookup,
  zephyrMeasurementOptions,
  ZephyrQuoteModalContent,
  zephyrPowerOptions,
} from '../../utils/consts';

// Type Imports
import { ContactFormSelection, ContactFormZephyr, ReduxState } from '../../utils/interface';
import { connect } from 'react-redux';

// Component Interfaces
interface InitState {
  confirmationScreen: boolean;
}

interface ZephyrQuoteProps {
  contactFormOptions: ContactFormZephyr;
  setModalsOff: Function;
  bearerToken: string | null;
}

// Component
const ZephyrQuoteModal = ({
  contactFormOptions,
  setModalsOff,
  bearerToken,
}: ZephyrQuoteProps) => {
  const { t: translate } = useTranslation();

  // State
  const initState: InitState = {
    confirmationScreen: false,
  };
  const [confirmationScreen, setConfirmationScreen] = useState(
    initState.confirmationScreen,
  );
  const [formData, setFormData] = useState(contactFormOptions.formData);
  const [userMessage, setUserMessage] = useState(
    contactFormOptions.userMessage,
  );

  // Functions
  const addMeasurement = (measurement: ContactFormSelection) => {
    const updatedMeasurementsList = [...formData.species, measurement];
    setFormData({ ...formData, species: updatedMeasurementsList });
  };

  const handleDropdown = (id: string, selected: string) => {
    const updatedFormData = { ...formData, [id]: selected };
    setFormData(updatedFormData);
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      company,
      zephyrs,
      powerOption,
      species,
    } = formData;
    if (firstName && lastName && email && company) {
      const enquiry = 'Sales';
      const details = detailsContactFormLookup.Sales[0].value;
      const speciesList = species.map((sp) => `${sp.label} `);
      const comments = `Zephyrs requested: ${zephyrs}, Power option selected: ${powerOption}, Species: ${speciesList}`;
      const formSubmission = {
        firstName,
        lastName,
        email,
        company,
        enquiry,
        details,
        comments,
      };
      const response = await utilsRequest.submitContactForm(formSubmission, bearerToken);
      if (response) {
        setFormData(defaultZephyrForm.formData);
        setConfirmationScreen(true);
      } else {
        setUserMessage(translate('ZephyrContactMessageProblem'));
      }
    } else {
      setUserMessage(translate('ZephyrContactMessageMissingFields'));
    }
  };

  const removeMeasurement = (measurement: ContactFormSelection) => {
    const updatedMeasurementsList = formData.species.filter(
      (s) => s.value !== measurement.value,
    );
    setFormData({ ...formData, species: updatedMeasurementsList });
  };

  return (
    <>
      {confirmationScreen ? (
        <Confirmation
          confirmationOpen={setModalsOff}
          header={translate('ZephyrConfirmationHeader')}
          showTick
          text={translate('ZephyrConfirmationText')}
        />
      ) : (
        <div className="modal-container zephyr-quote-modal-container">
          <h4>{translate('ZephyrContactHeader')}</h4>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="form-content-container">
              <div className="row">
                <div className="col-12 col-md-6">
                  <label className="contact-form__label" htmlFor="firstName">
                    {translate('ZephyrContactFirstName')}
                  </label>
                  <input
                    className="contact-form__input"
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={(e) =>
                      setFormData({ ...formData, firstName: e.target.value })
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label className="contact-form__label" htmlFor="lastName">
                    {translate('ZephyrContactLastName')}
                  </label>
                  <input
                    className="contact-form__input"
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={(e) =>
                      setFormData({ ...formData, lastName: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label className="contact-form__label" htmlFor="email">
                    {translate('ZephyrContactEmail')}
                  </label>
                  <input
                    className="contact-form__input"
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label className="contact-form__label" htmlFor="company">
                    {translate('ZephyrContactCompany')}
                  </label>
                  <input
                    className="contact-form__input"
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={(e) =>
                      setFormData({ ...formData, company: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label className="contact-form__label" htmlFor="zephyrs">
                    {translate('zephyrs')}
                  </label>
                  <input
                    className="contact-form__input"
                    type="number"
                    name="zephyrs"
                    value={formData.zephyrs}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        zephyrs: parseInt(e.target.value),
                      })
                    }
                  />
                </div>
                <div className="col">
                  <label className="contact-form__label" htmlFor="powerOption">
                    {translate('powerOptions')}
                  </label>
                  <Dropdown
                    additionalClass="custom-dropdown-enquiry-container"
                    currentSelectionValue={formData.powerOption}
                    handleSelection={handleDropdown}
                    placeholderSelectionLabel="Select"
                    id="powerOption"
                    selections={zephyrPowerOptions}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <label className="contact-form__label" htmlFor="measurements">
                    {translate('measurements')}
                  </label>
                  <div>
                    <ul className="air-alert-configurator-unit-list zephyr-quote-measurements">
                      {formData.species.map((s) => (
                        <li key={s.value}>
                          <div>{s.label}</div>
                          <button
                            type="button"
                            onClick={() => removeMeasurement(s)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="0 0 24 24"
                              fill="#fff"
                            >
                              <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
                            </svg>
                          </button>
                        </li>
                      ))}
                    </ul>
                    {zephyrMeasurementOptions.filter(
                      (zMO) =>
                        formData.species.filter(
                          (fDs) => fDs.value === zMO.value,
                        ).length === 0,
                    ).length > 0 ? (
                      <ul className="air-alert-configurator-unit-list add-units zephyr-quote-add-unit-list">
                        {zephyrMeasurementOptions
                          .filter(
                            (zMO) =>
                              formData.species.filter(
                                (fDs) => fDs.value === zMO.value,
                              ).length === 0,
                          )
                          .map((s) => (
                            <li key={s.value}>
                              <div>{s.label}</div>
                              <button
                                type="button"
                                onClick={() => addMeasurement(s)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 24 24"
                                  fill="#FFF"
                                >
                                  <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
                                </svg>
                              </button>
                            </li>
                          ))}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col text-left validation-dialog">
                <p className="air-alerts-error-item">
                  {userMessage || translate('ZephyrQuoteRequiredFields')}
                </p>
              </div>
            </div>
            <button type="submit" className="button primary send-modal">
              {translate('ZephyrContactButton')}
            </button>
          </form>
          <button
            className="button close close-modal"
            onClick={() => setModalsOff()}
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 24 24"
              fill="#000"
            >
              <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
            </svg>
          </button>
        </div>
      )}
    </>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  bearerToken: state.auth.bearerToken,
});

export default connect(mapStateToProps, {})(ZephyrQuoteModal);