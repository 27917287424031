import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import { isStringDefinedAndNotEmpty } from './utils/functions/general.ts';
import {config as dotenvConfig} from './utils/consts';



// Load .env variables and override with local environment variables if they exist
const _sentryEnable = process.env.REACT_APP_SENTRY_ENABLED_LOCAL ? process.env.REACT_APP_SENTRY_ENABLED_LOCAL : dotenvConfig.sentryENABLED;
const _sentryDSN = process.env.REACT_APP_SENTRY_DSN_LOCAL ? process.env.REACT_APP_SENTRY_DSN_LOCAL : dotenvConfig.sentryDSN;
const _sentryRelease = process.env.REACT_APP_SENTRY_RELEASE_LOCAL ? process.env.REACT_APP_SENTRY_RELEASE_LOCAL : dotenvConfig.sentryRELEASE;
const _sentryEnvironment = process.env.REACT_APP_SENTRY_ENVIRONMENT_LOCAL ? process.env.REACT_APP_SENTRY_ENVIRONMENT_LOCAL : dotenvConfig.sentryENVIRONMENT;


// Sentry
if (_sentryEnable === true || _sentryEnable === 'true') { 
  if (isStringDefinedAndNotEmpty(_sentryDSN) 
      && isStringDefinedAndNotEmpty(_sentryRelease) 
      && isStringDefinedAndNotEmpty(_sentryEnvironment)) {
    Sentry.init({
      dsn: _sentryDSN,
      release: _sentryRelease, 
      environment: _sentryEnvironment,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      replaysSessionSampleRate: 0.03, // Session Replay
      replaysOnErrorSampleRate: 1.0, // Session Replay
      tracesSampleRate: 1.0, // Performance Monitoring
    });
  }
};



ReactDOM.render(
  <Sentry.ErrorBoundary fallback={'An error has occurred'}>
    <App />
  </Sentry.ErrorBoundary>, document.getElementById('root'));

serviceWorker.unregister();
