// Package Imports
import { combineReducers } from 'redux';

// Reducer Imports
import airAlerts from './airAlerts';
import alerts from './alerts';
import aq101 from './aq101';
import aside from './aside';
import auth from './auth';
import dataConfig from './dataConfig';
import draggedUnit from './draggedUnit';
import feedback from './feedback';
import getStyleGroups from './getStyleGroups';
import getZephyrs from './getZephyrs';
import getZephyrHistoricalData from './getZephyrHistoricalData';
//import getZephyrMeasurementData from './getZephyrMeasurementData';
import loading from './loading';
import mappAirFilters from './mappAirFilters';
import mapsLocationLoaded from './mapsLocationLoaded';
import modal from './modal';
import publicAdvice from './publicAdvice';
import queryStringParams from './queryStringParams';
import showAQMALayer from './showAQMQLayer';
import setAveragingPeriods from './setAveragingPeriods';
import setDisplayConfig from './setDisplayConfig';
import setLocation from './setLocation';
import setOverlays from './setOverlays';
import setThresholdTab from './setThresholdTab';
import setTimePeriods from './setTimePeriods';
import setTodayData from './setTodayData';
import setUnitLocation from './setLocation';
import setUserConfig from './setUserConfig';
import setVersions from './setVersions';
import setLocale from './setLocale';
import setZephyr from './setZephyr';
import tour, { AnalysisTour, selectChartModeOpen } from './tour';
import setAnnualAverages from './annualAverage';
import setMapAirPointValueHistory from './mapAirPointValueHistory';
import unitHistoriesOptions from './dataAnalytics';
import thresholdsReducer from './thresholds';
import selectedChartViewMode from './chartViewMode';


// Types Imports
import { LOGOUT } from '../actions/types';
import modularFeatures from './modularFeatures';

// Type Safety
interface Logout {
  type: typeof LOGOUT;
}

const appReducer = combineReducers({
  airAlerts,
  alerts,
  aq101,
  aside,
  auth,
  dataConfig,
  draggedUnit,
  feedback,
  getStyleGroups,
  getZephyrs,
  getZephyrHistoricalData,
  loading,
  mappAirFilters,
  mapsLocationLoaded,
  modal,
  publicAdvice,
  queryStringParams,
  showAQMALayer,
  setAveragingPeriods,
  setDisplayConfig,
  setLocation,
  setOverlays,
  setTimePeriods,
  setTodayData,
  setThresholdTab,
  setUnitLocation,
  setUserConfig,
  setVersions,
  setLocale,
  setZephyr,
  tour,
  setAnnualAverages,
  setMapAirPointValueHistory,
  modularFeatures,
  unitHistoriesOptions,
  thresholds: thresholdsReducer,
  analysisTourOpen: AnalysisTour, selectChartModeOpen, selectedChartViewMode
});

const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: Logout,
) => {
  if (action.type === LOGOUT) {
    // reset state on logout
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
