// Package Imports
import { v4 as uuidv4 } from 'uuid';

// Type Imports
import {
  AlertMappedSpeciesExtent,
  AlertPeriod,
  AlertRepeat,
  AlertThreshold,
  DisplayConfig,
  SpeciesDataIdentifiers,
  SpeciesExtentIdentifiers,
  SpeciesExtentIndEnriched,
  ZephyrAlertUnit,
  SpeciesData,
  Zephyr
} from '../interface';
import { gaseousSlots } from '../consts';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';

/* Function
DESC: orderSpeciesExtent returns an orderd speciesExtent array according to displayConfig
ARGS: displayConfig (derived from the getDisplayConfig API call), species extent.
*/
const orderSpeciesExtent = (
  displayConfig: DisplayConfig,
  mappedSpeciesExtent: AlertMappedSpeciesExtent[],
) => {
  const orderedMappedSpeciesExtent: AlertMappedSpeciesExtent[] = [];
  displayConfig.tabs.forEach((tab) =>
    tab.species.forEach((species) =>
      mappedSpeciesExtent.forEach((mappedSpeciesExtent) => {
        if (mappedSpeciesExtent.dataIdentifier === species.dataIdentifier) {
          orderedMappedSpeciesExtent.push(mappedSpeciesExtent);
        }
      }),
    ),
  );
  return orderedMappedSpeciesExtent;
};

/* Function
DESC: mapSpeciesExtentToAlerts returns an array of mapped species extents with enriched properties of
      alerts. Unused species are added with their default settings.
ARGS: airAlerts, speciesExtent (created in the calling function).
*/
const mapSpeciesExtentToAlerts = (
  airAlerts: SpeciesData[],
  speciesExtent: SpeciesExtentIndEnriched[],
) => {
  const mappedSpeciesExtent: AlertMappedSpeciesExtent[] = [];
  speciesExtent.forEach((se) =>
    airAlerts.forEach((aa, idx) => {
      if (se.dataIdentifier === aa.Species) {
        // parse alertLevel to string
        const formattedAa: AlertMappedSpeciesExtent = {
          period: "",
          threshold: aa.Comparator as AlertThreshold,
          repeat: "",
          alertLevel:
            aa.Threshold != null ? aa.Threshold.toString() : '',
          dataIdentifier: se.dataIdentifier,
          HTMLLabel: se.HTMLLabel,
          HTMLShortUnitLabel: se.HTMLShortUnitLabel,
          HTMLUnitLabel: se.HTMLUnitLabel,
          id: aa.Species,
          speciesExtentIdentifier: se.speciesExtentIdentifier,
          status: false
        };
        const enrichedSpeciesExtent = {
          ...formattedAa,
          ...se,
          id: uuidv4(),
          status: true,
        };
        //delete enrichedSpeciesExtent.species
        mappedSpeciesExtent.push(enrichedSpeciesExtent);
      }
    }),
  );
  speciesExtent.forEach((se) => {
    const speciesUsed = mappedSpeciesExtent.filter(
      (mSE) => mSE.dataIdentifier === se.dataIdentifier,
    );
    if (!speciesUsed.length) {
      const period: AlertPeriod = '';
      const repeat: AlertRepeat = '';
      const threshold: AlertThreshold = '';
      const enrichedSpeciesExtent = {
        ...se,
        alertLevel: '',
        id: uuidv4(),
        period,
        repeat,
        status: false,
        threshold,
        dataIdentifier: se.dataIdentifier,
      };
      mappedSpeciesExtent.push(enrichedSpeciesExtent);
    }
  });
  return mappedSpeciesExtent;
};

/* Function
DESC: getDataIdentifier returns the dataIdentifier to match Alert species types with main Data types
ARGS: displayConfig (derived from the getDisplayConfig API call), HTMLLabel of species.
*/
const getDataIdentifier = (displayConfig: DisplayConfig, HTMLLabel: string) => {
  const { dataIdentifier } = displayConfig.tabs
    .map(
      (tab) =>
        tab.species.filter((species) => species.HTMLLabel === HTMLLabel)[0],
    )
    .filter((speciesMatch) => speciesMatch)[0];
  return dataIdentifier;
};

/* Function
DESC: speciesExtentFinder returns an array of species that exist across a given set of Zephyrs
ARGS: displayConfig (derived from the getDisplayConfig API call), list of currently selected Zephyrs
      on an alert.
*/
export const speciesExtentFinder = (
  displayConfig: DisplayConfig,
  zephyrs: ZephyrAlertUnit[],
  zephyrsList?: Zephyr[] | null
) => {
  const zList = zephyrsList !== null ? zephyrsList : zephyrs as (ZephyrAlertUnit | Zephyr)[]
  const alertZephyrsList = zList?.filter((z) => {
    let zephyrFounded = false;
    for (let i = 0; i < zephyrs.length; i++) {
      if (z.id_pod === zephyrs[i].id_pod && ((typeof z.HasCertificate === 'number' && z.type === 0) || z.type === 100)) {
        zephyrFounded = true;
        break;
      }
    }
    if (zephyrFounded) return z;
  });
  const speciesExtent: SpeciesExtentIndEnriched[] = [];
  alertZephyrsList?.forEach((z) => {
    const slotSpeciesCombined: SpeciesExtentIdentifiers[] = [];
    slotSpeciesCombined.push('Charge');
    if (z.type === 0) {
      gaseousSlots.forEach((gs) => {
        if (z.slotsInfo) {
          const slotInfo = z.slotsInfo[gs.label];
          if (slotInfo && slotInfo.species) {
            slotInfo.species.forEach((sp) => {
              if (!slotSpeciesCombined.includes(sp)) {
                slotSpeciesCombined.push(sp);
              }
            });
          }
        }
      });
    }
    else if (z.type === 100) {
      if (z.species) {
        z.species.forEach((sp: any) => {
          if (!slotSpeciesCombined.includes(sp)) {
            slotSpeciesCombined.push(sp);
          }
        });
      }
    }
    if (slotSpeciesCombined.length) {
      slotSpeciesCombined.forEach((zSe) => {
        const speciesExtentFiltered = speciesExtent.filter(
          (spE) => spE.speciesExtentIdentifier === zSe,
        );
        if (!speciesExtentFiltered.length) {
          displayConfig.speciesExtent.forEach((dcSE) => {
            if (dcSE.dataIdentifier === zSe) {
              dcSE.species.forEach((dcSES) => {
                const { HTMLLabel, HTMLUnitLabel, HTMLShortUnitLabel } = dcSES;
                const dataIdentifier: SpeciesDataIdentifiers = getDataIdentifier(
                  displayConfig,
                  HTMLLabel,
                );
                const newSpeciesExtent = {
                  dataIdentifier,
                  speciesExtentIdentifier: dcSE.dataIdentifier,
                  HTMLLabel,
                  HTMLUnitLabel,
                  HTMLShortUnitLabel,
                };
                speciesExtent.push(newSpeciesExtent);
              });
            }
          });
        }
      });
    }
  });
  return speciesExtent;
};

/* Function
DESC: speciesExtentProvider packages all of the functions need to create the initialised list of 
      gas alerts for a set of Zephyrs and Alerts on Configurator mount
ARGS: airAlerts(derived from getAlerts API call), displayConfig (derived from the getDisplayConfig API call), list of currently selected Zephyrs
      on an alert.
*/
const speciesExtentProvider = (
  airAlerts: SpeciesData[],
  displayConfig: DisplayConfig,
  zephyrs: ZephyrAlertUnit[],
  zephyrsList?: Zephyr[]
) => {
  const speciesExtent = speciesExtentFinder(displayConfig, zephyrs, zephyrsList);
  // Combine species extent with alerts and add unused species
  const mappedSpeciesExtent = mapSpeciesExtentToAlerts(
    airAlerts,
    speciesExtent,
  );
  // Order species according to displayConfig
  const orderedMappedSpeciesExtent = orderSpeciesExtent(
    displayConfig,
    mappedSpeciesExtent,
  );
  return orderedMappedSpeciesExtent;
};

/* Function
DESC: speciesExtentUpdater returns of mappedSpeciesAirAlerts updated according to a new list of Zephyrs and
      their associated species extents
ARGS: mappedSpeciesExtent (as is displayed on Alerts Configurator), displayConfig (derived from the getDisplayConfig API call), list of currently 
      selected Zephyrs on an alert.
*/
const speciesExtentUpdater = (
  displayConfig: DisplayConfig,
  mappedSpeciesExtent: AlertMappedSpeciesExtent[],
  zephyrs: ZephyrAlertUnit[],
  zephyrsList?: Zephyr[]
) => {
  // For the list of alertZephyrs, work out the array of species allowed
  const speciesExtent = speciesExtentFinder(displayConfig, zephyrs, zephyrsList);
  let updatedMappedSpeciesExtent: AlertMappedSpeciesExtent[] = mappedSpeciesExtent;
  // If there are species in z list that aren't in ag list, add them with default values
  speciesExtent.forEach((se) => {
    const speciesUsed = mappedSpeciesExtent.filter(
      (mSE) => mSE.dataIdentifier === se.dataIdentifier,
    );
    if (!speciesUsed.length) {
      const period: AlertPeriod = '';
      const repeat: AlertRepeat = '';
      const threshold: AlertThreshold = '';
      const enrichedSpeciesExtent = {
        ...se,
        alertLevel: '',
        id: uuidv4(),
        period,
        repeat,
        status: false,
        threshold,
        dataIdentifier: se.dataIdentifier,
      };
      updatedMappedSpeciesExtent.push(enrichedSpeciesExtent);
    }
  });
  // If there are species in ag list that aren't in z list, remove them
  updatedMappedSpeciesExtent.forEach((umSE) => {
    const speciesExtentIncluded = speciesExtent.filter(
      (se) => se.dataIdentifier === umSE.dataIdentifier,
    );
    if (!speciesExtentIncluded.length) {
      updatedMappedSpeciesExtent = updatedMappedSpeciesExtent.filter(
        (umSE2) => umSE2.dataIdentifier !== umSE.dataIdentifier,
      );
    }
  });
  const orderedUpdatedMappedSpeciesExtent = orderSpeciesExtent(
    displayConfig,
    updatedMappedSpeciesExtent,
  );
  return orderedUpdatedMappedSpeciesExtent;
};

/* Function
DESC: speciesExtentInputter returns of mappedSpeciesAirAlerts with an added species item
ARGS: species data identifier, displayConfig (derived from the getDisplayConfig API call), mappedSpeciesExtent (as is displayed on Alerts Configurator).
*/
const speciesExtentInputter = (
  dataIdentifier: SpeciesDataIdentifiers,
  displayConfig: DisplayConfig,
  mappedSpeciesExtent: AlertMappedSpeciesExtent[],
) => {
  const updatedMappedSpeciesExtent = mappedSpeciesExtent;
  const speciesExtentMatcher = mappedSpeciesExtent.filter(
    (mSE) => mSE.dataIdentifier === dataIdentifier,
  );
  if (speciesExtentMatcher.length) {
    // replace the id with a new id for the new SE
    const period: AlertPeriod = '';
    const repeat: AlertRepeat = '';
    const threshold: AlertThreshold = '';
    const newSpeciesExtentItem = {
      ...speciesExtentMatcher[0],
      alertLevel: '0',
      id: uuidv4(),
      period,
      repeat,
      threshold,
    };
    updatedMappedSpeciesExtent.push(newSpeciesExtentItem);
  }
  const orderedUpdatedMappedSpeciesExtent = orderSpeciesExtent(
    displayConfig,
    updatedMappedSpeciesExtent,
  );
  return orderedUpdatedMappedSpeciesExtent;
};

export { speciesExtentInputter, speciesExtentProvider, speciesExtentUpdater };
