// Package Imports
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Type Imports
import {
  AnnualAvgData,
  RequestError,
  Zephyr,
  ZephyrHistoryWithId,
  ZephyrTypes,
} from '../../utils/interface';

// Component Interfaces
interface DataChartHeaderProps {
  progressBarOn: boolean;
  requestErrors: RequestError[];
  tz: {
    tzType: string;
    tzSpecific: string;
  };
  unitHistories: ZephyrHistoryWithId | null;
  unitList: Zephyr[];
  annualAvgData: AnnualAvgData;
  showingAnnualAverage: boolean;
  statusCode: number;
  statusDescription: string | null;
}

// Styled Component
const ChartHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // min-height: 110px;
  button {
    margin-top: 5px;
    background: #e1e4e6;
    font-size: 1.3rem;
    color: #252834;
    border-radius: 2.5px;
    border: transparent;
    cursor: pointer;
    padding: 5px 8px;
    &:hover {
      background: #b1b5b5;
    }
  }
`;

// Component
const DataChartHeader = ({
  progressBarOn,
  requestErrors,
  tz,
  unitHistories,
  unitList,
  annualAvgData,
  showingAnnualAverage,
  statusCode,
  statusDescription,
}: DataChartHeaderProps) => {
  const { t: translate } = useTranslation();

  const getHeaderText = () => {
    if (progressBarOn) return translate('DataChartHeaderLoading');

    if (showingAnnualAverage) {
      if (annualAvgData && annualAvgData.list)
        return `${translate('DataChartHeaderTzPrefix')} ${translate(
          'DataChartHeaderTzUTC',
        )}`;

      return translate('DataChartHeaderNull');
    }

    if (unitHistories && Object.keys(unitHistories).length) {
      if (requestErrors.length === 0)
        return `${translate('DataChartHeaderTzPrefix')} ${tz.tzSpecific}`;

      if (unitList && unitList.length === requestErrors.length) {
        return translate('DataChartAllTimedOut');
      }

      return `${translate('DataChartSomeTimedOut')} ${translate(
        'DataChartHeaderTzPrefix',
      )} ${tz.tzSpecific}`;
    }

    if (requestErrors.length) {
      let message = '';
      if (statusCode && statusCode !== 200) {
        switch (statusCode) {
          case 240:
            message = statusDescription ? statusDescription : `${translate('DataChart_240')}`;
            break;
          case 400:
            message = statusDescription ? statusDescription : `${translate('DataChart_400')}`;
            break;
          case 401:
            message = statusDescription ? statusDescription : `${translate('DataChart_401')}`;
            break;
          case 403:
            message = statusDescription ? statusDescription : `${translate('DataChart_403')}`;
            break;
          case 404:
            message = statusDescription ? statusDescription : `${translate('DataChart_404')}`;
            break;
          case 429:
            message = statusDescription ? statusDescription : `${translate('DataChart_429')}`;
            break;
          case 500:
            message = statusDescription ? statusDescription : `${translate('DataChart_500')}`;
            break;

          default:
            message = statusCode.toString();
        }
      }
      return message;
    }

    if (unitList.length === 1 && unitList[0].type === ZephyrTypes.virtual) {
      return `${translate('vZephyrLoadingMsg')}`;
    }

    return `${translate('DataChartHeaderNull')}`;
  };

  return (
    <ChartHeader className="chart-header">
      <h4 className="graph-title">{getHeaderText()}</h4>
    </ChartHeader>
  );
};

export default DataChartHeader;
