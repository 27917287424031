// Package Imports
import React, { useEffect } from 'react';

// Component Interfaces
interface DataChartLabelCanvasProps {
  canvasId: string;
  dashPattern: number[];
  strokeColor: string;
}

// Component
const DataChartLabelsCanvas = ({
  canvasId,
  dashPattern,
  strokeColor,
}: DataChartLabelCanvasProps) => {
  useEffect(() => {
    const canvas: any = document.getElementById(canvasId);
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.strokeStyle = strokeColor;
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.setLineDash(dashPattern);
      ctx.moveTo(0, 8);
      ctx.lineTo(23, 8);
      ctx.stroke();
    }
  }, []);

  return <canvas id={canvasId} height="15" width="23"></canvas>;
};

export default DataChartLabelsCanvas;
