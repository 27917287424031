/* Function
DESC: General utility functions
*/

export function isStringDefinedAndNotEmpty(str: string): boolean {
    return typeof str !== 'undefined' && str !== '';
  }

//function to avoid runtime errors when importing the config below
export function getEnvVar(v: string): string {
  const ret = process.env[v];

  if (ret === undefined) {
    throw new Error('process.env.' + v + ' is undefined!');
  }
  return ret;
}