// Package Imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';

// Component Imports
import DataChartContainer from '../view_components/DataChartContainer';
import DataDiagnosticsModal from '../view_components/DataDiagnosticsModal';
import DataDownloadButton from '../view_components/DataDownloadButton';
import DataDownloadModal from '../view_components/DataDownloadModal';
import DataGroupTabs from '../view_components/DataGroupTabs';
import DataSpeciesFilters from '../view_components/DataSpeciesFilters';
import DataTimeAveragingOptions from '../view_components/DataTimeAveragingOptions';
import DataTimePeriodOptions from '../view_components/DataTimePeriodOptions';
import Modal from '../shared_components/Modal';

// Action Imports
import {
  endLoading,
  getZephyrData,
  startLoading,
  setZephyr,
} from '../../actions/zephyrs';
import { setMappairPointModeOff } from '../../actions/layers';
import { setDataModalOn, setModalsOff } from '../../actions/modal';
import { setAnnualAverages } from '../../actions/annualAverage';

// Function Imports
import { getAvailableSpecies, slotValidator } from '../../utils/functions/slotValidator';

// Util Imports
import {
  activeTabFinder,
  extentActiveTabFinder,
  mappairSpeciesFinder,
} from '../../utils/functions/tabFinder';
import { slotFinder } from '../../utils/functions/slotFinder';
import { pointDataPackager } from '../../utils/functions/saDataPackager';
import {
  getChartDataset,
  getIdxOfUnit,
  getSlotData,
  getTzConfig,
  getTzConfigFromAllHistories,
  getDateObjectArray,
  updateChartDatasets,
} from '../../utils/functions/chartHelper';
import {
  dateFinder,
  startEndFinder,
  defaultStartEndFinder,
  utcConvertor,
} from '../../utils/functions/dateFinder';
import {
  csvGenerator,
  mappairCsvGenerator,
} from '../../utils/functions/csvGenerator';
import utilsRequest from '../../utils/request';
import analyticsEventFirer from '../../utils/functions/analyticsEventFirer';

// Const Imports
import { gtmEventIdentifiers, YearOptions } from '../../utils/consts';

// Type Imports
import {
  ActiveTab,
  AveragingPeriod,
  AveragingPeriods,
  LineChartData,
  DataConfig,
  DisplayConfig,
  DownloadOptions,
  GetZephyrHistoricalData,
  ReduxState,
  RequestError,
  SlotOptions,
  SpeciesDataLabels,
  SpeciesGroupOptions,
  TimePeriod,
  TimePeriods,
  UrlQueryStringParams,
  Zephyr,
  ZephyrHistoryWithId,
  AnnualAvgData,
  Overlay,
  PointValues,
  ZephyrTypes,
} from '../../utils/interface';
import { checkSlotABData, getAvailableAveragingOption } from '../../utils/functions/getAvailableAveragingOption';
import Banner from '../shared_components/Banner';
import { ModularFeatures } from '../../reducers/modularFeatures';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const AnnouncementBanner = styled.p`
  text-align: center;
  background-color: #ffffbf;
  font-weight: 600;
  margin: 0;
  line-height: 2.5;
`;

interface Thresholds {
  NO2: number,
  O3: number,
  particulatePM10: number,
  particulatePM25: number,
};

export interface ThresholdExceedingSpecie {
  zephyrID: Thresholds
}

// ComponentInterfaces
interface InitState {
  fetchMetData: boolean;
  showingAnnualAverage: boolean;
  selectedAnnualAvgYrs: string;
  activeTab: ActiveTab | null;
  averagingOption: AveragingPeriod;
  chartData: {
    labels: Date[];
    datasets: LineChartData[];
  };
  chartRedraw: boolean;
  chartZoomOn: boolean;
  dataNarrative: string[][];
  dataNarrativeModalOn: boolean;
  date: {
    start: Date;
    end: Date;
  };
  progressBarOn: boolean;
  requestErrors: RequestError[];
  slot: SlotOptions;
  speciesFiltered: SpeciesDataLabels[];
  timePeriod: TimePeriod;
  timePeriodSwitching: boolean;
  timezone: {
    tzType: string;
    tzSpecific: string;
  };
  unitHistories: ZephyrHistoryWithId | null;
  unitList: Zephyr[];
  unitListGrowing: boolean | null;
  coordinates: any | null;
  defaultActiveTab: number | null;
  statusCode: null | number | undefined;
  thresholdExceedingSpecies: ThresholdExceedingSpecie[];
  statusDescription: string | null;
}

interface DataProps {
  asideOpen: boolean;
  averagingPeriods: AveragingPeriods;
  caching: number;
  dataConfig: DataConfig;
  displayConfig: DisplayConfig;
  downloadModalOn: boolean;
  endLoading: Function;
  getZephyrData: Function;
  historicalData: GetZephyrHistoricalData;
  isPublic: boolean;
  queryStringParams: UrlQueryStringParams;
  setDataModalOn: Function;
  setMappairPointModeOff: Function;
  setModalsOff: Function;
  startLoading: Function;
  timePeriods: TimePeriods;
  zephyr: Zephyr;
  annualAvgData: AnnualAvgData;
  setZephyr: Function;
  overlay: string;
  overlays: Overlay[];
  curOverlayName: string;
  isDSEEnabled: boolean;
  setIsDSEEnabled: Function;
  modularFeatures: ModularFeatures;
  bearerToken: string | null,
}

// Component
const Data = ({
  asideOpen,
  averagingPeriods,
  caching,
  dataConfig,
  downloadModalOn,
  displayConfig,
  endLoading,
  getZephyrData,
  historicalData,
  isPublic,
  queryStringParams,
  setDataModalOn,
  setMappairPointModeOff,
  setModalsOff,
  startLoading,
  timePeriods,
  zephyr,
  annualAvgData,
  setZephyr,
  overlay,
  overlays,
  curOverlayName,
  isDSEEnabled,
  setIsDSEEnabled,
  modularFeatures,
  bearerToken,
}: DataProps) => {
  const hourAveraginPeriod = averagingPeriods.periods.find((p) => p.averagingOption === 'Hourly average on the hour');
  const indexOfHourAveragingPeriod = hourAveraginPeriod ? averagingPeriods.periods.indexOf(hourAveraginPeriod) : 0;
  // State
  const initState: InitState = {
    activeTab: null,
    // Corresponds with 1 Hour TimeAveraging default
    averagingOption: isPublic
      ? averagingPeriods.periods[1]
      : averagingPeriods.periods.length > 0 ? averagingPeriods.periods[indexOfHourAveragingPeriod] : averagingPeriods.periods[0],
    chartData: {
      labels: [],
      datasets: [],
    },
    chartRedraw: false,
    chartZoomOn: false,
    dataNarrative: [],
    dataNarrativeModalOn: false,
    date: {
      start: dateFinder(new Date(), 1, true),
      end: new Date(),
    },
    progressBarOn: false,
    requestErrors: [],
    speciesFiltered: [],
    slot: '',
    timePeriod: timePeriods.periods[0],
    timePeriodSwitching: false,
    timezone: {
      tzType: '',
      tzSpecific: '',
    },
    unitHistories: null,
    unitList: [],
    unitListGrowing: null,
    fetchMetData: false,
    showingAnnualAverage: false,
    selectedAnnualAvgYrs: '5',
    coordinates: null,
    statusCode: null,
    defaultActiveTab: null,
    thresholdExceedingSpecies: [],
    statusDescription: null,
  };

  const [activeTab, setActiveTab] = useState(initState.activeTab);
  const [averagingOption, setAveragingOption] = useState(
    initState.averagingOption,
  );
  const [chartData, setChartData] = useState(initState.chartData);
  const [chartRedraw, setChartRedraw] = useState(initState.chartRedraw);
  const [chartZoomOn, setChartZoomOn] = useState(initState.chartZoomOn);
  const [dataNarrative, setDataNarrative] = useState(initState.dataNarrative);
  const [dataNarrativeModalOn, setDataNarrativeModalOn] = useState(
    initState.dataNarrativeModalOn,
  );
  const [date, setDate] = useState(initState.date);
  const [progressBarOn, setProgressBarOn] = useState(initState.progressBarOn);
  const [requestErrors, setRequestErrors] = useState(initState.requestErrors);
  const [slot, setSlot] = useState(initState.slot);
  const [speciesFiltered, setSpeciesFiltered] = useState(
    initState.speciesFiltered,
  );
  const [timePeriod, setTimePeriod] = useState(initState.timePeriod);
  const [timePeriodSwitching, setTimePeriodSwitching] = useState(
    initState.timePeriodSwitching,
  );
  const [timezone, setTimezone] = useState(initState.timezone);
  const [unitHistories, setUnitHistories] = useState(initState.unitHistories);
  const [unitList, setUnitList] = useState(initState.unitList);
  const [unitListGrowing, setUnitListGrowing] = useState(
    initState.unitListGrowing,
  );
  const [fetchMetData, setFetchMetData] = useState(initState.fetchMetData);
  const [showingAnnualAverage, setShowingAnnualAverage] = useState(
    initState.showingAnnualAverage,
  );
  const [selectedAnnualAvgYrs, setSelectedAnnualAvgYrs] = useState(
    initState.selectedAnnualAvgYrs,
  );
  const [coordinates, setCoordinates] = useState(initState.coordinates);
  const [zephyrAvgData, setZephyrAvgData] = useState({});
  const [statusCode, setStatusCode] = useState(initState.statusCode);
  const [statusDescription, setStatusDescription] = useState(initState.statusDescription);
  const [defaultActiveTab, setDefaultActiveTab] = useState(initState.defaultActiveTab);
  const [thresholdExceedingSpecies, setThresholdExceedingSpecies] = useState(initState.thresholdExceedingSpecies);
  const [isModalOpened, setIsModalOpened] = useState(true);
  const [isUnitDataNull, setIsUnitDataNull] = useState(false);
  const [hasZephyrNull, setHasZephyrNull] = useState<boolean | null>(null);
  const bannerValue = ['Several of the Zephyrs in the account have accelerated access to measurement data via DSE.', 'Enable DSE checkbox and try viewing historic data (2020 through 2022) via the custom datetime selector'];
  const location = useLocation();
  
  // Effects
  useEffect(() => {
    // if public user, turn mappairpoints mode off
    if (isPublic) {
      setMappairPointModeOff();
    }
  }, []);

  // useEffect(() => {
  //   const numberYrsHistory = parseInt(selectedAnnualAvgYrs);
  //   const lat = annualAvgData?.list?.latlon?.lat;
  //   const lon = annualAvgData?.list?.latlon?.lon;

  //   const handleMapClickGetAnnualAvg = async () => {
  //     if (!showingAnnualAverage) return;
  //     const endDateObj = new Date(new Date().getFullYear(), 0, 1);
  //     endDateObj.setFullYear(endDateObj.getFullYear() - 1);
  //     const enddt = endDateObj.toISOString().slice(0, -5);

  //     const startDateObj = new Date(new Date().getFullYear(), 0, 1);
  //     startDateObj.setFullYear(endDateObj.getFullYear() - numberYrsHistory);
  //     const startdt = startDateObj.toISOString().slice(0, -5);

  //     const annualAvgRawData = await utilsRequest.getAnnualAvgAtCoords(
  //       lat,
  //       lon,
  //       startdt,
  //       enddt,
  //       '1y',
  //       'no2,pm2p5',
  //       'aa',
  //     );

  //     let selectedOverlay = 'no2';
  //     if (curOverlayName === 'UK PM25') {
  //       selectedOverlay = 'pm2p5';
  //     }

  //     let speciesWithData = annualAvgRawData.results.find(
  //       (res: any) =>
  //         res.species === selectedOverlay ||
  //         (res.species === selectedOverlay &&
  //           res.values_coordinates[0].values_timestamps[0].find(
  //             (val: any) => val.value >= 0,
  //           )),
  //     );

  //     const {
  //       latlon,
  //       values_timestamps,
  //     } = speciesWithData.values_coordinates[0];

  //     speciesWithData = {
  //       species: speciesWithData.species,
  //       units: values_timestamps[0].units,
  //       latlon,
  //       values_timestamps,
  //     };

  //     const curSpecies = mappairSpeciesFinder(
  //       curOverlayName,
  //       displayConfig,
  //       overlays,
  //     );

  //     const annualAvgChartData = pointDataPackager(
  //       annualAvgRawData,
  //       curSpecies,
  //       true,
  //     );

  //     setAnnualAverages({
  //       list: speciesWithData || null,
  //       raw: annualAvgRawData || null,
  //       chart: annualAvgChartData || { labels: [], datasets: [] },
  //     });
  //   };

  //   handleMapClickGetAnnualAvg();
  // }, [selectedAnnualAvgYrs]);

  useEffect(() => {

    if (!zephyr) return;
    if (unitList.length && unitList[0].zNumber === zephyr.zNumber) {
      if (zephyr.name !== unitList[0].name) {
        // map through the unit list and update with new name
        const updatedUnitList = unitList.map((unit) => {
          const updatedUnit = { ...unit };
          if (unit.zNumber === zephyr.zNumber) {
            updatedUnit.name = zephyr.name;
          }
          return updatedUnit;
        });
        // map through the datasets and update with new name 
        const updatedDatasets = chartData.datasets.map((dataset) => {
          const updatedDataset = { ...dataset };
          if (dataset.unitId === zephyr.zNumber) {
            updatedDataset.label = `${zephyr.name}-${dataset.speciesLabel}`;
          }
          return updatedDataset;
        });
        setUnitListGrowing(false);
        setUnitList(updatedUnitList);
        setChartData({ ...chartData, datasets: updatedDatasets });
      }
    } else {
      handleChartReset();
      setUnitList([zephyr]);
      setActiveTab(initState.activeTab);
      setTimezone(initState.timezone);
      setUnitHistories(initState.unitHistories);
      setRequestErrors(initState.requestErrors);
      setUnitListGrowing(true);
    }
  }, [zephyr]);

  useEffect(() => {

    const annualAvgShowing =
      !zephyr || (Array.isArray(zephyr) && zephyr.length === 0);
    setShowingAnnualAverage(annualAvgShowing);
    if (annualAvgShowing) {
      const lat = annualAvgData?.list?.latlon?.lat;
      const lon = annualAvgData?.list?.latlon?.lon;
      setCoordinates([lat, lon]);
    } else {
      setCoordinates(null);
    }
    // setActiveTab({
    //   group: 'NO2',
    //   labels: ['NO(μg/m³)', 'NO₂(μg/m³)', 'O₃(μg/m³)'],
    //   tab: ['NO', 'NO2', 'O3'],
    // });
    // Todo
    // need to make UI dropdown with numberYrsHistory(default selected)
    // fetch selected years
  }, [zephyr]);

  useEffect(() => {
    if (!timePeriod) return;
    if (unitList.length && unitListGrowing) {
      getZephyrHistory(unitList[unitList.length - 1]);
      // Analytics
      if (unitList.length > 1) {
        const labelEnrichment = `unitCount:${unitList.length}_unitType:${unitList[unitList.length - 1].type
          }`;
        analyticsEventFirer(gtmEventIdentifiers.unitOverplot, labelEnrichment);
      }
      // Only private portals have Unaveraged
      if (averagingOption?.averagingOption === 'Unaveraged') {
        const defaultAveragingOption = averagingPeriods.periods[1];
        handleAveragingOption(defaultAveragingOption);
      }
    }
  }, [unitList]);

  const getValidAveraginOption = () => {
    const hData: any = historicalData.zephyrHistory;
    let validOption = null;
    const { periods } = averagingPeriods;
    if (hData) {
      if ('15 min average on the quarter hours' in hData) {
        const availableSpeciesSlotA = getAvailableSpecies(hData['15 min average on the quarter hours']['slotA'])
        const availableSpeciesSlotB = getAvailableSpecies(hData['15 min average on the quarter hours']['slotB'])
        validOption = getAvailableAveragingOption(availableSpeciesSlotA, availableSpeciesSlotB, hData, '15 min average on the quarter hours', { labelHTML: '15 Min', averagingOption: '15 min average on the quarter hours' }, false);
      }
      if (validOption) return validOption;
      for (let i = 0; i < periods.length; i++) {
        if ((periods[i].averagingOption as string) in hData) {
          const availableSpeciesSlotA = getAvailableSpecies(hData[periods[i].averagingOption]['slotA'])
          const availableSpeciesSlotB = getAvailableSpecies(hData[periods[i].averagingOption]['slotB'])
          validOption = getAvailableAveragingOption(availableSpeciesSlotA, availableSpeciesSlotB, hData, periods[i].averagingOption, periods[i], false);
          if (validOption) break;
        }
      }
    }
    return validOption;
  };

  useEffect(() => {
    if (unitHistories && modularFeatures.dseIntegration[isPublic ? 'isPublic' : 'isPrivate']) {
      const HR8Mean = '8 hour average at midnight and 8am and 4pm';
      const HR24Mean = 'Daily average at midnight';
      const O3FreeSpeciesArr = ['NO2', 'particulatePM10', 'particulatePM25'];
      const unitHistorySpecies = Object.keys(unitHistories);
      const exceededSpeciesThresholds = unitHistorySpecies.map((z: any) => {
        let no2 = 0;
        let o3 = 0;
        let pm10 = 0;
        let pm25 = 0;
        const availableSlots = slotValidator(unitHistories[z]);
        if (unitHistories[z][HR8Mean] && availableSlots) {
          availableSlots.forEach((slot: string) => {
            if (unitHistories[z][HR8Mean][slot] && 'O3' in unitHistories[z][HR8Mean][slot]! && unitHistories[z][HR8Mean][slot]!['O3'].data) {
              const specieArr = unitHistories[z][HR8Mean][slot]!['O3'].data;
              specieArr.forEach((n: number | null) => {
                if (n && n > 100) o3++;
              })
            }
          })
        }
        // another if statement for 24hrs
        if (unitHistories[z][HR24Mean] && availableSlots) {
          availableSlots.forEach((slot: string) => {
            if (unitHistories[z][HR24Mean][slot]) {
              O3FreeSpeciesArr.forEach((specie: string) => {
                if (specie in unitHistories[z][HR24Mean][slot]! && unitHistories[z][HR24Mean][slot]![specie].data) {
                  const specieArr = unitHistories[z][HR24Mean][slot]![specie].data;
                  specieArr.forEach((n: number | null) => {
                    switch (specie) {
                      case 'NO2':
                        if (n && n > 25) no2++;
                        break;
                      case 'particulatePM10':
                        if (n && n > 45) pm10++;
                        break;
                      case 'particulatePM25':
                        if (n && n > 37.5) pm25++;
                        break;
                    }
                  })
                }
              })
            }
          })
        }
        const specieObj: Thresholds | any = {};
        specieObj[z] = {
          NO2: no2,
          O3: o3,
          particulatePM10: pm10,
          particulatePM25: pm25,
        }
        return specieObj;
      })
      setThresholdExceedingSpecies(exceededSpeciesThresholds);
    }
  }, [unitHistories])

  const validateAveragingOption = (averagingOption: any) => {
    const hData: any = historicalData.zephyrHistory
    if (hData) {
      return historicalData.zephyrHistory ? averagingOption.averagingOption in hData && ((hData[averagingOption.averagingOption]['slotB'] 
        && !hData[averagingOption.averagingOption]['slotB']['NO2'].allNull) || hData[averagingOption.averagingOption]['slotA'] 
        && !hData[averagingOption.averagingOption]['slotA']['NO2'].allNull) ? false : true : false;
    }
  };

  useEffect(() => {
    if (historicalData.zephyrHistory && historicalData.zType === zephyr.type) {
      const hData = historicalData.zephyrHistory;
      if (!(averagingOption.averagingOption in hData) || validateAveragingOption(averagingOption.averagingOption)) {
        const res: any = getValidAveraginOption();
        setAveragingOption(res);
      }
    }

    if (
      historicalData.zephyrHistory &&
      !historicalData.loading &&
      unitListGrowing &&
      !timePeriodSwitching
    ) {

      const newZephyrHistory = historicalData.zephyrHistory;
      const availableSlots = slotValidator(newZephyrHistory, unitHistories);
      const processSlot = zephyr.type === 100 ? slotFinder(newZephyrHistory)[0] : availableSlots.length > 1 || !availableSlots ? slotFinder(newZephyrHistory)[0] : availableSlots[0];
      let processTab;
      let unitId: number | null = null;
      let startingDataset: LineChartData[] = [];
      if (!activeTab && unitList.length === 1) {
        processTab = activeTabFinder(
          displayConfig,
          processSlot,
          newZephyrHistory,
        );
        unitId = zephyr.zNumber;
        startingDataset = [];
        setSlot(processSlot);
        setUnitHistories({ [unitId]: newZephyrHistory });
        setDefaultActiveTab(0);
      } else if (!activeTab && unitList.length > 0) {
        processTab = activeTabFinder(
          displayConfig,
          processSlot,
          newZephyrHistory,
        );
        unitId = unitList[unitList.length - 1].zNumber;
        startingDataset = [];
        setUnitHistories({ [unitId]: newZephyrHistory });
      } else if (activeTab && unitHistories && unitList.length > 0) {
        processTab = extentActiveTabFinder(
          displayConfig,
          slot,
          activeTab.group,
          unitHistories,
          zephyr.zNumber,
        );
        unitId = unitList[unitList.length - 1].zNumber;
        startingDataset = chartData.datasets;
        setUnitHistories({ ...unitHistories, [unitId]: newZephyrHistory });
      }
      if (processTab && unitId) {
        // here
        // if (historicalData.isHistoricalDataNull) { unitId = unitList[unitList.length - 2].zNumber };
        addUnitDataToChart(processSlot, processTab, startingDataset, unitId, availableSlots);
      }
    } else if (
      !historicalData.zephyrHistory &&
      !historicalData.loading &&
      unitListGrowing &&
      !timePeriodSwitching
    ) {
      if ((unitList[0].type === 0 && unitList[0].averageOfLastHourOfData) || unitList[0].type === 1 || unitList[0].type === 100) setUnitListGrowing(false);
      if (!unitHistories || !Object.keys(unitHistories).length) {
        setUnitHistories(initState.unitHistories);
        setActiveTab(initState.activeTab);
        setTimezone(initState.timezone);
      }
      if (historicalData.zephyrHistory === false) {

        const newError = {
          unitId: zephyr.zNumber,
          unitName: zephyr.name,
          errorType: '500',
        };
        setRequestErrors([newError]);
      }
    }
  }, [historicalData]);

  // Functions

  const keepPullutantTogglingState = (newDatasets: LineChartData[]) => {
    const { datasets } = chartData;
    newDatasets.forEach((s, idx) => {
      s.hidden = datasets[idx].hidden
    });
  }

  const getZephyrHistory = async (unit: Zephyr) => {
    if (!unit || !timePeriod) return;
    setChartZoomOn(false);

    const defaultStartEnd = defaultStartEndFinder();
    let { end, start } = defaultStartEnd;
    if (timePeriod.timeOption === 'custom') {
      start = moment(date.start)
        .set({ h: 0, m: 0, s: 0 })
        .format('YYYYMMDDHHmm');
      const unformattedEnd = moment(date.end).set({ h: 23, m: 59, s: 0 });
      const current = moment();
      const selectedEnd = current < unformattedEnd ? current : unformattedEnd;
      end = selectedEnd.format('YYYYMMDDHHmm');
    } else if (timePeriod.timeOption) {
      const startEnd = startEndFinder(timePeriod.timeOption);
      if (isPublic) {
        start = moment(startEnd.start, 'YYYYMMDDHHmm')
          .set({ h: 0, m: 0, s: 0 })
          .format('YYYYMMDDHHmm');
        end = moment(startEnd.end, 'YYYYMMDDHHmm')
          .set({ h: 0, m: 0, s: 0 })
          .format('YYYYMMDDHHmm');
      } else {
        start = startEnd.start;
        end = startEnd.end;
      }
    }

    // Convert start/end
    const startUTC = utcConvertor(start);
    const endUTC = utcConvertor(end);
    const updatedDataConfig = queryStringParams.ctv
      ? { ...dataConfig, ctv: queryStringParams.ctv }
      : dataConfig;
    const newUnitHistory = await getZephyrData(
      unit,
      timePeriod.timeOption,
      startUTC,
      endUTC,
      updatedDataConfig,
      false,
      bearerToken,
      caching,
      location.pathname === '/',
      isDSEEnabled ? isDSEEnabled : undefined,
    );
    // if (newUnitHistory.data === null) setIsUnitDataNull(true);
    // else if (newUnitHistory.data !== null && isUnitDataNull) setIsUnitDataNull(false);
    if (zephyr.type === 0) {
      const { statusCode: unitStatusCode } = newUnitHistory;
      if (unitStatusCode && unitStatusCode.responseCode) {
        setStatusCode(unitStatusCode.responseCode);
        setStatusDescription(unitStatusCode.description);
      }
    }
    setZephyrAvgData(newUnitHistory);
    //if (newUnitHistory.data) {
    //  const newDataNarrative = newUnitHistory.info.narrative;
    //  setDataNarrative([newDataNarrative]);
    //}
  };

  const handleChartReset = () => {
    setChartData(initState.chartData);
    setActiveTab(initState.activeTab);
  };

  const handleSlotChange = (newSlot: SlotOptions) => {
    if (newSlot !== slot) {
      updateChartDatasetsWithNewSlot(newSlot);
    }
  };

  const handleTabSwitch = (tab: SpeciesGroupOptions, slotOption?: SlotOptions) => {
    if (unitHistories && Object.keys(unitHistories).length) {
      const newActiveTab = extentActiveTabFinder(
        displayConfig,
        slotOption ? slotOption : slot,
        tab,
        unitHistories,
        zephyr.zNumber,
      );
      if (newActiveTab) {
        processTabSwitch(newActiveTab);
        setSpeciesFiltered(initState.speciesFiltered);
        // Analytics
        analyticsEventFirer(
          gtmEventIdentifiers.speciesGroupSwitch,
          newActiveTab.group,
        );
      }
    }
    setDefaultActiveTab(null);
  };

  const handleSpeciesFilter = (speciesLabel: SpeciesDataLabels) => {
    const prevFilterStatus = speciesFiltered.includes(speciesLabel);
    const newChartData = chartData.datasets.map((dataset) => {
      const updatedData = { ...dataset };
      if (dataset.speciesLabel === speciesLabel) {
        updatedData.hidden = !prevFilterStatus;
      }
      return updatedData;
    });
    if (!prevFilterStatus) {
      setSpeciesFiltered([...speciesFiltered, speciesLabel]);
    } else {
      const newSpeciesFilters = speciesFiltered.filter(
        (sp) => sp !== speciesLabel,
      );
      setSpeciesFiltered(newSpeciesFilters);
    }
    setChartData({ ...chartData, datasets: newChartData });
  };

  const handleUnitSpeciesFilter = (
    label: 'LineChartData.["label"]',
    speciesLabel: SpeciesDataLabels,
  ) => {
    const newChartData = chartData.datasets.map((dataset) => {
      const updatedData = { ...dataset };
      if (dataset.label === label) {
        updatedData.hidden = !dataset.hidden;
      }
      return updatedData;
    });
    const overallFiltered = speciesFiltered.includes(speciesLabel);
    let flipOverall: boolean = true;
    newChartData.map((dataset) => {
      if (
        dataset.speciesLabel === speciesLabel &&
        dataset.hidden === overallFiltered
      ) {
        flipOverall = false;
      }
    });
    if (flipOverall) {
      if (overallFiltered) {
        const newSpeciesFiltered = speciesFiltered.filter(
          (sp) => sp !== speciesLabel,
        );
        setSpeciesFiltered(newSpeciesFiltered);
      } else {
        setSpeciesFiltered([...speciesFiltered, speciesLabel]);
      }
    }
    setChartData({ ...chartData, datasets: newChartData });
  };

  const handleAveragingOption = (newAveragingPeriod: AveragingPeriod) => {
    addNewAveragingToChart(newAveragingPeriod);
    // Analytics
    analyticsEventFirer(
      gtmEventIdentifiers.timeAveragingSwitch,
      newAveragingPeriod.labelHTML,
    );
  };

  const handleCustomDateChange = (newDate: Date, type: 'start' | 'end') => {
    type === 'start'
      ? setDate({ ...date, start: newDate })
      : setDate({ ...date, end: newDate });
  };

  const handleCustomSubmit = () => {
    const start = moment(date.start)
      .set({ h: 0, m: 0, s: 0 })
      .format('YYYYMMDDHHmm');
    const end = moment(date.end).set({ h: 23, m: 59, s: 59 });
    const current = moment();
    const selectedEnd = current < end ? current : end;
    const formattedEnd = selectedEnd.format('YYYYMMDDHHmm');
    handleTimePeriodChange(timePeriod, start, formattedEnd);
  };

  const handleTimePeriod = (
    newTimePeriod: TimePeriod,
    start: Date,
    end: Date,
  ) => {
    setTimePeriod(newTimePeriod);
    if (
      !(newTimePeriod === timePeriod || newTimePeriod.timeOption === 'custom')
    ) {
      handleTimePeriodChange(newTimePeriod, start, end);
    }
    // Analytics
    analyticsEventFirer(
      gtmEventIdentifiers.timePeriodSwitch,
      newTimePeriod.labelHTML,
    );
  };

  const handleTimePeriodChange = async (
    newTimePeriod: TimePeriod,
    start: Date | string,
    end: Date | string,
  ) => {
    startLoading();
    handleChartReset();
    setUnitHistories(initState.unitHistories);
    setChartZoomOn(false);
    setTimePeriodSwitching(true);
    setRequestErrors(initState.requestErrors);
    //
    const newRequestErrors: RequestError[] = [];
    let newUnitHistories: ZephyrHistoryWithId = {};
    const newDataNarrative: string[][] = [];
    let correctedStart: Date | string = start;
    let correctedEnd: Date | string = end;
    const updatedDataConfig = queryStringParams.ctv
      ? { ...dataConfig, ctv: queryStringParams.ctv }
      : dataConfig;
    if (isPublic) {
      correctedStart = moment(start, 'YYYYMMDDHHmm')
        .set({ h: 0, m: 0, s: 0 })
        .format('YYYYMMDDHHmm');
      correctedEnd = moment(end, 'YYYYMMDDHHmm')
        .set({ h: 0, m: 0, s: 0 })
        .format('YYYYMMDDHHmm');
    }
    for (let i = 0; i < unitList.length; i++) {
      const utcStart = utcConvertor(correctedStart);
      const utcEnd = utcConvertor(correctedEnd);
      const newUnitHistory = await getZephyrData(
        unitList[i],
        newTimePeriod.timeOption,
        utcStart,
        utcEnd,
        updatedDataConfig,
        true,
        bearerToken,
        caching,
        location.pathname === '/',
        isDSEEnabled ? isDSEEnabled : undefined,
      );
      // newUnitHistory.then((res: any) => {
      if (newUnitHistory) {
        if (newUnitHistory.data) {
          newUnitHistories = {
            ...newUnitHistories,
            [unitList[i].zNumber]: newUnitHistory.data,
          };
          //newDataNarrative.push(newUnitHistory.info.narrative);
        } else if (newUnitHistory.data === false) {
          const newError = {
            unitId: unitList[i].zNumber,
            unitName: unitList[i].name,
            errorType: '500',
          };
          newRequestErrors.push(newError);
        }
        if (i === unitList.length - 1) {
          setDataNarrative(newDataNarrative);
          if (newUnitHistories && Object.keys(newUnitHistories).length) {
            // See if we need to set the slot
            if (!slot) {
              const availableSlots = slotFinder(newUnitHistories[zephyr.zNumber]);
              if (availableSlots.length) {
                setSlot(availableSlots[0]);
              }
            }
            // Process all of the chart data
            const newTzConfig = getTzConfigFromAllHistories(
              averagingOption,
              slot,
              newUnitHistories,
              zephyr,
            );
            // Grab the TzConfig for one of the units in the unit history
            let newLabels: Date[] = [];
            for (const [unitKey, unitValue] of Object.entries(newUnitHistories)) {
              const processSlot = slotFinder(unitValue)[0];
              newLabels = getDateObjectArray(
                newUnitHistories[unitKey as any][averagingOption.averagingOption][
                  processSlot
                ]![newTzConfig.tzType].data,
              );
              break;
            }
            if (newLabels.length) {
              processUnitHistorySet(newUnitHistories, unitList, newLabels);
              setUnitHistories(newUnitHistories);
              setTimezone(newTzConfig);
            }
          } else {
            setUnitHistories(initState.unitHistories);
          }
          if ((unitList[0].type === 0 && unitList[0].averageOfLastHourOfData) || unitList[0].type === 1 || unitList[0].type === 100) setUnitListGrowing(false);
          setChartRedraw(true);
          setTimePeriodSwitching(false);
          setRequestErrors(newRequestErrors);
          endLoading();
        }
      }
      // })
    }
  };

  const handleDownloadModal = () => {
    // If public bypass the modal and download CSV straight away
    if (isPublic) {
      handleDownloadData('csv');
    } else if (!downloadModalOn) {
      setDataModalOn();
    } else {
      setModalsOff();
    }
  };

  const handleDataNarrativeModal = () => {
    setDataNarrativeModalOn(!dataNarrativeModalOn);
  };

  const fetchMetDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    setFetchMetData(target.checked);
  };

  const handleDownloadData = async (type: DownloadOptions) => {
    if (showingAnnualAverage && annualAvgData && annualAvgData.chart) {
      const curSpecies = mappairSpeciesFinder(
        curOverlayName,
        displayConfig,
        overlays,
      );

      mappairCsvGenerator(annualAvgData.chart, curSpecies);
      return;
    }

    if (unitHistories && Object.keys(unitHistories).length) {
      const { start, end } =
        timePeriod.timeOption === 'custom'
          ? {
            start: moment(date.start).format('YYYYMMDDHHmm'),
            end: moment(date.end).format('YYYYMMDDHHmm'),
          }
          : startEndFinder(timePeriod.timeOption);

      const { met, ...displayConfigCopy } = { ...displayConfig };
      if (type === 'csv') {
        if (fetchMetData) {
          const metDataCSVPromise = unitList.map(async (unitItem) => {
            let metData = null;
            if (unitItem.HasMetStation === 1) {
              metData = await utilsRequest.getZephyrMetData(
                unitItem.zNumber,
                start,
                end,
                dataConfig,
                'csv',
                bearerToken,
              );
            }

            if (metData) {
              const itemsKVP = metData?.Unaveraged?.head;

              // turning KVP-KeyValuePair to array
              const itemsArr: any[] = [];
              Object.keys(itemsKVP).forEach((key: string) => {
                const headerObj = itemsKVP[key].header;
                itemsArr.push({
                  ...itemsKVP[key],
                  ...{ key, CSVOrder: headerObj.CSVOrder },
                });
              });

              // sort data using CSVOrder
              const itemsArrSorted = itemsArr.sort(
                (a, b) => a.CSVOrder - b.CSVOrder,
              );

              // get csv headers
              const headers = itemsArrSorted.map(
                (item) => `${item.header.label}_${item.header.units}`,
              );

              let rows: any = [];
              const numberOfRows = itemsArrSorted[0].data.length;
              for (let i = 0; i < numberOfRows; i++) {
                rows[i] = [];
                for (let j = 0; j < headers.length; j++) {
                  rows[i][j] = itemsArrSorted[j].data[i];
                }
              }

              const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: '',
                useTextFile: false,
                useBom: true,
                filename: `${unitItem.name}_${unitItem.zNumber}_${start}_${end}`,
                headers,
              };
              const csvExporter = new ExportToCsv(options);
              csvExporter.generateCsv(rows);
            } else {
              csvGenerator(
                averagingOption,
                displayConfigCopy,
                isPublic,
                slot,
                unitList,
                unitHistories,
              );
            }
          });

          Promise.all(metDataCSVPromise);
        } else {
          csvGenerator(
            averagingOption,
            displayConfigCopy,
            isPublic,
            slot,
            unitList,
            unitHistories,
          );
        }
      } else if (type === 'kml') {
        const availableUnits = unitList.filter(
          (unit) =>
            unit.zNumber in unitHistories &&
            slotFinder(unitHistories[unit.zNumber]),
        );
        if (availableUnits.length) {
          const processSlot = slotFinder(
            unitHistories[availableUnits[0].zNumber],
          ).toString();
          const slotString = processSlot.includes('slotB') ? 'B' : 'A';
          utilsRequest.downloadDataKML(
            availableUnits[0].zNumber,
            start,
            end,
            slotString,
            'NO2',
            dataConfig,
            bearerToken,
          );
        }
      }
    }
    // Analytics
    const labelEnrichment = `${type}${type === 'csv'
      ? `_${timePeriod.labelHTML}_${averagingOption.labelHTML}_${unitList.length} Unit(s)`
      : ''
      }`;
    analyticsEventFirer(gtmEventIdentifiers.dataDownload, labelEnrichment);
  };
  // {labelHTML: '1 Hour', averagingOption: 'Hourly average on the hour'}

  const processChartDataset = (
    startingDataset: LineChartData[],
    processSlot: SlotOptions,
    processTab: ActiveTab,
    unitId: number,
  ) => {
    const newDatasets = startingDataset.slice();
    const { zephyrHistory } = historicalData;
    const unitIdx = getIdxOfUnit(unitId, unitList);
    if (zephyrHistory) {
      processTab.tab.map((species, idx) => {
        const dataset = getChartDataset(
          averagingOption,
          processTab,
          idx,
          displayConfig,
          processSlot,
          species,
          unitId,
          unitIdx,
          unitList,
          zephyrHistory,
        );
        if (dataset) {
          newDatasets.push(dataset);
        }
      });
    }
    return newDatasets;
  };

  const processUnitHistorySet = (
    newUnitHistories: ZephyrHistoryWithId,
    newUnitList: Zephyr[],
    newLabels: Date[],
  ) => {
    const { datasets } = chartData;
    let availableUnit: string = '';
    for (const [unitKey, unitValue] of Object.entries(newUnitHistories)) {
      availableUnit = unitKey;
      break;
    }
    // Find the first available tab for all species and process the data
    const zephyrHistory = newUnitHistories[zephyr.zNumber]
      ? newUnitHistories[zephyr.zNumber]
      : newUnitHistories[availableUnit! as any];
    const processSlot =
      newUnitHistories[zephyr.zNumber] && slot !== ''
        ? slot
        : slotFinder(zephyrHistory)[0];
    if (zephyrHistory) {
      const availableSlots = slotValidator(historicalData.zephyrHistory!, newUnitHistories);
      const activeTabConfig = activeTabFinder(
        displayConfig,
        processSlot,
        zephyrHistory,
      );
      if (activeTabConfig) {
        const extentActiveTabConfig = extentActiveTabFinder(
          displayConfig,
          processSlot,
          activeTabConfig.group,
          newUnitHistories,
          zephyr.zNumber,
        );
        if (extentActiveTabConfig && newUnitHistories) {
          const updatedNewDatasets: LineChartData[] = [];
          newUnitList.map((unit) => {
            const unitProcessSlot =
              unit.zNumber === zephyr.zNumber && slot !== ''
                ? slot
                : newUnitHistories
                  ? slotFinder(newUnitHistories[unit.zNumber])[0]
                  : slot;
            const secondProcessSlot = availableSlots.length > 1 ? processSlot === 'slotA' ? 'slotB' : 'slotA' : availableSlots.length === 1 ? availableSlots[0] : processSlot === 'slotA' && (timezone.tzType || timezone.tzSpecific) ? 'slotB' : unitProcessSlot;
            const unitIdx = getIdxOfUnit(unit.zNumber, unitList);
            extentActiveTabConfig.tab.map((species, idx) => {
              if (newUnitHistories[unit.zNumber]) {
                const firstSlotDataSet = getChartDataset(
                  averagingOption,
                  extentActiveTabConfig,
                  idx,
                  displayConfig,
                  unitProcessSlot,
                  species,
                  unit.zNumber,
                  unitIdx,
                  unitList,
                  newUnitHistories[unit.zNumber],
                );
                const secondSlotDataSet = secondProcessSlot ? getChartDataset(
                  averagingOption,
                  extentActiveTabConfig,
                  idx,
                  displayConfig,
                  secondProcessSlot,
                  species,
                  unit.zNumber,
                  unitIdx,
                  unitList,
                  newUnitHistories[unit.zNumber],
                ) : null;
                if (firstSlotDataSet && secondSlotDataSet) {
                  availableSlots.length === 1 ? updatedNewDatasets.push(secondSlotDataSet) : updatedNewDatasets.push(firstSlotDataSet, secondSlotDataSet);
                }
                else if (firstSlotDataSet && !secondSlotDataSet) {
                  updatedNewDatasets.push(firstSlotDataSet);
                }
                else if (!firstSlotDataSet && secondSlotDataSet) {
                  updatedNewDatasets.push(secondSlotDataSet);
                }
              }
            });
          });
          if (datasets.length === updatedNewDatasets.length) keepPullutantTogglingState(updatedNewDatasets);
          const removeDataSetDuplicates = updatedNewDatasets.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.label === value.label && t.speciesLabel === value.speciesLabel
            ))
          );
          setChartData({
            ...chartData,
            datasets: removeDataSetDuplicates,
            labels: newLabels,
          });
          setActiveTab(extentActiveTabConfig);
        }
      }
    }
    setDefaultActiveTab(null);
  };

  const getFilteredDataSet = (slotData: LineChartData[], unitId: number) => {
    return slotData.map((dataset) => {
      const newDataset = { ...dataset };
      if (dataset.unitId === unitId) {
        newDataset.hidden = speciesFiltered.includes(dataset.speciesLabel);
      }
      return newDataset;
    });
  };

  // {labelHTML: '1 Hour', averagingOption: 'Hourly average on the hour'}
  // data chart
  const addUnitDataToChart = (
    processSlot: SlotOptions,
    processTab: ActiveTab,
    startingDataset: LineChartData[],
    unitId: number,
    availableSlots: SlotOptions[]
  ) => {
    const { zephyrHistory } = historicalData;
    if (zephyrHistory) {
      const secondSlot = availableSlots.length > 1 && processSlot === 'slotA' ? 'slotB' : availableSlots.length > 1 && processSlot === 'slotB' ? 'slotA' : availableSlots.length === 1 ? availableSlots[0] : null;
      const newDataset = processChartDataset(
        startingDataset,
        processSlot,
        processTab,
        unitId,
      );
      const secondSlotDataSet = secondSlot ? processChartDataset(
        startingDataset,
        secondSlot,
        processTab,
        unitId,
      ) : null;
      const updatedDataSet = secondSlotDataSet ? newDataset.concat(secondSlotDataSet) : null;
      let newLabels: Date[] = chartData.labels;
      const { tzType, tzSpecific } = getTzConfig(
        averagingOption,
        processSlot,
        zephyrHistory,
      );

      if (
        (tzType === 'localDateTime' && tzSpecific === timezone.tzSpecific) ||
        (tzType === 'localDateTime' && !timezone.tzType)
      ) {
        newLabels = getDateObjectArray(
          zephyrHistory[averagingOption.averagingOption][processSlot]!
            .localDateTime.data,
        );
        setTimezone({ tzType, tzSpecific });
      } else {
        if (
          !zephyrHistory[averagingOption.averagingOption][processSlot]?.dateTime
        ) {
          newLabels = getDateObjectArray(
            zephyrHistory[averagingOption.averagingOption].head!.dateTime.data,
          );
        } else {
          newLabels = getDateObjectArray(
            zephyrHistory[averagingOption.averagingOption][processSlot]!
              .dateTime.data,
          );
        }
        setTimezone({ tzType: 'dateTime', tzSpecific: 'UTC' });
      }
      // Check which datasets to hide based on current species filters
      // const removeDuplicatesFromDataSet = updatedDataSet ? updatedDataSet.filter((u) => )
      const filteredNewDataset = updatedDataSet ? getFilteredDataSet(updatedDataSet, unitId) : getFilteredDataSet(newDataset, unitId);
      const removeNewDataSetDuplicates = filteredNewDataset ? filteredNewDataset.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.label === value.label && t.speciesLabel === value.speciesLabel
        ))
      ) : null;
      const hasDataSetDuplicates = filteredNewDataset.length === removeNewDataSetDuplicates?.length;
      setChartData({
        ...chartData,
        labels: newLabels,
        datasets: hasDataSetDuplicates ? filteredNewDataset : removeNewDataSetDuplicates!,
      });
      setActiveTab(processTab);

      setUnitListGrowing(false);
      setChartRedraw(true);
    }
  };
  const updateChartDatasetsWithNewSlot = (newSlot: SlotOptions) => {
    if (unitHistories && activeTab) {
      const newDatasets: LineChartData[] = [];
      const newLabels: Date[] = getDateObjectArray(
        unitHistories[zephyr.zNumber][averagingOption.averagingOption][
          newSlot
        ]![timezone.tzType].data,
      );
      unitList.map((unit) => {
        const processSlot =
          unit.zNumber === zephyr.zNumber
            ? newSlot
            : slotFinder(unitHistories[unit.zNumber])[0];
        const unitIdx = getIdxOfUnit(unit.zNumber, unitList);
        const updatedActiveTab = activeTabFinder(
          displayConfig,
          processSlot,
          unitHistories[zephyr.zNumber],
          activeTab.group,
        );
        if (updatedActiveTab) {
          const updatedExtentActiveTab = extentActiveTabFinder(
            displayConfig,
            processSlot,
            updatedActiveTab.group,
            unitHistories,
            zephyr.zNumber,
          );
          if (updatedExtentActiveTab) {
            updatedExtentActiveTab.tab.forEach((species, idx) => {
              if (unitHistories[unit.zNumber]) {
                const dataset = getChartDataset(
                  averagingOption,
                  updatedExtentActiveTab,
                  idx,
                  displayConfig,
                  processSlot,
                  species,
                  unit.zNumber,
                  unitIdx,
                  unitList,
                  unitHistories[unit.zNumber],
                );
                if (dataset) {
                  newDatasets.push(dataset);
                }
              }
            });
            setActiveTab(updatedExtentActiveTab);
          }
        }
      });
      setChartData({ ...chartData, datasets: newDatasets, labels: newLabels });
      setSlot(newSlot);
      setChartRedraw(true);
    }
  };

  const addNewAveragingToChart = (newAveragingOption: AveragingPeriod) => {
    if (unitHistories && activeTab) {
      const { datasets } = chartData;
      const availableSlots = slotValidator(historicalData.zephyrHistory!, unitHistories);
      const newDatasets: LineChartData[] = [];
      // get labels based on one available Zephyr
      const newTzConfig = getTzConfigFromAllHistories(
        newAveragingOption,
        slot,
        unitHistories,
        zephyr,
      );
      // Grab the TzConfig for one of the units in the unit history
      let newLabels: Date[] = [];
      for (const [unitKey, unitValue] of Object.entries(unitHistories)) {
        const processSlot = slotFinder(unitValue)[0];

        const slotData = getSlotData(
          newAveragingOption,
          processSlot,
          unitValue,
        );
        if (slotData === null) break;

        newLabels = getDateObjectArray(slotData![newTzConfig.tzType]?.data);
        break;
      }
      unitList.map((unit) => {
        const processSlot =
          unit.zNumber === zephyr.zNumber
            ? slot
            : slotFinder(unitHistories[unit.zNumber])[0];
        const unitIdx = getIdxOfUnit(unit.zNumber, unitList);
        const secondProcessSlot = availableSlots.length > 1 ? processSlot === 'slotA' ? 'slotB' : 'slotA' : availableSlots.length === 1 ? availableSlots[0] : null;
        activeTab.tab.map((species, idx) => {
          if (unitHistories[unit.zNumber]) {
            const firstSlotDataSet = getChartDataset(
              newAveragingOption,
              activeTab,
              idx,
              displayConfig,
              processSlot,
              species,
              unit.zNumber,
              unitIdx,
              unitList,
              unitHistories[unit.zNumber],
            );
            const secondSlotDataSet = secondProcessSlot ? getChartDataset(
              newAveragingOption,
              activeTab,
              idx,
              displayConfig,
              secondProcessSlot,
              species,
              unit.zNumber,
              unitIdx,
              unitList,
              unitHistories[unit.zNumber],
            ) : null;
            if (firstSlotDataSet && secondSlotDataSet) {
              availableSlots.length === 1 ? newDatasets.push(secondSlotDataSet) : newDatasets.push(firstSlotDataSet, secondSlotDataSet);
            }
            else if (firstSlotDataSet && !secondSlotDataSet) {
              newDatasets.push(firstSlotDataSet);
            }
            else if (!firstSlotDataSet && secondSlotDataSet) {
              newDatasets.push(secondSlotDataSet);
            }
          }
        });
      });
      if (datasets.length === newDatasets.length) keepPullutantTogglingState(newDatasets);
      setChartData({ ...chartData, datasets: newDatasets, labels: newLabels });
      setAveragingOption(newAveragingOption);
      setChartRedraw(true);
    }
  };

  const processTabSwitch = (processTab: ActiveTab) => {
    if (unitHistories && Object.keys(unitHistories).length) {
      const availableSlots = slotValidator(historicalData.zephyrHistory!, unitHistories);
      const newDatasets: LineChartData[] = [];
      unitList.map((unit) => {
        const processSlot =
          unit.zNumber === zephyr.zNumber
            ? slot
            : slotFinder(unitHistories[unit.zNumber])[0];
        const unitIdx = getIdxOfUnit(unit.zNumber, unitList);
        const secondProcessSlot = availableSlots.length > 1 ? processSlot === 'slotA' ? 'slotB' : 'slotA' : availableSlots.length === 1 ? availableSlots[0] : null;
        processTab.tab.map((species, idx) => {
          if (unitHistories[unit.zNumber]) {
            const firstSlotDataSet = getChartDataset(
              averagingOption,
              processTab,
              idx,
              displayConfig,
              processSlot,
              species,
              unit.zNumber,
              unitIdx,
              unitList,
              unitHistories[unit.zNumber],
            );
            const secondSlotDataSet = secondProcessSlot ? getChartDataset(
              averagingOption,
              processTab,
              idx,
              displayConfig,
              secondProcessSlot,
              species,
              unit.zNumber,
              unitIdx,
              unitList,
              unitHistories[unit.zNumber],
            ) : null;
            if (firstSlotDataSet && secondSlotDataSet) {
              availableSlots.length === 1 ? newDatasets.push(secondSlotDataSet) : newDatasets.push(firstSlotDataSet, secondSlotDataSet);
            }
            else if (firstSlotDataSet && !secondSlotDataSet) {
              newDatasets.push(firstSlotDataSet);
            }
            else if (!firstSlotDataSet && secondSlotDataSet) {
              newDatasets.push(secondSlotDataSet);
            }
          }
        });
      });
      setChartData({ ...chartData, datasets: newDatasets });
      setActiveTab(processTab);
      setDefaultActiveTab(null);
      setUnitListGrowing(false);
      setChartRedraw(true);
    }
  };

  const handleRemoveUnit = (unitZNumber: number) => {
    const newUnitList = unitList.filter(
      (listUnit) => listUnit.zNumber !== unitZNumber,
    );
    const newDatasets = chartData.datasets.filter(
      (ds) => ds.unitId !== unitZNumber,
    );
    const newRequestErrorList = requestErrors.filter(
      (re) => re.unitId !== unitZNumber,
    );
    let newUnitHistories = unitHistories;
    if (unitHistories && unitHistories[unitZNumber]) {
      newUnitHistories = { ...unitHistories };
      delete newUnitHistories[unitZNumber];
      // Calculate if there needs to be a new activeTabConfig
      if (activeTab && Object.keys(newUnitHistories).length) {
        const newTzConfig = getTzConfigFromAllHistories(
          averagingOption,
          slot,
          newUnitHistories,
          zephyr,
        );
        // Get new labels
        let newLabels: Date[] = [];
        for (const [unitKey, unitValue] of Object.entries(newUnitHistories)) {
          const processSlot = slotFinder(unitValue)[0];
          newLabels = getDateObjectArray(
            newUnitHistories[unitKey as any][averagingOption.averagingOption][
              processSlot
            ]![newTzConfig.tzType].data,
          );
          break;
        }
        const checkTabSwitch = extentActiveTabFinder(
          displayConfig,
          slot,
          activeTab.group,
          newUnitHistories,
          zephyr.zNumber,
        );
        if (checkTabSwitch) {
          const updatedNewDatasets = updateChartDatasets(
            newDatasets,
            displayConfig,
            newUnitList,
          );
          // get new colours
          setChartData({
            ...chartData,
            datasets: updatedNewDatasets,
            labels: newLabels,
          });
        } else {
          processUnitHistorySet(newUnitHistories, newUnitList, newLabels);
        }
        setTimezone(newTzConfig);
      } else {
        handleChartReset();
      }
    }
    setChartRedraw(true);
    setUnitHistories(newUnitHistories);
    setUnitList([...newUnitList]);
    setRequestErrors([...newRequestErrorList]);
    setUnitListGrowing(false);
  };

  return (
    <>
      <main
        className={`data-page ${isDSEEnabled ? 'dse-enabled' : ''} ${
          asideOpen ? '' : 'full-extent'
        }`}
      >
        <header
          className={`filters-header ${isDSEEnabled ? 'dse-enabled' : ''} ${
            asideOpen ? '' : 'shrink-filters'
          }`}
        >
          {showingAnnualAverage ? (
            <div className="annual-avg-years">
              {/* <select
                className="annual-avg-select"
                onChange={(event) => {
                  setSelectedAnnualAvgYrs(event.target.value);
                }}
                value={selectedAnnualAvgYrs}
              >
                {YearOptions.map((selectOption, i) => {
                  return (
                    <option key={`${i}-option`} value={selectOption.value}>
                      {selectOption.label}
                    </option>
                  );
                })}
              </select> */}
            </div>
          ) : (
            <>
              <DataTimePeriodOptions
                curTimePeriod={timePeriod.labelHTML}
                endDate={date.end}
                handleCustomDateChange={handleCustomDateChange}
                handleCustomSubmit={handleCustomSubmit}
                handleTimePeriod={handleTimePeriod}
                isPublic={isPublic}
                progressBarOn={progressBarOn}
                startDate={date.start}
                timePeriods={timePeriods}
                isDSEEnabled={isDSEEnabled}
                setIsDSEEnabled={setIsDSEEnabled}
              />

              <DataTimeAveragingOptions
                averagingPeriods={averagingPeriods}
                curAveraging={averagingOption.labelHTML}
                handleAveragingOption={handleAveragingOption}
                progressBarOn={progressBarOn}
                unitList={unitList}
                zNumber={zephyr.zNumber}
                dataConfig={dataConfig}
                zephyrType={zephyr.type}
                zephyrStartDate={zephyr.userStartTimeDate}
                zephyrEndDate={zephyr.userEndTimeDate}
                zephyr={zephyr}
                curAveragingOption={averagingOption}
                setAveragingOption={setAveragingOption}
                zephyrAvgData={Object.values(zephyrAvgData)}
                historicalData={historicalData.zephyrHistory}
                isHistoricalDataLoading={historicalData.loading}
                historicalDataZType={historicalData.zType}
                timePeriod={timePeriod}
                chartData={chartData}
                unitHistories={unitHistories}
              />
              {modularFeatures.dseIntegration[
                isPublic ? 'isPublic' : 'isPrivate'
              ] && (
                <Modal
                  on={isModalOpened}
                  modalComponent={
                    <Banner
                      setIsModalOpened={setIsModalOpened}
                      bannerValue={bannerValue}
                      dataTab={true}
                    />
                  }
                />
              )}
            </>
          )}

          {zephyr.HasMetStation &&
          !progressBarOn &&
          unitHistories &&
          Object.keys(unitHistories).length > 0 ? (
            <>
              <div className="form-check d-flex flex-row">
                <label htmlFor="metDataCheckbox">
                  Met Data
                  <div className="switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="metDataCheckbox"
                      checked={fetchMetData}
                      onChange={fetchMetDataChange}
                    />
                    <span className="slider round" />
                  </div>
                </label>
              </div>
            </>
          ) : (
            <></>
          )}

          <DataDownloadButton
            diagnosticMode={queryStringParams.diagnostics === 'true'}
            handleDataNarrativeDialog={handleDataNarrativeModal}
            handleDownloadDialog={handleDownloadModal}
            on={
              (!progressBarOn &&
                unitHistories &&
                Object.keys(unitHistories).length > 0) ||
              showingAnnualAverage
            }
          />
          <Modal
            on={downloadModalOn}
            modalComponent={
              <DataDownloadModal
                handleDownloadData={handleDownloadData}
                handleDownloadDialog={handleDownloadModal}
                unitList={unitList}
                annualAvgData={annualAvgData}
                showingAnnualAverage={showingAnnualAverage}
              />
            }
          />
          <Modal
            on={dataNarrativeModalOn}
            modalComponent={
              <DataDiagnosticsModal
                dataNarrative={dataNarrative}
                handleDataNarrativeDialog={handleDataNarrativeModal}
              />
            }
          />
        </header>
        
        {!isPublic && (
          <AnnouncementBanner>
            <p>
              Data is being replaced by Analysis. All functionality is retained
              along with new analysis tools to get the most from your data.
            </p>
          </AnnouncementBanner>
        )}

        <div className="container">
          <SpeciesTabs
            progressBarOn={progressBarOn}
            loading={historicalData.loading}
            activeTab={activeTab}
            slot={slot}
            displayConfig={displayConfig}
            handleTabSwitch={handleTabSwitch}
            unitHistories={unitHistories}
            zephyr={zephyr}
            chartData={chartData}
            handleSpeciesFilter={handleSpeciesFilter}
            speciesFiltered={speciesFiltered}
            handleSlotChange={handleSlotChange}
            averagingOption={averagingOption}
            showingAnnualAverage={showingAnnualAverage}
            annualAvgData={annualAvgData}
            defaultActiveTab={defaultActiveTab}
            setDefaultActiveTab={setDefaultActiveTab}
            setActiveTab={setActiveTab}
            isDSEEnabled={isDSEEnabled}
            thresholdExceedingSpecies={thresholdExceedingSpecies}
          />

          <DataChartContainer
            showingAnnualAverage={showingAnnualAverage}
            annualAvgData={annualAvgData}
            averagingOption={averagingOption}
            chartData={showingAnnualAverage ? annualAvgData.chart : chartData}
            // chartData={chartData}
            date={date}
            dataLoading={historicalData.loading}
            displayConfig={displayConfig}
            handleRemoveUnit={handleRemoveUnit}
            handleUnitSpeciesFilter={handleUnitSpeciesFilter}
            isPublic={isPublic}
            progressBarOn={progressBarOn}
            requestErrors={requestErrors}
            setProgressBarOn={setProgressBarOn}
            setUnitList={setUnitList}
            setUnitListGrowing={setUnitListGrowing}
            timezone={timezone}
            timePeriod={timePeriod}
            timePeriodSwitching={timePeriodSwitching}
            unitHistories={unitHistories}
            unitList={unitList}
            zephyr={zephyr}
            statusCode={statusCode!}
            setRequestErrors={setRequestErrors}
            isDSEEnabled={isDSEEnabled}
            setUnitHistories={setUnitHistories}
            setHasZephyrNull={setHasZephyrNull}
            hasZephyrNull={hasZephyrNull}
            statusDescription={statusDescription}
          />
        </div>
      </main>
    </>
  );
};

const SpeciesTabs = ({
  progressBarOn,
  loading,
  activeTab,
  slot,
  displayConfig,
  handleTabSwitch,
  unitHistories,
  zephyr,
  chartData,
  handleSpeciesFilter,
  speciesFiltered,
  handleSlotChange,
  averagingOption,
  showingAnnualAverage,
  annualAvgData,
  hasVirtualZephyr,
  defaultActiveTab,
  setDefaultActiveTab,
  setActiveTab,
  isDSEEnabled,
  thresholdExceedingSpecies,
}: any) => {
  if (progressBarOn && loading)
    return (
      <>
        <div className="filter-placeholder" />
        <div className="species-filter-placeholder" />
      </>
    );

  return (
    <>
      <DataGroupTabs
        activeTab={activeTab}
        curSlot={slot}
        displayConfig={displayConfig}
        handleTabSwitch={handleTabSwitch}
        unitHistories={unitHistories}
        zephyr={zephyr}
        showingAnnualAverage={showingAnnualAverage}
        annualAvgData={annualAvgData}
        hasVirtualZephyr={hasVirtualZephyr}
        defaultActiveTab={defaultActiveTab}
        setDefaultActiveTab={setDefaultActiveTab}
        setActiveTab={setActiveTab}
        thresholdExceedingSpecies={thresholdExceedingSpecies}
      />
      {averagingOption ? (
        <DataSpeciesFilters
          chartData={chartData}
          handleSpeciesFilter={handleSpeciesFilter}
          progressBarOn={progressBarOn}
          speciesFiltered={speciesFiltered}
          handleSlotChange={handleSlotChange}
          curSlot={slot}
          timeAveragingOptions={averagingOption.averagingOption}
          unitHistories={unitHistories}
          zephyr={zephyr}
          isDSEEnabled={isDSEEnabled}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  asideOpen: state.aside.on,
  averagingPeriods: state.setAveragingPeriods,
  caching: state.auth.userInfo.caching,
  dataConfig: state.dataConfig,
  displayConfig: state.setDisplayConfig,
  downloadModalOn: state.modal.data,
  historicalData: state.getZephyrHistoricalData,
  isPublic: state.auth.userInfo.isPublic,
  queryStringParams: state.queryStringParams,
  timePeriods: state.setTimePeriods,
  zephyr: state.setZephyr.zephyr,
  annualAvgData: state.setAnnualAverages,
  curOverlayName: state.showAQMALayer.overlay,
  overlay: state.showAQMALayer.overlay,
  overlays: state.setOverlays,
  modularFeatures: state.modularFeatures,
  bearerToken: state.auth.bearerToken,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      endLoading,
      getZephyrData,
      setDataModalOn,
      setMappairPointModeOff,
      setModalsOff,
      setZephyr,
      startLoading,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Data);
