// Package Imports
import React from 'react';

// Component Interfaces
interface ModalProps {
  additionalClass?: string;
  on: boolean;
  modalComponent: React.FunctionComponentElement<any>;
}

// Component
const Modal = ({ additionalClass, on, modalComponent }: ModalProps) => {
  return on ? (
    <div className={`modal-wrapper ${additionalClass || ''}`}>
      {modalComponent}
    </div>
  ) : (
    <></>
  );
};

export default Modal;
