// Package Imports
import React from 'react';
import Plot from 'react-plotly.js';

// Type Imports
import { WindRoseData } from '../../utils/interface';
import { plotlyFont } from '../../utils/plotySharedConfig';

// Component Interfaces
interface AQWindChartProps {
  windData: WindRoseData[];
}

// Component
const AQWindChart = ({ windData }: AQWindChartProps) => {
  return (
    <div className="wind-rose-container">
      {windData ? (
        <>
          <div className="wind-rose-chart-container">
            <Plot
              // windData has 1 extra unit property that won't impact the Plotly API
              data={windData as any}
              layout={
                {
                  font: plotlyFont,
                  showlegend: false,
                  polar: {
                    angularaxis: {
                      ticklen: 10,
                      color: '#252834',
                      direction: 'clockwise',
                    },
                    radialaxis: {
                      angle: 0,
                      tickcolor: '#252834',
                      tickwidth: 1,
                      tickangle: 0,
                      ticklen: 3,
                      fixedrange: true,
                      tickmode: 'auto',
                      nticks: 4,
                      tickfont: {
                        family: 'Open Sans',
                        size: 10,
                      },
                    },
                  },
                  margin: {
                    l: 50,
                    r: 50,
                    b: 0,
                    t: 0,
                    pad: 4,
                  },
                  autosize: true,
                  dragmode: false,
                } as any
              }
              config={{ displayModeBar: false }}
              useResizeHandler
              style={{ width: '100%', height: '100%' }}
            />
          </div>
          <ul className="wind-rose-legend">
            {windData.map((wD) => (
              <li key={wD.name} className="wind-rose-legend-item">
                <div
                  className="wind-rose-legend-block"
                  style={{ background: wD.marker.color }}
                />
                <div className="wind-rose-legend-title">{wD.name}</div>
                {/* <div className="wind-rose-legend-units">{wD.unit}</div> */}
              </li>
            ))}
          </ul>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AQWindChart;
