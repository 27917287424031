// Package Imports
import React, { useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

// Component Imports
import GaugeChart from 'react-gauge-chart';

// Util Imports
import qualityCalculator from '../../utils/functions/qualityCalculator';
import {
  colourConverter,
  colourFinder,
} from '../../utils/functions/colourFinder';
import utilsRequest from '../../utils/request';
import { formatGoogleAddress } from '../../utils/functions/googleFormatter';
import { todayDataPackager } from '../../utils/functions/todayDataPackager';

// Const Imports
import {
  speciesMap,
  TodayContent,
  todaySubspeciesMap,
} from '../../utils/consts';

// Action Imports
import { setAQ101On, setAQ101Section } from '../../actions/aq101';
import { setTodayData } from '../../actions/today';

// Type Imports
import {
  DisplayConfig,
  GooglePlace,
  Overlay,
  PlacePrediction,
  ReduxState,
  Stylegroup,
  TodayData,
  TodayDatasetsPackaged,
  UserInfo,
} from '../../utils/interface';

interface InitState {
  curDay: number;
  packagedTodayData: TodayDatasetsPackaged | null;
  predictions: PlacePrediction[];
  search: string;
  searchOn: boolean;
}

interface TodayProps {
  displayConfig: DisplayConfig;
  overlay: string;
  overlays: Overlay[];
  setAQ101On: Function;
  setAQ101Section: Function;
  setTodayData: Function;
  stylegroups: Stylegroup[];
  thresholdTab: string;
  todayData: TodayData | null;
  userInfo: UserInfo;
  bearerToken: string | null;
}

// Component
const Today = ({
  displayConfig,
  overlay,
  overlays,
  setAQ101On,
  setAQ101Section,
  setTodayData,
  stylegroups,
  thresholdTab,
  todayData,
  userInfo,
  bearerToken,
}: TodayProps) => {
  const { t: translate } = useTranslation();

  // State
  const initState: InitState = {
    curDay: 0,
    packagedTodayData: null,
    predictions: [],
    search: '',
    searchOn: false,
  };

  const [curDay, setCurDay] = useState(initState.curDay);
  const [packagedTodayData, setPackagedTodayData] = useState(
    initState.packagedTodayData,
  );
  const [predictions, setPredictions] = useState(initState.predictions);
  const [search, setSearch] = useState(initState.search);
  const [searchOn, setSearchOn] = useState(initState.searchOn);

  // Consts
  const inputEl = useRef<HTMLInputElement | null>(null);

  // Effects
  useEffect(() => {
    if (todayData) {
      const formattedTodayData: any = todayData.meta.timestamps.map(() => []);
      todayData.results.forEach((r) => {
        r.values_coordinates[0].values_timestamps.forEach(
          (rvt: any, idx: number) => {
            const year = parseInt(rvt.timestamp.slice(6, 10));
            const day = parseInt(rvt.timestamp.slice(0, 2));
            const month = parseInt(rvt.timestamp.slice(3, 5)) - 1;
            const newDate = new Date(year, month, day);
            const dayOfWeek = newDate.toString().slice(0, 3);
            const newRVT = {
              advice: rvt.advice,
              advice_sensitive: rvt.advice_sensitive,
              behavioural_change: rvt.behavioural_change,
              day: dayOfWeek,
              label: rvt.label,
              species: speciesMap[r.species],
              timestamp: rvt.timestamp,
              unit: r.units || rvt.units || '',
              value: rvt.value,
            };
            formattedTodayData[idx].push(newRVT);
          },
        );
      });
      const finalPackagedTodayData = {
        data: formattedTodayData,
        species: todayData.meta.species_list,
        location: todayData.location,
      };
      setPackagedTodayData(finalPackagedTodayData);
    }
  }, [todayData]);

  // Need this because MA species mismatch, TODO: change MA species or create new map config
  const getCurOverlay = () => {
    const curOverlay = getSpeciesLabel()
      ? overlays.filter((ol) => ol.name === overlay)[0]
      : overlays.filter((ol) => ol.name.toLowerCase().includes('aqi'))[0];
    return curOverlay;
  };

  const getSpeciesColor = (val: number) => {
    const curOverlay = getCurOverlay();
    const speciesColor = colourFinder(
      curOverlay,
      stylegroups,
      thresholdTab,
      0.9,
      overlays,
      val,
      'mappair',
    );
    return speciesColor;
  };

  const getSpeciesColorArray = () => {
    const curOverlay = getCurOverlay();
    const curStylegroups = stylegroups.filter(
      (group) => group.name === thresholdTab,
    );
    if (curStylegroups.length && curOverlay) {
      const colors: string[] = [];
      const thresholds = curStylegroups[0].thresholds[curOverlay.name];
      thresholds.forEach((th) => {
        const rgb = { r: `${th.r}`, g: `${th.g}`, b: `${th.b}` };
        const color = colourConverter(rgb);
        colors.push(`#${color}`);
      });
      return colors;
    }
    return [];
  };

  const getSpeciesLabel = () => {
    // Empty string as AQI needs no further header
    let speciesLabel = '';
    if (userInfo.todaySpecies) {
      displayConfig.tabs.forEach((tab) => {
        tab.species.forEach((sp) => {
          if (sp.label.toLowerCase() === userInfo!.todaySpecies) {
            speciesLabel = `: ${sp.HTMLLabel}`;
          }
        });
      });
    }
    return speciesLabel;
  };

  const getSpeciesScore = (val: number) => {
    if (getSpeciesLabel()) {
      const speciesScore = qualityCalculator(
        val,
        overlay,
        thresholdTab,
        stylegroups,
      );
      return speciesScore;
    }
    // AQI scores are 0 based
    return val + 1;
  };

  const getGaugePercentage = () => {
    let aqPercentage = 0;
    if (packagedTodayData) {
      const curVal = packagedTodayData.data[curDay][0].value;
      if (typeof curVal === 'number' && curVal !== -999) {
        const aqScore = getSpeciesScore(curVal);
        if (aqScore) {
          aqPercentage = aqScore / 10;
        }
      }
    }
    return aqPercentage;
  };

  const handleAQ101 = (sectionId?: string) => {
    if (sectionId) {
      setAQ101Section(sectionId);
    }
    setAQ101On();
  };

  const handlePlaceSelection = async (placeId: string) => {
    const place = await utilsRequest.getLocationFromPlaceId(placeId);
    if (place.results.length && userInfo.today && userInfo.todaySpecies) {
      const { lat, lng } = place.results[0].geometry.location;
      const updatedTodayData = await todayDataPackager(userInfo.todaySpecies, [
        lat,
        lng,
      ], bearerToken);
      if (updatedTodayData) {
        setTodayData(updatedTodayData);
      }
    }
    setSearchOn(false);
    setSearch('');
  };

  const handleSearch = async (input: string) => {
    if (input) {
      const searchBias = [
        userInfo.minLat,
        userInfo.minLng,
        userInfo.maxLat,
        userInfo.maxLng,
      ];
      await utilsRequest.getLocationSuggestions(
        input,
        searchBias,
        (googlePredictions: GooglePlace[]) => {
          if (googlePredictions && googlePredictions.length) {
            const updatedPredictions = googlePredictions.map((gp) => ({
              description: gp.description,
              placeId: gp.place_id,
            }));
            if (updatedPredictions.length > 3) {
              updatedPredictions.length = 3;
            }
            setPredictions(updatedPredictions);
          }
        },
      );
    } else {
      setPredictions([]);
    }
    setSearch(input);
  };

  const handleSearchStatus = (on: boolean) => {
    if (on) {
      setSearchOn(true);
      if (inputEl.current) {
        inputEl.current.focus();
      }
    } else {
      setSearch('');
      setSearchOn(false);
      setPredictions([]);
    }
  };

  return (
    <div className="today-public">
      <div className="today-content-container">
        {packagedTodayData &&
        packagedTodayData.data &&
        packagedTodayData.data[curDay] &&
        stylegroups.length &&
        thresholdTab &&
        overlay ? (
          <>
            <div className="header-section">
              <div
                className={`header-section-search ${searchOn ? 'active' : ''}`}
              >
                <div className="header-section-top-bar">
                  <input
                    ref={inputEl}
                    type="text"
                    value={search}
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search location"
                  />
                  <div className="search-item cancel">
                    <button
                      onClick={() => handleSearchStatus(false)}
                      type="button"
                    />
                  </div>
                </div>
                {predictions.length ? (
                  <ul>
                    {predictions.map((p) => (
                      <li
                        onClick={() => handlePlaceSelection(p.placeId)}
                        key={p.placeId}
                      >
                        {formatGoogleAddress(p.description)}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <></>
                )}
              </div>
              {!searchOn ? (
                <div
                  className="header-section-unsearch"
                  onClick={() => handleSearchStatus(true)}
                >
                  <h4 className="location-header">
                    {packagedTodayData.location.desc}
                  </h4>
                  <div className="search-item">
                    <button onClick={() => handleSearchStatus(true)} />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="forecast-section">
              <h4>
                {translate('ForecastHeader')}
                {parse(getSpeciesLabel())}
              </h4>
              <div className="forecast-items">
                {packagedTodayData.data.map((td: any, idx: number) => (
                  <div
                    onClick={() => setCurDay(idx)}
                    key={`${td[0].day}-${idx}`}
                    className={`forecast-item ${
                      idx === curDay ? 'active' : ''
                    }`}
                  >
                    <div className="forecast-day">{td[0].day}</div>
                    <div
                      className="forecast-value"
                      style={{
                        backgroundColor: `${getSpeciesColor(td[0].value)}`,
                      }}
                    >
                      <div>
                        {typeof td[0].value === 'number' && td[0].value !== -999
                          ? getSpeciesScore(td[0].value)
                          : 'NA'}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="cur-day-section">
              <h4>
                {translate('PollutionHeader')}
                {parse(getSpeciesLabel())}
              </h4>
              <div className="cur-day-section-main">
                <div className="aq-score-main">
                  <div className="gauge-container">
                    <GaugeChart
                      id="gauge-chart4"
                      nrOfLevels={10}
                      arcPadding={0.05}
                      arcWidth={0.3}
                      cornerRadius={4}
                      percent={getGaugePercentage()}
                      animate={false}
                      formatTextValue={(v) => `${parseInt(v) / 10}`}
                      textColor="#1d1e30"
                      style={{ height: 100, width: 220 }}
                      colors={getSpeciesColorArray()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="information-section">
              <h4>{translate('AdviceHeader')}</h4>
              <div className="advice-item">
                Take our{' '}
                <span onClick={() => handleAQ101()}>intro on Air Quality</span>{' '}
                to learn more about the impacts of pollution and how you can
                help.
              </div>
              <h5>
                {userInfo.todayCustomInfo
                  ? userInfo.todayCustomInfo.customTitle
                  : translate('BehaviourHeader')}
              </h5>
              <div className="advice-item">
                <p>
                  {parse(packagedTodayData.data[curDay][0].behavioural_change)}{' '}
                </p>
                {userInfo.todayCustomInfo ? (
                  <p>
                    {userInfo.todayCustomInfo.preText}{' '}
                    <span
                      onClick={() =>
                        handleAQ101(userInfo.todayCustomInfo!.spanSectionLink)
                      }
                    >
                      {userInfo.todayCustomInfo.spanText}
                    </span>{' '}
                    {userInfo.todayCustomInfo.postText}
                  </p>
                ) : (
                  <></>
                )}
              </div>
              <h5>{translate('HealthAdviceHeader')}</h5>
              <div className="advice-item">
                <p>
                  Find guidance on the health effects of air pollution in our{' '}
                  <span onClick={() => handleAQ101('HealthEffects')}>
                    guide
                  </span>
                  .
                </p>
                {packagedTodayData.data[curDay][0].advice !== 'N/A' ? (
                  <p>{parse(packagedTodayData.data[curDay][0].advice)}</p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  displayConfig: state.setDisplayConfig,
  overlay: state.showAQMALayer.overlay,
  overlays: state.setOverlays,
  stylegroups: state.getStyleGroups.styleGroups,
  thresholdTab: state.setThresholdTab.tab,
  todayData: state.setTodayData,
  userInfo: state.auth.userInfo,
  bearerToken: state.auth.bearerToken,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setAQ101On,
      setAQ101Section,
      setTodayData,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Today);
