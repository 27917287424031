// Asset Imports
import { defaultModelUnit } from '../consts';

// Type Imports
import { DisplayConfig, Overlay } from '../interface';

const overlayUnitFinder = (displayConfig: DisplayConfig, overlay: Overlay) => {
  let unit = defaultModelUnit;
  if (overlay) {
    if (overlay.isSAOverlay) {
      unit = displayConfig.sources[0].HTMLExposedUnitLabel;
    } else {
      displayConfig.tabs.forEach((tab) =>
        tab.species.forEach((species) => {
          if (species.label === overlay.speciesIdentifier) {
            unit = species.HTMLExposedUnitLabel;
          }
        }),
      );
    }
  }
  return unit;
};

export { overlayUnitFinder };
