import { SpeciesExtentIdentifiers, Zephyr } from "../interface";

type ErrorMessage = string;

interface SubTableErrorMessages {
    noLocation: ErrorMessage,
    noSlotSpecies: ErrorMessage,
    noExternalSensorsConnected: ErrorMessage,
    noBatteryChargeInformation: ErrorMessage
};

const errorMessages: SubTableErrorMessages = {
    noLocation: 'No Information',
    noSlotSpecies: '-',
    noExternalSensorsConnected: 'None',
    noBatteryChargeInformation: 'No Information'
};

const extractSlotSpecies = (species: SpeciesExtentIdentifiers[]) => {
    let speciesText = '';
    const filteredSpecies = species.filter((s: string) => s !== 'BT' && s !== 'BRH');
    for (let i = 0; i < filteredSpecies.length; i++) {
        if (i < filteredSpecies.length - 1) speciesText += `${filteredSpecies[i]}, `;
        if (i === filteredSpecies.length - 1) speciesText += filteredSpecies[i];
    }
    return speciesText;
};

const tableSubRowGenerator = (zephyr: Zephyr) => {
    const hasSlotASpecies = zephyr.type === 0 ? zephyr.slotsInfo.slotA && zephyr.slotsInfo.slotA.species : null;
    const hasSlotBSpecies = zephyr.type === 0 ? zephyr.slotsInfo.slotB && zephyr.slotsInfo.slotB.species : null;
    const slotASpecies = zephyr.type === 0 ? zephyr.slotsInfo.slotA?.species : null;
    const slotBSpecies = zephyr.type === 0 ? zephyr.slotsInfo.slotB?.species : null;
    const hasZephyrLocation = zephyr.location;
    const hasPowerMode = zephyr.type === 0 || zephyr.type === 100 ? zephyr.SampPeriod ? zephyr.SampPeriod : null : null;
    const powerMode = hasPowerMode ? hasPowerMode > 7 && hasPowerMode < 20 ? 'High Power' : hasPowerMode >= 50 && hasPowerMode < 60 ? 'Normal Power' : 'Low Power' : 'Low Power';
    const externalSensorsConnected = zephyr.type === 0 ? zephyr.HasMetStation ? 'Met Station' : errorMessages.noExternalSensorsConnected : errorMessages.noExternalSensorsConnected;
    return (
        `
        <div>
        <h3 class="drop-down-view__zephyr-title">Zephyr ${zephyr.zNumber}</h3>
        <table cellpadding="5" cellspacing="0" border="0" style="padding-left:27px;"> 
        <tr> 
        <td>GPS Setting:</td> 
        <td> 
        ${hasZephyrLocation ? hasZephyrLocation : errorMessages.noLocation} 
        </td> 
        </tr> 
        <tr> 
        <td>External Sensors Connected:</td> 
        <td> 
        ${externalSensorsConnected} 
        </td> 
        </tr> 
        <tr> 
        <td>Slot A Species:</td> 
        <td>
        ${!hasSlotASpecies ?
            errorMessages.noSlotSpecies : extractSlotSpecies(slotASpecies!)} 
        </td> 
        </tr> 
        <tr> 
        <td>Slot B Species:</td> 
        <td>
        ${!hasSlotBSpecies ?
            errorMessages.noSlotSpecies : extractSlotSpecies(slotBSpecies!)} 
        </td> 
        </tr> 
        <tr> 
        <td>Power Mode:</td> 
        <td> 
        ${hasPowerMode ? powerMode : 'Low Power'} 
        </td> 
        </tr>
        </table>
        </div>
        `
    );
};

export {
    tableSubRowGenerator
};