import React from 'react';
import { timeSliderOptions } from '../../utils/consts';
import { TimeSliderOption } from '../../utils/interface';

interface TimeSliderProps {
    asideOn: boolean;
    curTimeSliderOption: number | null;
    setCurTimeSliderOption: Function | null;
    wmsDateTime: Date | null;
    setWMSDateTime: Function | null;
};

const TimeSlider = ({ asideOn, curTimeSliderOption, setCurTimeSliderOption, wmsDateTime, setWMSDateTime }: TimeSliderProps) => {

    const set7DaysOR1YearDateTime = (daysBack: number) => {
        if (setWMSDateTime) {
            const date = new Date();
            date.setDate(date.getDate() - daysBack);
            setWMSDateTime(date.toISOString());
        }
    }

    const setDateTime = (id: number) => {
        switch (id) {
            case 0:
                if (wmsDateTime && setWMSDateTime) setWMSDateTime(null);
                break;
            case 1:
                setWMSDateTime!(new Date().toISOString());
                break;
            // case 2:
            //     set7DaysOR1YearDateTime(90);
            //     break;
            case 2:
                if (wmsDateTime && setWMSDateTime) setWMSDateTime(null);
                break;
            default:
                setWMSDateTime!(new Date().toISOString);
        }
    }

    const changeTimeSliderOption = (id: number) => {
        if (id !== curTimeSliderOption && setCurTimeSliderOption && setWMSDateTime) {
            setCurTimeSliderOption(id);
            setDateTime(id);
        };
    };

    const extractTimeSliderOptions = () => {
        return timeSliderOptions.map((o: TimeSliderOption) => {
            return (
                <div className={`time-slider-option ${curTimeSliderOption === o.id ? 'active-slider-option' : ''}`} key={o.id} onClick={() => { changeTimeSliderOption(o.id) }}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path className={curTimeSliderOption === o.id ? `active-path` : ''} fillRule={curTimeSliderOption === o.id ? `evenodd` : undefined} clipRule={curTimeSliderOption === o.id ? `evenodd` : ''} d={o.d} fill="#545454"></path>
                    </svg>
                    {o.text}
                </div>
            );
        })
    }

    return <div className={`map-layers-group ${asideOn ? '' : 'expand'} time-slider__container`} style={{ position: 'absolute', top: 20 }}>
        {extractTimeSliderOptions()}
    </div>
};

export default TimeSlider;