// Types Imports
import { Dispatch } from 'redux';
import { OPEN_ASIDE, CLOSE_ASIDE } from './types';

// Action Creators
export const openAside = () => (dispatch: Dispatch) => {
  dispatch({
    type: OPEN_ASIDE,
  });
};

export const closeAside = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLOSE_ASIDE,
  });
};
