// Package Imports
import React from 'react';

// Const Imports
import { FeedbackTagContent } from '../../utils/consts';

// Component Interfaces
interface FeedbackTagProps {
  on: boolean;
  setFeedbackOn: Function;
}

// Component
const FeedbackTag = ({ on, setFeedbackOn }: FeedbackTagProps) => {
  return (
    <>
      {on ?  (
        <button
          onClick={() => setFeedbackOn()}
          type="button"
          className="feedback-tag"
        >
          <div>{FeedbackTagContent.feedbackTagButton}</div>
        </button>
      ) : (
        <></>
      )}
    </>
  );
}
export default FeedbackTag;
