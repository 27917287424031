// Package Imports
import React from 'react';

// Type Imports
import { Zephyr } from '../../utils/interface';

// Types
interface LocationMarkerProps {
  isActive: boolean;
  isVisible: boolean;
  markerColour: string;
  selectUnit: Function;
  showLabel: boolean;
  unit: Zephyr;
}
interface MarkerProps {
  isActive: boolean;
  markerColour: string;
  showLabel: boolean;
  unit: Zephyr;
}

// issue with react fragment, needed to use a normal html element as wrapper(div)

const MarkerType = ({
  unit,
  markerColour,
  showLabel,
  isActive,
}: MarkerProps) => {
  return (
    <div>
      <div
        className={`marker type-${unit.type} ${isActive ? 'active' : ''}`}
        style={{ background: markerColour }}
      >
        {unit.type === 1 ? (
          <div
            className="triangle-container"
            style={{ borderBottomColor: markerColour }}
          />
        ) : (
          <></>
        )}
      </div>
      <div className={`marker-label ${showLabel ? 'show' : ''}`}>
        {unit.name}
      </div>
    </div>
  );
};

const LocationMarker = ({
  isActive,
  isVisible,
  markerColour,
  selectUnit,
  showLabel,
  unit,
}: LocationMarkerProps) => {
  return (
    <div>
      <div
        className={`marker-container ${
          isVisible ? 'marker-visible' : 'marker-invisible'
        }`}
        id={`${unit.id_pod}-${unit.type}`}
        onClick={() => selectUnit(unit)}
      >
        <MarkerType
          unit={unit}
          markerColour={markerColour}
          showLabel={showLabel}
          isActive={isActive}
        />
      </div>
    </div>
  );
};

export default LocationMarker;
