// Type Imports
import { SET_LOCALE } from '../actions/types';
import { i18n } from '../translations/i18n';

// Type Safety
interface setLocale {
  type: typeof SET_LOCALE;
  payload: string[];
}

// State
const initState: string = i18n.language || localStorage.i18nextLng;

// Reducer
export default function (state = initState, action: setLocale) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOCALE:
      return payload;

    default:
      return state;
  }
}
