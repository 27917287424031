export const formatGoogleAddress = (address: string, shortFormat?: boolean) => {
  const nonUKAddress = address.replace(/, UK/g, '');
  const formattedGoogleAddress = nonUKAddress.replace(/Unnamed Road, /g, '');
  if (shortFormat) {
    const googleAddressArray = formattedGoogleAddress.split(',');
    if (googleAddressArray.length > 2) {
      const croppedGoogleAddress = [
        googleAddressArray[0],
        googleAddressArray[googleAddressArray.length - 1],
      ].join(', ');
      return croppedGoogleAddress;
    }
  }
  return formattedGoogleAddress;
};
