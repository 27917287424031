import * as React from 'react';
import Tour, { ReactourStep } from 'reactour';
import { useSelector, useDispatch } from 'react-redux'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { ReduxState } from '../../../utils/interface';
import { setChartViewMode } from '../../../actions/chartViewMode';


const stepStyle: React.CSSProperties = {
  height: 250,
  width: 330,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export default function AnalysisTour() {
  const [show, setShow] = React.useState(false);

  const analysisTourOpen = useSelector((state: ReduxState) => state.analysisTourOpen);
  const dispatch = useDispatch()

  const disableBody = (target: HTMLElement) => disableBodyScroll(target);
  const enableBody = (target: HTMLElement) => enableBodyScroll(target);

  const steps: ReactourStep[] = [
    {
      selector: '',
      content: () => (
        <div className="tour-content">
          Analysis provides a comprehensive toolkit to explore your data, enabling detailed insights and seamless reporting.
        </div>
      ),
      style: stepStyle
    },

    {
      selector: '[data-tour="step-1"]',
      position: 'center',
      content: () => (
        <div className="tour-content">
          Select your devices, pollutants, period and time averaging options. Then press run to retrieve the data.
        </div>
      ),
      style: stepStyle
    },

    {
      selector: '[data-tour="step-3"]',
      position: 'center',
      content: () => (
        <div className="tour-content">
          The device view focuses on data from individual units. Switch between them here.
        </div>
      ),
      style: stepStyle,
      action() {
        dispatch(setChartViewMode('deviceView'));
      },
      highlightedSelectors: ['#select-chart-mode']
    },

    {
      selector: '[data-tour="step-3"]',
      position: 'center',
      content: () => (
        <div className="tour-content">
          The network view lets you see a summarised view of all the devices currently selected.
        </div>
      ),
      action() {
        dispatch(setChartViewMode('networkView'));
      },
      style: stepStyle,
      highlightedSelectors: ['#select-chart-mode']
    },

    {
      selector: '[data-tour="step-3"]',
      position: 'center',
      content: () => (
        <div className="tour-content">
          Comparison allows you to compare devices directly. Add devices using the plus icon on each badge and remove them with the cross.
        </div>
      ),
      action() {
        dispatch(setChartViewMode('comparisonView'));
      },
      style: stepStyle,
      highlightedSelectors: ['#select-chart-mode']
    },

    {
      selector: '[data-tour="step-6"]',
      position: 'center',
      content: () => (
        <div className="tour-content">
          Using these tools you can scroll, crop and resize the chart as desired. The three dots menu provides functional actions, such as adding threshold lines.
        </div>
      ),
      action() {
        dispatch(setChartViewMode('networkView'));
      },
      style: stepStyle
    },

    {
      selector: '[data-tour="step-7"]',
      position: 'center',
      content: () => (
        <div className="tour-content">
          Press Download CSV on any view to generate a CSV specific to the current view.
        </div>
      ),
      style: stepStyle
    },

    {
      selector: '[data-tour="step-8"]',
      position: 'center',
      content: () => (
        <div className="tour-content">
          Use 'Save view' to the current settings and revisit later through the 'Load View'.
        </div>
      ),
      style: stepStyle
    },
    {
      selector: '[data-analysis-tour="step-9"]',
      position: 'center',
      content: () => (
        <div className="tour-content">
          Revisit this tour or the tours on other pages anytime from within the Help menu.
        </div>
      ),
      style: stepStyle
    },
  ];

  return (
      <Tour
        steps={steps}
        isOpen={analysisTourOpen || show}
        onRequestClose={() => {
          setShow(false)
          dispatch({ type: 'analysis_tour_toggle' })
          dispatch(setChartViewMode('deviceView'));
        }}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        startAt={0}
        closeWithMask={false}
        disableInteraction
        disableDotsNavigation
        showNavigationNumber={false}
        lastStepNextButton={<p style={{ margin: 0, fontSize: 16 }}>Finish</p>}
        badgeContent={(curr) => `${curr}/${steps.length}`}
        accentColor="#4e73c3"
        rounded={3}
      />
  );
}
