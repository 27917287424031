// Package Imports
import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';

// Type Imports
import { DropdownSelection, Overlay, ReduxState, Stylegroup } from '../../utils/interface';
import { connect } from 'react-redux';

// Type Safety
interface DropdownProps {
  currentPollutant?: string | null;
  setCurrentPollutant?: Function;
  additionalClass?: string;
  currentSelectionValue: string;
  handleSelection: Function;
  id: string;
  placeholderSelectionLabel: string;
  selections: DropdownSelection[];
  stylegroups: Stylegroup[];
  threshold: string;
  curOverlayName: string;
  overlays: Overlay[];
  mappAirFilters: boolean;
  alert?: any;
  selectedAlert?: any;
  annualAveragesOn?: boolean;
  dynamicStyles?: any;
}

// Component
const Dropdown = ({
  currentPollutant,
  setCurrentPollutant,
  additionalClass,
  currentSelectionValue,
  handleSelection,
  id,
  placeholderSelectionLabel,
  selections,
  alert,
  selectedAlert,
  annualAveragesOn,
  stylegroups,
  threshold,
  curOverlayName,
  overlays,
  mappAirFilters,
  dynamicStyles,
}: DropdownProps) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const currentSelectionLabel = !selectedAlert || (id !== 'perioddropdown' && currentSelectionValue)
    ? selections.filter((s) => s.value === currentSelectionValue).length
      ? selections.filter((s) => s.value === currentSelectionValue)[0].label
      : placeholderSelectionLabel
    : !currentSelectionValue && id === 'perioddropdown' && alert.airAlerts[0].Duration ? selections.filter((s) => s.value === alert.airAlerts[0].Duration.toString())[0].label : currentSelectionValue && id === 'perioddropdown' ? selections.filter((s) => s.value === currentSelectionValue)[0].label : placeholderSelectionLabel;


  const handleDropdownSelection = (value: string) => {
    setShowDropdown(false);
    handleSelection(id, value);
    if (id === 'species-selection' && setCurrentPollutant) setCurrentPollutant(value)
  };

  useEffect(() => {
    if (id === 'species-selection' && setCurrentPollutant && !currentPollutant) setCurrentPollutant(currentSelectionValue);
  }, [currentSelectionValue]);

  const curStylegroup = stylegroups.filter(
    (group) => group.name === threshold,
  )[0];

  const getOverlay = (updatedSpeciesIdentifier: string) => {
    return overlays.filter(
      (ol) =>
        ol.speciesIdentifier === updatedSpeciesIdentifier &&
        (mappAirFilters
          ? ol.isMappairOverlay
          : !ol.isMappairOverlay && !ol.isSAOverlay),
    )[0]
  };

  return (
    <div className={`custom-dropdown-container ${additionalClass || ''}`}>
      <div className="custom-dropdown-label-container">
        <div
          className="custom-dropdown-label"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          {parse(currentSelectionLabel)}
        </div>
        <ul
          className={showDropdown ? 'active-list' : 'inactive-list'}
          onMouseLeave={() => setShowDropdown(false)}
        >
          {selections
            .filter((sf) => {
              if (!annualAveragesOn) return sf.value !== currentSelectionLabel;
              return sf.value !== currentSelectionLabel && sf.value !== 'AQI';
            })
            .map((s) => (
              <li
                key={`${id}-${s.value}`}
                onClick={() => handleDropdownSelection(s.value)}
                style={dynamicStyles && curStylegroup && {
                  pointerEvents: Object.keys(curStylegroup.thresholds).find((sg) => {
                    const reformattedSpecie = s.value === 'PM2p5' ? 'PM25' : s.value;
                    const value = getOverlay(s.value)?.name.includes(reformattedSpecie) && sg === getOverlay(s.value)?.name;
                    return value;
                  }) ? 'initial' : 'none', opacity: Object.keys(curStylegroup.thresholds).find((sg) => {
                    const reformattedSpecie = s.value === 'PM2p5' ? 'PM25' : s.value;
                    const value = getOverlay(s.value)?.name.includes(reformattedSpecie) && sg === getOverlay(s.value)?.name;
                    return value;
                  }) ? 1 : 0.5
                }}
              >
                <span>{parse(s.label)}</span>
              </li>
            ))}
        </ul>
      </div>
      <button
        className={`dropdown-button ${showDropdown ? 'active' : ''}`}
        onClick={(e) => {
          e.preventDefault();
          setShowDropdown(!showDropdown);
        }}
        type="button"
      />
    </div>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  stylegroups: state.getStyleGroups.styleGroups,
  threshold: state.setThresholdTab.tab,
  curOverlayName: state.showAQMALayer.overlay,
  overlays: state.setOverlays,
  mappAirFilters: state.mappAirFilters.showMappAirFilters
});

export default connect(mapStateToProps, {})(Dropdown);
