// Type Imports
import {
  UrlQueryStringOptions,
  UrlQueryStringParams,
} from '../../utils/interface';

/* Function
DESC: queryStringParser returns an object of keys and values parsed from a raw query string
ARGS: rawQueryString
*/
const queryStringParser = (rawQueryString: string) => {
  let qsParams: UrlQueryStringParams = { ctv: '', diagnostics: '' };
  let mode = '';
  let curKey: UrlQueryStringOptions = '';
  let curVal: UrlQueryStringOptions = '';
  rawQueryString.split('').forEach((char, idx, arr) => {
    const validateAndPushNewParam = () => {
      if (curKey in qsParams) {
        qsParams[curKey] = curVal;
      }
    };
    if (char === '&' || char === '?') {
      mode = 'key';
      if (char === '&') {
        validateAndPushNewParam();
        curKey = '';
        curVal = '';
      }
    } else if (char === '=') {
      mode = 'value';
    } else {
      if (mode === 'key') {
        curKey += char;
      } else {
        curVal += char;
      }
    }
    if (idx === arr.length - 1) {
      validateAndPushNewParam();
    }
  });
  return qsParams;
};

export { queryStringParser };
