// Const Imports
import { periods } from '../consts';

// Common JS Imports
const moment = require('moment');
const momentTimezone = require('moment-timezone');

const current = (format?: string) => {
  const dtFormat = format || 'YYYYMMDDHHmm';
  const currentTime = momentTimezone().tz("Europe/London").format(dtFormat);
  return currentTime;
};

const dateFinder = (date: Date, daysDiff: number, past: boolean) => {
  past
    ? date.setDate(date.getDate() - daysDiff)
    : date.setDate(date.getDate() + daysDiff);
  return date;
};

const defaultStartEndFinder = () => {
  const endTime = current();
  const period = periods.default;
  const startTime = subtractTimeFromNow(period.quantity, period.unit);
  return { start: startTime, end: endTime };
};

const getDateFromTimestamp = (date: string) => {
  const newlyFormattedDate = moment(date, 'YYYY-MM-DDZHH:mm:ss').format(
    'DD-MM-YYYY',
  );
  return newlyFormattedDate;
};

const getTimeOld = (date: string | null, dateFormat: string, unit: string) => {
  if (date) {
    const curDate = new Date().getTimezoneOffset() !== -60 ? moment(moment.tz(new Date(), 'Europe/London').format('YYYY-MM-DD HH:mm:ss')) : moment(new Date());
    const targetDate = moment(date, dateFormat);
    const difference = curDate.diff(targetDate, unit, true);
    return difference;
  }
  return 10;
};

const getShortDateFromISO = (date: string) => {
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  const day = date.slice(8, 10);
  const newlyFormattedStringDate = `${day}-${month}-${year}`;
  return newlyFormattedStringDate;
};

const startEndFinder = (timePeriod: string) => {
  const endTime = current();
  const period = periods[timePeriod];
  const startTime = subtractTimeFromNow(period.quantity, period.unit);
  return { start: startTime, end: endTime };
};

const subtractTimeFromNow = (periodQuantity: number, periodUnit: string) => {
  const subtractedTime = momentTimezone(new Date()).tz("Europe/London")
    .subtract(periodQuantity, periodUnit)
    .format('YYYYMMDDHHmm');
  return subtractedTime;
};

const subtractTwoDates = (start: any, end: any) => {
  const strStart = start.toString();
  const strEnd = end.toString();
  const dateSplicer = (dateString: string, start: number, end: number) => {
    const dateSpliced = parseInt(dateString.slice(start, end));
    return dateSpliced;
  };
  const diffStart = moment([
    dateSplicer(strStart, 0, 4),
    dateSplicer(strStart, 4, 6) - 1,
    dateSplicer(strStart, 6, 8),
  ]);
  const diffEnd = moment([
    dateSplicer(strEnd, 0, 4),
    dateSplicer(strEnd, 4, 6) - 1,
    dateSplicer(strEnd, 6, 8),
  ]);
  // true flag doesn't round the number
  const difference = diffEnd.diff(diffStart, 'days', true);
  return difference;
};

const todayHeaderFormatter = () => {
  const today = moment().format('dddd MMMM Do YYYY');
  return today;
};

const twinFormatDateFinder = (date: Date, daysDiff: number, past: boolean) => {
  const isoDate = dateFinder(date, daysDiff, past).toISOString;
  const stringDate = subtractTimeFromNow(daysDiff, 'days');
  const output = { isoDate, stringDate };
  return output;
};

const tzConverter = (date: any) => {
  const tz = new Date();
  const tzOffset = tz.getTimezoneOffset();
  const tzDate = moment(date, 'YYYYMMDDHHmm')
    .subtract(tzOffset, 'minutes')
    .format('YYYYMMDDHHmm');
  return tzDate;
};

const utcConvertor = (date: any) => {
  const ukOffset = moment.tz.zonesForCountry('GB', true)[0].offset;
  const utcDate = moment(date, 'YYYYMMDDHHmm')
    .add(ukOffset, 'minutes')
    .format('YYYYMMDDHHmm');
  return utcDate;
};

const getCurrentTimeZoneAbbreviation = () => {
  const curTimeZone = moment.tz.guess();
  const timeZoneAbbr = moment().tz(curTimeZone).format('z');
  return timeZoneAbbr;
};

const convertUnitTimeToLocalTime = (date: any) => {

  const convertedTime = `${moment(date).format('YYYY/MM/DD HH:mm:ss')} +0000`;
  return moment(new Date((typeof date === "string" ? new Date(convertedTime) : convertedTime)
    .toLocaleString("en-US", { timeZone: moment.tz.guess() })));
};

export {
  current,
  dateFinder,
  getDateFromTimestamp,
  getTimeOld,
  getShortDateFromISO,
  startEndFinder,
  defaultStartEndFinder,
  subtractTimeFromNow,
  subtractTwoDates,
  todayHeaderFormatter,
  twinFormatDateFinder,
  tzConverter,
  utcConvertor,
  getCurrentTimeZoneAbbreviation,
  convertUnitTimeToLocalTime
};
