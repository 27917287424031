// Type Imports
import { Zephyr } from '../interface';

/* Function
DESC: zephyrSorter maps the returned Zephyr API call to a new array of Zephyrs and processes them 
      through sorter(see above function)
ARGS: A Zephyr object returned from the Zephyr API
*/
const zephyrSorter = (units: Zephyr[], isPublic: boolean) => {
  const filteredUnits = units.filter(
    (u) => !isPublic || u.type !== 0 || !u.userEndTimeDate,
  );
  filteredUnits.sort((a: Zephyr, b: Zephyr) => {
    if (a.type === b.type) {
      if (a.type === 0) {
        if (a.userEndTimeDate === null && b.userEndTimeDate !== null) {
          return -1;
        }
        if (a.userEndTimeDate !== null && b.userEndTimeDate === null) {
          return 1;
        }
        if (a.alias && b.alias) {
          return a.alias < b.alias ? -1 : 1;
        }
        if (a.alias) {
          return -1;
        }
        if (b.alias) {
          return 1;
        }
        return a.zNumber < b.zNumber ? -1 : 1;
      }
      if (a.type === 1) {
        return a.location < b.location ? -1 : 1;
      }
    } else if (a.type !== b.type) {
      return a.type === 0 ? -1 : 1;
    }
    return 0;
  });
  return filteredUnits;
};

const sortAnArrayOfZephyrsByName = (arrOfZephyrs: any[]) => {
  const copyArrOfZephyrs = [...arrOfZephyrs]
  return copyArrOfZephyrs.sort((a, b) => a.name.localeCompare(b.name));
};

export { zephyrSorter, sortAnArrayOfZephyrsByName };
