// Types Imports
import {
  LOADING_INIT_APP_OFF,
  LOADING_INIT_APP_ON,
  LOADING_INIT_APP_DATA_OFF,
  LOADING_INIT_APP_DATA_ON,
  LOADING_INIT_APP_THIRDPARTY_DATA_OFF,
  LOADING_INIT_APP_THIRDPARTY_DATA_ON,
} from '../actions/types';

// Type Imports
import { Loading } from '../utils/interface';

// Type Safety
interface LoadingReducer {
  type:
    | typeof LOADING_INIT_APP_OFF
    | typeof LOADING_INIT_APP_ON
    | typeof LOADING_INIT_APP_DATA_OFF
    | typeof LOADING_INIT_APP_DATA_ON
    | typeof LOADING_INIT_APP_THIRDPARTY_DATA_OFF
    | typeof LOADING_INIT_APP_THIRDPARTY_DATA_ON;
}

// State
const initState: Loading = {
  aurnLastHour: false,
  initApp: false,
  zephyrLastHour: false,
};

// Reducer
export default function (state = initState, action: LoadingReducer) {
  const { type } = action;

  switch (type) {
    case LOADING_INIT_APP_OFF:
      return {
        ...state,
        initApp: false,
      };

    case LOADING_INIT_APP_ON:
      return {
        ...state,
        initApp: true,
      };

    case LOADING_INIT_APP_DATA_OFF:
      return {
        ...state,
        zephyrLastHour: false,
      };

    case LOADING_INIT_APP_DATA_ON:
      return {
        ...state,
        zephyrLastHour: true,
      };

    case LOADING_INIT_APP_THIRDPARTY_DATA_OFF:
      return {
        ...state,
        aurnLastHour: false,
      };

    case LOADING_INIT_APP_THIRDPARTY_DATA_ON:
      return {
        ...state,
        aurnLastHour: true,
      };

    default:
      return state;
  }
}
