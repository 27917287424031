// Package Imports
import { Dispatch } from 'redux';

// Types Imports
import { SET_QUERY_STRING_PARAMS } from './types';

// Type Imports
import { UrlQueryStringParams } from '../utils/interface';

// Action Creators
export const queryStringParams = (
  urlQueryStringParams: UrlQueryStringParams,
) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_QUERY_STRING_PARAMS,
    payload: urlQueryStringParams,
  });
};
