// Package Imports
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import moment from 'moment';

// Component Imports
import AnalyticsPlots from '../view_components/AnalyticsPlots';

// Action Imports
import { getZephyrData } from '../../actions/zephyrs';

// Util Imports
import utilsRequest from '../../utils/request';
import { dateFinder } from '../../utils/functions/dateFinder';
import { windDataPackager } from '../../utils/functions/saDataPackager';

// Type Imports
import {
  DataConfig,
  DisplayConfig,
  PolarPlots,
  ReduxState,
  TodayData,
  Zephyr,
  ZephyrHistoryIndSlotData,
} from '../../utils/interface';
import { useLocation } from 'react-router-dom';

// Component Interfaces
interface InitState {
  error: string | null;
  loading: boolean;
  polarPlots: PolarPlots | null;
}

interface AnalyticsProps {
  dataConfig: DataConfig;
  displayConfig: DisplayConfig;
  getZephyrData: Function;
  zephyr: Zephyr;
  bearerToken: string | null;
}

// Component
const Analytics = ({
  dataConfig,
  displayConfig,
  getZephyrData,
  zephyr,
  bearerToken,
}: AnalyticsProps) => {
  // State
  const initState: InitState = {
    error: null,
    loading: false,
    polarPlots: null,
  };
  const [error, setError] = useState(initState.error);
  const [loading, setLoading] = useState(initState.loading);
  const [polarPlots, setPolarPlots] = useState(initState.polarPlots);
  const location = useLocation();

  // Functions
  const getAnalyticsReport = async () => {
    setLoading(true);

    const analysisLength = 31;
    const minDataAllowance = 600;

    // Work out Z and MA date formats
    const startDT = dateFinder(new Date(), analysisLength, true);
    startDT.setHours(0, 0, 0);
    const endDT = dateFinder(new Date(), 1, true);
    endDT.setHours(23, 59, 59);
    const isoStartDT = startDT.toISOString();
    const isoEndDT = endDT.toISOString();
    const ZStart = moment(startDT).format('YYYYMMDDHHmm');
    const ZEnd = moment(endDT).format('YYYYMMDDHHmm');

    // Get Z Data
    let packagedZephyrData: ZephyrHistoryIndSlotData | null = null;
    const rawZephyrData = await getZephyrData(
      zephyr,
      'month',
      ZStart,
      ZEnd,
      dataConfig,
      true,
      bearerToken,
      0,
      location.pathname === '/',
    );
    // Package Z Data
    // TODO: Move this to displayConfig in database
    const exclusionList = [
      'UTS',
      'localDateTime',
      'latitude',
      'longitude',
      'ambHumidity',
      'humidity',
      'tempC',
      'latitude',
      'longitude',
      'ambPressure',
      'ambTempC',
    ];
    if (rawZephyrData) {
      packagedZephyrData =
        rawZephyrData.data['Hourly average on the hour'].slotB;
    }
    if (packagedZephyrData) {
      for (const [species, dataObj] of Object.entries(packagedZephyrData)) {
        if (
          (exclusionList.includes(species) || !dataObj || !dataObj.data) &&
          species !== 'dateTime'
        ) {
          delete packagedZephyrData[species];
        } else {
          const notNull = dataObj.data.filter((dp) => dp !== null && dp !== 0)
            .length;
          if (notNull < minDataAllowance) {
            delete packagedZephyrData[species];
          }
        }
      }
    }
    // Get GFS Data
    const metData: TodayData = await utilsRequest.getTodayData(
      'winddir,windspeed',
      [parseFloat(zephyr.latitude), parseFloat(zephyr.longitude)],
      3,
      bearerToken,
      {
        startDT: isoStartDT,
        endDT: isoEndDT,
      },
    );
    // Package GFS Data
    const packagedGFSData = windDataPackager(metData);
    // Package Z and GFS Data together
    if (packagedZephyrData && packagedGFSData) {
      // packagedZephyrData, packagedGFSData
      const zephyrDTs = packagedZephyrData.dateTime.data;
      const gfsDTs = packagedGFSData.dateTime;
      const zephyrFirstDT = moment(zephyrDTs[0], 'YYYY-MM-DDTHH:mm:ss');
      const zephyrLastDT = moment(
        zephyrDTs[zephyrDTs.length - 1],
        'YYYY-MM-DDTHH:mm:ss',
      );
      const gfsFirstDT = moment(gfsDTs[0], 'YYYY-MM-DDTHH:mm:ss');
      const gfsLastDT = moment(
        gfsDTs[gfsDTs.length - 1],
        'YYYY-MM-DDTHH:mm:ss',
      );
      // Clip GFS dataset if overhanging either end of Z dataset
      if (gfsFirstDT < zephyrFirstDT) {
        let clipCount = 0;
        gfsDTs.forEach((dt) => {
          if (moment(dt, 'YYYY-MM-DDTHH:mm:ss') < zephyrFirstDT) {
            clipCount += 1;
          }
        });
        for (const [gfsKey, gfsValue] of Object.entries(packagedGFSData)) {
          const clippedArr = gfsValue.slice(clipCount);
          packagedGFSData[gfsKey] = clippedArr;
        }
      }
      if (zephyrLastDT < gfsLastDT) {
        let clipCount = 0;
        gfsDTs.forEach((dt) => {
          if (moment(dt, 'YYYY-MM-DDTHH:mm:ss') > zephyrLastDT) {
            clipCount += 1;
          }
        });
        for (const [gfsKey, gfsValue] of Object.entries(packagedGFSData)) {
          const clippedArr = gfsValue.slice(0, gfsValue.length - clipCount);
          packagedGFSData[gfsKey] = clippedArr;
        }
      }
      // Duplicate GFS data to match hourly resolution of Zephyr data
      const updatedPackagedGFSData = { ...packagedGFSData };
      zephyrDTs.forEach((dt, idx) => {
        const parsedZDT = moment(dt, 'YYYY-MM-DDTHH:mm:ss');
        if (updatedPackagedGFSData.dateTime[idx]) {
          const parsedGFSDT = moment(
            updatedPackagedGFSData.dateTime[idx],
            'YYYY-MM-DDTHH:mm:ss',
          );
          if (parsedZDT < parsedGFSDT) {
            const repeatDirVal = updatedPackagedGFSData.direction[idx];
            const repeatSpeedVal = updatedPackagedGFSData.speed[idx];
            while (
              moment(
                updatedPackagedGFSData.dateTime[idx],
                'YYYY-MM-DDTHH:mm:ss',
              ).diff(parsedZDT, 'hours')
            ) {
              const newGFSDate = moment(
                updatedPackagedGFSData.dateTime[idx],
                'YYYY-MM-DDTHH:mm:ss',
              )
                .subtract(1, 'hour')
                .format('YYYY-MM-DDTHH:mm:ss');
              updatedPackagedGFSData.dateTime.splice(idx, 0, newGFSDate);
              updatedPackagedGFSData.direction.splice(idx, 0, repeatDirVal);
              updatedPackagedGFSData.speed.splice(idx, 0, repeatSpeedVal);
            }
          }
        } else {
          const repeatDirVal =
            updatedPackagedGFSData.direction[
            updatedPackagedGFSData.direction.length - 1
            ];
          const repeatSpeedVal =
            updatedPackagedGFSData.speed[
            updatedPackagedGFSData.speed.length - 1
            ];
          // at the end of the Z data
          updatedPackagedGFSData.dateTime.push(
            parsedZDT.format('YYYY-MM-DDTHH:mm:ss'),
          );
          updatedPackagedGFSData.direction.push(repeatDirVal);
          updatedPackagedGFSData.speed.push(repeatSpeedVal);
        }
      });
      // Create final data package for OpenAir processing
      const finalPackagedData: any = {};
      for (const [species, dataObj] of Object.entries(packagedZephyrData)) {
        if (species !== 'dateTime') {
          finalPackagedData[dataObj.header.label] = dataObj.data;
        }
      }
      for (const [gfsKey, gfsValue] of Object.entries(packagedGFSData)) {
        if (gfsKey !== 'dateTime') {
          const key = gfsKey === 'direction' ? 'wd' : 'ws';
          finalPackagedData[key] = gfsValue;
        }
      }

      // Get/set plots
      const requestedPlots = await utilsRequest.getPolarPlots(
        finalPackagedData,
        bearerToken,
      );
      setPolarPlots(requestedPlots);
    }
    setLoading(false);
  };

  return (
    <div className="analytics-container">
      <button type="button" onClick={() => getAnalyticsReport()}>
        Generate Report
      </button>
      <AnalyticsPlots displayConfig={displayConfig} polarPlots={polarPlots} />
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  dataConfig: state.dataConfig,
  displayConfig: state.setDisplayConfig,
  zephyr: state.setZephyr.zephyr,
  bearerToken: state.auth.bearerToken,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getZephyrData,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
