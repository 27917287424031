// Package Imports
import React from 'react';

// Component Interfaces
interface SliderProps {
  on: boolean;
  value: number;
  actionCreator: Function;
  id: string;
}

// Component
const Slider = ({ on, value, actionCreator, id }: SliderProps) => {
  const handleSliderChange = (value: number) => {
    actionCreator(Math.round(value));
  };
  return (
    <>
      {on ? (
        <div className="slider-container">
          <div className="slider-label">
            <strong>Opacity:</strong>
          </div>
          <input
            type="range"
            min="20"
            max="80"
            value={value}
            onChange={(e) => handleSliderChange(parseInt(e.target.value))}
            className="slider"
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Slider;
