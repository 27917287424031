// Types Imports
import { Dispatch } from 'redux';
import {
  SET_CONTACT_MODAL_ON,
  SET_DATA_MODAL_ON,
  SET_MAPPAIR_QUOTE_MODAL_ON,
  SET_MAPPAIR_TIMESERIES_MODAL_ON,
  SET_MODALS_OFF,
  SET_ZEPHYR_QUOTE_MODAL_ON,
} from './types';

// Action Creators
export const setContactModalOn = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_CONTACT_MODAL_ON,
  });
};

export const setDataModalOn = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_DATA_MODAL_ON,
  });
};

export const setMappairQuoteModalOn = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MAPPAIR_QUOTE_MODAL_ON,
  });
};

export const setMappairTimeseriesModalOn = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MAPPAIR_TIMESERIES_MODAL_ON,
  });
};

export const setModalsOff = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MODALS_OFF,
  });
};

export const setZephyrQuoteModalOn = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ZEPHYR_QUOTE_MODAL_ON,
  });
};
