import React, { Suspense, useMemo } from 'react';
import { ChartProps } from '../../../utils/interface';
import { ChartTitleBar } from '../DataAnalyticsChartCtas';

const networkViewComponents: any = {
  networkViewMap: {
    component: React.lazy(() => import('../Charts/MiniMap')),
    // Data Analytics version 2.1- add chart widget. the below will be use as a preview with text...
    image: '/path/to/map/image.png',
    title: 'Network Map',
    description: 'Display the network map with relevant information.',
    html: '<div>Network Map</div>',
  },
  networkViewParticulates: {
    component: React.lazy(() => import('../Charts/MinMaxMeanChartPlotly')),
  },
  networkViewGases: {
    component: React.lazy(() => import('../Charts/MinMaxMeanChartPlotly')),
  },
  networkViewPollutantSummary: {
    component: React.lazy(() => import('../Charts/PollutantSummary')),
  },
};

const deviceViewComponents: any = {
  weatherWindRose: {
    component: React.lazy(() => import('../Charts/WindChart')),
  },
  precipitation: {
    component: React.lazy(() => import('../Charts/Precipitation')),
  },
  map: { component: React.lazy(() => import('../Charts/MiniMap')) },
  pollutantAvg: {
    component: React.lazy(() => import('../Charts/PollutantAvg')),
  },
  particulate: {
    component: React.lazy(() => import('../Charts/PollutantChartPlotly')),
  },
  gases: {
    component: React.lazy(() => import('../Charts/PollutantChartPlotly')),
  },
  thpChartPlotly: {
    component: React.lazy(() => import('../Charts/THPChartPlotly')),
  },
  windSpeedDirChartPlotly: {
    component: React.lazy(() => import('../Charts/WindSpeedDirChartPlotly')),
  },
};

export const lazyComponents = {
  ...networkViewComponents,
  ...deviceViewComponents,
};


const DynamicChart: React.FC<{ type: string | undefined; props?: ChartProps }> = React.memo(({ type = '', ...props }) => {
  const { selectedView, unitHistories, comparisonViewZephyrs } = props as ChartProps;

  const ChartComponent = useMemo(() => {
    if (selectedView === 'comparisonView') {
      lazyComponents[type] = {
        component: React.lazy(() => import('../Charts/ComparisonPollutant'))
      };
    }
    return lazyComponents[type]?.component;
  }, [unitHistories, comparisonViewZephyrs]);

  if (!ChartComponent) {
    // Handle unrecognized chart type
    return (
      <div className={`chart ${type} p-0`}>
        <ChartTitleBar title={`chart ${type}`} />
        <div className="chart center-text p-0">Unknown chart: {type}</div>
      </div>
    );
  }

  return (
    <Suspense fallback={<div className="chart center-text">Loading...</div>}>
      <div className={`chart ${type} ${selectedView === 'comparisonView' ? 'comparisonView' : undefined}`}>
        <ChartComponent {...props} type={type} />
      </div>
    </Suspense>
  );
});

export default DynamicChart;
