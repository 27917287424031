// Package Imports
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Location } from 'history';

// Action Imports
import {
  setSelectedFilters,
  mappAirFilters,
} from '../../actions/mappAirFilters';
import {
  showAQMALayer,
  showOverlayLayer,
  setMappairPointModeOff,
  showSchoolsLayer
} from '../../actions/layers';

// Util Imports
import analyticsEventFirer from '../../utils/functions/analyticsEventFirer';

// Const Imports
import {
  AsideOverlaysPublicSpecies,
  gtmEventIdentifiers,
} from '../../utils/consts';

// Type Imports
import {
  MappAirSelectedFilter,
  ReduxState,
  UnitOverlayTypes,
  Zephyr,
  UserInfo,
} from '../../utils/interface';

// Component Interfaces
interface InitState { }

interface AsidePublicOverlaysProps {
  aqmaOn: boolean;
  schoolsOn: boolean;
  asideOn: boolean;
  location: Location;
  mappAirFilters: Function;
  mappairOn: boolean;
  selectedFilters: MappAirSelectedFilter[];
  selectedUnitListCheck: Function;
  setMappairPointModeOff: Function;
  setSelectedFilters: Function;
  showAQMALayer: Function;
  showSchoolsLayer: Function;
  showOverlayLayer: Function;
  zephyrs: Zephyr[];
  isOverlayMenuVisible: boolean;
  setIsOverlayMenuVisible: Function;
  userInfo: UserInfo;
}

const AsidePublicOverlays = ({
  aqmaOn,
  asideOn,
  location,
  mappAirFilters,
  mappairOn,
  selectedFilters,
  selectedUnitListCheck,
  setMappairPointModeOff,
  setSelectedFilters,
  showAQMALayer,
  showOverlayLayer,
  zephyrs,
  isOverlayMenuVisible,
  setIsOverlayMenuVisible,
  userInfo,
  schoolsOn,
  showSchoolsLayer
}: AsidePublicOverlaysProps) => {
  // Functions
  const filterOverlays = (overlay: UnitOverlayTypes) => {
    let newFilters = selectedFilters.slice();
    if (newFilters.filter((nF) => nF.name === overlay).length) {
      const updatedNewFilters = newFilters.filter((nF) => nF.name !== overlay);
      newFilters = updatedNewFilters;
    } else {
      const newFilter = {
        name: overlay,
        showAllLabels: true,
        showSelectedLabel: true,
      };
      newFilters.push(newFilter);
    }
    setSelectedFilters(newFilters);
  };

  const fireToggleAnalyticsEvent = (
    eventIdentifier: string,
    itemStatus: boolean,
  ) => {
    const labelEnrichment = itemStatus ? 'Off' : 'On';
    analyticsEventFirer(eventIdentifier, labelEnrichment);
  };

  const getUser = () => localStorage.getItem('user') || '';

  return (
    <>
      {asideOn ? (
        <ul className="aside-public-overlays">
          {AsideOverlaysPublicSpecies.speciesMap
            .filter(
              (sm) =>
                !(
                  (sm.type === 'zephyr' &&
                    !zephyrs.filter((z) => z.type === 0).length) ||
                  (sm.type === 'aurn' &&
                    !zephyrs.filter((z) => z.type === 1).length) ||
                  (sm.type === 'virtual' &&
                    !zephyrs.filter((z) => z.type === 100).length)
                ),
            )
            .map((unit) => (
              <li key={unit.type}>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    id={unit.type}
                    checked={selectedUnitListCheck(unit.type)}
                    name={unit.type}
                    onChange={() => {
                      filterOverlays(unit.type);
                      // Analytics
                      fireToggleAnalyticsEvent(
                        gtmEventIdentifiers[`${unit.type}Toggle`],
                        selectedUnitListCheck(unit.type),
                      );
                    }}
                  />
                  <span className="checkmark" />
                  {unit.label}
                </label>
              </li>
            ))}
        </ul>
      ) : (
        <></>
      )}
    </>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => {
  return {
    aqmaOn: state.showAQMALayer.showAQMALayer,
    schoolsOn: state.showAQMALayer.showSchoolsLayer,
    mappairOn: state.showAQMALayer.showOverlayLayer,
    selectedFilters: state.mappAirFilters.selectedFilters,
    userInfo: state.auth.userInfo,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      mappAirFilters,
      showAQMALayer,
      showSchoolsLayer,
      showOverlayLayer,
      setMappairPointModeOff,
      setSelectedFilters,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AsidePublicOverlays);
