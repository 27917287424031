// Types Imports
import { SET_MAPPAIR_POINT_VALUE_HISTORY } from '../actions/types';
import { MapAirPointValueHistoryData } from '../utils/interface';
import { MapAirPointValueHistoryInitialState } from '../utils/consts';

// Type Safety
interface setMapAirPointValueHistory {
  type: typeof SET_MAPPAIR_POINT_VALUE_HISTORY;
  payload: MapAirPointValueHistoryData;
}

// Reducer
export default function (
  state = MapAirPointValueHistoryInitialState,
  action: setMapAirPointValueHistory,
) {
  const { type, payload } = action;

  switch (type) {
    case SET_MAPPAIR_POINT_VALUE_HISTORY:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}
