import React from 'react';
import Accordion from '../../../shared_components/Accordions';
import { Pollutant, Zephyr } from '../../../../utils/interface';
import { getZephyrType } from '../../ReactGridLayout/ReactGridLayout';
import styled from 'styled-components';

interface UnitListProps {
  zephyrsPollutantData: {
    filteredZephyrs: Zephyr[];
    pollutantData: Pollutant[];
  };
  accordionIndexes: { [key: number]: boolean };
  setAccordionIndexes: Function;
  isDataAvailabilityChartLoading: boolean;
  addUnitToAnalyticsList: Function;
  selectedDeviceKeys: string[];
}

const UnitList: React.FC<UnitListProps> = ({
  zephyrsPollutantData,
  accordionIndexes,
  setAccordionIndexes,
  isDataAvailabilityChartLoading,
  addUnitToAnalyticsList,
  selectedDeviceKeys,
}) => {
  
  const getGroupedZephyrs = () => {
    const currentDate = new Date();

    const groupedZephyrs = zephyrsPollutantData.filteredZephyrs.reduce(
      (acc: any, zephyr: any) => {
        const key = zephyr.type.toString();
        if (selectedDeviceKeys.includes(key)) {
          if (!acc[key]) acc[key] = { active: [], retired: [] };

          const isRetired =zephyr.userEndTimeDate && new Date(zephyr.userEndTimeDate) < currentDate;
          const units = isRetired ? acc[key].retired : acc[key].active;

          const zNumberExists = units.some(
            (unit: any) => unit.zNumber === zephyr.zNumber,
          );
          if (!zNumberExists) {
            units.push(zephyr);
          }
        }
        return acc;
      },
      {},
    );

    // Sort retired units by userEndTimeDate in descending order
    Object.values(groupedZephyrs).forEach((group: any) => {
      group.retired.sort(
        (a: any, b: any) => new Date(b.userEndTimeDate).getTime() - new Date(a.userEndTimeDate).getTime(),
      );
    });

    return groupedZephyrs;
  };

  if (
    !zephyrsPollutantData.filteredZephyrs ||
    zephyrsPollutantData.filteredZephyrs.length === 0
  )
    return null;

  return (
    <div>
      {Object.entries(getGroupedZephyrs()).map(([typeKey, group]: any, index) => (
        <Accordion
          isOpenInitially={accordionIndexes[index]}
          key={typeKey}
          title={`${getZephyrType(parseInt(typeKey))} (${group.active.length + group.retired.length})`}
          index={index}
          setAccordionIndexes={setAccordionIndexes}
          content={[...group.active, ...group.retired].map(
            (unit: any, i: number) => {
              const isRetired = unit.userEndTimeDate && new Date(unit.userEndTimeDate) < new Date();
              const key = `${unit.id_pod}-${unit.zNumber}`;
              return (
                <label htmlFor={`${unit.id_pod}`} key={key} className="list">
                  <input
                    disabled={isDataAvailabilityChartLoading}
                    type="checkbox"
                    id={unit.id_pod}
                    name={unit.id_pod}
                    value={unit.id_pod}
                    checked={unit.isSelected}
                    onChange={() => {
                      addUnitToAnalyticsList(unit);
                    }}
                  />
                  <p
                    style={{
                      margin: 0,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 12,
                    }}
                  >
                    {unit.name}
                    <span className="device-znumber">
                      {unit.type === 0 ? 'Z' : unit.type === 100 ? 'VZ' : 'A'}
                      {unit.zNumber}
                    </span>
                  </p>
                  {isRetired && (<RetiredBadge>Data until{' '}{new Date(unit.userEndTimeDate).toLocaleDateString()}</RetiredBadge>
                  )}
                </label>
              );
            },
          )}
        />
      ),
      )}
    </div>
  );
};

const RetiredBadge = styled.span`
  display: inline-block;
  padding: 4px 8px;
  background-color: #edf2f7;
  color: #666;
  border-radius: 16px;
  font-size: 0.8em;
  font-weight: normal;
  margin-left: auto;
`;

export default UnitList;
