// Type Imports
import { SET_DISPLAY_CONFIG } from '../actions/types';

// Type Safety
interface DisplayConfig {
  tabs: {
    group: string;
    species: {
      label: string;
      HTMLLabel: string;
      HTMLUnitLabel: string;
      dataIdentifier: string;
    }[];
  }[];
}

interface SetDisplayConfigAction {
  type: typeof SET_DISPLAY_CONFIG;
  payload: DisplayConfig;
}

// State
/*TODO: Fix this to the DisplayConfig in interface.ts*/
const initState: DisplayConfig = {
  tabs: [
    {
      group: '',
      species: [
        {
          label: '',
          HTMLLabel: '',
          HTMLUnitLabel: '',
          dataIdentifier: '',
        },
      ],
    },
  ],
};

// Reducer
export default function (state = initState, action: SetDisplayConfigAction) {
  const { type, payload } = action;

  switch (type) {
    case SET_DISPLAY_CONFIG:
      return {
        ...payload,
      };

    default:
      return state;
  }
}
