// Package Imports
import React from 'react';

// Component Interfaces
interface DataChartDownloadButtonProps {
  handleChartDownload: Function;
  on?: boolean | null;
}

const DataChartDownloadButton = ({
  handleChartDownload,
  on,
}: DataChartDownloadButtonProps) => {
  return (
    <>
      {on ? (
        <button
          onClick={() => handleChartDownload()}
          type="button"
          className="chart-download-custom-button"
          data-html2canvas-ignore="true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="#000"
          >
            <path d="M12 21l-8-9h6v-12h4v12h6l-8 9zm9-1v2h-18v-2h-2v4h22v-4h-2z" />
          </svg>
        </button>
      ) : (
        <></>
      )}
    </>
  );
};

// Default Props
DataChartDownloadButton.defaultProps = {
  on: false,
};

export default DataChartDownloadButton;
