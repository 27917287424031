// Type Imports
import { SET_TODAY_DATA } from '../actions/types';

// Types Imports
import { TodayData } from '../utils/interface';

// Type Safety
interface SetTodayData {
  type: typeof SET_TODAY_DATA;
  payload: TodayData;
}

// State
const initState: TodayData | null = null;

// Reducer
export default function (state = initState, action: SetTodayData) {
  const { type, payload } = action;

  switch (type) {
    case SET_TODAY_DATA:
      return payload;

    default:
      return state;
  }
}
