import { useEffect } from 'react';

const useOutsideClick = (ref, callback) => {
  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback(e);
    }
  };

  useEffect(() => {
    const handleClickDocument = (e) => {
      handleClickOutside(e);
    };

    document.addEventListener('click', handleClickDocument);

    return () => {
      document.removeEventListener('click', handleClickDocument);
    };
  }, [ref, callback]);
};

export default useOutsideClick;