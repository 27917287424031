// Package Imports
import { Dispatch } from 'redux';

// Util Imports
import { wideScreenFinder } from '../utils/functions/wideScreenFinder';
import { tallScreenFinder } from '../utils/functions/tallScreenFinder';
import analyticsEventFirer from '../utils/functions/analyticsEventFirer';

// Const Imports
import { gtmEventIdentifiers } from '../utils/consts';

// Types Imports
import {
  ALERTS_ON,
  CHANGE_OVERLAY_LAYER,
  GET_STYLE_GROUPS,
  LOADING_INIT_APP_OFF,
  LOADING_INIT_APP_ON,
  LOADING_INIT_APP_DATA_OFF,
  LOADING_INIT_APP_DATA_ON,
  LOADING_INIT_APP_THIRDPARTY_DATA_OFF,
  LOADING_INIT_APP_THIRDPARTY_DATA_ON,
  LOGIN_FAIL,
  LOGOUT,
  SET_ACTIVE_THRESHOLD,
  SET_AVERAGING_PERIODS,
  SET_DATA_CONFIG,
  SET_DISPLAY_CONFIG,
  SET_FEEDBACK,
  SET_OVERLAYS,
  SET_PUBLIC_ADVICE,
  SET_TIME_PERIODS,
  SET_TODAY_DATA,
  SET_USER_CONFIG,
  SET_VERSIONS,
  USER_LOADED,
  SET_MASTER_AUTH_BEARER_TOKEN,
} from './types';

// Type Imports
import {
  Agreement,
  AveragingPeriods,
  DataConfig,
  DisplayConfig,
  FeedbackQuestion,
  Overlay,
  PublicAdvice,
  Stylegroup,
  TimePeriods,
  TodayData,
  UserConfig,
  UserInfo,
} from '../utils/interface';
import { dispatch } from 'd3';

// Action Creators
const loadingInitAppOff = () => async (dispatch: Dispatch) => {
  dispatch({
    type: LOADING_INIT_APP_OFF,
  });
};

const loadingInitAppOn = () => async (dispatch: Dispatch) => {
  dispatch({
    type: LOADING_INIT_APP_ON,
  });
};

const loadingInitAppDataOff = () => async (dispatch: Dispatch) => {
  dispatch({
    type: LOADING_INIT_APP_DATA_OFF,
  });
};

const loadingInitAppDataOn = () => async (dispatch: Dispatch) => {
  dispatch({
    type: LOADING_INIT_APP_DATA_ON,
  });
};

const loadingInitAppThirdPartyDataOff = () => async (dispatch: Dispatch) => {
  dispatch({
    type: LOADING_INIT_APP_THIRDPARTY_DATA_OFF,
  });
};

const loadingInitAppThirdPartyDataOn = () => async (dispatch: Dispatch) => {
  dispatch({
    type: LOADING_INIT_APP_THIRDPARTY_DATA_ON,
  });
};

const logout = () => async (dispatch: Dispatch) => {
  dispatch({ type: LOGOUT });
  analyticsEventFirer(gtmEventIdentifiers.logout);
};

const setFailedLogin = () => async (dispatch: Dispatch) => {
  dispatch({ type: LOGOUT });
  dispatch({ type: LOGIN_FAIL });
};

const setTodayData = (todayData: TodayData) => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_TODAY_DATA,
    payload: todayData,
  });
};

const setPublicAdvice = (publicAdvice: PublicAdvice) => async (
  dispatch: Dispatch,
) => {
  dispatch({
    type: SET_PUBLIC_ADVICE,
    payload: publicAdvice,
  });
};

const setDisplayConfig = (displayConfig: DisplayConfig) => async (
  dispatch: Dispatch,
) => {
  dispatch({
    type: SET_DISPLAY_CONFIG,
    payload: displayConfig,
  });
};

const setOverlays = (overlays: Overlay[]) => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_OVERLAYS,
    payload: overlays,
  });
  const initOverlay =
    wideScreenFinder(800) && tallScreenFinder(500)
      ? overlays.find((ol: Overlay) => !ol.isMappairOverlay)
      : overlays.find((ol: Overlay) => ol.isMappairOverlay);
  if (initOverlay) {
    dispatch({
      type: CHANGE_OVERLAY_LAYER,
      payload: initOverlay.name,
    });
  }
};

const setAlerts = (agreements: Agreement[]) => async (dispatch: Dispatch) => {
  if (agreements.length > 0) {
    dispatch({
      type: ALERTS_ON,
      payload: agreements,
    });
  }
};

const setFeedback = (feedbackQuestions: FeedbackQuestion[]) => async (
  dispatch: Dispatch,
) => {
  dispatch({
    type: SET_FEEDBACK,
    payload: feedbackQuestions,
  });
};

const setUserConfig = (userConfig: UserConfig) => async (
  dispatch: Dispatch,
) => {
  dispatch({
    type: SET_USER_CONFIG,
    payload: userConfig,
  });
};

const setStylegroups = (stylegroups: Stylegroup[]) => async (
  dispatch: Dispatch,
) => {
  dispatch({
    type: GET_STYLE_GROUPS,
    payload: stylegroups,
  });
};

const setThreshold = (threshold: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_ACTIVE_THRESHOLD,
    payload: threshold,
  });
};

const setTimePeriods = (timePeriods: TimePeriods) => async (
  dispatch: Dispatch,
) => {
  dispatch({
    type: SET_TIME_PERIODS,
    payload: timePeriods,
  });
};

const setAveragingPeriods = (averagingPeriods: AveragingPeriods) => async (
  dispatch: Dispatch,
) => {
  dispatch({
    type: SET_AVERAGING_PERIODS,
    payload: averagingPeriods,
  });
};

const setDataConfig = (dataConfig: DataConfig) => async (
  dispatch: Dispatch,
) => {
  dispatch({
    type: SET_DATA_CONFIG,
    payload: dataConfig,
  });
};

const setUserInfo = (userInfo: UserInfo) => async (dispatch: Dispatch) => {
  dispatch({
    type: USER_LOADED,
    payload: userInfo,
  });
};

const setVersions = (versions: string[]) => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_VERSIONS,
    payload: versions,
  });
};

const setMasterAuthBearerToken = (bearerToken: string) => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_MASTER_AUTH_BEARER_TOKEN,
    payload: bearerToken,
  });
};

export {
  loadingInitAppDataOff,
  loadingInitAppDataOn,
  loadingInitAppThirdPartyDataOff,
  loadingInitAppThirdPartyDataOn,
  loadingInitAppOff,
  loadingInitAppOn,
  logout,
  setAlerts,
  setAveragingPeriods,
  setDataConfig,
  setDisplayConfig,
  setFailedLogin,
  setFeedback,
  setPublicAdvice,
  setOverlays,
  setStylegroups,
  setThreshold,
  setTimePeriods,
  setTodayData,
  setUserConfig,
  setUserInfo,
  setVersions,
  setMasterAuthBearerToken,
};
