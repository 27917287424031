import React from 'react';

interface AnalyticsFeedbackFormProps {
    setFeedbackModalOn: Function;
}

const AnalyticsFeedbackForm = ({
    setFeedbackModalOn,
}: AnalyticsFeedbackFormProps) => {
    const [loading, setLoading] = React.useState(true);

    return (
        <>
            {loading && <p>Loading...</p>}
            <iframe
                title="Data Analytics Feedback Form"
                onLoad={() => setLoading(false)}
                src="https://forms.monday.com/forms/embed/1fa3fdcbdaf1efacb2fad84842fdc00b?r=use1"
                // src="https://forms.monday.com/forms/embed/0831c9522b0aa6a2b4755ee8f32fe9a1?r=use1" - BETA
                width="100%"
                height="100%"
                style={{ border: 0, boxShadow: '5px 5px 56px 0px rgba(0,0,0,0.25)' }}>
            </iframe>
            {!loading && <button
                style={{
                    position: 'absolute',
                    top: 15,
                    right: 25,
                    backgroundColor: 'rgba(0,0,0,0)'
                }}
                className="button close close-modal"
                onClick={() => {
                    setFeedbackModalOn((prevState: boolean) => !prevState)
                }}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 24 24"
                    fill="#000"
                >
                    <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
                </svg>
            </button>}
        </>
    )
};

export default AnalyticsFeedbackForm;