// Type Imports
import { Stylegroup } from '../interface';

/* Function
DESC: qualityCalculator finds the indexed value for a given raw AQ value
ARGS: AQ value(e.g. 12), species name(e.g. NO2), styleGroupName(e.g. ES), styleGroups(derived from API)
*/
const qualityCalculator = (
  value: number,
  species: string,
  styleGroupName: string,
  stylegroups: Stylegroup[],
) => {
  if (typeof value === 'number') {
    if (species !== 'UK AQI') {
      const styleGroup = stylegroups.filter(
        (group) => group.name === styleGroupName,
      )[0].thresholds[species];
      let qualityScore = 0;
      styleGroup.forEach((threshold) => {
        if (value >= threshold.min) {
          qualityScore += 1;
        }
      });
      return qualityScore;
    }
    return value;
  }
  return '';
};

export default qualityCalculator;
