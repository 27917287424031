// Package Imports
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Util Imports
import { overlayUnitFinder } from '../../utils/functions/overlayUnitFinder';

// Type Imports
import {
  DisplayConfig,
  Overlay,
  PointValues,
  ShowAQMALayer,
  UserInfo,
  VZephyrsConfig,
} from '../../utils/interface';

// Component Interfaces
interface LocationPopoverProps {
  closePopup: Function;
  setZephyrModalOpen: Function;
  displayConfig: DisplayConfig;
  mappairOptions: ShowAQMALayer;
  overlay: Overlay;
  points: PointValues | null;
  userInfo: UserInfo;
  vZephyrsConfig: VZephyrsConfig;
}

// Component
const LocationPopover = ({
  closePopup,
  displayConfig,
  mappairOptions,
  overlay,
  points,
  userInfo,
  setZephyrModalOpen,
  vZephyrsConfig,
}: LocationPopoverProps) => {
  const { t: translate } = useTranslation();

  // Functions
  const hdmsSplitter = (hdms: string) => {
    if (hdms) {
      const hdmsWithDelimiter = hdms.replace(/N/g, 'N,');
      const hdmsArr = hdmsWithDelimiter.split(',');
      return hdmsArr;
    }
    return '';
  };

  return (
    <div id="popup">
      {points && points.value !== null ? (
        <div
          className={`popup-container ${userInfo.isPublic ? 'public' : ''}`}
          id="popup-container"
        >
          <div className="flex">
            <div className="popup-content-aq">
              <div
                className="popup-aq-score"
                style={{
                  backgroundColor: points.colour.replace(
                    '1)',
                    `${mappairOptions.opacity / 100})`,
                  ),
                }}
              >
                <div>
                  <strong>{points.value >= 0 ? points.value : 'NA'}</strong>
                </div>
                {points.value >= 0 ? (
                  <div className="unit">
                    {overlayUnitFinder(displayConfig, overlay)}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="popup-content-right">
              <div className="popup-coords">
                <div>{hdmsSplitter(points.hdms)[0]}</div>
                <div>{hdmsSplitter(points.hdms)[1]}</div>
              </div>

              {vZephyrsConfig.active !== undefined && !userInfo.isPublic ? (
                <button
                  id="add-zephyr"
                  className="button btn primary add-zephyr"
                  onClick={() => setZephyrModalOpen(true)}
                  type="button"
                  disabled={
                    vZephyrsConfig.active >= vZephyrsConfig.max &&
                    vZephyrsConfig.new30Days >= vZephyrsConfig.new30DaysMax
                  }
                >
                  {translate('popoverCTAText')}
                </button>
              ) : null}
            </div>

            <button
              className="button close close-modal"
              onClick={(e) => closePopup(e)}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="#000"
              >
                <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
              </svg>
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default LocationPopover;
