// Package Imports
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { Location } from 'history';
import { useTranslation } from 'react-i18next';

// Action Imports
import {
  setSelectedFilters,
  mappAirFilters,
} from '../../actions/mappAirFilters';
import {
  showAQMALayer,
  showOverlayLayer,
  showSatelliteLayer,
  showSchoolsLayer,
  showAALayer
} from '../../actions/layers';

// Util Imports
import analyticsEventFirer from '../../utils/functions/analyticsEventFirer';

// Const Imports
import {
  AsideOverlaysSpecies,
  defaultSelectedFilters,
  gtmEventIdentifiers,
  mappAirFilterToggles,
} from '../../utils/consts';

// Type Imports
import {
  MappAirFilterOverlayToggles,
  MappAirSelectedFilter,
  ReduxState,
  UnitOverlayTypes,
  Zephyr,
  ZephyrTypes,
} from '../../utils/interface';

// Component Interfaces
interface InitState {
  subMenus: {
    aurn: boolean;
    zephyr: boolean;
    virtual: boolean;
  };
}

interface AsideOverlaysProps {
  allUnits: Zephyr[];
  asideOn: boolean;
  aqmaOn: boolean;
  schoolsOn: boolean;
  location: Location;
  mappairOn: boolean;
  mappAirFilters: Function;
  satelliteOn: boolean;
  selectedFilters: MappAirSelectedFilter[];
  selectedUnitListCheck: Function;
  setSelectedFilters: Function;
  showAllUnits: Function;
  showAQMALayer: Function;
  showSchoolsLayer: Function;
  showOverlayLayer: Function;
  showSatelliteLayer: Function;
  tourFilterOpen: boolean;
  zephyrs: Zephyr[];
  isOverlayMenuVisible: boolean;
  setIsOverlayMenuVisible: Function;
  anualAveragesOn: boolean;
}

// Component
const AsideOverlays = ({
  allUnits,
  asideOn,
  aqmaOn,
  location,
  mappairOn,
  mappAirFilters,
  satelliteOn,
  selectedFilters,
  selectedUnitListCheck,
  setSelectedFilters,
  showAllUnits,
  showAQMALayer,
  showOverlayLayer,
  showSatelliteLayer,
  tourFilterOpen,
  zephyrs,
  isOverlayMenuVisible,
  setIsOverlayMenuVisible,
  schoolsOn,
  showSchoolsLayer,
  anualAveragesOn
}: AsideOverlaysProps) => {
  const { t: translate } = useTranslation();

  // State
  const initState: InitState = {
    subMenus: {
      aurn: false,
      zephyr: false,
      virtual: false,
    },
  };

  const [subMenus, setSubMenus] = useState(initState.subMenus);

  // Functions
  const changeOverlayOptions = (
    overlay: UnitOverlayTypes,
    option: MappAirFilterOverlayToggles,
  ) => {
    const updatedFilters = selectedFilters.map((sF) => {
      if (sF.name === overlay) {
        const updatedOverlayOptions = { ...sF };
        updatedOverlayOptions[option] = !sF[option];
        if (option === 'showAllLabels') {
          updatedOverlayOptions.showSelectedLabel = true;
        }
        return updatedOverlayOptions;
      }
      return sF;
    });
    setSelectedFilters(updatedFilters);
  };

  const changeSubMenus = (overlay: UnitOverlayTypes) => {
    const newSubMenu = { ...initState.subMenus };
    newSubMenu[overlay] = !subMenus[overlay];
    setSubMenus(newSubMenu);
  };

  const changeSubMenusToOff = (overlay: UnitOverlayTypes) => {
    const newSubMenu = { ...subMenus };
    newSubMenu[overlay] = false;
    setSubMenus(newSubMenu);
  };

  const filterOverlays = (overlay: UnitOverlayTypes) => {
    let newFilters = selectedFilters.slice();
    if (newFilters.filter((nF) => nF.name === overlay).length) {
      const updatedNewFilters = newFilters.filter((nF) => nF.name !== overlay);
      newFilters = updatedNewFilters;
    } else {
      const newFilter = {
        name: overlay,
        showAllLabels: false,
        showSelectedLabel: true,
      };
      newFilters.push(newFilter);
    }
    if (overlay in subMenus) {
      changeSubMenusToOff(overlay);
    }
    setSelectedFilters(newFilters);
  };

  const fireToggleAnalyticsEvent = (
    eventIdentifier: string,
    itemStatus: boolean,
  ) => {
    const labelEnrichment = itemStatus ? 'Off' : 'On';
    analyticsEventFirer(eventIdentifier, labelEnrichment);
  };

  const handleShowAll = () => {
    const checked = showAllStatus();
    if (!checked) {
      setSelectedFilters(defaultSelectedFilters);
    } else {
      setSelectedFilters([]);
    }

    showAllUnits();
    // Analytics
    fireToggleAnalyticsEvent(gtmEventIdentifiers.allToggle, checked);
  };
  const showAllStatus = () => {
    const zephyrsAvailable = allUnits.filter((u) => u.type === 0).length > 0;
    const aurnsAvailable = allUnits.filter((u) => u.type === 1).length > 0;
    const virtualZephyrsAvailable =
      allUnits.filter((u) => u.type === ZephyrTypes.virtual).length > 0;

    const allAvailableUnitsSelected =
      (selectedUnitListCheck('aurn') || !aurnsAvailable) &&
      (selectedUnitListCheck('zephyr') || !zephyrsAvailable) &&
      (selectedUnitListCheck('virtual') || !virtualZephyrsAvailable);
    const allOverlaysSelected = mappairOn && aqmaOn && satelliteOn && schoolsOn && anualAveragesOn;

    const status =
      location.pathname.includes('data') || location.pathname.includes('alerts')
        ? allAvailableUnitsSelected
        : allAvailableUnitsSelected && allOverlaysSelected;
    return status;
  };

  const switchOff = () => {
    setSubMenus(initState.subMenus);
    setIsOverlayMenuVisible(false);
  };

  return (
    <>
      {location.pathname.includes('data') && !asideOn ? (
        <></>
      ) : (
        <header>
          <div
            className={
              isOverlayMenuVisible || tourFilterOpen
                ? 'filter-menu active'
                : 'filter-menu inactive'
            }
            onMouseLeave={() => switchOff()}
          >
            <ul className="tourOverlays">
              <li>
                <div className="overlay-control">
                  <div
                    className="overlay-checkbox"
                    onClick={() => handleShowAll()}
                  >
                    <label className="checkbox">
                      {showAllStatus()
                        ? translate('OverlaysRemoveAll')
                        : translate('OverlaysShowAll')}
                      <input
                        type="checkbox"
                        id="filter_showall"
                        checked={showAllStatus()}
                        name="filter-all"
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => null}
                      />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
              </li>
              {AsideOverlaysSpecies.speciesMap
                .filter(
                  (sm) =>
                    !(
                      (sm.type === 'zephyr' &&
                        !zephyrs.filter((z) => z.type === 0).length) ||
                      (sm.type === 'aurn' &&
                        !zephyrs.filter((z) => z.type === 1).length) ||
                      (sm.type === 'virtual' &&
                        !zephyrs.filter((z) => z.type === ZephyrTypes.virtual)
                          .length)
                    ),
                )
                .map((unit) => (
                  <li key={unit.type}>
                    <div className="overlay-control">
                      <div
                        className="overlay-checkbox"
                        onClick={(e) => {
                          e.stopPropagation();
                          filterOverlays(unit.type);
                          // Analytics
                          fireToggleAnalyticsEvent(
                            gtmEventIdentifiers[`${unit.type}Toggle`],
                            selectedUnitListCheck(unit.type),
                          );
                        }}
                      >
                        <label className="checkbox">
                          {unit.label}
                          <input
                            type="checkbox"
                            id={unit.type}
                            checked={selectedUnitListCheck(unit.type)}
                            name={unit.type}
                            onClick={(e) => e.stopPropagation()}
                            onChange={() => null}
                            className="filter-input"
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                      {!location.pathname.includes('data') &&
                        !location.pathname.includes('alerts') &&
                        !location.pathname.includes('analytics') &&
                        selectedUnitListCheck(unit.type) ? (
                        <div className="unit-filter-options-toggle">
                          <button
                            className={`button toggler unit-filter-options-toggle-button ${subMenus[unit.type] ? 'closed' : ''
                              }`}
                            type="button"
                            onClick={() => changeSubMenus(unit.type)}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {subMenus[unit.type] && selectedUnitListCheck(unit.type) ? (
                      <div className="overlay-options">
                        <div className="overlay-options-section">
                          <h4>Map labels</h4>
                          <div className="overlay-options-toggles">
                            {mappAirFilterToggles.map((mFT) => (
                              <div
                                className="overlay-options-toggle"
                                key={mFT.value}
                              >
                                <label className="small-toggle">
                                  <input
                                    type="checkbox"
                                    checked={
                                      selectedFilters.filter(
                                        (sF) => sF.name === unit.type,
                                      )[0][mFT.value]
                                    }
                                    onChange={() =>
                                      changeOverlayOptions(unit.type, mFT.value)
                                    }
                                  />
                                  <span className="small-toggle-slider" />
                                </label>
                                <div className="overlay-options-toggle-title">
                                  {mFT.label}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </header>
      )}
    </>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  aqmaOn: state.showAQMALayer.showAQMALayer,
  schoolsOn: state.showAQMALayer.showSchoolsLayer,
  mappairOn: state.showAQMALayer.showOverlayLayer,
  tourFilterOpen: state.tour.openAsideFilter,
  satelliteOn: state.showAQMALayer.showSatelliteLayer,
  selectedFilters: state.mappAirFilters.selectedFilters,
  anualAveragesOn: state.showAQMALayer.showAALayer,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      mappAirFilters,
      setSelectedFilters,
      showAQMALayer,
      showSchoolsLayer,
      showOverlayLayer,
      showSatelliteLayer,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AsideOverlays);
