// Types Imports
import { CLEAR_DRAGGED_UNIT, SET_DRAGGED_UNIT } from '../actions/types';

// Type Imports
import { Zephyr } from '../utils/interface';

// Type Safety
interface SetDraggedUnitAction {
  type: typeof SET_DRAGGED_UNIT;
  payload: Zephyr;
}

// State
const initState = null;

// Reducer
export default function (state = initState, action: SetDraggedUnitAction) {
  const { type, payload } = action;

  switch (type) {
    case SET_DRAGGED_UNIT:
      return payload;

    case CLEAR_DRAGGED_UNIT:
      return null;

    default:
      return state;
  }
}
