// Package Imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Component Imports
import AQUnit from './AQUnit';
import AQOther from './AQOther';

// Util Imports
import { aqTabHeaderFinder } from '../../utils/functions/tabFinder';
import utilsRequest from '../../utils/request';
import {
  setZephyrs,
} from '../../actions/zephyrs';

// Const Imports

// Type Imports
import {
  AQLatestMeasurementDataTabs,
  DisplayConfig,
  Loading,
  ReduxState,
  Zephyr,
} from '../../utils/interface';
import { specificZephyrMetDataEnricher, specificZephyrMetRequestPackager, zephyrMetDataEnricher, zephyrMetRequestPackager } from '../../utils/functions/zephyrMetDataEnricher';
import { bindActionCreators, Dispatch } from 'redux';

// Component Interfaces
interface AQLatestDataProps {
  aqlIsOn: AQLatestMeasurementDataTabs;
  toggleAccordion: Function;
  changeLatestMeasurementTab: Function;
  displayConfig: DisplayConfig;
  isOn: boolean;
  loading: Loading;
  tourAQDataOpen: boolean;
  tourOpen: boolean;
  zephyr: Zephyr;
  unitList: Zephyr[];
  setZephyrs: Function;
  bearerToken: string | null;
}

// Component
const AQLatestData = ({
  aqlIsOn,
  toggleAccordion,
  changeLatestMeasurementTab,
  displayConfig,
  isOn,
  loading,
  tourAQDataOpen,
  tourOpen,
  zephyr,
  unitList,
  setZephyrs,
  bearerToken,
}: AQLatestDataProps) => {
  const { t: translate } = useTranslation();
  const tabs = [
    { label: 'Zephyr', value: 'zephyr' },
    { label: 'Regional Met', value: 'other' }
  ];

  // Effects
  useEffect(() => {
    if (!loading.zephyrLastHour) {
      switchAQLTab('zephyr');
    }
  }, []);

  useEffect(() => {
    if (zephyr && !Array.isArray(zephyr)) {
      const availableTabs = aqTabHeaderFinder(displayConfig, zephyr);
      if (!availableTabs) return;
      const metStationData = availableTabs.filter((t) => t.value === 'met')
        .length;
      const externalMetData = availableTabs.filter((t) => t.value === 'other')
        .length;
      if (!loading.zephyrLastHour) {
        if (
          (aqlIsOn.met && !metStationData) ||
          (aqlIsOn.other && !externalMetData)
        ) {
          switchAQLTab('zephyr');
        }
      }
    }
  }, [zephyr]);

  // Functions
  const switchAQLTab = (tab: string) => {
    if (!aqlIsOn[tab]) {
      changeLatestMeasurementTab(tab);
    }
  };

  const generateMetInfoToZephyr = async (actualZephyr: Zephyr) => {
    const packagedMetRequest = [specificZephyrMetRequestPackager(actualZephyr)];
    const externalMetData = await utilsRequest.getLatestMetDataForUser(
      packagedMetRequest as any,
      bearerToken,
    );
    const enrichedZephyr = specificZephyrMetDataEnricher(externalMetData);
    return enrichedZephyr;
  };

  const addMetInfoToZephyr = async () => {
    const metInfo = await generateMetInfoToZephyr(zephyr);
    const copyZephyrsList = [...unitList];
    const curZephyr: any = copyZephyrsList.find((z: Zephyr) => z.id_pod === zephyr.id_pod);
    curZephyr!['externalMetData'] = metInfo.externalMetData![0];
    setZephyrs(copyZephyrsList);
  };

  const addMetInfoToZephyrsList = async (label: string) => {
    if (label === 'Regional Met' && !zephyr.externalMetData) await addMetInfoToZephyr();
  }

  return (
    <>
      {!loading.zephyrLastHour ? (
        <li className="aql tourLatestMeasurements" data-user-tour="step-5">
          <button
            className={
              isOn || tourAQDataOpen
                ? 'active'
                : ''
            }
            onClick={() => toggleAccordion('aqData')}
          >
            {translate('AQLatestDataButton')}
          </button>
          {isOn  || tourAQDataOpen ? (
            <div className="aq-lm">
              <nav className="aq-nav">
                <ul>
                  {tabs.map(
                    (op, idx, arr) =>
                      arr.length > 1 ? (
                        <li
                          key={op.label}
                          onClick={async () => { switchAQLTab(op.value); await addMetInfoToZephyrsList(op.label); }}
                          className={aqlIsOn[op.value] ? 'active' : ''}
                        >
                          {op.value === 'zephyr'
                            ? zephyr.type === 0
                              ? translate('AQZephyrLabel')
                              : zephyr.type === 1 ? translate('AQAurnLabel') : translate('AQVZephyrLabel')
                            : op.label}
                        </li>
                      ) : (
                        <div key={op.label} />
                      ),
                  )}
                </ul>
              </nav>
              <div className="aq-sensor-data">
                <AQUnit
                  displayConfig={displayConfig}
                  isOn={aqlIsOn.zephyr || aqlIsOn.met}
                  tabs={aqlIsOn}
                  zephyr={zephyr}
                />
                <AQOther
                  displayConfig={displayConfig}
                  isOn={aqlIsOn.other}
                  zephyr={zephyr}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </li>
      ) : (
        <></>
      )}
    </>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  displayConfig: state.setDisplayConfig,
  loading: state.loading,
  tourAQDataOpen: state.tour.openAQIndex.openAQData,
  tourOpen: state.tour.openTour,
  unitList: state.getZephyrs.zephyrs,
  bearerToken: state.auth.bearerToken,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setZephyrs,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AQLatestData);
