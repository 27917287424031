// Type Imports
import {
  AlertPeriodMap,
  AlertRepeatMap,
  AlertThresholdMap,
  AQOptionsMap,
  AreaConfig,
  AsideOverlaysSpeciesMap,
  AsideUnitMetricsMap,
  BorderDashStrings,
  CardinalDirections,
  ComponentContent,
  Config,
  ContactForm,
  ContactFormMappair,
  ContactFormSelection,
  ContactFormSelectionLookup,
  ContactFormZephyr,
  CookieSettings,
  Days,
  GtmConfig,
  GtmEvents,
  GtmEventIdentifiers,
  HighDefinitionPointsGroup,
  Keys,
  Link,
  LocationCoordinates,
  MappAirFilterToggles,
  MappAirSelectedFilter,
  ObjectMap,
  Paths,
  Periods,
  PlaceholderAxis,
  SecondaryAxisConfig,
  ShowAQMALayer,
  Slot,
  Species,
  SpeciesMap,
  TilingGroup,
  TimePeriod,
  TodaySubspeciesMap,
  UnitSelections,
  UnitStatus,
  UrlQueryStringOptions,
  AnnualAvgTabMap,
  VZephyrsConfig,
  translationOption,
  tileDimensions,
  FleetManagementSpeciesMap,
  YearObj,
  TimeSliderOption,
  DropdownSelection,
  Pollutant,
  AveragingChain,
} from './interface';

import { getEnvVar } from './functions/general';

// Temp points config for high def queries
export const highDefinitionPointsGroup: HighDefinitionPointsGroup[] = [
  {
    users: ['ShropshireCouncil_exp', 'ShropshirePublic'],
    layers: [
      {
        curOverlay: 'UK PM25',
        highDefRef: 'shrewspm2p5',
        extent: [
          [-2.7642189, 52.705036],
          [-2.7449922, 52.714261],
        ],
      },
      {
        curOverlay: 'UK NO2',
        highDefRef: 'shrewsno2',
        extent: [
          [-2.7642189, 52.705036],
          [-2.7449922, 52.714261],
        ],
      },
      {
        curOverlay: 'UK PM25',
        highDefRef: 'bridgepm2p5',
        extent: [
          [-2.4258688154576897, 52.53372104880171],
          [-2.421977158630069, 52.53610104410657],
        ],
      },
      {
        curOverlay: 'UK NO2',
        highDefRef: 'bridgeno2',
        extent: [
          [-2.4258688154576897, 52.53372104880171],
          [-2.421977158630069, 52.53610104410657],
        ],
      },
    ],
  },
  {
    users: ['LeicesterCityCouncilv2', 'LeicesterPublic'],
    layers: [
      {
        curOverlay: 'UK PM25',
        highDefRef: 'lccpm2p5',
        extent: [
          [-1.209584207810321, 52.58458996570488],
          [-1.051119027894003, 52.68734057338594],
        ],
      },
    ],
  },
  {
    users: ['AMEY'],
    layers: [
      {
        curOverlay: 'UK PM25',
        highDefRef: 'newcastlepm2p5',
        extent: [
          [-2.236963, 53.007055],
          [-2.222011, 53.016015],
        ],
      },
      {
        curOverlay: 'UK PM25',
        highDefRef: 'cellarheadpm2p5',
        extent: [
          [-2.076753, 53.018486],
          [-2.054369, 53.031958],
        ],
      },
      {
        curOverlay: 'UK NO2',
        highDefRef: 'newcastleno2',
        extent: [
          [-2.236963, 53.007055],
          [-2.222011, 53.016015],
        ],
      },
      {
        curOverlay: 'UK NO2',
        highDefRef: 'cellarheadno2',
        extent: [
          [-2.076753, 53.018486],
          [-2.054369, 53.031958],
        ],
      },
    ],
  },
  {
    users: ['SiemensAdept'],
    layers: [
      {
        curOverlay: 'UK NO2',
        highDefRef: 'thatchamno2',
        extent: [
          [-1.269507, 51.394629],
          [-1.24105, 51.412793],
        ],
      },
      {
        curOverlay: 'UK PM25',
        highDefRef: 'thatchampm2p5',
        extent: [
          [-1.269507, 51.394629],
          [-1.24105, 51.412793],
        ],
      },
    ],
  },
  {
    users: ['LeicestershireCCPublic'],
    layers: [
      {
        curOverlay: 'UK NO2',
        highDefRef: 'no2uk2019',
        extent: [
          [-9.45, 49.8],
          [2.78, 60.85],
        ],
      },
      {
        curOverlay: 'UK PM25',
        highDefRef: 'pm2p5uk2019',
        extent: [
          [-9.45, 49.8],
          [2.78, 60.85],
        ],
      },
    ],
  },
  {
    users: ['liberiapublico'],
    layers: [
      {
        curOverlay: 'Global NO2',
        highDefRef: 'no2global',
        extent: [
          [-180, -90],
          [180, 90],
        ],
      },
      {
        curOverlay: 'Global PM25',
        highDefRef: 'pm2p5global',
        extent: [
          [-180, -90],
          [180, 90],
        ],
      },
    ],
  },
];

// Config
export const config: Config = {
  machine: getEnvVar('REACT_APP_CURRENT_MACHINE'),
  alertsAPI: getEnvVar('REACT_APP_ALERTS_API'),
  mappairAPI: getEnvVar('REACT_APP_MAPAIR_API'),
  myAirApi: getEnvVar('REACT_APP_MYAIR_API'),
  zephyrAPI: getEnvVar('REACT_APP_ZEPHYR_API'),
  zephyrDataAPI: getEnvVar('REACT_APP_ZEPHYR_API'),
  vZephyrAPI: getEnvVar('REACT_APP_VZEPHYR_API'),
  mappairTiledAPI: getEnvVar('REACT_APP_MAPAIR_Tiled_API'),
  masterAuthAPI: getEnvVar('REACT_APP_AUTH_API'),
  enableGridDragAndDrop:
    getEnvVar('REACT_APP_ENABLE_GRID_DRAG_AND_DROP') === 'true',
  enableAutoLoadFirstView:
    getEnvVar('REACT_APP_AUTOLOAD_FIRST_VIEW') === 'true',
  sentryENABLED: getEnvVar('REACT_APP_SENTRY_ENABLED') === 'true',
  sentryDSN: getEnvVar('REACT_APP_SENTRY_DSN'),
  sentryRELEASE: getEnvVar('REACT_APP_SENTRY_RELEASE'),
  sentryENVIRONMENT: getEnvVar('REACT_APP_SENTRY_ENVIRONMENT'),
};

export const gtmConfig: GtmConfig = {
  gtmId: 'GTM-KQQKNSK',
  auth: 'YYNlLIG2o0VS3dIJxQuwUg',
  preview: 'env-2',
};

// External Links
export const helpResourceLink: Link = {
  url: 'https://www.earthsense.co.uk/zephyr-resources',
  target: '_blank',
};

export const privacyPolicyLink: Link = {
  url: 'https://www.earthsense.co.uk/privacy-policy',
  target: '_blank',
};

export const tutorialLink: Link = {
  url: 'https://www.earthsense.co.uk/product-resources',
  target: '_blank',
};

export const webinarResourceLink: Link = {
  url: 'https://www.earthsense.co.uk/myair-training-webinar',
  target: '_blank',
};

export const mappairLink: Link = {
  content: 'MappAir',
  url: 'https://www.earthsense.co.uk/mappair',
  target: '_blank',
};

// Copyright links and statements

export const naeiCRLink: Link = {
  content: 'Defra & BEIS',
  precedingContent: '©',
  url: 'https://naei.beis.gov.uk/',
  target: '_blank',
};

export const osmCRLink: Link = {
  content: 'OpenStreetMap',
  precedingContent: '©',
  url: 'https://www.openstreetmap.org/copyright',
  target: '_blank',
};

export const publicCRLink: Link = {
  content: 'Open Government License',
  precedingContent: '',
  url:
    'http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/',
  target: '_blank',
};

export const mapboxCRLink: Link = {
  content: 'Mapbox',
  precedingContent: '©',
  url: 'https://www.mapbox.com/about/maps/',
  target: '_blank',
};

export const mapboxImproveLink: Link = {
  content: 'Improve this map',
  precedingContent: '',
  url: 'https://www.mapbox.com/map-feedback/',
  target: '_blank',
};

export const maxarCRLink: Link = {
  content: 'Maxar',
  precedingContent: '©',
  url: 'https://www.maxar.com/',
  target: '_blank',
};

// Route Paths
export const routePaths: Paths = {
  alerts: '/alerts',
  analytics: '/analytics',
  app: '/',
  data: '/data',
  login: '/login',
  today: '/today',
  fleetManagement: '/fleetManagement',
  smsAlerts: '/smsAlerts',
  dataAnalytics: '/analysis',
};

// jbdsfjsdfjjk
// API Paths
export const APIPaths: Paths = {
  api: 'api/',
  airAlertsApi: '',
  pathMappair: '',
  createZephyrAlias: 'v2/createZephyrAlias',
  csvDataDownload: 'v2/dataForViewBySlotsAveraged',
  cachedDataForViewBySlotsAveraged: 'cachedDataForViewBySlotsAveraged',
  dataForViewBySlotsAveraged: 'dataForViewBySlotsAveraged',
  GetMeasurementData: 'v2/measurementdata',
  deleteAirAlert: 'DeleteAlert',
  downloadCalibrationCertificate: 'calibrationCertificatesForZephyr',
  GetMeasurmentData: '',
  GetMeasurmentDataDSE: 'v2/measurementdata',
  getAirAlertTypes: 'GetAlertTypes',
  getAirAlerts: 'GetAlerts',
  updateAlertParameters: 'UpdateAlertParameter',
  updateAlertTargets: 'UpdateTarget',
  addAlertTarget: 'AddTarget',
  removeAlertTarget: 'RemoveTargetFromAlert',
  getAurns: 'v2/GetUserAurns',
  getAveragingPeriods: 'getAveragingPeriods',
  getCartridge: 'v2/GetZephyrCartridgeData',
  getDataConfig: 'getDataConfig',
  getDisplayConfig: 'getDisplayConfig',
  getToken: 'getToken',
  getLatestMetDataForUser: 'getLatestMetDataForUser',
  getLocationGoogle: 'https://maps.googleapis.com/maps/api/geocode/json',
  getLocationSuggestionsGoogle:
    'https://maps.googleapis.com/maps/api/place/autocomplete/json',
  getMappairAPIVersion: 'getMappairAPIVersion',
  getMappairOverlays: 'getMappairOverlays',
  getMappairStylegroups: 'getMappairStylegroups',
  getMappairOverlaySAValues: 'sa',
  getMappairOverlayValue: 'getMappairOverlayValue',
  getMappairOverlayImage: 'getMappairOverlayImage',
  getMappairOverlayImageTiled: 'getMappairOverlayImageTiled',
  getMappairAQMAImage: 'getAQMAImage',
  getMappairSchoolsImage: 'getShpImage',
  getMappairPoint: 'point',
  getMyAirVersions: 'getMyAirVersions',
  getPolarPlots: 'getPolarPlots',
  getPublicAdvice: 'getPublicAdvice',
  getTimePeriods: 'getTimePeriods',
  getTodayData: 'point',
  getUserAgreements: 'getUserAgreements',
  getUserConfig: 'getUserConfig',
  getUserFeedbackQuestions: 'getUserFeedbackQuestions',
  getUserInfo: 'getUserInfo',
  getZephyrs: 'v2/GetZephyrs',
  kmlDataDownload: 'dataForViewSpecies',
  mapboxSatellite:
    'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/256/{z}/{x}/{y}',
  postAirAlerts: 'AddAlerts',
  postContactFormEmail: 'postContactFormEmail',
  postUserFeedback: 'postUserFeedback',
  setUserTour: 'setUserTour',
  signUserAgreement: 'signUserAgreement',
  updateAirAlert: 'UpdateAlert',
  updateUserLatestVersionViewed: 'updateUserLatestVersionViewed',
  zephyrsForUserWithHistory: 'zephyrsForUserWithHistory',
  zephyrsForUserWithHistoryAndAQ: 'zephyrsForUserWithHistoryAndAQ',
};

// Versions
// Set to empty string, when wanting to take value from latest v in DB (production)
// export const appVersion: string = '4.3.1';

// Keys
export const keys: Keys = {
  GoogleMaps: 'AIzaSyBEUtG_mjM0jd4CxG1MBcoT0l9LYcFqoKM',
  Mapbox:
    'pk.eyJ1IjoiZWFydGhzZW5zZWFxIiwiYSI6ImNrZ3FicWNsNDAzcGkycXF3ZG43YXU4amoifQ.j_pJt2C_k-8yS-T0o8xzxg',
};

// Cardinal Directions
export const cardinalDirectionsPlus: CardinalDirections[] = [
  {
    minDegrees: 0,
    dirName: 'N',
  },
  {
    minDegrees: 22.5,
    dirName: 'NNE',
  },
  {
    minDegrees: 45,
    dirName: 'NE',
  },
  {
    minDegrees: 67.5,
    dirName: 'ENE',
  },
  {
    minDegrees: 90,
    dirName: 'E',
  },
  {
    minDegrees: 112.5,
    dirName: 'ESE',
  },
  {
    minDegrees: 135,
    dirName: 'SE',
  },
  {
    minDegrees: 157.5,
    dirName: 'SSE',
  },
  {
    minDegrees: 180,
    dirName: 'S',
  },
  {
    minDegrees: 202.5,
    dirName: 'SSW',
  },
  {
    minDegrees: 225,
    dirName: 'SW',
  },
  {
    minDegrees: 247.5,
    dirName: 'WSW',
  },
  {
    minDegrees: 270,
    dirName: 'W',
  },
  {
    minDegrees: 292.5,
    dirName: 'WNW',
  },
  {
    minDegrees: 315.5,
    dirName: 'NW',
  },
  {
    minDegrees: 337.5,
    dirName: 'NNW',
  },
];

// Analytics
export const gtmEventIdentifiers: GtmEventIdentifiers = {
  login: 'login',
  logout: 'logout',
  versionModal: 'versionModal',
  privacyPolicyLink: 'privacyPolicyLink',
  resourcesLink: 'resourcesLink',
  tutorialModal: 'tutorialModal',
  tour: 'tour',
  contactModal: 'contactModal',
  mappairToggle: 'mappairToggle',
  aurnToggle: 'aurnToggle',
  satelliteToggle: 'satelliteToggle',
  aqmaToggle: 'aqmaToggle',
  schoolsToggle: 'schoolsToggle',
  smokeControlZonesToggle: 'smokeControlZonesToggle',
  zephyrToggle: 'zephyrToggle',
  vZephyrToggle: 'vZephyrToggle',
  allToggle: 'allToggle',
  unitSelect: 'unitSelect',
  unitCertificateDownload: 'unitCertificateDownload',
  pollutantSelect: 'pollutantSelect',
  sourceSelect: 'sourceSelect',
  colourscaleSelect: 'colourscaleSelect',
  aqiIndexSwitch: 'aqiIndexSwitch',
  aqlIndexSwitch: 'aqlIndexSwitch',
  aqmIndexSwitch: 'aqmIndexSwitch',
  timeseriesExpansion: 'timeseriesExpansion',
  mapClick: 'mapClick',
  timePeriodSwitch: 'timePeriodSwitch',
  timeAveragingSwitch: 'timeAveragingSwitch',
  dataDownload: 'dataDownload',
  speciesGroupSwitch: 'speciesGroupSwitch',
  unitOverplot: 'unitOverplot',
  annualAveragesToggle: 'annualAveragesToggle',
};

export const gtmEvents: GtmEvents = {
  login: {
    category: 'App',
    action: 'Login Attempt',
    label: 'Log In',
    value: 0,
  },
  logout: {
    category: 'App',
    action: 'Log Out',
    label: 'Log Out',
    value: 0,
  },
  versionModal: {
    category: 'App',
    action: 'Version History View',
    label: 'Version History Modal View',
    value: 0,
  },
  privacyPolicyLink: {
    category: 'App',
    action: 'Privacy Link Click',
    label: 'Privacy Link Click',
    value: 0,
  },
  resourcesLink: {
    category: 'Help',
    action: 'Resources Link Click',
    label: 'Resources Link Click',
    value: 0,
  },
  tutorialModal: {
    category: 'Help',
    action: 'Tutorial View',
    label: 'Tutorial Modal View',
    value: 0,
  },
  tour: {
    category: 'Help',
    action: 'Tour Launch',
    label: 'Tour Launch',
    value: 0,
  },
  contactModal: {
    category: 'Help',
    action: 'Contact Form View',
    label: 'Contact Form View',
    value: 0,
  },
  mappairToggle: {
    category: 'Product Inventory',
    action: 'Overlays Checkbox',
    label: 'MappAir Overlay',
    value: 0,
  },
  aurnToggle: {
    category: 'Product Inventory',
    action: 'Overlays Checkbox',
    label: 'AURN Overlay',
    value: 0,
  },
  satelliteToggle: {
    category: 'Product Inventory',
    action: 'Overlays Checkbox',
    label: 'Satellite Overlay',
    value: 0,
  },
  aqmaToggle: {
    category: 'Product Inventory',
    action: 'Overlays Checkbox',
    label: 'AQMA Overlay',
    value: 0,
  },
  schoolsToggle: {
    category: 'Product Inventory',
    action: 'Overlays Checkbox',
    label: 'Schools Overlay',
    value: 0,
  },
  smokeControlZonesToggle: {
    category: 'Product Inventory',
    action: 'Overlays Checkbox',
    label: 'UK Smoke Control Zones Overlay',
    value: 0,
  },
  zephyrToggle: {
    category: 'Product Inventory',
    action: 'Overlays Checkbox',
    label: 'Zephyr Overlay',
    value: 0,
  },
  vZephyrToggle: {
    category: 'Product Inventory',
    action: 'Overlays Checkbox',
    label: 'Virtual Zephyr Overlay',
    value: 0,
  },
  allToggle: {
    category: 'Product Inventory',
    action: 'Overlays Checkbox',
    label: 'Show All Overlays',
    value: 0,
  },
  unitSelect: {
    category: 'Product Inventory',
    action: 'Unit Click',
    label: 'Unit Select',
    value: 0,
  },
  unitCertificateDownload: {
    category: 'Product Inventory',
    action: 'Unit Click',
    label: 'Calibration Certificate Download',
    value: 0,
  },
  pollutantSelect: {
    category: 'Map',
    action: 'Pollutant Dropdown Select',
    label: 'Pollutant Switch',
    value: 0,
  },
  sourceSelect: {
    category: 'Map',
    action: 'Source Dropdown Select',
    label: 'Source Layer Switch',
    value: 0,
  },
  colourscaleSelect: {
    category: 'Map',
    action: 'Colourscale Dropdown Select',
    label: 'Colourscale Switch',
    value: 0,
  },
  aqiIndexSwitch: {
    category: 'Map',
    action: 'AQI Index Switch',
    label: 'AQI Index Switch',
    value: 0,
  },
  aqlIndexSwitch: {
    category: 'Map',
    action: 'AQL Index Switch',
    label: 'AQL Index Switch',
    value: 0,
  },
  aqmIndexSwitch: {
    category: 'Map',
    action: 'AQM Index Switch',
    label: 'AQM Index Switch',
    value: 0,
  },
  timeseriesExpansion: {
    category: 'Map',
    action: 'Timeseries Expansion',
    label: 'Timeseries Expansion View',
    value: 0,
  },
  mapClick: {
    category: 'Map',
    action: 'Map Point Click',
    label: 'MappAir Point Click',
    value: 0,
  },
  timePeriodSwitch: {
    category: 'Data',
    action: 'Time Period Checkbox',
    label: 'Time Period Switch',
    value: 0,
  },
  timeAveragingSwitch: {
    category: 'Data',
    action: 'Time Averaging Checkbox',
    label: 'Time Averaging Switch',
    value: 0,
  },
  dataDownload: {
    category: 'Data',
    action: 'Download Select',
    label: 'Data Download',
    value: 0,
  },
  speciesGroupSwitch: {
    category: 'Data',
    action: 'Species Group Switch',
    label: 'Species Group Switch',
    value: 0,
  },
  unitOverplot: {
    category: 'Data',
    action: 'Unit Overplot',
    label: 'Unit Overplot',
    value: 0,
  },
};

// Cookies
export const permissionCookieSettings: CookieSettings = {
  name: 'Opt-out',
  expiry: 60,
};

// MappAir
export const speciesMap: SpeciesMap = {
  combinedpollen: 'Pollen',
  pm2p5: 'PM₂.₅',
  relhum: 'Humidity',
  temperature: 'Temp',
};

// Periods
export const days: Days = {
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
  7: 'Sun',
};

export const periods: Periods = {
  24: { quantity: 1, unit: 'days' },
  48: { quantity: 2, unit: 'days' },
  72: { quantity: 3, unit: 'days' },
  week: { quantity: 7, unit: 'days' },
  month: { quantity: 1, unit: 'months' },
  default: { quantity: 1, unit: 'days' },
};

// Alerts
export const alertPeriodSelections: AlertPeriodMap[] = [
  {
    rawPeriod: '15',
    formattedPeriod: '15 min',
  },
  {
    rawPeriod: '30',
    formattedPeriod: '30 min',
  },
  {
    rawPeriod: '60',
    formattedPeriod: '60 min',
  },
  {
    rawPeriod: '480',
    formattedPeriod: '8hr',
  },
  {
    rawPeriod: '1440',
    formattedPeriod: '24hr',
  },
];

export const alertRepeatSelections: AlertRepeatMap[] = [
  {
    rawRepeat: 'never',
    formattedRepeat: 'Never',
  },
  {
    rawRepeat: 'hourly',
    formattedRepeat: 'Hourly',
  },
  {
    rawRepeat: 'daily',
    formattedRepeat: 'Daily',
  },
];

export const alertThresholdSelections: AlertThresholdMap[] = [
  {
    rawThreshold: 'greater',
    formattedThreshold: 'greater',
    postVerb: 'than',
  },
  // {
  //   rawThreshold: 'greaterOrEqual',
  //   formattedThreshold: 'greater or equal',
  //   postVerb: 'to',
  // },
  // {
  //   rawThreshold: 'lessOrEqual',
  //   formattedThreshold: 'less or equal',
  //   postVerb: 'to',
  // },
  {
    rawThreshold: 'less',
    formattedThreshold: 'less',
    postVerb: 'than',
  },
];

export const todaySubspeciesMap: TodaySubspeciesMap[] = [
  {
    label: 'Temp',
    speciesRef: 'Temp',
  },
  {
    label: 'Humidity',
    speciesRef: 'Humidity',
  },
  {
    label: 'Pollen',
    speciesRef: 'Pollen',
  },
];

export const unitSelections: UnitSelections = {
  unitSelectionOptions: [
    { type: 'specific', HTMLLabel: 'Specific Zephyr(s)' },
    { type: 'non-specific', HTMLLabel: 'All Zephyrs in my inventory' },
  ],
};

export const unitStatus: UnitStatus[] = [
  {
    type: 'battery',
    HTMLLabel: 'Battery',
  },
  {
    type: 'charge',
    HTMLLabel: 'Charge',
  },
  {
    type: 'solar',
    HTMLLabel: 'Solar panel',
  },
  {
    type: 'power',
    HTMLLabel: 'Power',
  },
  {
    type: 'data',
    HTMLLabel: 'Data',
  },
];

// CRS
export const crs: string = 'EPSG%3A3857';

// Border Dashes
export const borderDashConfigs: number[][] = [
  [],
  [2, 1],
  [4, 2],
  [15, 3, 3, 3, 3],
  [8, 5],
];

export const borderDashConfigs2: BorderDashStrings[] = [
  'solid',
  'shortDot',
  'shortDash',
  'longDashDot',
  'longDash',
];

// Default Location
export const defaultLocation: LocationCoordinates = [
  -1.127121667,
  52.619883333,
];

// Quotes
export const zephyrMeasurementOptions: ContactFormSelection[] = [
  {
    value: 'NO, NO2, O3',
    label: 'NO, NO₂, O₃',
  },
  {
    value: 'PM1, PM2.5, PM10',
    label: 'PM₁, PM₂.₅, PM₁₀',
  },
  {
    value: 'SO2, H2S, CO',
    label: 'SO₂, H₂S, CO',
  },
  {
    value: 'CO2',
    label: 'CO₂',
  },
  {
    value: 'TVOCs',
    label: 'TVOCs',
  },
  {
    value: 'Met Station',
    label: 'Met Station',
  },
];

export const mappairMeasurementOptions: ContactFormSelection[] = [
  {
    value: 'NO2',
    label: 'NO₂',
  },
  {
    value: 'PM2.5',
    label: 'PM₂.₅',
  },
];

export const mappairCFDOptions: ContactFormSelection[] = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
];

export const mappairResolutionOptions: ContactFormSelection[] = [
  {
    value: '10m',
    label: '10m',
  },
  {
    value: '5m',
    label: '5m',
  },
  {
    value: '2m',
    label: '2m',
  },
];

export const mappairOutputOptions: ContactFormSelection[] = [
  {
    value: 'Export',
    label: 'Export',
  },
  {
    value: 'API',
    label: 'API',
  },
];

// Default Contact Form
export const defaultContactForm: ContactForm = {
  formData: {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    enquiry: '',
    details: '',
    comments: '',
  },
  userMessage: '',
};

export const detailsContactFormLookup: ContactFormSelectionLookup = {
  Sales: [
    {
      value: 'Expand Zephyr Network',
      label: 'Expand Zephyr Network',
    },
    {
      value: 'Upgrade MappAir Model',
      label: 'Upgrade MappAir Model',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ],
  Feedback: [
    {
      value: 'MyAir',
      label: 'MyAir',
    },
    {
      value: 'Zephyr',
      label: 'Zephyr',
    },
    {
      value: 'MappAir',
      label: 'MappAir',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ],
  Support: [
    {
      value: 'MyAir',
      label: 'MyAir',
    },
    {
      value: 'Zephyr',
      label: 'Zephyr',
    },
    {
      value: 'MappAir',
      label: 'MappAir',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ],
};

export const defaultAreaConfig: AreaConfig = {
  areaSize: null,
  centreHdms: null,
  latLngGeometry: null,
};

export const defaultMappairForm: ContactFormMappair = {
  formData: {
    area: defaultAreaConfig,
    email: '',
    comments: '',
    company: '',
    firstName: '',
    lastName: '',
    pollutants: [],
  },
  userMessage: '',
};

export const defaultMappairOptions: ShowAQMALayer = {
  opacity: 1,
  overlay: '',
  mappairPointModeOn: false,
  showAQMALayer: false,
  showSchoolsLayer: false,
  showSmokeControlZones: false,
  showOverlayLayer: false,
  showSatelliteLayer: false,
  showAALayer: false,
  annualAverage: null,
};

export const defaultVZephyrsConfig: VZephyrsConfig = {
  active: 0,
  max: 0,
  new30Days: 0,
  new30DaysMax: 0,
};

export const defaultZephyrForm: ContactFormZephyr = {
  formData: {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    zephyrs: 1,
    powerOption: '',
    species: [],
  },
  userMessage: '',
};

export const enquiryContactForm: ContactFormSelection[] = [
  { value: 'Sales', label: 'Sales' },
  { value: 'Feedback', label: 'Feedback' },
  { value: 'Support', label: 'Support' },
];

export const zephyrPowerOptions: ContactFormSelection[] = [
  { value: 'Mains', label: 'Mains' },
  { value: 'Solar', label: 'Solar' },
];

// AQM
export const defaultSpecies: Species = {
  colour: [{ r: '0', g: '0', b: '0' }],
  conversionDivider: false,
  conversionMultiplier: false,
  dataIdentifier: 'AQI',
  dataIdentifiers: ['AQI'],
  HTMLExposedUnitLabel: 'Max:10',
  HTMLLabel: 'AQI',
  HTMLUnitLabel: 'AQI',
  HTMLShortUnitLabel: '',
  label: 'AQI',
  maximum: false,
  minimum: 0,
  secondaryAxis: false,
};

export const aqlOtherTab: AQOptionsMap = {
  label: 'Regional Met',
  value: 'other',
};

export const annualAvgTab: AnnualAvgTabMap[] = [
  {
    label: 'Chart',
    value: 'chart',
  },
  {
    label: 'List',
    value: 'list',
  },
];

export const annualAvgtext: ComponentContent = {
  tableRowforYr: 'Year',
  tabContentTitleForChartPrefix: 'Last ',
  tabContentTitleForChartPostfix: ' year(s)',
  tabContentTitleForListPrefix: 'Area - Last ',
  tabContentTitleForListPostfix: ' year(s)',
  nodata: 'No data found for selected coordinates...',
  AAChartHeader: 'Virtual Zephyr',
  AADownloadData: 'Download data',
  AASeeMore: 'See more data',
  annualAvgNotAuthorised:
    "Annual averages functionality isn't turned on for this user. Please contact support@earthsense.co.uk if this isn't as expected.",
  annualAvgAccordionTitle: 'Annual Averages',
};

export const numberYrsHistory = 5;

export enum NoOfYearsTypes {
  one = '1',
  two = '2',
  three = '3',
  four = '4',
  five = '5',
  six = '6',
  seven = '7',
  eight = '8',
  nine = '9',
}

export const YearOptions: Array<{ label: string; value: string }> = [
  {
    label: '2 Years (min)',
    value: NoOfYearsTypes.two,
  },
  {
    label: '3 Years',
    value: NoOfYearsTypes.three,
  },
  {
    label: '4 Years',
    value: NoOfYearsTypes.four,
  },
  {
    label: '5 Years',
    value: NoOfYearsTypes.five,
  },
  {
    label: '6 Years',
    value: NoOfYearsTypes.six,
  },
  {
    label: '7 Years',
    value: NoOfYearsTypes.seven,
  },
  {
    label: '8 Years',
    value: NoOfYearsTypes.eight,
  },
  {
    label: '9 Years (max)',
    value: NoOfYearsTypes.nine,
  },
];

export const aqmOptions: AQOptionsMap[] = [
  {
    label: 'Temporal',
    value: 'aqmTemp',
  },
  {
    label: 'Sources',
    value: 'aqmSA',
  },
  {
    label: 'Wind',
    value: 'aqmWind',
  },
];

// Alert Options
export const alertThresholdOptions: ObjectMap = {
  greater: '>',
  greaterOrEqual: '>=',
  lessOrEqual: '<=',
  less: '<',
};

// URL Options
export const urlQueryStringOptions: UrlQueryStringOptions[] = [
  'ctv',
  'diagnostics',
];

// Data Options
export const customTimePeriod: TimePeriod = {
  labelHTML: 'Custom',
  timeOption: 'custom',
};

export const defaultModelUnit: string = 'score';

export const gaseousSlots: Slot[] = [
  { label: 'slotA', HTMLLabel: 'Slot A' },
  { label: 'slotB', HTMLLabel: 'Slot B' },
];

export const slots: Slot[] = [
  { label: 'slotA', HTMLLabel: 'Slot A' },
  { label: 'slotB', HTMLLabel: 'Slot B' },
  //{ label: 'head', HTMLLabel: 'Met Station' },
];

export const defaultSelectedFilters: MappAirSelectedFilter[] = [
  {
    name: 'zephyr',
    showAllLabels: true,
    showSelectedLabel: true,
  },
  {
    name: 'virtual',
    showAllLabels: true,
    showSelectedLabel: true,
  },
  {
    name: 'aurn',
    showAllLabels: true,
    showSelectedLabel: true,
  },
];

export const placeholderAxis: PlaceholderAxis = {
  labelFontSize: 0,
  lineThickness: 0,
  margin: 0,
  tickLength: 0,
  title: '',
};

export const placeholderAxisConfig: SecondaryAxisConfig[] = [
  { idx: 0, maximum: null, minimum: 0, title: '' },
  { idx: 1, maximum: null, minimum: 0, title: '' },
];

// Component Content
export const AirAlertsConfiguratorContent: ComponentContent = {
  AACValidationFailure: 'Please fix the issues below to submit this alert',
  AACDataSourceHeader: 'Select Data Source',
  AACDataSourceSelection: 'Choose Zephyrs',
  AACValidationFailureSources: 'Please select at least one Zephyr',
  AACAlertDataHeader: 'Alert Data',
  AACGasColumn: 'Select Gas',
  AACThresholdColumn: 'Threshold',
  AACAlertColumn: 'Alert Level',
  AACPeriodColumn: 'Period',
  AACRepeatColumn: 'Repeat',
  AACUnitStatusSelection: 'Send status alerts for:',
  AACValidationFailureData: 'Please add at least one gas or status to track',
  AACOutputHeader: 'Output',
  AACOutputName: 'Name',
  AACValidationFailureName: 'Please add a name for this alert',
  AACOuputEmail: 'Email address',
  AACOutputPhoneNumber: 'Phone Numbers',
  AACValidationFailureEmail: 'Please enter valid email address',
  AACValidationFailurePhoneNumber:
    'Number must be in format +(country code)(phone number)',
  AACValidationCommunicationType: 'Please add one method of communication',
};

export const AirAlertsOverviewContent: ComponentContent = {
  AAOHeader: 'Alerts (Max 5 per standard user)',
  AAOCreateNew: 'Create new alert',
  AAONameColumn: 'Name',
  AAOAlertDataColumn: 'Alert Data',
  AAOStatusColumn: 'Status',
  AAOActionscolumn: 'Actions',
  AAONoAlerts: "You haven't created any alerts yet",
};

export const AirQuality101Content: ComponentContent = {
  AQ101header: 'Air Quality Info',
  AQ101CloseModal: 'x',
};

export const AppIntroModelContent: ComponentContent = {
  AppIntroButton: 'OK',
};

export const AQColourscaleContent: ComponentContent = {
  AQCButton: 'Colourscale',
  AQCButtonPublic: 'Map Colourscale',
};

export const AQLatestDataContent: ComponentContent = {
  AQAurnLabel: 'AURN',
  AQLatestDataButton: 'Measurement Data',
  AQZephyrLabel: 'Zephyr',
  AQVZephyrLabel: 'Virtual Zephyr',
};

export const AQOtherContent: ComponentContent = {
  AQDisclaimerGFS:
    'Please note, the publicly available GFS data being displayed here is un-ratified and is not the property of EarthSense. EarthSense take no responsibility for the accuracy and currency of these data.',
  AQDisclaimerMet:
    'Please note, the publicly available Met Office data being displayed here is un-ratified and is not the property of EarthSense. EarthSense take no responsibility for the accuracy and currency of these data.',
  AQGFSDesc: 'NOAA Global Forecast',
  AQMetDesc: 'Met Office Station',
  AQPeriodGFS: 'last 3 hours',
  AQPeriodMet: 'last hour',
};

export const AQModelDataContent: ComponentContent = {
  AQModelDataButton: 'MappAir Model Data',
  AQModelDataLoading: 'Loading model data',
  AQModelDataClickPrompt:
    'Click a point on the map to get additional model data.',
  AQModelNoData: 'No additional model data is available at this location.',
};

export const AQSAContent: ComponentContent = {
  AQTotal: 'Total',
  AQUnits: '2018 tonnes/annum',
};

export const AQTemporalContent: ComponentContent = {
  AQChartHeader: 'Historic & Forecast',
  AQDownloadData: 'Download data',
};

export const AQUnitContent: ComponentContent = {
  AQSummaryHeader: 'score: ',
  AQPeriod: 'Last hourly averages',
  AQNoData: 'No data available.',
  AQNoOwnership: 'No data available.',
};

export const AQWindContent: ComponentContent = {
  AQChartHeaderOne: 'Frequency & Speed',
  AQChartSubheader: 'Last 30 days',
};

export const AsideContent: ComponentContent = {
  AsideHeader: 'Product Inventory',
  PublicAsideHeader: 'Air Quality Data Sources',
};

export const AsidePromptContent: ComponentContent = {
  AsideButtonContent: 'Get Quote',
  AsidePromptExplainer: 'Expand Zephyr Network',
  AsideAnalyticsPrompt: 'Back To Configuration Menu',
  AsideAnalyticsButton: 'Back',
};

export const AsideOverlaysContent: ComponentContent = {
  OverlaysAQMA: 'Air Quality Management Areas',
  OverlaysSchools: 'Schools',
  OverlaysUKSmokeControlZones: 'Smoke Control Zones',
  OverlaysAURN: 'AURN',
  OverlaysHeader: 'Overlays',
  OverlaysDefault: 'Default',
  OverlaysMappair: 'Real-Time & Forecast',
  OverlaysRemoveAll: 'Remove all',
  OverlaysSatellite: 'Satellite',
  OverlaysShowAll: 'Show all',
  OverlaysZephyrs: 'Zephyr',
  MapDetails: 'Map details',
  MapTypes: 'Map type',
  OverlaysAA: 'Annual Averages',
  ModelLayers: 'Air Quality Map Layers',
  ChooseYear: 'Choose a year:',
  BaseMaps: 'Base maps',
};

export const AsideOverlaysContentPublic: ComponentContent = {
  OverlaysMappair: 'Pollution Model (MappAir)',
  OverlaysAQMA: 'Air Quality Management Areas',
  OverlaysHeader: 'Overlays',
  OverlaysDefault: 'Default',
  OverlaysSchools: 'Schools',
  OverlaysUKSmokeControlZones: 'Smoke Control Zones',
  OverlaysAA: 'Annual Averages',
};

export const AsideOverlaysSpecies: AsideOverlaysSpeciesMap = {
  speciesMap: [
    { type: 'zephyr', label: 'Zephyr' },
    { type: 'virtual', label: 'Virtual Zephyrs' },
    { type: 'aurn', label: 'AURN' },
  ],
};
export const FleetManagementAvailableSpecies: FleetManagementSpeciesMap = {
  speciesMap: [
    { type: 'zephyr', label: 'Zephyr', checked: false, zType: 0 },
    { type: 'virtual', label: 'Virtual Zephyrs', checked: false, zType: 100 },
    { type: 'aurn', label: 'AURN', checked: false, zType: 1 },
  ],
};

export const AsideOverlaysPublicSpecies: AsideOverlaysSpeciesMap = {
  speciesMap: [
    { type: 'zephyr', label: 'Local Network (Zephyrs)' },
    { type: 'virtual', label: 'Virtual Zephyrs' },
    { type: 'aurn', label: 'National Network (AURN)' },
  ],
};

export const AsideUnitContent: ComponentContent = {
  AsideUnitBatteryCharging: 'Charging',
  AsideUnitBatteryChargingDesc: 'Zephyr battery is charging.',
  AsideUnitBatteryDischarging: 'Draining',
  AsideUnitBatteryDischargingDesc:
    'Zephyr running on internal battery power, battery well-charged.',
  AsideUnitBatteryExplainer: 'Running on internal battery power',
  AsideUnitBatteryFlat: 'Flat',
  AsideUnitBatteryFlatDesc:
    'Zephyr running on internal battery power, battery with little or no charge.',
  AsideUnitBatteryPower: 'Power',
  AsideUnitCartridgeBlank: 'Blank',
  AsideUnitCartridgeExplainerStandard:
    'Sensors include: NO<sub>2</sub>, NO, O<sub>3</sub>, PM<sub>1</sub>, PM<sub>2.5</sub>, PM<sub>10</sub>, Pressure, Temperature & Relative Humidity',
  AsideUnitCartridgeExplainerEnhanced:
    'Sensors include: NO<sub>2</sub>, NO, O<sub>3</sub>, PM<sub>1</sub>, PM<sub>2.5</sub>, PM<sub>10</sub>, CO, SO<sub>2</sub>, H<sub>2</sub>S, Pressure, Temperature & Relative Humidity',
  AsideUnitCartridgeExplainerEnhancedPlus:
    'Sensors include: NO<sub>2</sub>, NO, O<sub>3</sub>, PM<sub>1</sub>, PM<sub>2.5</sub>, PM<sub>10</sub>, CO, SO<sub>2</sub>, H<sub>2</sub>S, CO<sub>2</sub>, Pressure, Temperature & Relative Humidity',
  AsideUnitCartridgeExplainderEnhancedPP:
    'Sensors include: NO<sub>2</sub>, NO, O<sub>3</sub>, PM<sub>1</sub>, PM<sub>2.5</sub>, PM<sub>10</sub>, CO, SO<sub>2</sub>, H<sub>2</sub>S, CO<sub>2</sub>, TVOCs, Pressure, Temperature & Relative Humidity',
  AsideUnitConnectionGSM: 'GSM',
  AsideUnitConnectionNetwork: 'Network',
  AsideUnitLocationHeader: 'Location:',
  AsideUnitUpdatedHeader: 'Updated:',
  AsideUnitIdHeader: 'ID:',
  AsideUnitStatusHeader: 'Status',
  AsideUnitFrequencyHeader: 'Frequency',
  AsideUnitCertificateDownloadButton: 'Download calibration certificates',
  AsideUnitConnectionExplainer:
    'Data communications available, data received in the last 2 hours.',
  AsideUnitConnectionWarning:
    'Temporary communications gap: No data uploaded in the last 2 hours, but data received in the last 24 hours. Measurements stored locally.',
  AsideUnitConnectionAlert: 'No data uploaded in the last 24 hours.',
  AsideUnitEditName: 'Edit Name',
  AsideUnitEditNetwork: 'Edit network credentials',
  AsideUnitDeleteName: 'Delete',
  VZUnitDeleteName: 'Remove',
  AsideUnitOwnership: 'Ownership:',
  AsideUnitSamplingData: 'Data',
  AsideUnitSamplingExplainerHigh: 'Sample Rate: 10 Seconds',
  AsideUnitSamplingExplainerMed: 'Sample Rate: 60 Seconds',
  AsideUnitSamplingExplainerLow: 'Sample Rating: 60 Seconds',
  AsideUnitSamplingOff: 'No Sample',
  AsideUnitSamplingOn: 'Sampling',
  AsideUnitStatus: 'Status:',
  AsideUnitStatusRetired: 'Retired:',
  AsideUnitType: 'Type:',
  AsideUnitTypeAURN: 'AURN',
  AsideUnitTypeVirtualZephyr: 'Virtual Zephyr',
  AsideUnitTypeZephyr: 'Zephyr',
};

export const WiFiCredentialsContent: ComponentContent = {
  EditWifiCredentials: 'Edit WiFi Credentials',
  EditWifiCloseModal: 'Cancel',
  EditWifiSaveButton: 'Upload Wifi Settings',
  StaticWiFiCredentials: 'Static Wifi Credentials (SSID)',
  DynamicWiFiCredentials: 'Dynamic Wifi Credentials',
  NetworkNameLabel: 'Network Name',
  NetworkPasswordLabel: 'Network Password',
  NetworkCredentialsError:
    'There was an issue retrieving the existing credentials',
};

export const AsideUnitMetrics: AsideUnitMetricsMap = {
  metricsStatusMap: [
    { item: 'extVoltage', header: 'Input voltage', unit: ' V' },
    { item: 'batteryVoltage', header: 'Battery voltage', unit: ' V' },
    { item: 'batteryCharge', header: 'Battery charge', unit: '%' },
  ],
  metricsFrequencyMap: [
    { item: 'measurementCount', header: 'Readings in last upload' },
  ],
};

export const PermissionCookieBannerContent: ComponentContent = {
  PCBExplainer:
    'MyAir uses third-party analytics cookies to inform the release of improved future versions.',
  PCBExplainerPreSpan: 'We use',
  PCBExplainerSpan: 'optional',
  PCBExplainPostSpan:
    'third-party analytics cookies to understand how we can build better products.',
  PCBButtonAccept: 'Accept',
  PCBButtonReject: 'Reject',
  PCBButtonOk: 'OK',
};

export const DataAQSummaryContent: ComponentContent = {
  DataAQSummaryHeader: 'Last hour',
};

export const DataChartDisclaimerContent: ComponentContent = {
  DataChartDisclaimer:
    'Please note, the publicly available DEFRA AURN data being displayed here is un-ratified and is not the property of EarthSense. EarthSense take no responsibility for the accuracy and currency of these data.',
};

export const DataChartHeaderContent: ComponentContent = {
  DataChartHeaderTzPrefix: 'Showing data in timezone:',
  DataChartHeaderTzUTC: 'UTC',
  DataChartHeaderLoading: 'Loading data..',
  DataChartHeaderNull: 'Data not currently available',
  DataChartSomeTimedOut: 'Some requests timed out.',
  DataChartAllTimedOut:
    'All unit requests with available data timed out - try requesting data in smaller chunks',
  ZoomResetButton: 'Reset zoom',
  vZephyrLoadingMsg:
    'Your virtual zephyr is initializing and data will be available soon. (1-2hours)',
  DataChart_240: 'Data not available for selected time period',
  DataChart_400: 'No start date set or wrong zephyr number',
  DataChart_401: 'No credentials provided',
  DataChart_403: 'Your account does not have permission to request data',
  DataChart_404: 'The requested resource does not exist',
  DataChart_429: 'Server overloaded, please try again later',
  DataChart_500: 'Something went wrong on the server',
};

export const DataCustomDateContent: ComponentContent = {
  DataCustomDatePickerMessage: 'Select any period up to 31 days',
  DataCustomeDateSubmit: 'Submit',
  DataCustomDateSave: 'Save',
};

export const dataDiagnosticsModalContent: ComponentContent = {
  dataDiagnosticsHeader: 'Data narrative',
  dataDiagnosticsIndex: 'Data request item:',
  dataDiagnosticsModalContentClose: 'x',
};

export const DataDownloadButtonContent: ComponentContent = {
  DataDownloadButtonText: 'Download data',
  DataDiagnosticsButtonText: 'Diagnostics',
};

export const DataTimeAveragingOptionsContent: ComponentContent = {
  DataTAHeader: 'Time Averaging',
  DataTASubheader: 'Average data by:',
};

export const DataTimePeriodOptionsContent: ComponentContent = {
  DataPOHeader: 'Time Period',
  DataPOSubheader: 'Show me data for:',
};

export const DataUnitsContent: ComponentContent = {
  DataUnitsHeaderMultiUnits: 'Units:',
  DataUnitsRemoveUnit: 'x',
  DataUnitsSingleUnit: 'Unit:',
  DataUnitsTooltipContent:
    'Drag and drop units from the Product Inventory tab to add them to the chart.',
  DataUnitsTooltipMakerContent: 'i',
};

export const DownloadModalContent: ComponentContent = {
  downloadHeader: 'Download data',
  downloadSubheader: 'Get data for',
  downloadCoordinates: 'coordinates',
  downloadButton: 'Download',
  closeModal: 'x',
};

export const FeedbackQuestionsContent: ComponentContent = {
  feedbackQuestionsHeader: 'Share your feedback',
  feedbackScoreLabelsLow: 'Not very likely',
  feedbackScoreLabelsHigh: 'Very likely',
  feedbackCommentsPlaceholder: 'Write your feedback here',
  feedbackButtonNext: 'Next',
  feedbackEmailHeader: 'Email address',
  feedbackOptional: 'Optional',
  feedbackConfirmationHeader: 'Thank you for your feedback',
  feedbackConfirmationText:
    'Your feedback is highly valued and is used to improve our service.',
  feedbackButtonConfirmation: 'Done',
};

export const FeedbackTagContent: ComponentContent = {
  feedbackTagButton: 'Feedback',
};

export const HeaderContactModalContent: ComponentContent = {
  confirmationHeader: 'Thank you for your enquiry',
  confirmationText: "You'll be contacted in response to your query shortly.",
  contactHeader: 'Contact Us',
  contactSubheader: 'Please detail your request below',
  contactFirstName: 'First Name*',
  contactLastName: 'Last Name*',
  contactEmail: 'Email Address*',
  contactCompany: 'Company*',
  contactDetails: 'Details',
  contactEnquiry: 'Reason for enquiry*',
  contactComments: 'Comments',
  contactButton: 'Send',
  closeModal: 'x',
  messageSuccess: 'Thank you. Your message has been sent.',
  messageProblem: 'There was a problem sending your message.',
  messageMissingFields: 'Please complete all fields.',
  requiredFields: '*Required fields',
};

export const AddZephyrModalContent: ComponentContent = {
  popoverCTAText: 'Add Virtual Zephyr',
  headerText: 'Add Virtual Zephyr',
  zephyrNameLabel: 'Name',
  secondaryCta: 'Cancel',
  primaryCta1: 'Add',
  totalPrefix: 'This will be ',
  totalPostfix: 'virtual zephyrs',
  maxReachPrefix: 'You have reached your limit of ',
  limitReachedSubtitle:
    'You can disable some of your live virtual zephyrs or Contact us to add more.',
  contactUsCtaTxt: 'Contact us',
};

export const RemoveZephyrModalContent: ComponentContent = {
  RemoveZephyrHeaderText: 'Delete Virtual Zephyr',
  confirmationTextPrefix: "Are you sure you would like to delete '",
  confirmationTextPostFix: "' virtual zephyr?",
  warningText: 'Warning: This cannot be undone',
  RemoveZephyrSecondaryCta: 'Cancel',
  primaryCta: 'Delete',
};

export const HeaderContent: ComponentContent = {
  AirQuality101Title: 'Info',
  AnalyticsHeader: 'Analytics',
  AlertsHeader: 'Alerts',
  FleetManagementHeader: 'Device Manager',
  DataAnalyticsHeader: 'Analysis',
  ContactLinkTitle: 'Contact',
  CouncilProjectTitle: 'About',
  DataHeader: 'Data',
  DefaultHeaderTitle: 'Earthsense',
  HelpHeader: 'Help',
  LoadingAURNHeader: 'Getting latest third-party data',
  LoadingZephyrHeader: 'Getting latest air quality data',
  LocationHeader: 'Location',
  LocationHeaderPublic: 'Map',
  LogoutLinkTitle: 'Logout',
  MappairUpgradePrompt: 'Upgrade MappAir Model',
  MissingOverlayEnd: 'MappAir',
  MissingOverlayIntro: 'Contact us ',
  MissingOverlayMid: 'for access to ',
  PrivacyLinkTitle: 'Privacy Policy',
  ResourcesLinkTitle: 'Resources',
  SourceEmissionTooltipIntro: 'View source emission layers for',
  SourceEmissionTooltipEnd: '.',
  SourceEmissionTooltipMissingSpecies: 'N/A',
  SourceEmissionTooltipSelectedIntro: 'Showing',
  SourceEmissionTooltipSelectedEnd: 'source emission layer.',
  SourcesSubHeader: 'Sources:',
  SpeciesSubHeader: 'Pollutant:',
  TodayTitle: 'Today',
  TooltipMarkerContent: '?',
  TourLinkTitle: 'Tour',
  TutorialLinkTitle: 'Tutorial',
  VersionLinkTitle: 'Version',
  FeedbackPrompt: 'Beta Feedback',
};

export const headerTutorialModalContent: ComponentContent = {
  closeModal: 'x',
};

export const headerVersionHistoryModalContent: ComponentContent = {
  versionHistoryHeader: 'Version history',
  currentVersionDescriptor: 'Current:',
  closeModal: 'x',
};

export const loginContent: ComponentContent = {
  incorrectPassword: 'Invalid credentials provided, please try again',
  loginHeader: 'Log in',
  welcomeMessage:
    'Welcome, please log in below with your username and password',
  loginButton: 'Log In',
  heroHeader: 'Welcome to MyAir',
  heroSubheader:
    'View, manage, and download your MappAir and Zephyr air quality data.',
  usernameField: 'Username',
  passwordField: 'Password',
};

export const mappAirFilterToggles: MappAirFilterToggles[] = [
  {
    value: 'showAllLabels',
    label: 'Show all',
  },
  {
    value: 'showSelectedLabel',
    label: 'Selected unit',
  },
];

export const MappairQuoteModalContent: ComponentContent = {
  aoi: 'Area of Interest',
  area: 'Size of area',
  centre: ' Centre: ',
  comments: 'Comments',
  MappairQuoteConfirmationHeader: 'Thank you for your enquiry',
  MappairQuoteConfirmationText:
    "You'll be contacted with information on upgrading MappAir shortly.",
  MappairQuoteContactHeader: 'Upgrade MappAir Model',
  MappairQuoteContactFirstName: 'First Name*',
  MappairQuoteContactLastName: 'Last Name*',
  MappairQuoteContactEmail: 'Email Address*',
  MappairQuoteContactCompany: 'Company*',
  MappairQuoteContactButton: 'Get Quote',
  MappairQuoteCloseModal: 'x',
  mappairLinkPost: ' models have a 10m resolution.',
  mappairLinkPre: 'Enhanced ',
  mappairOptionalCommentsPlaceholder:
    'Please use this space to tell us about any specific requirements you have, along with details of your intended use case.',
  messageSuccess: 'Thank you. Your quote has been sent.',
  mappairMessageProblem: 'There was a problem sending your quote.',
  mapairMessageMissingFields: 'Please complete all required fields.',
  output: 'Output',
  polygon: 'Bespoke polygon -',
  pollutants: 'Pollutants',
  mapairRequiredFields: '*Required fields',
  resolution: 'Resolution',
  selectArea: 'Select Area',
};

export const LocationSearchContent: ComponentContent = {
  LocationSearchPlaceholder: 'Search location',
  InvalidSearchPlaceholder: 'Location outside map',
};

export const TodayContent: ComponentContent = {
  AdviceHeader: 'Advice',
  BehaviourHeader: 'Your Part',
  CloseModal: 'x',
  ForecastHeader: 'Air Quality Forecast',
  GeneralAdviceHeader: 'General Population:',
  HealthAdviceHeader: 'Health Advice',
  HumidityHeader: 'Humidity',
  PollenHeader: 'Pollen',
  PollutionHeader: 'Pollution Level',
  SensitiveAdviceHeader: 'Sensitive Advice:',
  TempHeader: 'Temp',
  WoodBurningHeader: 'Wood Burning & Open Fires',
  Low: 'Low',
  High: 'High',
};

export const VersionLatestModalContent: ComponentContent = {
  updateTitle: 'Updates on MyAir®',
  CloseModal: 'x',
};

export const ZephyrQuoteModalContent: ComponentContent = {
  ZephyrConfirmationHeader: 'Thank you for your Zephyr enquiry',
  ZephyrConfirmationText:
    "You'll be contacted with information on your quote shortly.",
  ZephyrContactHeader: 'Expand Zephyr Network',
  ZephyrContactFirstName: 'First Name*',
  ZephyrContactLastName: 'Last Name*',
  ZephyrContactEmail: 'Email Address*',
  ZephyrContactCompany: 'Company*',
  ZephyrContactButton: 'Get Quote',
  closeModal: 'x',
  measurements: 'Sensors*',
  messageSuccess: 'Thank you. Your Quote has been sent.',
  ZephyrContactMessageProblem: 'There was a problem sending your quote.',
  ZephyrContactMessageMissingFields: 'Please complete all required fields.',
  powerOptions: 'Power Options*',
  ZephyrQuoteRequiredFields: '*Required fields',
  zephyrs: 'Number of Zephyrs*',
};

export const translationOptions: translationOption[] = [
  {
    value: 'en-GB',
    label: 'English',
    icon: 'UK',
  },
  {
    value: 'es',
    label: 'Spanish',
    icon: 'ES',
  },
];

export const MapAirPointValueHistoryInitialState = {
  saValue: null,
  valueHistory: null,
  status: 'idle',
};

export const AnnualAvgDataInitialState = {
  list: null,
  raw: null,
  chart: { labels: [], datasets: [] },
};

export const dimensions: tileDimensions = {
  width: 256,
  height: 256,
};

// export const csvHeaders = ['Site ID', 'Site Name', 'Lat, Lon', 'Transmission Interval', 'Sampling Interval', 'Cartridge ID', 'Battery Percentage', 'Last Connection (UTC)'];
export const csvHeaders = [
  'Site ID',
  'Site Name',
  'Lat, Lon',
  'Sampling Interval',
  'Cartridge ID',
  'Battery Percentage',
  'Last Connection (UTC)',
];

export const annualAveragesArr: YearObj[] = [
  {
    value: '2015',
    label: 2015,
  },
  {
    value: '2016',
    label: 2016,
  },
  {
    value: '2017',
    label: 2017,
  },
  {
    value: '2018',
    label: 2018,
  },
  {
    value: '2019',
    label: 2019,
  },
  {
    value: '2020',
    label: 2020,
  },
  {
    value: '2021',
    label: 2021,
  },
];

export const timeSliderOptions: TimeSliderOption[] = [
  {
    id: 0,
    d:
      'M2.62 10.002a7.508 7.508 0 017.717-7.5c3.909.108 7.175 3.375 7.284 7.283a7.508 7.508 0 01-7.5 7.717 7.381 7.381 0 01-4.6-1.592c-.392-.3-.417-.891-.067-1.241.3-.3.767-.325 1.1-.067a5.746 5.746 0 003.567 1.233 5.837 5.837 0 005.833-5.916c-.042-3.1-2.65-5.709-5.75-5.75a5.832 5.832 0 00-5.917 5.833H5.78c.375 0 .558.45.3.708l-2.325 2.334a.413.413 0 01-.592 0L.837 10.71a.413.413 0 01.292-.708H2.62zm6.667-2.708a.63.63 0 01.625-.625c.342 0 .625.283.625.616v2.833l2.4 1.425a.631.631 0 01.217.859.631.631 0 01-.858.216l-2.6-1.541a.843.843 0 01-.409-.717V7.294z',
    text: 'History',
  },
  {
    id: 1,
    d:
      'M12.917 9.583c0 1.667-2.083 2.917-2.083 4.167H9.167c0-1.25-2.083-2.5-2.083-4.167A2.92 2.92 0 0110 6.667a2.92 2.92 0 012.917 2.916zm-2.083 5H9.167v1.25h1.667v-1.25zm7.5-4.583c0-2.3-.934-4.383-2.442-5.892l-.883.884A7.068 7.068 0 0117.084 10c0 1.95-.792 3.725-2.075 5.008l.883.884A8.307 8.307 0 0018.334 10zM2.917 10c0-1.95.792-3.725 2.075-5.008l-.883-.884A8.307 8.307 0 001.667 10c0 2.3.933 4.383 2.442 5.892l.883-.884A7.067 7.067 0 012.917 10zm11.667 0a4.594 4.594 0 01-1.342 3.242l.883.883A5.813 5.813 0 0015.834 10c0-1.608-.65-3.067-1.709-4.125l-.883.883A4.594 4.594 0 0114.584 10zm-8.709 4.125l.884-.883A4.559 4.559 0 015.417 10c0-1.267.517-2.408 1.342-3.242l-.884-.883A5.813 5.813 0 004.167 10c0 1.608.65 3.067 1.708 4.125z',
    text: 'Now',
  },
  {
    id: 2,
    d:
      'M15.833 3.334H15V2.5a.836.836 0 00-.833-.833.836.836 0 00-.834.833v.834H6.667V2.5a.836.836 0 00-.834-.833A.836.836 0 005 2.5v.834h-.833A1.66 1.66 0 002.508 5L2.5 16.667c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V5c0-.916-.75-1.666-1.667-1.666zm0 12.5a.836.836 0 01-.833.833H5a.836.836 0 01-.833-.833V7.5h11.666v8.334zm-8.333-5V9.167H5.833v1.667H7.5zm1.667-1.667h1.666v1.667H9.167V9.167zm5 0H12.5v1.667h1.667V9.167z',
    text: 'Forecast',
  },
];

// export const timeSliderOptions: TimeSliderOption[] = [
//   {
//     id: 0,
//     d: "M12.917 9.583c0 1.667-2.083 2.917-2.083 4.167H9.167c0-1.25-2.083-2.5-2.083-4.167A2.92 2.92 0 0110 6.667a2.92 2.92 0 012.917 2.916zm-2.083 5H9.167v1.25h1.667v-1.25zm7.5-4.583c0-2.3-.934-4.383-2.442-5.892l-.883.884A7.068 7.068 0 0117.084 10c0 1.95-.792 3.725-2.075 5.008l.883.884A8.307 8.307 0 0018.334 10zM2.917 10c0-1.95.792-3.725 2.075-5.008l-.883-.884A8.307 8.307 0 001.667 10c0 2.3.933 4.383 2.442 5.892l.883-.884A7.067 7.067 0 012.917 10zm11.667 0a4.594 4.594 0 01-1.342 3.242l.883.883A5.813 5.813 0 0015.834 10c0-1.608-.65-3.067-1.709-4.125l-.883.883A4.594 4.594 0 0114.584 10zm-8.709 4.125l.884-.883A4.559 4.559 0 015.417 10c0-1.267.517-2.408 1.342-3.242l-.884-.883A5.813 5.813 0 004.167 10c0 1.608.65 3.067 1.708 4.125z",
//     text: 'Now'
//   },
//   {
//     id: 1,
//     d: "M15.833 3.334H15V2.5a.836.836 0 00-.833-.833.836.836 0 00-.834.833v.834H6.667V2.5a.836.836 0 00-.834-.833A.836.836 0 005 2.5v.834h-.833A1.66 1.66 0 002.508 5L2.5 16.667c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V5c0-.916-.75-1.666-1.667-1.666zm0 12.5a.836.836 0 01-.833.833H5a.836.836 0 01-.833-.833V7.5h11.666v8.334zm-8.333-5V9.167H5.833v1.667H7.5zm1.667-1.667h1.666v1.667H9.167V9.167zm5 0H12.5v1.667h1.667V9.167z",
//     text: '7 days back'
//   },
//   {
//     id: 2,
//     d: "M3.799 14.213c-.143.055-.29.111-.421.165a1.376 1.376 0 00-.782 1.773l.503 1.287c.14.357.424.642.786.789.362.147.771.142 1.13-.013.971-.42 1.868-.922 2.69-1.495.671.6 1.534.81 2.127.897a9.296 9.296 0 001.55.077h.018l.097-.003h.122a5.795 5.795 0 004.164-2.023c1.113-1.29 1.744-3.108 1.716-5.37-.035-2.824-1.129-5.945-3.449-8.22a1.45 1.45 0 00-1.014-.41 1.45 1.45 0 00-1.014.41c-.715.701-1.914 1.307-3.453 2.063l-.077.037c-1.244.61-2.685 1.317-3.805 2.215C3.51 7.337 2.5 8.642 2.5 10.45c0 1.284.445 2.688 1.299 3.763zm3.447 1.106c.182-.136.36-.276.533-.42l.05-.041a13.803 13.803 0 002.284-2.45c1.547-2.125 2.49-4.665 2.805-7.332l.016-.143a17.24 17.24 0 00.102-1.782 40.15 40.15 0 01-.054 2.015c-.174 3.247-.752 5.352-1.488 6.847-.717 1.458-1.583 2.338-2.368 3.136-.17.172-.335.34-.495.51a1.499 1.499 0 00.221.176c.333.223.759.342 1.176.405.529.08 1.045.07 1.342.064.08-.002.143-.003.187-.002 2.208-.093 4.412-1.858 4.511-5.596.003-.129.004-.26.003-.393A10.692 10.692 0 0014.39 4.72a9.57 9.57 0 00-1.354-1.664 4.823 4.823 0 01-.1.095c-.377.35-.835.672-1.342.978l-.024.014c-.718.432-1.533.832-2.357 1.236C6.614 6.654 3.93 7.972 3.93 10.45c0 1.183.466 2.382 1.241 3.185a3.198 3.198 0 001.012.723c.09.04.182.076.276.107-.38.313-.966.58-1.52.802-.186.075-.37.145-.54.21-.172.065-.33.126-.466.181l.503 1.287.045-.02a14.241 14.241 0 001.236-.612 13.7 13.7 0 001.505-.975l.025-.019z",
//     text: '90 days back'
//   },
//   {
//     id: 3,
//     d: "M2.62 10.002a7.508 7.508 0 017.717-7.5c3.909.108 7.175 3.375 7.284 7.283a7.508 7.508 0 01-7.5 7.717 7.381 7.381 0 01-4.6-1.592c-.392-.3-.417-.891-.067-1.241.3-.3.767-.325 1.1-.067a5.746 5.746 0 003.567 1.233 5.837 5.837 0 005.833-5.916c-.042-3.1-2.65-5.709-5.75-5.75a5.832 5.832 0 00-5.917 5.833H5.78c.375 0 .558.45.3.708l-2.325 2.334a.413.413 0 01-.592 0L.837 10.71a.413.413 0 01.292-.708H2.62zm6.667-2.708a.63.63 0 01.625-.625c.342 0 .625.283.625.616v2.833l2.4 1.425a.631.631 0 01.217.859.631.631 0 01-.858.216l-2.6-1.541a.843.843 0 01-.409-.717V7.294z",
//     text: 'History'
//   }
// ];

/* List of all country and phone codes */

export const countryCodes: {
  name: string;
  dial_code: string;
  code: string;
}[] = [
    {
      name: 'Afghanistan',
      dial_code: '+93',
      code: 'AF',
    },
    {
      name: 'Aland Islands',
      dial_code: '+358',
      code: 'AX',
    },
    {
      name: 'Albania',
      dial_code: '+355',
      code: 'AL',
    },
    {
      name: 'Algeria',
      dial_code: '+213',
      code: 'DZ',
    },
    {
      name: 'AmericanSamoa',
      dial_code: '+1684',
      code: 'AS',
    },
    {
      name: 'Andorra',
      dial_code: '+376',
      code: 'AD',
    },
    {
      name: 'Angola',
      dial_code: '+244',
      code: 'AO',
    },
    {
      name: 'Anguilla',
      dial_code: '+1264',
      code: 'AI',
    },
    {
      name: 'Antarctica',
      dial_code: '+672',
      code: 'AQ',
    },
    {
      name: 'Antigua and Barbuda',
      dial_code: '+1268',
      code: 'AG',
    },
    {
      name: 'Argentina',
      dial_code: '+54',
      code: 'AR',
    },
    {
      name: 'Armenia',
      dial_code: '+374',
      code: 'AM',
    },
    {
      name: 'Aruba',
      dial_code: '+297',
      code: 'AW',
    },
    {
      name: 'Australia',
      dial_code: '+61',
      code: 'AU',
    },
    {
      name: 'Austria',
      dial_code: '+43',
      code: 'AT',
    },
    {
      name: 'Azerbaijan',
      dial_code: '+994',
      code: 'AZ',
    },
    {
      name: 'Bahamas',
      dial_code: '+1242',
      code: 'BS',
    },
    {
      name: 'Bahrain',
      dial_code: '+973',
      code: 'BH',
    },
    {
      name: 'Bangladesh',
      dial_code: '+880',
      code: 'BD',
    },
    {
      name: 'Barbados',
      dial_code: '+1246',
      code: 'BB',
    },
    {
      name: 'Belarus',
      dial_code: '+375',
      code: 'BY',
    },
    {
      name: 'Belgium',
      dial_code: '+32',
      code: 'BE',
    },
    {
      name: 'Belize',
      dial_code: '+501',
      code: 'BZ',
    },
    {
      name: 'Benin',
      dial_code: '+229',
      code: 'BJ',
    },
    {
      name: 'Bermuda',
      dial_code: '+1441',
      code: 'BM',
    },
    {
      name: 'Bhutan',
      dial_code: '+975',
      code: 'BT',
    },
    {
      name: 'Bolivia, Plurinational State of',
      dial_code: '+591',
      code: 'BO',
    },
    {
      name: 'Bosnia and Herzegovina',
      dial_code: '+387',
      code: 'BA',
    },
    {
      name: 'Botswana',
      dial_code: '+267',
      code: 'BW',
    },
    {
      name: 'Brazil',
      dial_code: '+55',
      code: 'BR',
    },
    {
      name: 'British Indian Ocean Territory',
      dial_code: '+246',
      code: 'IO',
    },
    {
      name: 'Brunei Darussalam',
      dial_code: '+673',
      code: 'BN',
    },
    {
      name: 'Bulgaria',
      dial_code: '+359',
      code: 'BG',
    },
    {
      name: 'Burkina Faso',
      dial_code: '+226',
      code: 'BF',
    },
    {
      name: 'Burundi',
      dial_code: '+257',
      code: 'BI',
    },
    {
      name: 'Cambodia',
      dial_code: '+855',
      code: 'KH',
    },
    {
      name: 'Cameroon',
      dial_code: '+237',
      code: 'CM',
    },
    {
      name: 'Canada',
      dial_code: '+1',
      code: 'CA',
    },
    {
      name: 'Cape Verde',
      dial_code: '+238',
      code: 'CV',
    },
    {
      name: 'Cayman Islands',
      dial_code: '+ 345',
      code: 'KY',
    },
    {
      name: 'Central African Republic',
      dial_code: '+236',
      code: 'CF',
    },
    {
      name: 'Chad',
      dial_code: '+235',
      code: 'TD',
    },
    {
      name: 'Chile',
      dial_code: '+56',
      code: 'CL',
    },
    {
      name: 'China',
      dial_code: '+86',
      code: 'CN',
    },
    {
      name: 'Christmas Island',
      dial_code: '+61',
      code: 'CX',
    },
    {
      name: 'Cocos (Keeling) Islands',
      dial_code: '+61',
      code: 'CC',
    },
    {
      name: 'Colombia',
      dial_code: '+57',
      code: 'CO',
    },
    {
      name: 'Comoros',
      dial_code: '+269',
      code: 'KM',
    },
    {
      name: 'Congo',
      dial_code: '+242',
      code: 'CG',
    },
    {
      name: 'Congo, The Democratic Republic of the Congo',
      dial_code: '+243',
      code: 'CD',
    },
    {
      name: 'Cook Islands',
      dial_code: '+682',
      code: 'CK',
    },
    {
      name: 'Costa Rica',
      dial_code: '+506',
      code: 'CR',
    },
    {
      name: "Cote d'Ivoire",
      dial_code: '+225',
      code: 'CI',
    },
    {
      name: 'Croatia',
      dial_code: '+385',
      code: 'HR',
    },
    {
      name: 'Cuba',
      dial_code: '+53',
      code: 'CU',
    },
    {
      name: 'Cyprus',
      dial_code: '+357',
      code: 'CY',
    },
    {
      name: 'Czech Republic',
      dial_code: '+420',
      code: 'CZ',
    },
    {
      name: 'Denmark',
      dial_code: '+45',
      code: 'DK',
    },
    {
      name: 'Djibouti',
      dial_code: '+253',
      code: 'DJ',
    },
    {
      name: 'Dominica',
      dial_code: '+1767',
      code: 'DM',
    },
    {
      name: 'Dominican Republic',
      dial_code: '+1849',
      code: 'DO',
    },
    {
      name: 'Ecuador',
      dial_code: '+593',
      code: 'EC',
    },
    {
      name: 'Egypt',
      dial_code: '+20',
      code: 'EG',
    },
    {
      name: 'El Salvador',
      dial_code: '+503',
      code: 'SV',
    },
    {
      name: 'Equatorial Guinea',
      dial_code: '+240',
      code: 'GQ',
    },
    {
      name: 'Eritrea',
      dial_code: '+291',
      code: 'ER',
    },
    {
      name: 'Estonia',
      dial_code: '+372',
      code: 'EE',
    },
    {
      name: 'Ethiopia',
      dial_code: '+251',
      code: 'ET',
    },
    {
      name: 'Falkland Islands (Malvinas)',
      dial_code: '+500',
      code: 'FK',
    },
    {
      name: 'Faroe Islands',
      dial_code: '+298',
      code: 'FO',
    },
    {
      name: 'Fiji',
      dial_code: '+679',
      code: 'FJ',
    },
    {
      name: 'Finland',
      dial_code: '+358',
      code: 'FI',
    },
    {
      name: 'France',
      dial_code: '+33',
      code: 'FR',
    },
    {
      name: 'French Guiana',
      dial_code: '+594',
      code: 'GF',
    },
    {
      name: 'French Polynesia',
      dial_code: '+689',
      code: 'PF',
    },
    {
      name: 'Gabon',
      dial_code: '+241',
      code: 'GA',
    },
    {
      name: 'Gambia',
      dial_code: '+220',
      code: 'GM',
    },
    {
      name: 'Georgia',
      dial_code: '+995',
      code: 'GE',
    },
    {
      name: 'Germany',
      dial_code: '+49',
      code: 'DE',
    },
    {
      name: 'Ghana',
      dial_code: '+233',
      code: 'GH',
    },
    {
      name: 'Gibraltar',
      dial_code: '+350',
      code: 'GI',
    },
    {
      name: 'Greece',
      dial_code: '+30',
      code: 'GR',
    },
    {
      name: 'Greenland',
      dial_code: '+299',
      code: 'GL',
    },
    {
      name: 'Grenada',
      dial_code: '+1473',
      code: 'GD',
    },
    {
      name: 'Guadeloupe',
      dial_code: '+590',
      code: 'GP',
    },
    {
      name: 'Guam',
      dial_code: '+1671',
      code: 'GU',
    },
    {
      name: 'Guatemala',
      dial_code: '+502',
      code: 'GT',
    },
    {
      name: 'Guernsey',
      dial_code: '+44',
      code: 'GG',
    },
    {
      name: 'Guinea',
      dial_code: '+224',
      code: 'GN',
    },
    {
      name: 'Guinea-Bissau',
      dial_code: '+245',
      code: 'GW',
    },
    {
      name: 'Guyana',
      dial_code: '+595',
      code: 'GY',
    },
    {
      name: 'Haiti',
      dial_code: '+509',
      code: 'HT',
    },
    {
      name: 'Holy See (Vatican City State)',
      dial_code: '+379',
      code: 'VA',
    },
    {
      name: 'Honduras',
      dial_code: '+504',
      code: 'HN',
    },
    {
      name: 'Hong Kong',
      dial_code: '+852',
      code: 'HK',
    },
    {
      name: 'Hungary',
      dial_code: '+36',
      code: 'HU',
    },
    {
      name: 'Iceland',
      dial_code: '+354',
      code: 'IS',
    },
    {
      name: 'India',
      dial_code: '+91',
      code: 'IN',
    },
    {
      name: 'Indonesia',
      dial_code: '+62',
      code: 'ID',
    },
    {
      name: 'Iran, Islamic Republic of Persian Gulf',
      dial_code: '+98',
      code: 'IR',
    },
    {
      name: 'Iraq',
      dial_code: '+964',
      code: 'IQ',
    },
    {
      name: 'Ireland',
      dial_code: '+353',
      code: 'IE',
    },
    {
      name: 'Isle of Man',
      dial_code: '+44',
      code: 'IM',
    },
    {
      name: 'Israel',
      dial_code: '+972',
      code: 'IL',
    },
    {
      name: 'Italy',
      dial_code: '+39',
      code: 'IT',
    },
    {
      name: 'Jamaica',
      dial_code: '+1876',
      code: 'JM',
    },
    {
      name: 'Japan',
      dial_code: '+81',
      code: 'JP',
    },
    {
      name: 'Jersey',
      dial_code: '+44',
      code: 'JE',
    },
    {
      name: 'Jordan',
      dial_code: '+962',
      code: 'JO',
    },
    {
      name: 'Kazakhstan',
      dial_code: '+77',
      code: 'KZ',
    },
    {
      name: 'Kenya',
      dial_code: '+254',
      code: 'KE',
    },
    {
      name: 'Kiribati',
      dial_code: '+686',
      code: 'KI',
    },
    {
      name: "Korea, Democratic People's Republic of Korea",
      dial_code: '+850',
      code: 'KP',
    },
    {
      name: 'Korea, Republic of South Korea',
      dial_code: '+82',
      code: 'KR',
    },
    {
      name: 'Kuwait',
      dial_code: '+965',
      code: 'KW',
    },
    {
      name: 'Kyrgyzstan',
      dial_code: '+996',
      code: 'KG',
    },
    {
      name: 'Laos',
      dial_code: '+856',
      code: 'LA',
    },
    {
      name: 'Latvia',
      dial_code: '+371',
      code: 'LV',
    },
    {
      name: 'Lebanon',
      dial_code: '+961',
      code: 'LB',
    },
    {
      name: 'Lesotho',
      dial_code: '+266',
      code: 'LS',
    },
    {
      name: 'Liberia',
      dial_code: '+231',
      code: 'LR',
    },
    {
      name: 'Libyan Arab Jamahiriya',
      dial_code: '+218',
      code: 'LY',
    },
    {
      name: 'Liechtenstein',
      dial_code: '+423',
      code: 'LI',
    },
    {
      name: 'Lithuania',
      dial_code: '+370',
      code: 'LT',
    },
    {
      name: 'Luxembourg',
      dial_code: '+352',
      code: 'LU',
    },
    {
      name: 'Macao',
      dial_code: '+853',
      code: 'MO',
    },
    {
      name: 'Macedonia',
      dial_code: '+389',
      code: 'MK',
    },
    {
      name: 'Madagascar',
      dial_code: '+261',
      code: 'MG',
    },
    {
      name: 'Malawi',
      dial_code: '+265',
      code: 'MW',
    },
    {
      name: 'Malaysia',
      dial_code: '+60',
      code: 'MY',
    },
    {
      name: 'Maldives',
      dial_code: '+960',
      code: 'MV',
    },
    {
      name: 'Mali',
      dial_code: '+223',
      code: 'ML',
    },
    {
      name: 'Malta',
      dial_code: '+356',
      code: 'MT',
    },
    {
      name: 'Marshall Islands',
      dial_code: '+692',
      code: 'MH',
    },
    {
      name: 'Martinique',
      dial_code: '+596',
      code: 'MQ',
    },
    {
      name: 'Mauritania',
      dial_code: '+222',
      code: 'MR',
    },
    {
      name: 'Mauritius',
      dial_code: '+230',
      code: 'MU',
    },
    {
      name: 'Mayotte',
      dial_code: '+262',
      code: 'YT',
    },
    {
      name: 'Mexico',
      dial_code: '+52',
      code: 'MX',
    },
    {
      name: 'Micronesia, Federated States of Micronesia',
      dial_code: '+691',
      code: 'FM',
    },
    {
      name: 'Moldova',
      dial_code: '+373',
      code: 'MD',
    },
    {
      name: 'Monaco',
      dial_code: '+377',
      code: 'MC',
    },
    {
      name: 'Mongolia',
      dial_code: '+976',
      code: 'MN',
    },
    {
      name: 'Montenegro',
      dial_code: '+382',
      code: 'ME',
    },
    {
      name: 'Montserrat',
      dial_code: '+1664',
      code: 'MS',
    },
    {
      name: 'Morocco',
      dial_code: '+212',
      code: 'MA',
    },
    {
      name: 'Mozambique',
      dial_code: '+258',
      code: 'MZ',
    },
    {
      name: 'Myanmar',
      dial_code: '+95',
      code: 'MM',
    },
    {
      name: 'Namibia',
      dial_code: '+264',
      code: 'NA',
    },
    {
      name: 'Nauru',
      dial_code: '+674',
      code: 'NR',
    },
    {
      name: 'Nepal',
      dial_code: '+977',
      code: 'NP',
    },
    {
      name: 'Netherlands',
      dial_code: '+31',
      code: 'NL',
    },
    {
      name: 'Netherlands Antilles',
      dial_code: '+599',
      code: 'AN',
    },
    {
      name: 'New Caledonia',
      dial_code: '+687',
      code: 'NC',
    },
    {
      name: 'New Zealand',
      dial_code: '+64',
      code: 'NZ',
    },
    {
      name: 'Nicaragua',
      dial_code: '+505',
      code: 'NI',
    },
    {
      name: 'Niger',
      dial_code: '+227',
      code: 'NE',
    },
    {
      name: 'Nigeria',
      dial_code: '+234',
      code: 'NG',
    },
    {
      name: 'Niue',
      dial_code: '+683',
      code: 'NU',
    },
    {
      name: 'Norfolk Island',
      dial_code: '+672',
      code: 'NF',
    },
    {
      name: 'Northern Mariana Islands',
      dial_code: '+1670',
      code: 'MP',
    },
    {
      name: 'Norway',
      dial_code: '+47',
      code: 'NO',
    },
    {
      name: 'Oman',
      dial_code: '+968',
      code: 'OM',
    },
    {
      name: 'Pakistan',
      dial_code: '+92',
      code: 'PK',
    },
    {
      name: 'Palau',
      dial_code: '+680',
      code: 'PW',
    },
    {
      name: 'Palestinian Territory, Occupied',
      dial_code: '+970',
      code: 'PS',
    },
    {
      name: 'Panama',
      dial_code: '+507',
      code: 'PA',
    },
    {
      name: 'Papua New Guinea',
      dial_code: '+675',
      code: 'PG',
    },
    {
      name: 'Paraguay',
      dial_code: '+595',
      code: 'PY',
    },
    {
      name: 'Peru',
      dial_code: '+51',
      code: 'PE',
    },
    {
      name: 'Philippines',
      dial_code: '+63',
      code: 'PH',
    },
    {
      name: 'Pitcairn',
      dial_code: '+872',
      code: 'PN',
    },
    {
      name: 'Poland',
      dial_code: '+48',
      code: 'PL',
    },
    {
      name: 'Portugal',
      dial_code: '+351',
      code: 'PT',
    },
    {
      name: 'Puerto Rico',
      dial_code: '+1939',
      code: 'PR',
    },
    {
      name: 'Qatar',
      dial_code: '+974',
      code: 'QA',
    },
    {
      name: 'Romania',
      dial_code: '+40',
      code: 'RO',
    },
    {
      name: 'Russia',
      dial_code: '+7',
      code: 'RU',
    },
    {
      name: 'Rwanda',
      dial_code: '+250',
      code: 'RW',
    },
    {
      name: 'Reunion',
      dial_code: '+262',
      code: 'RE',
    },
    {
      name: 'Saint Barthelemy',
      dial_code: '+590',
      code: 'BL',
    },
    {
      name: 'Saint Helena, Ascension and Tristan Da Cunha',
      dial_code: '+290',
      code: 'SH',
    },
    {
      name: 'Saint Kitts and Nevis',
      dial_code: '+1869',
      code: 'KN',
    },
    {
      name: 'Saint Lucia',
      dial_code: '+1758',
      code: 'LC',
    },
    {
      name: 'Saint Martin',
      dial_code: '+590',
      code: 'MF',
    },
    {
      name: 'Saint Pierre and Miquelon',
      dial_code: '+508',
      code: 'PM',
    },
    {
      name: 'Saint Vincent and the Grenadines',
      dial_code: '+1784',
      code: 'VC',
    },
    {
      name: 'Samoa',
      dial_code: '+685',
      code: 'WS',
    },
    {
      name: 'San Marino',
      dial_code: '+378',
      code: 'SM',
    },
    {
      name: 'Sao Tome and Principe',
      dial_code: '+239',
      code: 'ST',
    },
    {
      name: 'Saudi Arabia',
      dial_code: '+966',
      code: 'SA',
    },
    {
      name: 'Senegal',
      dial_code: '+221',
      code: 'SN',
    },
    {
      name: 'Serbia',
      dial_code: '+381',
      code: 'RS',
    },
    {
      name: 'Seychelles',
      dial_code: '+248',
      code: 'SC',
    },
    {
      name: 'Sierra Leone',
      dial_code: '+232',
      code: 'SL',
    },
    {
      name: 'Singapore',
      dial_code: '+65',
      code: 'SG',
    },
    {
      name: 'Slovakia',
      dial_code: '+421',
      code: 'SK',
    },
    {
      name: 'Slovenia',
      dial_code: '+386',
      code: 'SI',
    },
    {
      name: 'Solomon Islands',
      dial_code: '+677',
      code: 'SB',
    },
    {
      name: 'Somalia',
      dial_code: '+252',
      code: 'SO',
    },
    {
      name: 'South Africa',
      dial_code: '+27',
      code: 'ZA',
    },
    {
      name: 'South Sudan',
      dial_code: '+211',
      code: 'SS',
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      dial_code: '+500',
      code: 'GS',
    },
    {
      name: 'Spain',
      dial_code: '+34',
      code: 'ES',
    },
    {
      name: 'Sri Lanka',
      dial_code: '+94',
      code: 'LK',
    },
    {
      name: 'Sudan',
      dial_code: '+249',
      code: 'SD',
    },
    {
      name: 'Suriname',
      dial_code: '+597',
      code: 'SR',
    },
    {
      name: 'Svalbard and Jan Mayen',
      dial_code: '+47',
      code: 'SJ',
    },
    {
      name: 'Swaziland',
      dial_code: '+268',
      code: 'SZ',
    },
    {
      name: 'Sweden',
      dial_code: '+46',
      code: 'SE',
    },
    {
      name: 'Switzerland',
      dial_code: '+41',
      code: 'CH',
    },
    {
      name: 'Syrian Arab Republic',
      dial_code: '+963',
      code: 'SY',
    },
    {
      name: 'Taiwan',
      dial_code: '+886',
      code: 'TW',
    },
    {
      name: 'Tajikistan',
      dial_code: '+992',
      code: 'TJ',
    },
    {
      name: 'Tanzania, United Republic of Tanzania',
      dial_code: '+255',
      code: 'TZ',
    },
    {
      name: 'Thailand',
      dial_code: '+66',
      code: 'TH',
    },
    {
      name: 'Timor-Leste',
      dial_code: '+670',
      code: 'TL',
    },
    {
      name: 'Togo',
      dial_code: '+228',
      code: 'TG',
    },
    {
      name: 'Tokelau',
      dial_code: '+690',
      code: 'TK',
    },
    {
      name: 'Tonga',
      dial_code: '+676',
      code: 'TO',
    },
    {
      name: 'Trinidad and Tobago',
      dial_code: '+1868',
      code: 'TT',
    },
    {
      name: 'Tunisia',
      dial_code: '+216',
      code: 'TN',
    },
    {
      name: 'Turkey',
      dial_code: '+90',
      code: 'TR',
    },
    {
      name: 'Turkmenistan',
      dial_code: '+993',
      code: 'TM',
    },
    {
      name: 'Turks and Caicos Islands',
      dial_code: '+1649',
      code: 'TC',
    },
    {
      name: 'Tuvalu',
      dial_code: '+688',
      code: 'TV',
    },
    {
      name: 'Uganda',
      dial_code: '+256',
      code: 'UG',
    },
    {
      name: 'Ukraine',
      dial_code: '+380',
      code: 'UA',
    },
    {
      name: 'United Arab Emirates',
      dial_code: '+971',
      code: 'AE',
    },
    {
      name: 'United Kingdom',
      dial_code: '+44',
      code: 'GB',
    },
    {
      name: 'United States',
      dial_code: '+1',
      code: 'US',
    },
    {
      name: 'Uruguay',
      dial_code: '+598',
      code: 'UY',
    },
    {
      name: 'Uzbekistan',
      dial_code: '+998',
      code: 'UZ',
    },
    {
      name: 'Vanuatu',
      dial_code: '+678',
      code: 'VU',
    },
    {
      name: 'Venezuela, Bolivarian Republic of Venezuela',
      dial_code: '+58',
      code: 'VE',
    },
    {
      name: 'Vietnam',
      dial_code: '+84',
      code: 'VN',
    },
    {
      name: 'Virgin Islands, British',
      dial_code: '+1284',
      code: 'VG',
    },
    {
      name: 'Virgin Islands, U.S.',
      dial_code: '+1340',
      code: 'VI',
    },
    {
      name: 'Wallis and Futuna',
      dial_code: '+681',
      code: 'WF',
    },
    {
      name: 'Yemen',
      dial_code: '+967',
      code: 'YE',
    },
    {
      name: 'Zambia',
      dial_code: '+260',
      code: 'ZM',
    },
    {
      name: 'Zimbabwe',
      dial_code: '+263',
      code: 'ZW',
    },
  ];

export const corsProxyURL = 'https://cors-anywhere.herokuapp.com/';

/* Data Analytics Consts */

export const dataAnalysisSelections: DropdownSelection[] = [
  {
    label: 'Time Series Chart',
    value: 'time-series',
  },
  {
    label: 'Averaged Summary Statistics',
    value: 'averaged-summary-statistics',
  },
  {
    label: 'Calendar Plot',
    value: 'calendar-plot',
  },
  {
    label: 'Pollution Rose',
    value: 'pollution-rose',
  },
  {
    label: 'Polar Plot',
    value: 'polar-plot',
  },
];

export const emptyDataAvailabilityChartOptions = {
  xAxis: {
    axisLine: {
      show: true,
    },
  },
  yAxis: {
    axisLine: {
      show: true,
    },
  },
  series: [],
  tooltip: {},
  dataZoom: [
    {
      type: 'slider',
      filterMode: 'none',
    },
  ],
};

export const averagingChainsWithoutUnaveraged = {
  6: 13,
  4: 1,
  5: 14,
  10: 11,
};

export const virtualZephyrsPollutants: Pollutant[] = [
  {
    dataIdentifier: 'O3',
    speciesExtentIdentifier: 'O3',
    HTMLLabel: 'O<sub>3</sub>',
    HTMLUnitLabel: 'O₃(μg/m³)',
    HTMLShortUnitLabel: 'μg/m³',
    isChecked: false,
    isSelected: false,
  },
  {
    dataIdentifier: 'particulatePM1',
    speciesExtentIdentifier: 'PM',
    HTMLLabel: 'PM<sub>1</sub>',
    HTMLUnitLabel: 'PM₁(μg/m³)',
    HTMLShortUnitLabel: 'μg/m³',
    isChecked: false,
    isSelected: false,
  },
  {
    dataIdentifier: 'particulatePM10',
    speciesExtentIdentifier: 'PM',
    HTMLLabel: 'PM<sub>10</sub>',
    HTMLUnitLabel: 'PM₁₀(μg/m³)',
    HTMLShortUnitLabel: 'μg/m³',
    isChecked: false,
    isSelected: false,
  },
  {
    dataIdentifier: 'particulatePM25',
    speciesExtentIdentifier: 'PM',
    HTMLLabel: 'PM<sub>2.5</sub>',
    HTMLUnitLabel: 'PM₂.₅(μg/m³)',
    HTMLShortUnitLabel: 'μg/m³',
    isChecked: false,
    isSelected: false,
  },
  {
    dataIdentifier: 'NO2',
    speciesExtentIdentifier: 'NO2',
    HTMLLabel: 'NO<sub>2</sub>',
    HTMLUnitLabel: 'NO₂(μg/m³)',
    HTMLShortUnitLabel: 'μg/m³',
    isChecked: false,
    isSelected: false,
  },
  {
    dataIdentifier: 'NO',
    speciesExtentIdentifier: 'NO',
    HTMLLabel: 'NO',
    HTMLUnitLabel: 'NO(μg/m³)',
    HTMLShortUnitLabel: 'μg/m³',
    isChecked: false,
    isSelected: true,
  },
  {
    dataIdentifier: 'SO2',
    speciesExtentIdentifier: 'SO2',
    HTMLLabel: 'SO<sub>2</sub>',
    HTMLUnitLabel: 'SO₂(μg/m³)',
    HTMLShortUnitLabel: 'μg/m³',
    isChecked: false,
    isSelected: false,
  },
];

export const averagingChains: AveragingChain = {
  '1 min average on the minute': 14,
  '5 minute averaging on the hour': 15,
  '15 min average on the quarter hours': 3,
  'Hourly average on the hour': 1,
  '8 hour average at midnight and 8am and 4pm': 8,
  'Daily average at midnight': 2,
};

export const thresholdMenuItemStyles = {
  width: 135,
  padding: '3px 0 3px 40px',
  fontSize: 14,
  '& span:first-of-type': {
    color: '#4a5568',
  },
};

export const thresholdMainItemStyles = {
  '& span:first-of-type': {
    color: '#4a5568',
  },
};

export const staticPollutantsList = [
  {
    dataIdentifier: 'NO2',
    speciesExtentIdentifier: 'NO2',
    HTMLLabel: 'NO<sub>2</sub>',
    HTMLUnitLabel: 'NO₂(μg/m³)',
    HTMLShortUnitLabel: 'μg/m³',
    isChecked: false,
    isSelected: false,
  },
  {
    dataIdentifier: 'NO',
    speciesExtentIdentifier: 'NO',
    HTMLLabel: 'NO',
    HTMLUnitLabel: 'NO(μg/m³)',
    HTMLShortUnitLabel: 'μg/m³',
    isChecked: false,
    isSelected: false,
  },
  {
    dataIdentifier: 'O3',
    speciesExtentIdentifier: 'O3',
    HTMLLabel: 'O<sub>3</sub>',
    HTMLUnitLabel: 'O₃(μg/m³)',
    HTMLShortUnitLabel: 'μg/m³',
    isChecked: false,
    isSelected: false,
  },
  {
    dataIdentifier: 'particulatePM1',
    speciesExtentIdentifier: 'PM',
    HTMLLabel: 'PM<sub>1</sub>',
    HTMLUnitLabel: 'PM₁(μg/m³)',
    HTMLShortUnitLabel: 'μg/m³',
    isChecked: false,
    isSelected: false,
  },
  {
    dataIdentifier: 'particulatePM25',
    speciesExtentIdentifier: 'PM',
    HTMLLabel: 'PM<sub>2.5</sub>',
    HTMLUnitLabel: 'PM₂.₅(μg/m³)',
    HTMLShortUnitLabel: 'μg/m³',
    isChecked: false,
    isSelected: false,
  },
  {
    dataIdentifier: 'particulatePM10',
    speciesExtentIdentifier: 'PM',
    HTMLLabel: 'PM<sub>10</sub>',
    HTMLUnitLabel: 'PM₁₀(μg/m³)',
    HTMLShortUnitLabel: 'μg/m³',
    isChecked: false,
    isSelected: false,
  },
  {
    dataIdentifier: 'tempC',
    speciesExtentIdentifier: 'BT',
    HTMLLabel: 'Temp',
    HTMLUnitLabel: 'Temperature(°C)',
    HTMLShortUnitLabel: '°C',
    isChecked: false,
    isSelected: false,
  },
  {
    dataIdentifier: 'ambHumidity',
    speciesExtentIdentifier: 'BRH',
    HTMLLabel: 'Humidity',
    HTMLUnitLabel: 'Humidity(%)',
    HTMLShortUnitLabel: '%',
    isChecked: false,
    isSelected: false,
  },
  {
    dataIdentifier: 'SO2',
    speciesExtentIdentifier: 'SO2',
    HTMLLabel: 'SO<sub>2</sub>',
    HTMLUnitLabel: 'SO₂(μg/m³)',
    HTMLShortUnitLabel: 'μg/m³',
    isChecked: false,
    isSelected: false,
  },
  {
    dataIdentifier: 'H2S',
    speciesExtentIdentifier: 'H2S',
    HTMLLabel: 'H<sub>2</sub>S',
    HTMLUnitLabel: 'H₂S(μg/m³)',
    HTMLShortUnitLabel: 'μg/m³',
    isChecked: false,
    isSelected: false,
  },
  {
    dataIdentifier: 'CO',
    speciesExtentIdentifier: 'CO',
    HTMLLabel: 'CO',
    HTMLUnitLabel: 'CO(mg/m³)',
    HTMLShortUnitLabel: 'mg/m³',
    isChecked: false,
    isSelected: false,
  },
  {
    dataIdentifier: 'CO2',
    speciesExtentIdentifier: 'CO2',
    HTMLLabel: 'CO<sub>2</sub>',
    HTMLUnitLabel: 'CO₂(ppm)',
    HTMLShortUnitLabel: 'ppm',
    isChecked: false,
    isSelected: false,
  },
  {
    dataIdentifier: 'TVOC',
    speciesExtentIdentifier: 'TVOC',
    HTMLLabel: 'TVOCs',
    HTMLUnitLabel: 'TVOCs(ppb)',
    HTMLShortUnitLabel: 'ppb',
    isChecked: false,
    isSelected: false,
  },
];
