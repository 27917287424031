// Package Imports
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import utilsRequest from '../../utils/request';
import { ReduxState } from '../../utils/interface';
import { connect } from 'react-redux';

interface InitState {
  credentials: any | null;
  networkCredentialsError: string;
}

// Component
const NetworkCredentialsModal = ({ closeModal, confirmSubmit, unit, bearerToken }: any) => {
  const initState: InitState = {
    credentials: null,
    networkCredentialsError: '',
  };

  const { t: translate } = useTranslation();
  const [networkCredentials, setNetworkCredentials] = useState(
    initState.credentials,
  );
  const [networkCredentialsError, setNetworkCredentialsError] = useState(
    initState.networkCredentialsError,
  );

  useEffect(() => {
    const getNetworkCredentials = async () => {
      const [error, credentials] = await utilsRequest.apiGetWifiCredentials(
        unit.zNumber,
        bearerToken,
      );

      if (error) {
        setNetworkCredentialsError(translate('NetworkCredentialsError'));
        return;
      }

      setNetworkCredentials({
        defaultSSID: credentials.DefaultSSID,
        defaultPassword: credentials.DefaultPassword,
        networkName: credentials.ssid || credentials.DefaultSSID,
        networkPassword:
          credentials.wpaKey || credentials.DefaultPassword || '',
      });
    };
    getNetworkCredentials();
  }, []);

  const handleChangeNetworkCredentials = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const { value, name } = target;
    setNetworkCredentials({ ...networkCredentials, [name]: value });
  };

  const handleSaveWifiCredentials = async (
    zNumber: any,
    networkCredentialsData: any,
  ) => {
    const [error, credentials] = await utilsRequest.apiRenameUnitWifiNetwork(
      zNumber,
      networkCredentialsData,
      bearerToken,
    );
    if (!error) {
      closeModal(true);
    }
  };

  return (
    <div className="modal-container network-credentials-modal-container d-flex flex-column">
      <button
        className="button close close-modal"
        onClick={() => closeModal(true)}
        type="button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 24 24"
          fill="#000"
        >
          <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
        </svg>
      </button>
      <h4>{translate('EditWifiCredentials')}</h4>
      <form onSubmit={(e) => confirmSubmit(e)}>
        <div className="form-content-container pb-2">
          <div className="row ">
            <h5 className="text"> {unit.name}</h5>
          </div>
          {networkCredentials ? (
            <>
              <p className="text"> {translate('StaticWiFiCredentials')}</p>

              <div className="row pb-5">
                <p className="text"> {translate('NetworkNameLabel')}</p>
                <input
                  type="text"
                  name="networkName"
                  value={networkCredentials.defaultSSID}
                  readOnly
                  className=""
                />
                <p className="text"> {translate('NetworkPasswordLabel')}</p>
                <input
                  type="text"
                  name="networkPassword"
                  value={networkCredentials.defaultPassword}
                  readOnly
                  className=""
                />
              </div>

              <p className="text"> {translate('DynamicWiFiCredentials')}</p>

              <div className="row pb-5">
                <p className="text"> {translate('NetworkNameLabel')}</p>
                <input
                  type="text"
                  name="networkName"
                  value={networkCredentials.networkName}
                  onChange={handleChangeNetworkCredentials}
                  className=""
                />
                <p className="text"> {translate('NetworkPasswordLabel')}</p>
                <input
                  type="text"
                  name="networkPassword"
                  value={networkCredentials.networkPassword}
                  onChange={handleChangeNetworkCredentials}
                  className=""
                />
              </div>
            </>
          ) : networkCredentialsError ? (
            <div className="row pb-5">
              <p className="text-warning"> {translate('warningText')}</p>
            </div>
          ) : (
            <div>Loading ...</div>
          )}

          <div className="row d-flex justify-content-between">
            <button
              className="button primary"
              onClick={(e) => {
                e.preventDefault();
                handleSaveWifiCredentials(unit.zNumber, networkCredentials);
              }}
            >
              {translate('EditWifiSaveButton')}
            </button>

            <button
              type="button"
              className="button tertiary"
              onClick={() => closeModal(true)}
            >
              {translate('EditWifiCloseModal')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  bearerToken: state.auth.bearerToken,
});

export default connect(mapStateToProps, {})(NetworkCredentialsModal);


