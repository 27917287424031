// These need to changed from hardcoded FE to be retrieved from the MappAir API on a per user basis
const aqiBands: { NO2: number; PM2p5: number;[key: string]: number }[] = [
  { NO2: 0, PM2p5: 0 },
  { NO2: 5, PM2p5: 4 },
  { NO2: 15, PM2p5: 8 },
  { NO2: 25, PM2p5: 12 },
  { NO2: 35, PM2p5: 16 },
  { NO2: 45, PM2p5: 20 },
  { NO2: 55, PM2p5: 24 },
  { NO2: 70, PM2p5: 30 },
  { NO2: 100, PM2p5: 40 },
  { NO2: 150, PM2p5: 70 },
];

/* ===== Function =====
@DESC: aqiCalculator returns a calculated AQI score based on aqiBands
@ARGS: a value or null for NO2, a value of null for Pm2p5
*/

const aqiBandsObjPopulator = (aqiBandsArr: any) => {
  const aqiBandsObj: { [key: string]: number }[] = [];
  for (let i = 0; i < aqiBandsArr.length; i++) {
    const { species, bands } = aqiBandsArr[i];
    const formattedSpecie = species === 'no2' ? 'NO2' : 'PM2p5';
    for (let j = 0; j < bands.length; j++) {
      const { band_lower_limit } = bands[j];
      aqiBandsObj[j] = 'band' in bands[j] ? { ...aqiBandsObj[j], [formattedSpecie]: band_lower_limit, band: bands[j].band } : { ...aqiBandsObj[j], [formattedSpecie]: band_lower_limit };
    }
  }
  return aqiBandsObj;
};

const aqiCalculator = (NO2: number | null, PM2p5: number | null, aqiBandsArr: any) => {
  let AQIMax = null;
  const aqiSpeciesConfig: {
    name: string;
    value: number | null;
    AQI: number;
  }[] = [
      { name: 'NO2', value: NO2, AQI: 0 },
      { name: 'PM2p5', value: PM2p5, AQI: 0 },
    ];
  const aqiSpecies: any[] = aqiSpeciesConfig.filter(
    (aSC) => typeof aSC.value === 'number',
  );
  if (aqiSpecies.length) {
    const updatedAqiBands = aqiBandsArr ? aqiBandsObjPopulator(aqiBandsArr) : aqiBands;
    AQIMax = 0;
    for (let i = 0; i < aqiSpecies.length; i++) {
      while (AQIMax <= updatedAqiBands.length - 1) {
        if (updatedAqiBands[AQIMax][aqiSpecies[i].name] <= aqiSpecies[i].value) {
          AQIMax += 1;
        }
        else {
          break;
        }
      }
    }
  }
  return AQIMax;
};

export default aqiCalculator;
