// Package Imports
import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Type Imports
import { ReduxState, UserInfo } from '../../utils/interface';

// Component Interfaces
interface LocationSearchProps {
  handleLocationSearch: Function;
  on: boolean;
  tourOpenSearch: boolean;
  userInfo: UserInfo;
}

// Component
const LocationSearch = ({
  handleLocationSearch,
  on,
  tourOpenSearch,
  userInfo,
}: LocationSearchProps) => {
  const { t: translate } = useTranslation();

  // State
  const [invalidSearch, setInvalidSearch] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [typedLocation, setTypedLocation] = useState('');

  // Consts
  const inputEl = useRef<HTMLInputElement | null>(null);

  // Functions
  const handleSearch = async () => {
    if (showSearch) {
      if (typedLocation) {
        const ans = await handleLocationSearch(typedLocation);
        if (!ans) {
          setInvalidSearch(true);
        } else {
          setInvalidSearch(false);
          setShowSearch(false);
        }
        setTypedLocation('');
      } else {
        setShowSearch(false);
        setInvalidSearch(false);
        setTypedLocation('');
      }
    } else {
      setShowSearch(true);
      if (inputEl.current) {
        inputEl.current.focus();
      }
    }
  };

  return (
    <>
      {on && (
        <>
          <div className="control-container">
            <button
              className={`search-location ${
                showSearch || tourOpenSearch ? 'search-active' : ''
              } ${userInfo.isPublic ? 'public' : ''}`}
              onClick={() => {
                handleSearch();
              }}
            />
          </div>
          <input
            ref={inputEl}
            className={`${
              showSearch || tourOpenSearch
                ? 'search-input-active'
                : 'search-input '
            } ${userInfo.isPublic ? 'public' : ''} ${
              invalidSearch ? 'invalid-search' : ''
            }`}
            value={typedLocation}
            type="text"
            name="search"
            placeholder={
              invalidSearch
                ? translate('InvalidSearchPlaceholder')
                : translate('LocationSearchPlaceholder')
            }
            onChange={(e) => {
              setTypedLocation(e.target.value);
              setInvalidSearch(false);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        </>
      )}
    </>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  tourOpenSearch: state.tour.openLocationSearch,
});

export default connect(mapStateToProps, {})(LocationSearch);
