// Package Imports
import { Dispatch } from 'redux';

// Types Imports
import { SET_LOCALE } from './types';

// Action Creators
const setLocale = (locale: String) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_LOCALE,
    payload: locale,
  });
};

export default setLocale;
