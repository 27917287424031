// Package Imports
import React from 'react';
import parse from 'html-react-parser';

// Const Imports
import { AppIntroModelContent } from '../../utils/consts';

// Type Imports
import { AppIntro } from '../../utils/interface';

// Component Interfaces
interface AppIntroModalProps {
  appIntro: AppIntro;
  completeAppIntro: Function;
}

// Component
const AppIntroModal = ({ appIntro, completeAppIntro }: AppIntroModalProps) => (
  <div className="modal-container intro-modal-container">
    <div className="modal-text">
      <h5>{appIntro.header}</h5>
      <div>{parse(appIntro.body)}</div>
    </div>
    <button
      className="button primary close-modal-button"
      onClick={() => completeAppIntro()}
    >
      {AppIntroModelContent.AppIntroButton}
    </button>
  </div>
);
export default AppIntroModal;
