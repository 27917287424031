// Package Imports
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Component Imports
import DatePicker from 'react-datepicker';

// Style Imports
import 'react-datepicker/dist/react-datepicker.css';

// Content Imports
import { customTimePeriod, DataCustomDateContent } from '../../utils/consts';

// Util Imports
import { dateFinder } from '../../utils/functions/dateFinder';

// Type Imports
import { PeriodLabels, TimePeriod } from '../../utils/interface';
import { useLocation } from 'react-router-dom';

// Component Interfaces
interface DataCustomDateProps {
  curTimePeriod: PeriodLabels | null;
  endDate: Date;
  handleCustomDateChange: Function;
  handleCustomSubmit: Function;
  handleTimePeriod: Function | null;
  progressBarOn: boolean;
  toggleCustom: boolean;
  setToggleCustom: Function;
  startDate: Date;
  name?: string;
  curState?: TimePeriod | null;
  period?: TimePeriod;
  setSelectedTimePeriod?: Function;
  style?: any;
}

const DataCustomDate = ({
  curTimePeriod,
  endDate,
  handleCustomDateChange,
  handleCustomSubmit,
  handleTimePeriod,
  progressBarOn,
  toggleCustom,
  setToggleCustom,
  startDate,
  name,
  curState,
  period,
  setSelectedTimePeriod,
  style,
}: DataCustomDateProps) => {
  const { t: translate } = useTranslation();

  const location = useLocation();

  return (
    <div className="custom-select">
      {!location.pathname.includes('analysis') && (
        <label className="radio" htmlFor="radio-custom">
          {customTimePeriod.labelHTML}
          <input
            type="radio"
            id="radio-custom"
            name="radio"
            checked={curTimePeriod === 'Custom'}
            value={customTimePeriod.timeOption}
            onClick={() => {
              setToggleCustom(!toggleCustom);
            }}
            onChange={() => {
              handleTimePeriod!(customTimePeriod);
            }}
            disabled={progressBarOn}
          />
          <span className="checkmark" />
        </label>
      )}

      {location.pathname.includes('analysis') && (
        <input
          type="radio"
          id="radio-custom"
          name={name}
          defaultChecked={
            curState ? curState.labelHTML === period?.labelHTML : false
          }
          value={period?.labelHTML}
          onClick={() => {
            setToggleCustom(!toggleCustom);
          }}
          onChange={() => {
            setSelectedTimePeriod!(period as TimePeriod);
          }}
          disabled={progressBarOn}
        />
      )}

      <div
        className={
          toggleCustom
            ? 'custom-select-options active'
            : 'custom-select-options ninja'
        }
        style={
          style
            ? style
            : {
                width: 400,
                right: 'initial',
              }
        }
        onMouseLeave={() => {
          if (!setSelectedTimePeriod) setToggleCustom(false);
        }}
      >
        <div className="row">
          <div className="col">
            <DatePicker
              selected={startDate}
              onChange={(date) => handleCustomDateChange(date, 'start')}
              dateFormat="dd/MM/yyyy"
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
            />
          </div>
          <div className="col">
            <DatePicker
              selected={endDate}
              onChange={(date) => handleCustomDateChange(date, 'end')}
              dateFormat="dd/MM/yyyy"
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={dateFinder(new Date(startDate.valueOf()), 1, false)}
              maxDate={new Date()}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <button
              className="button primary button--full-width"
              onClick={() => {
                setToggleCustom(!toggleCustom);
                handleCustomSubmit();
              }}
            >
              {!location.pathname.includes('analysis')
                ? translate('DataCustomeDateSubmit')
                : translate('DataCustomDateSave')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataCustomDate;
