// Types Imports
import { SET_DATA_CONFIG } from '../actions/types';

// Type Safety
interface DataConfig {
  averagingChain: number;
  ctv: string;
}

interface SetDataConfig {
  type: typeof SET_DATA_CONFIG;
  payload: DataConfig;
}

// State
const initState: DataConfig = {
  averagingChain: 0,
  ctv: 'proposedMyAirPublic_metAPIOverhaul',
};

// Reducer
export default function (state = initState, action: SetDataConfig) {
  const { type, payload } = action;

  switch (type) {
    case SET_DATA_CONFIG:
      return {
        ...payload,
      };

    default:
      return state;
  }
}
