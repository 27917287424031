import React from 'react';

interface BannerProps {
    setIsModalOpened: Function;
    bannerValue: string[];
    dataTab?: boolean;
}

const Banner = ({ setIsModalOpened, bannerValue, dataTab }: BannerProps) => {

    const closeBanner = () => {
        setIsModalOpened(false);
    };

    const extractText = () => {
        return bannerValue.map((s: string, idx: number) => <p key={idx}>{s}</p>)
    }

    return (
        <div className='modal-container banner'>
            <h3 className='banner-title'>{!dataTab ? 'PM2.5 Improvements' : 'Speed Enhancement'}</h3>
            <div className='modal-text'>
                {extractText()}
            </div>
            <button
                className="button close close-modal"
                onClick={closeBanner}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 24 24"
                    fill="#000"
                >
                    <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
                </svg>
            </button>
        </div>
    )
};

export default Banner;