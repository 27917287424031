// Package Imports
import rgbHex from 'rgb-hex';

// Type Imports
import { Colour, Overlay, Stylegroup } from '../interface';

/* Function
DESC: colourConverter returns the Hex colour code for an RGB-provided colour
ARGS: object with r, g, b keys and legitemate numbers for each
*/
const colourConverter = (colour: Colour) => {
  const hexColor = rgbHex(
    parseInt(colour.r),
    parseInt(colour.g),
    parseInt(colour.b),
  );
  return hexColor;
};

/* Function
DESC: colourFinder finds the correct colour to display a beacon (typically on the Map or Aside bar)
ARGS: AQ value(e.g. 12), species name(e.g. NO2), styleGroupName(e.g. ES), styleGroups(derived from API), 
      opacity(value between 0 and 1)
*/
const colourFinder = (
  curOverlay: Overlay,
  stylegroups: Stylegroup[],
  styleGroupName: string,
  opacity: number,
  overlays: Overlay[],
  value: number | null,
  requestType: 'zephyr' | 'mappair' | 'virtual',
) => {
  const defaultColour = { r: 255, g: 255, b: 255 };
  let colour = defaultColour;
  if (stylegroups.length) {
    const speciesHasZephyrOverlay = overlays.filter(
      (ol) =>
        ol.speciesIdentifier === curOverlay.speciesIdentifier &&
        !ol.isMappairOverlay &&
        !ol.isSAOverlay,
    );

    if (
      (speciesHasZephyrOverlay.length > 0 || requestType === 'mappair') &&
      !curOverlay.isSAOverlay &&
      value !== null
    ) {
      const curStylegroup = stylegroups.filter(
        (group) => group.name === styleGroupName,
      )[0];
      if (curStylegroup) {
        const possibleColours = curStylegroup.thresholds[curOverlay.name].filter(
          (th) => value >= th.min,
        );
        colour = possibleColours[possibleColours.length - 1];
      }
    }

    // TODO add style for requestType === 'mappair'

    if (colour) {
      return `rgba(${colour.r},${colour.g},${colour.b},${opacity})`;
    }
  }
};

export { colourConverter, colourFinder };
