// Package Imports
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Component Imports
import DataCustomDate from './DataCustomDate';

// Util Imports
import { startEndFinder } from '../../utils/functions/dateFinder';

// Content Imports
import { DataTimePeriodOptionsContent } from '../../utils/consts';

// Type Imports
import { PeriodLabels, ReduxState, TimePeriods } from '../../utils/interface';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setDataConfig } from '../../actions/auth';
import { ModularFeatures } from '../../reducers/modularFeatures';

// Component Interfaces
interface DataTimePeriodOptionsProps {
  curTimePeriod: PeriodLabels;
  endDate: Date;
  handleCustomDateChange: Function;
  handleCustomSubmit: Function;
  handleTimePeriod: Function;
  isPublic: boolean;
  progressBarOn: boolean;
  startDate: Date;
  timePeriods: TimePeriods;
  isDSEEnabled: boolean;
  setIsDSEEnabled: Function;
  setDataConfig: Function;
  modularFeatures: ModularFeatures;
}

const DataTimePeriodOptions = ({
  curTimePeriod,
  endDate,
  handleCustomDateChange,
  handleCustomSubmit,
  handleTimePeriod,
  isPublic,
  progressBarOn,
  startDate,
  timePeriods,
  isDSEEnabled,
  setIsDSEEnabled,
  setDataConfig,
  modularFeatures,
}: DataTimePeriodOptionsProps) => {
  // State
  const [toggleCustom, setToggleCustom] = useState(false);
  const { t: translate } = useTranslation();

  const handleDSEToggling = (event: any) => {
    setIsDSEEnabled(event.target.checked);
  };

  // Component
  return (
    <div className={`tpSwitch tourPeriodSwitch ${isPublic ? 'public' : ''}`}>
      <h4>{translate('DataPOHeader')}</h4>
      {timePeriods.periods.map((tp, idx) => (
        <label className="radio" htmlFor={`radio-${idx}`} key={tp.labelHTML}>
          {tp.labelHTML}
          <input
            type="radio"
            id={`radio-${idx}`}
            name="radio"
            checked={curTimePeriod === tp.labelHTML}
            value={tp.labelHTML}
            onChange={() => {
              handleTimePeriod(
                tp,
                startEndFinder(tp.timeOption).start,
                startEndFinder(tp.timeOption).end,
              );
              setToggleCustom(false);
            }}
            disabled={progressBarOn}
          />
          <span className="checkmark" />
        </label>
      ))}
      {!isPublic ? (
        <DataCustomDate
          curTimePeriod={curTimePeriod}
          endDate={endDate}
          handleCustomDateChange={handleCustomDateChange}
          handleCustomSubmit={handleCustomSubmit}
          handleTimePeriod={handleTimePeriod}
          progressBarOn={progressBarOn}
          toggleCustom={toggleCustom}
          setToggleCustom={setToggleCustom}
          startDate={startDate}
        />
      ) : (
        <></>
      )}
      {modularFeatures.dseIntegration[isPublic ? 'isPublic' : 'isPrivate'] && (
        <label className="checkbox dse">
          DSE
          <input type="checkbox" onChange={handleDSEToggling} />
          <span className="checkmark"></span>
        </label>
      )}
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  modularFeatures: state.modularFeatures,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setDataConfig,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataTimePeriodOptions);
