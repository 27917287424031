import React from 'react';

interface FleetManagementFilterProps {
    sampIntervalRef: React.MutableRefObject<any>,
    slotsRef: React.MutableRefObject<any>,
    batteryPercentageRef: React.MutableRefObject<any>,
    lastConnectedRef: React.MutableRefObject<any>,
    filterTableData: () => void,
};


const FleetManagementFilter = ({ sampIntervalRef, slotsRef, batteryPercentageRef, lastConnectedRef, filterTableData }: FleetManagementFilterProps) => {
    return (
        <>
            {/* <span>Filter by:</span>
                <div className="table-checkboxes zephyr-types-options">
                    <span>Zephyrs:</span>
                    <label htmlFor="all">
                        <input type="checkbox" id="all" name="all" value="All" checked={defaultAllZephyrs} onChange={setAllZephyrsOption} />
                        <span>All</span>
                    </label>
                    {availableZephyrTypes && extractAvailableZephyrTypes()}
                </div> */}
            < div className="table-checkboxes issues-filter-options" >
                <span>Filter by issues:</span>
                <label htmlFor="sampling_interval">
                    <input type="checkbox" id="sampling_interval" name="sampling_interval" value="SampInterval" ref={sampIntervalRef} onChange={filterTableData} />
                    <span>No sampling interval</span>
                </label>

                <label htmlFor="slots">
                    <input type="checkbox" id="slots" name="slots" value="Slots" ref={slotsRef} onChange={filterTableData} />
                    <span>No slots</span>
                </label>

                <label htmlFor="batteryPercentage">
                    <input type="checkbox" id="batteryPercentage" name="batteryPercentage" value="BatteryPercentage" ref={batteryPercentageRef} onChange={filterTableData} />
                    <span>{`Battery <= 25%`}</span>
                </label>

                <label htmlFor="last_connected">
                    <input type="checkbox" id="last_connected" name="last_connected" value="LastConnected" ref={lastConnectedRef} onChange={filterTableData} />
                    <span>{`Last connection (>24hrs)`}</span>
                </label>
            </div >
        </>
    )
};

export default FleetManagementFilter;