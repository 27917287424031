// Type Imports
import { ExternalMetData, Zephyr } from '../interface';

/* Function
DESC: zephyrMetDataEnricher takes a list of Zephyrs and appends external met data
ARGS: array of Zephyrs, array of external met data objects
*/
const zephyrMetDataEnricher = (
  externalMetData: ExternalMetData[],
  zephyrs: Zephyr[],
) => {
  const enrichedZephyrs = zephyrs.map((z) => {
    const nearestMetData = externalMetData.filter(
      (eMD) => eMD.locationId === z.zNumber,
    );
    if (nearestMetData.length) {
      const enrichedZephyr = { ...z, externalMetData: nearestMetData[0] };
      return enrichedZephyr;
    }
    const enrichedZephyrNoMetData = { ...z, externalMetData: null };
    return enrichedZephyrNoMetData;
  });
  return enrichedZephyrs;
};

/* Function
DESC: zephyrMetRequestPackager takes a list of Zephyrs and returns a formatted list ready for a met data request
ARGS: array of Zephyrs
*/
const zephyrMetRequestPackager = (zephyrs: Zephyr[]) => {
  const packagedMetRequest = zephyrs
    .filter((z) => !z.userEndTimeDate && z.latitude && z.longitude)
    .map((z) => ({
      locationId: z.zNumber,
      lat: z.latitude,
      lon: z.longitude,
    }));
  return packagedMetRequest;
};

const specificZephyrMetDataEnricher = (externalMetData: ExternalMetData) => {
  return externalMetData ? { externalMetData: externalMetData } : { externalMetData: null };
}

const specificZephyrMetRequestPackager = (zephyr: Zephyr) => {
  if (!zephyr.userEndTimeDate && zephyr.latitude && zephyr.longitude) return {
    locationId: zephyr.zNumber,
    lat: zephyr.latitude,
    lon: zephyr.longitude,
  }
}

export { zephyrMetDataEnricher, specificZephyrMetDataEnricher, zephyrMetRequestPackager, specificZephyrMetRequestPackager };
