// Package Imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// @ts-ignore
import html2canvas from 'html2canvas';
// @ts-ignore
import canvasToImage from 'canvas-to-image';

// Component Imports
import DataLineChart from './DataLineChart';
import DataChartDisclaimer from './DataChartDisclaimer';
import DataChartHeader from './DataChartHeader';
import DataChartLabels from './DataChartLabels';
import DataUnits from './DataUnits';
import ProgressBar from '../shared_components/ProgressBar';

// Util Imports
import { periods } from '../../utils/consts';
import { defaultStartEndFinder, startEndFinder, subtractTwoDates, utcConvertor } from '../../utils/functions/dateFinder';
import utilsRequest from '../../utils/request';
import { checkDataResponseForData, setZephyr } from '../../actions/zephyrs';


// Type Imports
import {
  AnnualAvgData,
  AveragingPeriod,
  ChartData,
  DataConfig,
  DisplayConfig,
  ReduxState,
  RequestError,
  TimePeriod,
  UrlQueryStringParams,
  Zephyr,
  ZephyrHistoryWithId,
} from '../../utils/interface';
import { bindActionCreators, Dispatch } from 'redux';

// Component Interface
interface DataChartContainerProps {
  averagingOption: AveragingPeriod;
  chartData: ChartData;
  dataLoading: boolean;
  date: {
    start: Date;
    end: Date;
  };
  draggedUnit: Zephyr | null;
  displayConfig: DisplayConfig;
  handleRemoveUnit: Function;
  handleUnitSpeciesFilter: Function;
  isPublic: boolean;
  progressBarOn: boolean;
  requestErrors: RequestError[];
  setProgressBarOn: Function;
  setUnitList: Function;
  setUnitListGrowing: Function;
  timezone: {
    tzType: string;
    tzSpecific: string;
  };
  timePeriod: TimePeriod;
  timePeriodSwitching: boolean;
  unitHistories: ZephyrHistoryWithId | null;
  unitList: Zephyr[];
  zephyrs: Zephyr[];
  showingAnnualAverage: boolean;
  annualAvgData: AnnualAvgData;
  zephyr: any,
  statusCode: number,
  setZephyr: Function,
  setRequestErrors: Function,
  isDSEEnabled: boolean,
  setUnitHistories: Function,
  queryStringParams: UrlQueryStringParams,
  dataConfig: DataConfig,
  setHasZephyrNull: Function,
  hasZephyrNull: boolean | null,
  statusDescription: string | null,
}

const DataChartContainer = ({
  averagingOption,
  chartData,
  date,
  dataLoading,
  displayConfig,
  draggedUnit,
  handleRemoveUnit,
  handleUnitSpeciesFilter,
  isPublic,
  progressBarOn,
  requestErrors,
  setProgressBarOn,
  setUnitList,
  setUnitListGrowing,
  timezone,
  timePeriod,
  timePeriodSwitching,
  unitHistories,
  unitList,
  zephyrs,
  showingAnnualAverage,
  annualAvgData,
  zephyr,
  statusCode,
  setZephyr,
  setRequestErrors,
  isDSEEnabled,
  setUnitHistories,
  queryStringParams,
  dataConfig,
  setHasZephyrNull,
  hasZephyrNull,
  statusDescription,
}: DataChartContainerProps) => {
  // State
  const [readyForDrop, setReadyForDrop] = useState(false);

  // Refs
  const compRef = React.createRef<any>();

  // Functions
  const handleChartDownload = () => {
    let fileName = '';
    if (showingAnnualAverage) {
      fileName = `mappairChartExport_${annualAvgData?.list?.latlon?.lat.toFixed(
        2,
      )}_${annualAvgData?.list?.latlon?.lon.toFixed(2)}`;
    } else {
      fileName = `chartExport_${unitList.length === 1
        ? unitList[0].name.replace(' ', '_')
        : 'multipleUnits'
        }_${averagingOption.labelHTML}`;
    }

    const item = document.querySelector('#graph-container') as any;
    item.parentNode.style.overflow = 'visible';
    let html2canvasOptions = {
      backgroundColor: null,
      ignoreElements: () => false,
      windowWidth: window.outerWidth + window.innerWidth,
      logging: false,
    };
    if ((window.document as any).documentMode) {
      const ieOptions = {
        ...html2canvasOptions,
        x: 0,
        y: 400,
      };
      html2canvasOptions = ieOptions;
    }
    html2canvas(item as HTMLElement, html2canvasOptions).then((canvas) => {
      canvasToImage(canvas, {
        name: fileName,
        type: 'png',
        quality: 1,
      });
    });
  };

  const zephyrMeasurementRequestData = () => {
    const defaultStartEnd = defaultStartEndFinder();
    let { end, start } = defaultStartEnd;
    if (timePeriod.timeOption === 'custom') {
      start = moment(date.start)
        .set({ h: 0, m: 0, s: 0 })
        .format('YYYYMMDDHHmm');
      const unformattedEnd = moment(date.end).set({ h: 23, m: 59, s: 0 });
      const current = moment();
      const selectedEnd = current < unformattedEnd ? current : unformattedEnd;
      end = selectedEnd.format('YYYYMMDDHHmm');
    } else if (timePeriod.timeOption) {
      const startEnd = startEndFinder(timePeriod.timeOption);
      if (isPublic) {
        start = moment(startEnd.start, 'YYYYMMDDHHmm')
          .set({ h: 0, m: 0, s: 0 })
          .format('YYYYMMDDHHmm');
        end = moment(startEnd.end, 'YYYYMMDDHHmm')
          .set({ h: 0, m: 0, s: 0 })
          .format('YYYYMMDDHHmm');
      } else {
        start = startEnd.start;
        end = startEnd.end;
      }
    }

    // Convert start/end
    const startUTC = utcConvertor(start);
    const endUTC = utcConvertor(end);
    const updatedDataConfig = queryStringParams.ctv
      ? { ...dataConfig, ctv: queryStringParams.ctv }
      : dataConfig;

    return { startUTC, endUTC, updatedDataConfig }
  }

  const handleDropZone = (e: React.DragEvent) => {
    if (showingAnnualAverage) return;
    // Allow only a maximum of 5 units to be overplotted

    // Handle dropping units with no last hr of data
    // if (draggedUnit && !draggedUnit.averageOfLastHourOfData && draggedUnit.type === 0) {
    //   alert("Zephyr " + draggedUnit.name + " has no data at the moment. Try again later.");
    //   return;
    // }

    if (draggedUnit && unitList && ((draggedUnit.type === 0 && unitList[0].type === 100) || (draggedUnit.type === 100 && unitList[0].type === 0))) {
      alert("Stacking zephyrs and virtual zephyrs still in development. Available soon!");
      return;
    }
    if (draggedUnit && unitList && ((draggedUnit.type === 0 && unitList[0].type === 1) || (draggedUnit.type === 1 && unitList[0].type === 0))) {
      alert("Stacking zephyrs and aurns still in development. Available soon!");
      return;
    }
    if (draggedUnit && unitList && ((draggedUnit.type === 1 && unitList[0].type === 100) || (draggedUnit.type === 100 && unitList[0].type === 1))) {
      alert("Stacking virtual zephyrs and aurns still in development. Available soon!");
      return;
    }

    if (readyForDrop) {
      // if (unitList.length === 1 && !unitList[0].averageOfLastHourOfData && unitList[0].type === 0) { setUnitHistories([]); setUnitList([draggedUnit]); setZephyr(draggedUnit); setRequestErrors([]); }
      setUnitList([...unitList, draggedUnit]);

      setUnitListGrowing(true);
      setReadyForDrop(false);
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    if (showingAnnualAverage) return;
    e.stopPropagation();
    e.preventDefault();
    if (
      !progressBarOn &&
      draggedUnit &&
      !unitList.includes(draggedUnit) &&
      unitList.length < 5
    ) {
      setReadyForDrop(true);
    }
  };

  const handleDragLeave = (e: React.DragEvent) => {
    if (showingAnnualAverage) return;
    e.stopPropagation();
    e.preventDefault();
    setReadyForDrop(false);
  };

  const getRequestTimeEstimate = () => {
    const initial = 5000;
    const dayRate = 6000;
    const unitCount = timePeriodSwitching ? unitList.length : 1;
    const days =
      timePeriod.timeOption === 'custom'
        ? subtractTwoDates(
          moment(date.start).format('YYYYMMDDHHmm'),
          moment(date.end).format('YYYYMMDDHHmm'),
        )
        : timePeriod.timeOption === 'month'
          ? 31
          : periods[timePeriod.timeOption].quantity;
    const finalDays = days || 3;
    const estimate = (initial + dayRate * finalDays) * unitCount;
    return estimate;
  };

  return (
    <div className={`graph-container-wrapper ${isDSEEnabled ? 'dse-enabled' : ''}`} id="graph-container-wrapper">
      <div
        className={`graph-container tourChart ${readyForDrop ? 'droppable' : ''
          }`}
        id="graph-container"
        onDragOver={(e) => handleDragOver(e)}
        onDrop={(e) => handleDropZone(e)}
        onDragLeave={(e) => handleDragLeave(e)}
        ref={compRef}
      >
        <div className="chart-header-container">
          <DataChartHeader
            showingAnnualAverage={showingAnnualAverage}
            annualAvgData={annualAvgData}
            progressBarOn={progressBarOn}
            requestErrors={requestErrors}
            tz={timezone}
            unitHistories={unitHistories}
            unitList={unitList}
            statusCode={statusCode}
            statusDescription={statusDescription}
          />
          <DataUnits
            showingAnnualAverage={showingAnnualAverage}
            annualAvgData={annualAvgData}
            handleRemoveUnit={handleRemoveUnit}
            isPublic={isPublic}
            progressBarOn={progressBarOn}
            requestErrors={requestErrors}
            unitList={unitList}
            zephyrs={zephyrs}
          />
        </div>
        {
          // Ensure ProgressBar unmounts when complete
          (dataLoading || progressBarOn) && (
            <ProgressBar
              dataLoading={dataLoading}
              setProgressBarOn={setProgressBarOn}
              timeEstimate={getRequestTimeEstimate()}
            />
          )
        }

        {!dataLoading &&
          (!unitHistories || !Object.keys(unitHistories).length) &&
          !showingAnnualAverage ? (
          <div className="data-message-container" />
        ) : (
          <ChartContainer
            progressBarOn={progressBarOn}
            averagingOption={averagingOption}
            chartData={chartData}
            displayConfig={displayConfig}
            downloadOn={
              (!progressBarOn &&
                unitHistories &&
                Object.keys(unitHistories).length > 0) ||
              showingAnnualAverage
            }
            handleChartDownload={handleChartDownload}
            handleUnitSpeciesFilter={handleUnitSpeciesFilter}
            isPublic={isPublic}
            showingAnnualAverage={showingAnnualAverage}
          />
        )}

        <DataChartDisclaimer
          on={
            (unitList &&
              unitList.filter((unit) => unit.type === 1).length > 0) ||
            showingAnnualAverage
          }
        />
      </div>
    </div>
  );
};

const ChartContainer = ({
  progressBarOn,
  chartData,
  averagingOption,
  displayConfig,
  downloadOn,
  handleChartDownload,
  handleUnitSpeciesFilter,
  isPublic,
  showingAnnualAverage,
}: any) => {
  if (progressBarOn) return null;

  return (
    <div>
      <DataChartLabels
        chartData={chartData}
        handleUnitSpeciesFilter={handleUnitSpeciesFilter}
      />
      <DataLineChart
        averagingOption={averagingOption}
        chartData={chartData}
        displayConfig={displayConfig}
        downloadOn={downloadOn}
        handleChartDownload={handleChartDownload}
        handleUnitSpeciesFilter={handleUnitSpeciesFilter}
        isPublic={isPublic}
        showingAnnualAverage={showingAnnualAverage}
      />
    </div>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  draggedUnit: state.draggedUnit,
  zephyrs: state.getZephyrs.zephyrs,
  dataConfig: state.dataConfig,
  queryStringParams: state.queryStringParams,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setZephyr,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DataChartContainer);
