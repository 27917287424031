// Package Imports
import React from 'react';
import parse from 'html-react-parser';

// Type Imports
import { Agreement } from '../../utils/interface';

// Component Interfaces
interface AgreementModalProps {
  agreement: Agreement;
  buttonText: string;
  signAgreement: Function;
  setAgreementModalOn: Function;
}

// Component
const AgreementModal = ({
  agreement,
  buttonText,
  signAgreement,
  setAgreementModalOn,
}: AgreementModalProps) => (
  <div className="agreement-modal-outter">
    <div className="modal-text">{parse(agreement.terms)}</div>
    <button
      className="button primary"
      onClick={(e) => {
        e.preventDefault();
        signAgreement(agreement.id);
        setAgreementModalOn(false);
      }}
      type="submit"
    >
      {buttonText}
    </button>
  </div>
);

export default AgreementModal;
