// Util Imports
import { slotFinder } from './slotFinder';

// Const Imports
import { aqlOtherTab, defaultSpecies } from '../consts';

// Type Imports
import {
  AQOptionsMap,
  LineChartData,
  DisplayConfig,
  ExternalMetData,
  Overlay,
  SlotOptions,
  SpeciesDataLabels,
  SpeciesGroupOptions,
  StrippedTab,
  Zephyr,
  ZephyrHistory,
  ZephyrHistoryWithId,
  ZephyrLastHourInd,
  SpeciesDataIdentifiers,
} from '../interface';

/* Function
DESC: speciesObjFinder returns the full species object from the displayConfig
ARGS: displayConfig(derived from API), targetSpecies(dataIdentifier e.g. NO)
*/
const speciesObjFinder = (
  displayConfig: DisplayConfig,
  targetSpecies: SpeciesDataIdentifiers,
) => {
  const speciesObj = displayConfig.tabs
    .map(
      (group) =>
        group.species.filter((species) =>
          species.dataIdentifiers.includes(targetSpecies),
        )[0],
    )
    .filter((ans) => ans)[0];
  return speciesObj;
};

/* Function
DESC: speciesColourFinder finds the right colour to display for a given species
ARGS: displayConfig(derived from API), targetSpecies(dataIdentifier e.g. NO), unitIdx(from Zephyr list)
*/
const speciesColourFinder = (
  displayConfig: DisplayConfig,
  targetSpecies: SpeciesDataIdentifiers,
  unitIdx: number,
) => {
  const rgb = displayConfig.tabs
    .map(
      (group) =>
        group.species.filter((species) =>
          species.dataIdentifiers.includes(targetSpecies),
        )[0],
    )
    .filter((ans) => ans)[0].colour[unitIdx];
  const formattedRGB = `rgb(${rgb.r},${rgb.g},${rgb.b})`;
  return formattedRGB;
};

/* Function
DESC: speciesFinder finds which species have available data for a given unit, slot, and time period
ARGS: displayConfig(derived from API), slot(e.g. slotB), unitData(derived from API)
*/
const speciesFinder = (
  displayConfig: DisplayConfig,
  slot: SlotOptions,
  unitData: ZephyrHistory,
) => {
  const availableSpecies = [];
  const chartableSpecies: SpeciesDataIdentifiers[] = [];
  displayConfig.tabs.forEach((group) =>
    group.species.forEach((species) =>
      species.dataIdentifiers.forEach((sDI) => chartableSpecies.push(sDI)),
    ),
  );
  for (const species in unitData['Hourly average on the hour'][slot]) {
    if (
      chartableSpecies.includes(species as SpeciesDataIdentifiers) &&
      unitData['Hourly average on the hour'][slot]![species].data
    ) {
      if (
        unitData['Hourly average on the hour'][slot]![species].data.some(
          (sp) => sp !== null,
        )
      ) {
        availableSpecies.push(species);
      }
    }
  }
  return availableSpecies;
};

/* Function
DESC: mappairSpeciesFinder returns either a species from the mappairSpeciesFinder function 
      below or the default if one doesn't exist (aka is AQI)
ARGS: curOverlay(Redux State), displayConfig(derived from API), overlays, overlays (derived from API)
*/
const mappairSpeciesFinder = (
  curOverlay: string,
  displayConfig: DisplayConfig,
  overlays: Overlay[],
) => {
  const species =
    speciesLabelFinder(curOverlay, displayConfig, overlays) || defaultSpecies;
  return species;
};

/* Function
DESC: speciesLabelFinder finds a species label from an active overlay
ARGS: curOverlay(Redux State), displayConfig(derived from API), overlays, overlays (derived from API)
*/
const speciesLabelFinder = (
  curOverlay: string,
  displayConfig: DisplayConfig,
  overlays: Overlay[],
) => {
  let speciesData = null;
  const overlay = overlays.filter((ol) => ol.name === curOverlay);
  if (overlay.length) {
    const dataIdentifier = overlay[0].speciesIdentifier;
    displayConfig.tabs.forEach((tab) =>
      tab.species.forEach((sp) => {
        if (sp.label === dataIdentifier) {
          speciesData = sp;
        }
      }),
    );
  }
  return speciesData;
};

/* Function
DESC: extentTabFinder creates a hybrid tab array of available species(see above function) grouped as per their config in 
      the Zephyr API. For an array of units, it finds the full extent of tabs that should be displayed
ARGS: displayConfig(derived from API), slot(e.g. slotB), unitData(derived from API)
*/
const extentTabFinder = (
  slot: SlotOptions,
  displayConfig: DisplayConfig,
  unitHistories: ZephyrHistoryWithId,
  unitNumber: number,
) => {
  const extentTabConfigs: StrippedTab[] = [];
  if (!unitNumber) return extentTabConfigs;
  for (const [unitId, unitHistory] of Object.entries(unitHistories)) {
    if (unitHistory && unitHistory['Hourly average on the hour']) {
      const firstSlot =
        unitId === unitNumber.toString() && slot !== ''
          ? slot
          : slotFinder(unitHistory).length
            ? slotFinder(unitHistory)[0]
            : null;
      if (firstSlot) {
        const tabConfig = tabFinder(displayConfig, firstSlot, unitHistory);
        if (extentTabConfigs.length) {
          tabConfig.forEach((group) => {
            if (
              !extentTabConfigs.filter((g) => g.group === group.group).length
            ) {
              extentTabConfigs.push({
                group: group.group,
                HTMLGroupLabel: group.HTMLGroupLabel,
                curSlot: slot
              });
            }
          });
        } else {
          tabConfig.map((g) =>
            extentTabConfigs.push({
              group: g.group,
              HTMLGroupLabel: g.HTMLGroupLabel,
              curSlot: slot
            }),
          );
        }
      }
    }
  }
  // Sorts the tabs so they always occur in the same order regardless of Zephyr switching
  const sortedExtentTabConfigs: StrippedTab[] = [];
  displayConfig.tabs.forEach((dcGroup) => {
    extentTabConfigs.forEach((etGroup) => {
      if (dcGroup.group === etGroup.group) {
        sortedExtentTabConfigs.push(etGroup);
      }
    });
  });
  return sortedExtentTabConfigs;
};

/* Function
DESC: tabFinder creates a tab array of available species(see above function) grouped as per their config in 
      the Zephyr API
ARGS: displayConfig(derived from API), slot(e.g. slotB), unitData(derived from API)
*/
const tabFinder = (
  displayConfig: DisplayConfig,
  slot: SlotOptions,
  unitData: ZephyrHistory,
) => {
  if (unitData && unitData['Hourly average on the hour']) {
    const availableSpecies = speciesFinder(displayConfig, slot, unitData);
    // map over the tabs and remove empty species and empty groups
    const tabConfigs = displayConfig.tabs
      .map((group) => ({
        ...group,
        species: group.species.filter(
          (species) =>
            availableSpecies.filter((aS) =>
              species.dataIdentifiers.includes(aS as SpeciesDataIdentifiers),
            ).length,
        ),
      }))
      .filter((tab) => tab.species.length > 0);
    return tabConfigs;
  }
  return [];
};

/* Function
DESC: activeTabFinder creates an activeTabConfig object with the current group, species, and labels
      to be displayed
ARGS: displayConfig(derived from API), slot(e.g. slotB), unitData(derived from API)
*/
const activeTabFinder = (
  displayConfig: DisplayConfig,
  slot: SlotOptions,
  unitData: ZephyrHistory,
  specifiedGroup?: SpeciesGroupOptions,
) => {
  // return this object if the function cannot run
  const nullReturn = null;
  if (unitData && unitData['Hourly average on the hour']) {
    const availableSpecies = speciesFinder(displayConfig, slot, unitData).concat(speciesFinder(displayConfig, slot === 'slotA' ? 'slotB' : slot, unitData))

    if (availableSpecies.length > 0) {
      // map over the tabs and remove empty species and empty groups
      const tabConfigs = displayConfig.tabs
        .map((group) => ({
          ...group,
          species: group.species.filter(
            (species) =>
              availableSpecies.filter((aS) =>
                species.dataIdentifiers.includes(aS as SpeciesDataIdentifiers),
              ).length,
          ),
        }))
        .filter((tab) => tab.species.length > 0);
      // conditionally find the specific group
      const tabConfigArr = specifiedGroup
        ? tabConfigs.filter((tcGroup) => tcGroup.group === specifiedGroup)
          .length
          ? tabConfigs.filter((tcGroup) => tcGroup.group === specifiedGroup)
          : tabConfigs
        : tabConfigs;
      // construct activeTabConfig
      const activeTabConfig = {
        group: tabConfigArr[0].group,
        tab: tabConfigArr[0].species.map((species) => species.dataIdentifier),
        labels: tabConfigArr[0].species.map((species) => species.HTMLUnitLabel),
      };
      return activeTabConfig;
    }
    return nullReturn;
  }
  return nullReturn;
};

/* Function
DESC: extentActiveTabFinder creates a hybrid activeTab of available species(see above function) grouped as per their config in 
      the Zephyr API. For an array of units, it finds the full extent of a given active tabs species and labels that should be included.
ARGS: displayConfig(derived from API), slot(e.g. slotB), specifiedGroup(e.g. NO2), unitHistories(), unitData(derived from API)
*/

const extentActiveTabFinder = (
  displayConfig: DisplayConfig,
  slot: SlotOptions,
  specifiedGroup: SpeciesGroupOptions,
  unitHistories: ZephyrHistoryWithId,
  unitNumber: number,
) => {
  const availableSpecies: string[] = [];
  for (const [unitId, unitHistory] of Object.entries(unitHistories)) {
    const firstSlot =
      unitId === unitNumber.toString() && slot !== ''
        ? slot
        : slotFinder(unitHistory).length
          ? slotFinder(unitHistory)[0]
          : null;
    const unitAvailableSpecies = firstSlot
      ? speciesFinder(displayConfig, firstSlot, unitHistory).concat(speciesFinder(displayConfig, firstSlot === 'slotA' ? 'slotB' : firstSlot, unitHistory))
      : null;
    if (unitAvailableSpecies) {
      unitAvailableSpecies.forEach((sp) => {
        if (!availableSpecies.includes(sp)) {
          availableSpecies.push(sp);
        }
      });
    }
  }
  const filteredDisplayConfig = displayConfig.tabs
    .map((group) => ({
      ...group,
      species: group.species.filter(
        (species) =>
          availableSpecies.filter((aS: any) =>
            species.dataIdentifiers.includes(aS as SpeciesDataIdentifiers),
          ),
      ),
    }))
    .filter((tab) => tab.species.length > 0)
    .filter((tcGroup) => tcGroup.group === specifiedGroup);
  if (filteredDisplayConfig.length) {
    const extendedActiveTabConfig = {
      group: filteredDisplayConfig[0].group,
      tab: filteredDisplayConfig[0].species.map(
        (species) => species.dataIdentifier,
      ),
      labels: filteredDisplayConfig[0].species.map(
        (species) => species.HTMLUnitLabel,
      ),
    };
    return extendedActiveTabConfig;
  }
  return null;
};

/* Function
DESC: aqMetMapper creates an aqMetConfig array
ARGS: displayConfig(derived from API), externalMetData(derived from API)
*/
const aqMetMapper = (
  displayConfig: DisplayConfig,
  externalMetData: ExternalMetData,
) => {
  const aqiMetConfigs = displayConfig?.met?.map((group) => ({
    ...group,
    species: group.species.filter(
      (species) => typeof externalMetData[species.dataIdentifier] === 'number',
    ),
  }));
  return aqiMetConfigs;
};

const aqTabHeaderFinder = (displayConfig: DisplayConfig, zephyr: Zephyr) => {
  if (!zephyr) return [];
  const aqTabHeaders: AQOptionsMap[] = [];
  const lastHourUnitData = zephyr.averageOfLastHourOfData;
  const { externalMetData } = zephyr;
  if (lastHourUnitData) {
    const unitTabs = aqTabFinder(displayConfig, lastHourUnitData);
    // Get all tabs from unit
    unitTabs.forEach((group) => {
      const curAQTabHeaderVals = aqTabHeaders.map((uTH) => uTH.value);
      if (
        !curAQTabHeaderVals.includes(group.context) &&
        (group.species.length || group.context === 'zephyr')
      ) {
        const newUnitTabHeader = {
          label: group.contextLabel,
          value: group.context,
        };
        aqTabHeaders.push(newUnitTabHeader);
      }
    });
  }
  // Get external tabs
  const metStation = aqTabHeaders.filter((t) => t.value === 'met').length;
  if (!metStation && externalMetData && externalMetData.windDir) {
    aqTabHeaders.push(aqlOtherTab);
  }
  return aqTabHeaders;
};

/* Function
DESC: aqTabFinder creates an aqiTabConfigs array with each groups config
      to be displayed
ARGS: displayConfig(derived from API), lastHourunitData(derived from API)
*/
const aqTabFinder = (
  displayConfig: DisplayConfig,
  lastHourUnitData: ZephyrLastHourInd,
) => {
  const aqiTabConfigs = displayConfig.tabs.map((group) => ({
    ...group,
    species: group.species.filter(
      (species) => typeof lastHourUnitData[species.label] === 'number',
    ),
  }));
  return aqiTabConfigs;
};

/* Function
DESC: speciesFilterFinder creates an array of species filters to be displayed
ARGS: chartDatasets
*/
const speciesFilterFinder = (
  chartDatasets: LineChartData[],
  speciesFiltered: SpeciesDataLabels[],
) => {
  const filterSpecies: any[] = [];
  chartDatasets.forEach((dataset) => {
    const filterSpeciesLabels = filterSpecies.map((fs) => fs.label);
    if (!filterSpeciesLabels.includes(dataset.speciesLabel)) {
      const hiddenStatus = !!speciesFiltered.includes(dataset.speciesLabel);
      filterSpecies.push({ label: dataset.speciesLabel, hidden: hiddenStatus });
    }
  });
  return filterSpecies;
};

export {
  activeTabFinder,
  aqMetMapper,
  aqTabHeaderFinder,
  aqTabFinder,
  extentActiveTabFinder,
  extentTabFinder,
  mappairSpeciesFinder,
  speciesColourFinder,
  speciesFilterFinder,
  speciesFinder,
  speciesLabelFinder,
  speciesObjFinder,
  tabFinder,
};
