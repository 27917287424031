import React, { useEffect, useState } from 'react';
import DataTable, { Api } from 'datatables.net';
import { CSVLink } from 'react-csv';
import { getAuth } from '../../utils/functions/getAuth';
import { APIPaths, config, corsProxyURL, countryCodes } from '../../utils/consts';
import * as Sentry from '@sentry/react'

interface SmsGroupAlertsProps {
    tableData: any,
    setTableData: Function,
    csvData: any,
    setCsvData: Function,
    fileName: string | null,
    setFileName: Function,
    bearerToken: string | null,
}

const validFileExtensions = [".csv"];
const AlertsEndpoint = `${config.alertsAPI}${APIPaths.airAlertsApi}v2/alert`;
const PutAlertsEndpoint = `${config.alertsAPI}${APIPaths.airAlertsApi}v2/alert/csv`;

///Gets active alerts for a user in a CSV style format
const GetAlertsAsCsv = async (bearerToken: string) => {
    try {
        
        let requestOptions: any = {
            method: 'GET',
            headers: { 'Content-Type': 'text/csv', 'Authorization': `Bearer ${bearerToken}` },
            credentials: 'omit'
        }
        
        return await fetch(AlertsEndpoint, requestOptions);
    } catch (e) {
        alert('Server error or missing mandatory CSV titles! Please double-check your CSV content! If your mandatory CSV titles meet the requirements, please contact us at support@earthsense.co.uk');
        return;
    }
}

///Adds alerts based on the csv file provided
const AddAlertsAsCSV = async(bearerToken: string, files: any) => {
    //const files = (document.getElementById('files') as any).files;
    if (files.length > 0) {
        let file = files[0];

        //The es-reset header tells the alerts api to create a new record rather than update the existing one
        let requestOptions: any = {
            method: 'PUT',
            headers: { 'Content-Type': 'text/csv', 'Authorization': `Bearer ${bearerToken}`, 'es-reset': 'true' },
            body: file,
            credentials: 'omit'
        };

        return await fetch(PutAlertsEndpoint, requestOptions);
    }
}

const SmsGroupAlerts = ({ tableData, setTableData, csvData, setCsvData, fileName, setFileName, bearerToken }: SmsGroupAlertsProps) => {

    useEffect(() => {
        readCsvOnLoad();
    }, []);

    useEffect(() => {
        if (tableData) {
            const table: Api<any> = new DataTable('#table_id5', {
                columns: [ {title: 'Address'}, { title: "Phone Number"} ],
                data: [...tableData.slice(1, tableData.length)],
                lengthMenu: [1000],
                retrieve: true,
                searching: true,
                scrollY: "500",
            });
            // Extra step to do extra clean-up.
            return () => {
                table.destroy()
            };
        }
    }, [tableData]);

    const getExtension = (inputID: any, extentionsArray: string[]) => {
        const fileName = (document.getElementById(inputID) as any).value;
        return (new RegExp('(' + extentionsArray.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
    };

    const csvToArray = (text: any) => {
        let p = '', row = [''], ret = [row], i = 0, r = 0, s = !0, l;
        for (l of text) {
            if ('"' === l) {
                if (s && l === p) row[i] += l;
                s = !s;
            } else if (',' === l && s) l = row[++i] = '';
            else if ('\n' === l && s) {
                if ('\r' === p) row[i] = row[i].slice(0, -1);
                row = ret[++r] = [l = '']; i = 0;
            } else row[i] += l;
            p = l;
        }
        return ret;
    };

    const uploadNewCsv = async() => {
        const files = (document.getElementById('files') as any).files;
        if(files && files.length > 0) {
            //TODO: this should really be strongly typed or globally verifiable in some way. Probably need some sort of redirect in place if it doesn't exist anymore
            if (bearerToken){
                AddAlertsAsCSV(bearerToken, files).then((x) => {
                    if(x){
                        if (x?.status >= 200 && x?.status < 300) {
                            readCsvOnLoad();
                        }else{
                            x?.text().then((x2) => {
                                alert(x2)
                            })
                        }
                    }else{
                        alert('Something has gone wrong uploading your alerts.')
                        Sentry.captureException( {
                            'Message': 'Brill place alerts has received an unexpected answer when uploading a new csv',
                            'DNS': AlertsEndpoint,
                            'request information': x
                        })
                    }
                });
            }
        }
    }
    
    const getAlertsAsCSV = async () => {
        //TODO: see todo above
        if(bearerToken){
            return GetAlertsAsCsv(bearerToken)
        }
    }
    
    const readCsvOnLoad = async() => {
        getAlertsAsCSV().then((x) => {
            if(x){
                x?.text().then((x2) => {
                    csvData = csvToArray(x2)
                    let customerCsvData: string[][] = [];
                    const tableData: any = [{ title: 'Phone Number' }];
                    const flatNumberIndex = csvData[0].indexOf(csvData[0].find((header: string) => header === 'Address'));
                    const phoneNumberIndex = csvData[0].indexOf(csvData[0].find((header: string) => header === 'PhoneNumber'));
                    if (flatNumberIndex < 0 || phoneNumberIndex < 0) {
                        return;
                            //throw new Error("Missing headers from get alerts csv response")
                    }
                    customerCsvData.push(['Address', 'PhoneNumber'])
                    csvData.slice(1, csvData.length).forEach((el: string[]) => {
                        if (el.length > 1) {
                            let phoneNumber = el[phoneNumberIndex];
                            let address = el[flatNumberIndex]
                            customerCsvData.push([address, phoneNumber]);
                            let hashedPhoneNumber = phoneNumber.slice(0, 4)
                            for (let i = 0; i < 7; i++){
                                hashedPhoneNumber += '*';
                            }
                            //console.log(hashedPhoneNumber);
                            hashedPhoneNumber += phoneNumber.slice(phoneNumber.length - 3, phoneNumber.length);
                            tableData.push([address, hashedPhoneNumber]);
                        }
                    });
                    setCsvData(customerCsvData);
                    setTableData(tableData);
                })
            }
        }, (x2) => {
            Sentry.captureException( {
                'Message': 'Brill place alerts has experienced an unexpected answer from get alerts csv',
                'DNS': AlertsEndpoint,
                'Request Info': x2
            })
        });
    }

    return (
        <section className="sms-alerts-wrapper" style={{ marginLeft: 325, padding: '15px 20px', height: '90vh', overflowY: 'scroll' }}>
            <div className="sms-alerts-button-container" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 9 }}>
                <div style={{ width: '48%' }}>
                    <label className="prompt-button" style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} htmlFor="files">Upload New List</label>
                    <input
                        id="files"
                        type="file"
                        style={{ display: 'none' }}
                        value=''
                        onChange={async () => {
                            if (!getExtension('files', validFileExtensions)) { alert("Unsupported file format!"); return; }
                            await uploadNewCsv();
                        }}
                    />
                    {fileName && <p style={{ marginTop: 3, marginBottom: 0, fontSize: 12, textAlign: 'center' }}>File loaded: {fileName}</p>}
                </div>
                <div style={{ width: '48%' }}>
                    <CSVLink data={tableData && csvData ? csvData : []} filename='Recipient_List' style={{ width: '100%', pointerEvents: !tableData ? 'none' : 'initial' }}><button className="prompt-button" style={{ width: '100%', marginBottom: 3, backgroundColor: !tableData ? 'rgba(78, 115, 183, 0.7)' : 'rgb(78, 115, 183)' }}>Download Current List</button></CSVLink>
                    <CSVLink data={[['Address', 'PhoneNumber']]} filename='Recipient_List_Template' style={{ display: 'block', fontSize: 12, textAlign: 'center' }}>Download CSV Template</CSVLink>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 30 }}>
                {!tableData &&
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 5, backgroundColor: 'rgba(0,0,0,.1)' }}>
                        <p>No list uploaded yet.</p>
                    </div>
                }
                {tableData && <table id="table_id5" className="cell-border compact hover order-column row-border stripe" width={'100%'}>
                </table>}
                <div style={{ width: '100%', textAlign: 'justify' }}>
                    <h4 style={{ fontWeight: 'bold' }}>User Instructions:</h4>
                    <p>Download the CSV template or the existing CSV using the buttons above. Open the CSV in Notepad (recommended) or Excel. The template will be blank except for the column headers.</p>
                    <p>If using Excel, highlight the first two columns and change the data format to "Text", either by using the toolbar or by right clicking and selecting "Format Cells". If using Notepad, add commas after each address and phone number.</p>
                    <p>Enter phone numbers and addresses into the table. Ensure to include country codes and the international calling prefix for all numbers. Do not include any additional symbols or spaces. <br/>
                        Example format: <b>+441162967460</b></p>
                    <p>To ensure adherence to GDPR regulations please do not record any personal information other than flat number and phone number.</p>
                    <p>Save the CSV to your computer, then select "Upload New List". Select the CSV from your file browser and upload. An error message will display if any of the phone numbers are invalid.</p>
                    <p>All mobile numbers on the current list will be notified of pollution alerts. The pollution alerts will be inline with the agreed contract. The MyAir account holder is responsible for ensuring that recipients identity is kept safe and that user credentials are only shared with those authorised to use the system.</p>
                    <p>For any questions or bug reports, please contact the EarthSense Support Team at <a href="mailto:support@earthsense.co.uk">support@earthsense.co.uk</a>. </p>
                </div>
            </div>
        </section>
    )
};

export default SmsGroupAlerts;