// Package Imports
import React from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

// Component Imports
import AQTemporalChart from './AQTemporalChart';

// Util Imports
import { mappairCsvGenerator } from '../../utils/functions/csvGenerator';

// Type Imports
import { ChartData, Species } from '../../utils/interface';

// Component Interfaces
interface AQTemporalChartModalProps {
  chartDataset: ChartData;
  curSpecies: Species;
  handleChartDownload: Function;
  hdms: string;
  parentRef: React.RefObject<any>;
  setModalsOff: Function;
}

// Component
const AQTemporalChartModal = ({
  chartDataset,
  curSpecies,
  handleChartDownload,
  hdms,
  parentRef,
  setModalsOff,
}: AQTemporalChartModalProps) => {
  const { t: translate } = useTranslation();

  // Consts
  const additionalOptions = {
    zoomEnabled: true,
  };
  return (
    <div className="modal-container temporal-modal">
      <div ref={parentRef} className="temporal-modal-content">
        <div className="aqm-header aqm-header-modal">
          <h4>
            {hdms}
            <br />
            <span className="aq-model-unit">
              {translate('AQChartHeader')}: {parse(curSpecies.HTMLLabel)}{' '}
              {curSpecies.HTMLShortUnitLabel}
            </span>
          </h4>
          <div className="aqm-modal-header-buttons">
            <button
              className="button close close-modal aqm-close-modal"
              onClick={() => setModalsOff()}
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                viewBox="0 0 24 24"
                fill="#000"
              >
                <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
              </svg>
            </button>
          </div>
        </div>
        <div
          className="aqm-chart-container aqm-modal-chart-container"
          id="aqm-modal-chart-container"
        >
          <AQTemporalChart
            chartDataset={chartDataset}
            additionalOptions={additionalOptions}
            handleChartDownload={handleChartDownload}
          />
        </div>
        <button
          onClick={() => mappairCsvGenerator(chartDataset, curSpecies, hdms)}
          className="button tertiary aqm-download-data"
          data-html2canvas-ignore="true"
        >
          {translate('AQDownloadData')}
        </button>
      </div>
    </div>
  );
};

export default AQTemporalChartModal;
