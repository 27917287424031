// Types Imports
import { SET_ACTIVE_THRESHOLD } from '../actions/types';

// Type Safety
interface ThresholdTab {
  tab: string;
}

interface setThresholdTab {
  type: typeof SET_ACTIVE_THRESHOLD;
  payload: string;
}

// State
const initialState: ThresholdTab = {
  tab: '',
};

// Reducer
export default function (state = initialState, action: setThresholdTab) {
  const { type, payload } = action;

  switch (type) {
    case SET_ACTIVE_THRESHOLD:
      return {
        ...state,
        tab: payload,
      };
    default:
      return state;
  }
}
