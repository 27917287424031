
export const checkSlotABData = (data: any): any => {
    let isNullOnly = true;
    for (let i = 0; i < data.length; i++) {
        if (data[i] !== null) {
            isNullOnly = false;
            break;
        }
    }
    return isNullOnly ? true : false;
};

export const getAvailableAveragingOption = (availableSpeciesSlotA: any, availableSpeciesSlotB: any, hData: any, aPeriod: any, period: any, allowAveragingOption: boolean) => {
    let allowAvgOption: boolean = true;
    let hasAvgPeriodAnyData: boolean = false;
    if (availableSpeciesSlotA && !availableSpeciesSlotB) {
        for (let i = 0; i < availableSpeciesSlotA.length; i++) {
            if (!hData[aPeriod]['slotA'][availableSpeciesSlotA[i]].data.every((val: number | null) => val === null)) {
                if (allowAveragingOption) allowAvgOption = false;
                hasAvgPeriodAnyData = true;
                break;
            }
        }
    }
    else if (!availableSpeciesSlotA && availableSpeciesSlotB) {
        for (let i = 0; i < availableSpeciesSlotB.length; i++) {
            if (!hData[aPeriod]['slotB'][availableSpeciesSlotB[i]].data.every((val: number | null) => val === null)) {
                if (allowAveragingOption) allowAvgOption = false;
                hasAvgPeriodAnyData = true;
                break;
            }
        }
    }
    else if (availableSpeciesSlotA && availableSpeciesSlotB) {
        const concatedSpecies = availableSpeciesSlotA.concat(availableSpeciesSlotB);
        for (let i = 0; i < concatedSpecies.length; i++) {
            if ((concatedSpecies[i] && concatedSpecies[i] in hData[aPeriod]['slotA']
                && !hData[aPeriod]['slotA'][availableSpeciesSlotA[i]].data.every((val: number | null) => val === null)) || (concatedSpecies[i]
                    && concatedSpecies[i] in hData[aPeriod]['slotB']
                    && !hData[aPeriod]['slotB'][availableSpeciesSlotB[i]].data.every((val: number | null) => val === null))) {
                if (allowAveragingOption) allowAvgOption = false;
                hasAvgPeriodAnyData = true;
                break;
            }
        }
    }
    if (allowAveragingOption) return allowAvgOption;
    if (period && hasAvgPeriodAnyData) return period;
};