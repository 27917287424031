// Package Imports
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Type Imports
import { AnnualAvgData, Zephyr } from '../../utils/interface';

// Component Interfaces
interface DataDownloadModalProps {
  handleDownloadData: Function;
  handleDownloadDialog: Function;
  unitList: Zephyr[];
  annualAvgData: AnnualAvgData;
  showingAnnualAverage: boolean;
}

// Component
const DataDownloadModal = ({
  handleDownloadData,
  handleDownloadDialog,
  unitList,
  annualAvgData,
  showingAnnualAverage,
}: DataDownloadModalProps) => {
  const { t: translate } = useTranslation();

  // Consts
  // TODO: This should be in the utils/consts not here
  const downloadTypes =
    unitList[0].zNumber > 0
      ? // show both CSV and KLM radios for Zephyr's
        [
          { label: 'CSV', downloadType: 'csv' },
          { label: 'KML', downloadType: 'kml' },
        ]
      : // only show CSV download radio for AURNs
        [{ label: 'CSV', downloadType: 'csv' }];

  // State
  const initState = {
    selected: downloadTypes[0].downloadType,
  };

  const [selected, setSelected] = useState(initState.selected);

  // Functions
  const handleDownload = () => {
    // Make data request and close download dialog
    handleDownloadData(selected);
    handleDownloadDialog();
    // Analytics
  };

  return (
    <div className="download-data-modal">
      <div className="download-data-modal-header">
        <h4>{translate('downloadHeader')}</h4>
        <button
          className="button close close-modal"
          onClick={() => handleDownloadDialog()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 24 24"
            fill="#000"
          >
            <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
          </svg>
        </button>
      </div>
      {showingAnnualAverage ? (
        <p>
          {`${translate('downloadSubheader')} ${translate(
            'downloadCoordinates',
          )} ${annualAvgData?.list?.latlon?.lat.toFixed(
            2,
          )},${annualAvgData?.list?.latlon?.lon.toFixed(2)}`}
        </p>
      ) : (
        <p>
          {unitList.length > 1
            ? `${translate('downloadSubheader')} ${unitList.map(
                (unit, idx) => `${idx === 0 ? '' : ' '}${unit.name}`,
              )}`
            : `${translate('downloadSubheader')} ${unitList[0].name}`}
        </p>
      )}

      <p>Set file type:</p>
      <ul className="download-data-modal-options">
        {downloadTypes.map((dt) => (
          <li key={dt.label}>
            <label
              className="radio"
              htmlFor={`download-type-${dt.downloadType}`}
              key={dt.label}
            >
              {dt.label}
              <input
                type="radio"
                id={`download-type-${dt.downloadType}`}
                name={`download-type-${dt.downloadType}`}
                checked={selected === dt.downloadType}
                onChange={() => {
                  setSelected(dt.downloadType);
                }}
              />
              <span className="checkmark" />
            </label>
          </li>
        ))}
      </ul>
      <div className="download-data-modal-submit">
        <button className="button primary" onClick={() => handleDownload()}>
          {translate('downloadButton')}
        </button>
      </div>
    </div>
  );
};

export default DataDownloadModal;
