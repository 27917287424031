import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ReduxState, Zephyr } from '../../utils/interface';

interface AnalyticsProgressBarProps {
  requests: number;
  zephyrs: Zephyr[];
}

const Wrapper = styled.div`
  position: absolute;
  top: 275px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  text-align: center;
  background: #fff;
  border-radius: 16px;
  padding: 24px;
`;

const ProgressBarWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

const ProgressBar = styled.div`
  display: block;
  height: 100%;
  background-color: #4e73b7;
  transition: all 0.3s ease-in-out;
`;

const AnalyticsProgressBar = ({
  requests,
  zephyrs,
}: AnalyticsProgressBarProps) => {
  const [progressWidth, setProgressWidth] = useState<any>(0);
  const selectedZephyrs = zephyrs.filter((zephyr: Zephyr) => zephyr.isSelected);
  const widthChunk = parseInt((100 / selectedZephyrs.length) as any);
  const currentChunk = widthChunk * (requests + 1);

  const getRandomIntFromRange = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const updateProgressStateWhenResponseArrives = () => {
    setProgressWidth((width: number) => {
      const newWidth = width + getRandomIntFromRange(1, 3);
      return newWidth >= 100 ? 99 : newWidth;
    });
  };

  const increaseProgressBarPercentage = () => {
    if (requests === 0) {
      if (progressWidth >= widthChunk) {
        setProgressWidth(widthChunk);
        return;
      }
      updateProgressStateWhenResponseArrives();
      return;
    }
    if (progressWidth < widthChunk * requests) {
      setProgressWidth(widthChunk * requests);
      return;
    }
    if (progressWidth >= currentChunk) {
      setProgressWidth(currentChunk);
      return;
    }
    updateProgressStateWhenResponseArrives();
  };

  useEffect(() => {
    if (progressWidth < 99) {
      if (requests === selectedZephyrs.length) {
        setProgressWidth(100);
        return;
      }
      const interval = setInterval(increaseProgressBarPercentage, 300);
      return () => {
        clearInterval(interval);
      };
    }
  }, [requests, progressWidth]);

  return (
    <Wrapper className="progress-bar-wrapper">
      <h2
        className="generating-analysis-title"
        style={{
          marginTop: 0,
          fontSize: 21,
          fontWeight: 600,
          color: 'rgb(25 25 25 / 80%)',
        }}
      >
        Loading...
      </h2>
      <Stack direction="row" gap={2} alignItems="center">
        <ProgressBarWrapper className="progress-bar-container">
          <ProgressBar
            className="progress-bar"
            style={{ width: `${progressWidth}%` }}
          />
        </ProgressBarWrapper>
        <p style={{ fontSize: 14 }}>{parseInt(progressWidth)}%</p>
      </Stack>
    </Wrapper>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  zephyrs: state.getZephyrs.zephyrs,
  requests: state.unitHistoriesOptions.requests,
});

export default connect(mapStateToProps, {})(AnalyticsProgressBar);
