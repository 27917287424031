// Types Imports
import { SET_PUBLIC_ADVICE } from '../actions/types';

// Type Imports
import { PublicAdvice } from '../utils/interface';

// Type Safety
interface PublicAdviceConfig {
  advice: PublicAdvice[][];
}

interface SetPublicAdviceConfig {
  type: typeof SET_PUBLIC_ADVICE;
  payload: PublicAdvice[][];
}

// State
const initState: PublicAdviceConfig = {
  advice: [],
};

// Reducer
export default function (state = initState, action: SetPublicAdviceConfig) {
  const { type, payload } = action;

  switch (type) {
    case SET_PUBLIC_ADVICE:
      return {
        ...state,
        advice: payload,
      };

    default:
      return state;
  }
}
