// Package Imports
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import Cookies from 'js-cookie';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateMomentUtils from '@date-io/moment';

// Redux
import store from './store';

// Assets
import './assets/css/core.css';

// Consts
import { gtmConfig, permissionCookieSettings } from './utils/consts';

// Components
import AppContainer from './components/AppContainer';

// Init App
const App = () => {
  // Effects
  //TODO persistQuerystring
  // useEffect(() => {
  //   // persist url
  //   const persistQuerystring: Boolean = false;
  //   const currentLanguage: string = i18n.language || localStorage.i18nextLng;
  //   if (!location.search.includes('/?lang') && persistQuerystring) {
  //     history.push(`/?lng=${currentLanguage}`);
  //   }

  //   return () => {};
  // }, []);

  // Functions
  const getRouterBasename = () => {
    const defaultBasename = '';
    const basename = window.location.pathname.match(/\w+[^\/]/);
    const determinedBasename = basename
      ? basename[0].includes('login') ||
        basename[0].includes('data') ||
        basename[0].includes('alerts') ||
        basename[0].includes('today') ||
        basename[0].includes('analytics') ||
        basename[0].includes('fleetManagement') ||
        ((localStorage.getItem('user') === 'BP_Alerting' || localStorage.getItem('user') === 'BP_Alerting_Alternative') && basename[0].includes('smsAlerts')) ||
        basename[0].includes('analysis')
        ? defaultBasename
        : basename[0]
      : defaultBasename;
    return determinedBasename;
  };

  // Consts
  const basename = getRouterBasename();
  const optOutCookie = Cookies.get(permissionCookieSettings.name);

  // Initialization
  if (basename !== 'internal' && optOutCookie !== 'true') {
    TagManager.initialize({ ...gtmConfig, userId: '0000' });
  }

  // App
  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateMomentUtils}>
        <BrowserRouter basename={basename}>
          <div className="App">
            <AppContainer basename={basename} />
          </div>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </Provider>
  );
};

export default App;
