import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import React, { useState } from 'react';
import CloseIcon from '../../../assets/images/icon-close.svg';
import KebabIcon from '../../../assets/images/icon-kebab-dark.svg';
import MaximiseIcon from '../../../assets/images/icon-maximize.svg';
import { styleModalWithCta } from '../../../utils/styles';
import { thresholdLimitsInitState } from '../../../reducers/dataAnalytics';
import { ThresholdLimits, ThresholdsList } from '../../../utils/interface';
import { thresholdMainItemStyles, thresholdMenuItemStyles } from '../../../utils/consts';

function KebabMenuCta({
  setIsThresholdModalOpen,
  thresholdLimits,
  setThresholdsList,
  type
}: {
  setIsThresholdModalOpen: Function;
  thresholdLimits?: ThresholdLimits,
  setThresholdsList?: Function;
  type?: string
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menu2AnchorEl, setMenu2AnchorEl] = useState<null | HTMLElement>(null);
  const openChartMenu = Boolean(anchorEl);
  const openSubMenu = Boolean(menu2AnchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenu2AnchorEl(null);
  };

  const handleMenu2Open = (event: React.MouseEvent<HTMLElement>) => {
    setMenu2AnchorEl(event.currentTarget);
  };

  const handleMenu2Close = () => {
    setMenu2AnchorEl(null);
  };

  const updateThresholdsList = (value: ThresholdLimits) => {
    setThresholdsList!((prevState: ThresholdsList) => ({
      ...prevState,
      [type as keyof ThresholdsList]: value,
    }));
  };

  const handleThresholdToggling = () => {
    const updatedThresholds = {
      ...thresholdLimits,
      hidden: !(thresholdLimits as ThresholdLimits).hidden,
    };
    updateThresholdsList(updatedThresholds as ThresholdLimits);
  };

  const thresholdLinesAdded = () => {
    return thresholdLimits && (typeof thresholdLimits.thresholds.lowerThreshold.value === 'number' || typeof thresholdLimits.thresholds.upperThreshold.value === 'number');
  };

  const subMenuItems = () => {
    const linesExist = thresholdLinesAdded();
    return (
      <Menu
        anchorEl={menu2AnchorEl}
        open={openSubMenu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ top: -8 }}
        PaperProps={{
          style: {
            borderRadius: 0,
            marginLeft: -1,
            marginTop: 0
          },
        }}
      >
        {!linesExist && <MenuItem
          className='threshold-menu-item'
          onClick={() => { setIsThresholdModalOpen(true); handleClose(); }}
          sx={thresholdMenuItemStyles}
        >
          <span>Add</span>
          <span className="chevron-icon threshold add"></span>
        </MenuItem>}
        {linesExist && <div>
          <MenuItem
            onClick={handleThresholdToggling}
            sx={thresholdMenuItemStyles}
          >
            <span>{thresholdLimits?.hidden ? 'Show' : 'Hide'}</span>
            <span className={`chevron-icon threshold ${thresholdLimits?.hidden ? 'show' : 'hide'}`}></span>
          </MenuItem>
          <MenuItem
            onClick={() => { setIsThresholdModalOpen(true); handleClose(); }}
            sx={thresholdMenuItemStyles}
          >
            <span>Edit</span>
            <span className="chevron-icon threshold edit"></span>
          </MenuItem>
          <MenuItem
            onClick={() => { handleClose(); updateThresholdsList(thresholdLimitsInitState); }}
            sx={thresholdMenuItemStyles}
          >
            <span>Remove</span>
            <span className="chevron-icon threshold remove"></span>
          </MenuItem>
        </div>

        }
      </Menu>
    )
  };

  return (
    <div>
      <button
        type="button"
        className="buttonStyle no-style show-on-hover"
        aria-controls={openChartMenu ? 'basic-menu' : undefined}
        aria-expanded={openChartMenu ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img src={KebabIcon} alt="menu" />
      </button>
      <Menu
        anchorEl={anchorEl}
        open={openChartMenu}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            borderRadius: 0,
          },
        }}
      >
        <MenuItem
          onMouseEnter={handleMenu2Open}
          onClick={() => {
            handleClose();
          }}
          style={{ fontSize: 14, width: 165, paddingTop: 3, paddingBottom: 3 }}
          sx={thresholdMainItemStyles}
        >
          <span>Threshold line</span>
          <span className="chevron-icon threshold line"></span>
        </MenuItem>
      </Menu>
      {subMenuItems()}
    </div>
  );
}

function ModalWithCta({ modalChart = null }: { modalChart?: any | null }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenChartModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (!modalChart) return null;

  return (
    <div>
      <button
        type="button"
        className="buttonStyle no-style show-on-hover" onClick={handleOpenChartModal}>
        <img src={MaximiseIcon} alt="expand" />
      </button>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box sx={{ ...styleModalWithCta }}>
          <button
            className="closeButtonStyle no-style"
            type="button"
            onClick={handleCloseModal}
          >
            <img src={CloseIcon} alt="close modal" />
          </button>
          {modalChart}
        </Box>
      </Modal>
    </div>
  );
}

function TitleBarCtas({
  modalChart = null,
  setIsThresholdModalOpen = null,
  thresholdLimits,
  setThresholdsList,
  type,
}: {
  modalChart?: any | null;
  setIsThresholdModalOpen?: null | Function;
  thresholdLimits?: ThresholdLimits,
  setThresholdsList?: Function;
  type?: string
}) {
  return (
    <div className="d-flex justify-content-end grid-gap8px custom-modebar-actions" data-tour="step-6">
      {setIsThresholdModalOpen ? (
        <KebabMenuCta setIsThresholdModalOpen={setIsThresholdModalOpen} thresholdLimits={thresholdLimits} setThresholdsList={setThresholdsList} type={type} />
      ) : null}
      <ModalWithCta modalChart={modalChart} />
    </div>
  );
}

export const ChartTitleBar = ({
  title,
  HasMetStation,
  chartType,
  zType,
  children = null,
  setIsThresholdModalOpen,
  className = '',
  customModal,
  thresholdLimits,
  setThresholdsList,
  type,
  isComparisonView
}: {
  title: string | string[];
  HasMetStation?: number;
  chartType?: string;
  zType?: number;
  children?: any | null;
  setIsThresholdModalOpen?: Function;
  className?: string;
  customModal?: any;
  thresholdLimits?: ThresholdLimits,
  setThresholdsList?: Function;
  type?: string;
  isComparisonView?: boolean;
}) => {
  const chartBadge = !isComparisonView && (chartType || typeof zType === 'number') ? <span className='chart-badge'>{typeof zType === 'number' ? zType === 0 ? 'Zephyr' : 'Modelled' : chartType === 'th' ? HasMetStation ? 'Met Station' : zType === 0 ? 'Zephyr' : 'Modelled' : HasMetStation ? 'Met Station' : 'Modelled'}</span> : null;
  const getReformattedName = () => {
    return Array.isArray(title)
      ? title[1] === '25'
        ? <>{title[0]}<sub>2.5</sub></>
        : <>{title[0]}<sub>{title[1]}</sub></>
      : title;
  };
  return (
    <div className={className ? className : 'chart-title-bar'}>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="drag-handle">{getReformattedName()}{chartBadge}</h3>
        {customModal ? (
          customModal
        ) : (
          <TitleBarCtas
            modalChart={children}
            setIsThresholdModalOpen={setIsThresholdModalOpen} //! TO REACTIVATE AFTER LAUNCH
            thresholdLimits={thresholdLimits}
            setThresholdsList={setThresholdsList}
            type={type}
          />
        )}
      </div>
    </div>
  );
};
