const generateCSVName = (
  unitHistories: any,
  name?: string,
  selectedWeatherZephyr?: any,
  selectedView?: string
) => {
  const { historiesList } = unitHistories;
  const { id } = selectedWeatherZephyr;
  const { selectedAveragingPeriod: { labelHTML } } = Object.values(historiesList[0])[0] as any;
  const formattedAveraginOption = `${labelHTML.split(' ')[0]}${labelHTML.toLowerCase().includes('min') ? 'min' : 'hr'}`;
  const {
    selectedDate: { start, end },
  } = historiesList[0][Object.keys(historiesList[0])[0]];
  return `${selectedView === 'deviceView' ? `device_${id}` : selectedView === 'networkView' ? 'network' : 'comparison'}_${start}_${end}_${formattedAveraginOption}.${selectedView !== 'comparisonView' ? 'csv' : 'zip'}`
};

const splitByFirstNumber = (pollutant: string) => {
  let result = pollutant.match(/\d/);

  // If a number is found, split the string at that index
  if (result) {
    let index = result.index;
    let part1 = pollutant.slice(0, index);
    let part2 = pollutant.slice(index);
    return [part1, part2];
  }
  return pollutant;
}

export { generateCSVName, splitByFirstNumber };
