// Types Imports
import { SET_OVERLAYS } from '../actions/types';

// Type Improts
import { Overlays } from '../utils/interface';

// Type Safety
interface SetOverlays {
  type: typeof SET_OVERLAYS;
  payload: Overlays;
}

// State
const initState: Overlays = [{ name: '', nameHTML: '' }];

// Reducer
export default function (state = initState, action: SetOverlays) {
  const { type, payload } = action;

  switch (type) {
    case SET_OVERLAYS:
      return payload;

    default:
      return state;
  }
}
