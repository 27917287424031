// Type Imports
import { Dispatch } from 'redux';
import { SET_ACTIVE_THRESHOLD } from './types';

// Action Creators
export const setThresholdTab = (tab: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ACTIVE_THRESHOLD,
    payload: tab,
  });
};
