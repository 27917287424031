// Types Imports
import {
  TOUR_LAUNCHED,
  TOUR_FINISHED,
  TOUR_OPEN_ASIDE_FILTER,
  TOUR_CLOSE_ASIDE_FILTER,
  TOUR_OPEN_AQ_DATA_INDEX,
  TOUR_CLOSE_AQ_DATA_INDEX,
  TOUR_OPEN_AQ_COLORSCALE_INDEX,
  TOUR_CLOSE_AQ_COLORSCALE_INDEX,
  TOUR_OPEN_AQMA_INDEX,
  TOUR_CLOSE_AQMA_INDEX,
  TOUR_OPEN_ASIDE,
  TOUR_CLOSE_ASIDE,
  TOUR_OPEN_HELP,
  TOUR_CLOSE_HELP,
  TOUR_OPEN_LOCATION_SEARCH,
  TOUR_CLOSE_LOCATION_SEARCH,
  TOUR_MAP_CLICK,
  TOUR_CLOSE_MODEL_DATA_INDEX,
  TOUR_OPEN_MODEL_DATA_INDEX,
} from '../actions/types';

// Type Imports
import { Tour } from '../utils/interface';

interface SetTour {
  type:
    | typeof TOUR_LAUNCHED
    | typeof TOUR_FINISHED
    | typeof TOUR_OPEN_ASIDE_FILTER
    | typeof TOUR_CLOSE_ASIDE_FILTER
    | typeof TOUR_OPEN_AQ_DATA_INDEX
    | typeof TOUR_CLOSE_AQ_DATA_INDEX
    | typeof TOUR_OPEN_AQ_COLORSCALE_INDEX
    | typeof TOUR_CLOSE_AQ_COLORSCALE_INDEX
    | typeof TOUR_OPEN_AQMA_INDEX
    | typeof TOUR_CLOSE_AQMA_INDEX
    | typeof TOUR_OPEN_ASIDE
    | typeof TOUR_CLOSE_ASIDE
    | typeof TOUR_OPEN_HELP
    | typeof TOUR_CLOSE_HELP
    | typeof TOUR_OPEN_LOCATION_SEARCH
    | typeof TOUR_CLOSE_LOCATION_SEARCH
    | typeof TOUR_MAP_CLICK
    | typeof TOUR_CLOSE_MODEL_DATA_INDEX
    | typeof TOUR_OPEN_MODEL_DATA_INDEX;
}

// State
const initialState: Tour = {
  clickMap: false,
  openTour: false,
  openAsideFilter: false,
  openAQIndex: {
    openAQData: false,
    openAQColourscale: false,
    openAQMAIndex: false,
    openAQModelData: false,
  },
  openAside: false,
  openHelp: false,
  openLocationSearch: false,
};

// Reducer
export default function (state = initialState, action: SetTour) {
  const { type } = action;

  switch (type) {
    case TOUR_LAUNCHED:
      return {
        ...state,
        openTour: true,
      };

    case TOUR_FINISHED:
      return initialState;

    case TOUR_OPEN_ASIDE_FILTER:
      return {
        ...state,
        openAsideFilter: true,
      };

    case TOUR_CLOSE_ASIDE_FILTER:
      return {
        ...state,
        openAsideFilter: false,
      };

    case TOUR_OPEN_AQ_DATA_INDEX:
      return {
        ...state,
        openAQIndex: { ...state.openAQIndex, openAQData: true },
      };

    case TOUR_CLOSE_AQ_DATA_INDEX:
      return {
        ...state,
        openAQIndex: { ...state.openAQIndex, openAQData: false },
      };

    case TOUR_OPEN_AQ_COLORSCALE_INDEX:
      return {
        ...state,
        openAQIndex: { ...state.openAQIndex, openAQColourscale: true },
      };

    case TOUR_CLOSE_AQ_COLORSCALE_INDEX:
      return {
        ...state,
        openAQIndex: { ...state.openAQIndex, openAQColourscale: false },
      };

    case TOUR_OPEN_AQMA_INDEX:
      return {
        ...state,
        openAQIndex: { ...state.openAQIndex, openAQMAIndex: true },
      };

    case TOUR_CLOSE_AQMA_INDEX:
      return {
        ...state,
        openAQIndex: { ...state.openAQIndex, openAQMAIndex: false },
      };

    case TOUR_OPEN_ASIDE:
      return {
        ...state,
        openAside: true,
      };

    case TOUR_CLOSE_ASIDE:
      return {
        ...state,
        openAside: false,
      };

    case TOUR_OPEN_HELP:
      return {
        ...state,
        openHelp: true,
      };

    case TOUR_CLOSE_HELP:
      return {
        ...state,
        openHelp: false,
      };

    case TOUR_OPEN_LOCATION_SEARCH:
      return {
        ...state,
        openLocationSearch: true,
      };

    case TOUR_CLOSE_LOCATION_SEARCH:
      return {
        ...state,
        openLocationSearch: false,
      };

    case TOUR_MAP_CLICK:
      return {
        ...state,
        clickMap: !state.clickMap,
      };

    case TOUR_CLOSE_MODEL_DATA_INDEX:
      return {
        ...state,
        openAQIndex: { ...state.openAQIndex, openAQModelData: false },
      };

    case TOUR_OPEN_MODEL_DATA_INDEX:
      return {
        ...state,
        openAQIndex: { ...state.openAQIndex, openAQModelData: true },
      };

    default:
      return state;
  }
}

type AnalysisTourAction = {
  type: 'analysis_tour_toggle'
}
const initialAnalysisTour = false
export function AnalysisTour(state = initialAnalysisTour, action: AnalysisTourAction) {
  switch (action.type) {
    case 'analysis_tour_toggle':
      return !state
    default:
      return state
  }
}

type SelectChartOpenAction = {
  type: 'select-chart-mode-toggle' | 'select-chart-mode-open' | 'select-chart-mode-close'
}
export function selectChartModeOpen(state = false, action: SelectChartOpenAction) {
  switch (action.type) {
    case 'select-chart-mode-toggle':
      return !state
    case 'select-chart-mode-open':
      return true
    case 'select-chart-mode-close':
      return false
    default:
      return state
  }
}