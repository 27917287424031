// Package Imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

// Component Imports
import AirAlertsConfigurator from '../view_components/AirAlertsConfigurator';
import AirAlertsOverview from '../view_components/AirAlertsOverview';

// Action Imports
import { setAirAlerts } from '../../actions/airAlerts';

// Util Imports
import utilsRequest from '../../utils/request';
import { airAlertsApiPackager } from '../../utils/functions/airAlertsInterface';
import Spinner from '../shared_components/Spinner';

// Type Imports
import {
  AirAlerts,
  AlertObject,
  APIAirAlertPost,
  AlertModel,
  DisplayConfig,
  ReduxState,
  APIAirAlertTarget,
  Zephyr,
  SlotsInfo,
} from '../../utils/interface';
import auth from '../../reducers/auth';
import { gaseousSlots } from '../../utils/consts';
import { useLocation } from 'react-router-dom';

// Type Safety
interface AirAlertsState {
  editMode: boolean;
  selectedAlert: AlertObject | null;
}

interface AirAlertsTabProps {
  airAlerts: AirAlerts | null;
  displayConfig: DisplayConfig;
  setAirAlerts: Function;
  zephyrs: Zephyr[];
  asideOn: boolean;
}

// Component
const AirAlertsTab = ({
  airAlerts,
  displayConfig,
  setAirAlerts,
  zephyrs,
  asideOn,
}: AirAlertsTabProps) => {
  // State
  const initState: AirAlertsState = {
    editMode: false,
    selectedAlert: null,
  };
  const [editMode, setEditMode] = useState(initState.editMode);
  const [selectedAlert, setSelectedAlert] = useState(initState.selectedAlert);
  const [isAlertDataLoading, setIsAlertDataLoading] = useState<boolean | null>(
    null,
  );
  const location = useLocation();

  //Effects

  useEffect(() => {
    if (!location.pathname.includes('alerts')) return;
  
    const getAlertsData = async () => {
      const airAlerts = await utilsRequest.getAirAlerts();
      if (airAlerts) {
        const packagedAirAlerts = airAlertsApiPackager(airAlerts);
        await setAirAlerts(packagedAirAlerts);
      }
    };
    // IIFY
    (async () => {
      if (!localStorage.getItem('alerts')) {
        localStorage.setItem('alerts', 'true');
        setIsAlertDataLoading(true);
        await getAlertsData();
        setIsAlertDataLoading(false);
      }
    })();
  }, []);

  // Function
  const startNewAlert = () => {
    setEditMode(true);
    setSelectedAlert(null);
  };

  const endEdit = () => {
    setEditMode(false);
    setSelectedAlert(null);
  };

  const changeAlertStatus = (alertId: number) => {
    if (airAlerts && airAlerts.alerts.length) {
      const updatedUserAlerts = airAlerts.alerts.map((alert) => {
        const updatedAlert = { ...alert };
        if (alert.id === alertId) {
          updatedAlert.status = !updatedAlert.status;
        }
        return updatedAlert;
      });
      setAirAlerts({ ...airAlerts, alerts: [...updatedUserAlerts] });
    }
  };
  const AddTarget = async (target: APIAirAlertTarget) => {
    if (target) {
      await Promise.all(await utilsRequest['AddAlertTarget'](target));
    }
  };
  const removeAlertTarget = async (target: APIAirAlertTarget) => {
    if (target) {
      await Promise.all(await utilsRequest['removeAlertTarget'](target));
    }
  };
  const updateAlert = async (packagedAPIAlerts: APIAirAlertPost) => {
    //If we are in update mode, we go through and update any targets and/or parameters if we have any
    setIsAlertDataLoading(true);
    const airAlertRequest = selectedAlert ? 'updateAirAlert' : 'postAirAlert';
    await Promise.all(await utilsRequest[airAlertRequest](packagedAPIAlerts));
    const updatedAirAlerts = await utilsRequest.getAirAlerts();
    const packagedAirAlerts = airAlertsApiPackager(updatedAirAlerts);
    setAirAlerts(packagedAirAlerts);
    setIsAlertDataLoading(false);
  };

  const deleteAlert = async (alertId: number) => {
    if (airAlerts && airAlerts.alerts.length) {
      const updatedUserAlerts = airAlerts.alerts.filter(
        (alert) => alert.id !== alertId,
      );
      await utilsRequest.deleteAirAlert(alertId);
      setAirAlerts({ ...airAlerts, alerts: updatedUserAlerts });
    }
  };

  const setAlertConfigurator = (alertId: number) => {
    const selectedAlert = airAlerts!.alerts.filter(
      (alert) => alert.id === alertId,
    )[0];
    setEditMode(true);
    setSelectedAlert(selectedAlert);
  };

  return !isAlertDataLoading ? (
    <div
      className={`air-alerts-container ${!asideOn ? 'inventory-expanded' : ''}`}
    >
      {editMode ? (
        <AirAlertsConfigurator
          airAlerts={airAlerts}
          endEdit={endEdit}
          displayConfig={displayConfig}
          selectedAlert={selectedAlert}
          updateAlert={updateAlert}
          addTarget={AddTarget}
          removeAlertTarget={removeAlertTarget}
        />
      ) : (
        <AirAlertsOverview
          airAlerts={airAlerts}
          changeAlertStatus={changeAlertStatus}
          deleteAlert={deleteAlert}
          displayConfig={displayConfig}
          setAlertConfigurator={setAlertConfigurator}
          startNewAlert={startNewAlert}
          asideOn={asideOn}
        />
      )}
    </div>
  ) : (
    <div>
      <Spinner
        additionalClass="see-through initial-load"
        on={isAlertDataLoading}
      />
    </div>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  airAlerts: state.airAlerts,
  displayConfig: state.setDisplayConfig,
  zephyrs: state.getZephyrs.zephyrs,
  asideOn: state.aside.on,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setAirAlerts,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AirAlertsTab);
