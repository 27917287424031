// Package Imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

// Util Import
import { latestMeasurementMapper } from '../../utils/functions/latestMeasurementMapper';
import { publicAdviceFinder } from '../../utils/functions/publicAdviceFinder';
import { formatGoogleAddress } from '../../utils/functions/googleFormatter';
import { mappairSpeciesFinder } from '../../utils/functions/tabFinder';

// Const Imports
import { osmCRLink, publicCRLink } from '../../utils/consts';

// Type Imports
import {
  DisplayConfig,
  Overlay,
  PublicAdvice,
  PointValues,
  ReduxState,
  ShowAQMALayer,
  UserInfo,
  Zephyr,
  Species,
} from '../../utils/interface';

// Component Interfaces
interface LastestMeasurementsPublicProps {
  asideOn: boolean;
  cancelPopup: boolean;
  curOverlayName: string;
  curZephyr: Zephyr;
  displayConfig: DisplayConfig;
  mappairOptions: ShowAQMALayer;
  overlays: Overlay[];
  points: PointValues | null;
  publicAdvice: PublicAdvice[][];
  userInfo: UserInfo;
}

const LatestMeasurementsPublic = ({
  asideOn,
  cancelPopup,
  curOverlayName,
  curZephyr,
  displayConfig,
  mappairOptions,
  overlays,
  points,
  publicAdvice,
  userInfo,
}: LastestMeasurementsPublicProps) => {
  const { t: translate } = useTranslation();

  // State
  const [internalPoints, setInternalPoints] = useState(null as any);
  const popoverElement = document.getElementById(
    'popup-container',
  ) as HTMLElement;
  // Effects
  useEffect(() => {
    //if (!cancelPopup && points && points.value) {
    //    setInternalPoints(points);
    //} else if (cancelPopup) {
    //    setInternalPoints(null);
    //} else { setInternalPoints(points);}
    if (cancelPopup) {
      setInternalPoints(null);
    } else { setInternalPoints(points); }
  }, [cancelPopup, points]);

  // Consts
  const curOverlay =
    overlays && overlays.filter((ol) => ol.name === curOverlayName).length
      ? mappairSpeciesFinder(curOverlayName, displayConfig, overlays)
      : null;

  // Functions
  const checkAvailableData = () => {
    const availableData =
      (internalPoints && internalPoints.value && internalPoints.value >= 0) ||
      ((!internalPoints || !internalPoints.value) &&
        curZephyr.averageOfLastHourOfData &&
        curZephyr.averageOfLastHourOfData.AQI);

    return availableData;
  };

  // Component
  return (
    <>
      {userInfo.isPublic ? (
        <div className="public">
          {curOverlay ? (
            <div
              className={`latest-measurements-public ${asideOn ? '' : 'expand'
                }`}
            >
              <div className="OSM-credit">
                {osmCRLink.precedingContent}{' '}
                <a href={osmCRLink.url} target={osmCRLink.target}>
                  {translate('osmCRLink')}
                </a>
                {mappairOptions.showOverlayLayer ? (
                  <>
                    ,{' '}
                    <a href={publicCRLink.url} target={publicCRLink.target}>
                      {publicCRLink.content}
                    </a>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="lm-location-value-section">
                <div className="latest-measurements-public-location">
                  {ZephyrName(internalPoints, curZephyr, popoverElement)}
                </div>

                {PollutionValue(
                  internalPoints,
                  curZephyr,
                  displayConfig,
                  curOverlay,
                  popoverElement,
                )}
              </div>
              <div className="latest-measurements-public-advice">
                {PublicAdviceComponent(
                  internalPoints,
                  curZephyr,
                  popoverElement,
                  publicAdvice,
                  checkAvailableData,
                  curOverlay,
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  publicAdvice: state.publicAdvice.advice,
});

export default connect(mapStateToProps, {})(LatestMeasurementsPublic);

const ZephyrName = (
  internalPoints: any,
  curZephyr: Zephyr,
  popoverElement: HTMLElement,
) => {
  let name = '';

  if (internalPoints && internalPoints.namedLocation && internalPoints.value) {
    name = formatGoogleAddress(
      internalPoints.namedLocation[0].formatted_address,
      true,
    );
    return <h4 className="google-location">{name}</h4>;
  }
  if (popoverElement) return <h4></h4>;

  return <h4>{curZephyr.name} </h4>;
};

const PublicAdviceComponent = (
  internalPoints: any,
  curZephyr: Zephyr,
  popoverElement: HTMLElement,
  publicAdvice: any,
  checkAvailableData: Function,
  curOverlay: any,
) => {
  const curOverlayLabel = curOverlay.label;
  const pollutant = curZephyr ? 'averageOfLastHourOfData' in curZephyr && curZephyr.averageOfLastHourOfData ? { ...curZephyr.averageOfLastHourOfData }[curOverlayLabel] : null : null;

  const adviceValidator = () => {
    const publicAdvices = publicAdvice.filter((pA: any) => pA.length > 0);
    return publicAdvices.length > 0;
  };

  const getAdvice = () => {
    return parse(
      publicAdviceFinder(
        publicAdvice,
        !internalPoints
          ? pollutant
          : internalPoints.secondaryValue,
        curOverlay.label.toLowerCase(),
      ).advice,
    );
  };

  const getBehaviouralChange = () => {
    return parse(
      publicAdviceFinder(
        publicAdvice,
        !internalPoints
          ? pollutant
          : internalPoints.secondaryValue,
        curOverlay.label.toLowerCase(),
      ).behavioural_change,
    );
  };

  if (checkAvailableData()) {
    return adviceValidator() ? (
      <div>
        <span>Advice: </span>
        {getAdvice()}
        <br />
        <span>Your part: </span>
        {getBehaviouralChange()}
      </div>
    ) : <></>;
  }

  return <></>;
};

const PollutionValue = (
  internalPoints: any,
  curZephyr: Zephyr,
  displayConfig: any,
  curOverlay: any,
  popoverElement: HTMLElement,
) => {
  let { label, HTMLLabel, HTMLShortUnitLabel } = curOverlay;

  /* Either show Z value or MA value here depending on mode */
  if ((!internalPoints || !internalPoints.namedLocation) && !popoverElement) {
    if (
      curZephyr.averageOfLastHourOfData &&
      typeof curZephyr.averageOfLastHourOfData[label] === 'number'
    ) {
      return (
        <ul>
          {latestMeasurementMapper(
            displayConfig,
            curZephyr?.averageOfLastHourOfData,
            label,
          ).map((species) => (
            <li key={species.identifier}>
              <div className="lm-table-score">{species.value}</div>
              <div className="lm-table-data-label">
                {parse(species.HTMLLabel)}
              </div>
              <div className="lm-table-data-unit">{species.HTMLUnitLabel}</div>
            </li>
          ))}
        </ul>
      );
    }
  }

  if (internalPoints && internalPoints.value >= 0) {
    return (
      <ul>
        <li>
          <div className="lm-table-score">{internalPoints.value}</div>
          <div className="lm-table-data-label mappairPoint">
            {parse(HTMLLabel)}
          </div>
          <div className="lm-table-data-unit">{parse(HTMLShortUnitLabel)}
          </div>
        </li>
      </ul>
    );
  }

  return <div className="latest-measurement-no-data">No data</div>;
};
