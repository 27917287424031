// Type Imports
import { SET_QUERY_STRING_PARAMS } from '../actions/types';

import { UrlQueryStringParams } from '../utils/interface';

// Type Safety
interface SetQueryStringParams {
  type: typeof SET_QUERY_STRING_PARAMS;
  payload: UrlQueryStringParams;
}

// State
const initState: UrlQueryStringParams = {
  ctv: '',
  diagnostics: '',
};

// Reducer
export default function (state = initState, action: SetQueryStringParams) {
  const { type, payload } = action;

  switch (type) {
    case SET_QUERY_STRING_PARAMS:
      return {
        ...payload,
      };

    default:
      return state;
  }
}
