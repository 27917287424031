// Type Imports
import { SET_VERSIONS } from '../actions/types';

// Type Safety
interface SetVersions {
  type: typeof SET_VERSIONS;
  payload: string[];
}

// State
const initState: string[] = [];

// Reducer
export default function (state = initState, action: SetVersions) {
  const { type, payload } = action;

  switch (type) {
    case SET_VERSIONS:
      return payload;

    default:
      return state;
  }
}
