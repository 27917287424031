// Types Imports
import { GET_STYLE_GROUPS } from '../actions/types';

// Type Imports
import { Stylegroup } from '../utils/interface';

// Type Safety
interface StyleGroups {
  styleGroups: Stylegroup[] | any[];
}

interface getStyleGroups {
  type: typeof GET_STYLE_GROUPS;
  payload: Stylegroup[];
}

// State
const initialState: StyleGroups = {
  styleGroups: [],
};

// Reducer
export default function (state = initialState, action: getStyleGroups) {
  const { type, payload } = action;

  switch (type) {
    case GET_STYLE_GROUPS:
      return {
        ...state,
        styleGroups: payload,
      };
    default:
      return state;
  }
}
