// Const Imports
import { defaultLocation } from '../utils/consts';

// Type Imports
import { LocationCoordinates } from '../utils/interface';

// Types Imports
import { SET_LOCATION, SET_UNIT_LOCATION } from '../actions/types';

// Type Safety
interface SetLocation {
  location: LocationCoordinates;
}

interface setLocation {
  type: typeof SET_LOCATION | typeof SET_UNIT_LOCATION;
  payload: LocationCoordinates;
}

// State
const initialState: SetLocation = {
  location: defaultLocation,
};

// Reducer
export default function (state = initialState, action: setLocation) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOCATION:
      return {
        ...state,
        location: payload,
      };
    case SET_UNIT_LOCATION:
      return {
        ...state,
        location: payload,
      };
    default:
      return state;
  }
}
