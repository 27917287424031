// Types Imports
import { Dispatch } from 'redux';
import { SET_AQ101_OFF, SET_AQ101_ON, SET_AQ101_SECTION } from './types';

// Action Creators
export const setAQ101Off = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_AQ101_OFF,
  });
};

export const setAQ101On = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_AQ101_ON,
  });
};

export const setAQ101Section = (payload: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_AQ101_SECTION,
    payload,
  });
};
