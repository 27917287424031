// Package Imports
import React from 'react';

// Component Interfaces
interface SpinnerProps {
  additionalClass?: string;
  on: boolean | any;
}

// Component
const Spinner = ({ additionalClass, on }: SpinnerProps) => (
  <>
    {on ? (
      <div className={`spinner-container ${additionalClass}`}>
        <div className="lds-spinner">
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((s) => (
            <div key={s} />
          ))}
        </div>
      </div>
    ) : (
      <></>
    )}
  </>
);

export default Spinner;
