// Package Imports
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import parse from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// @ts-ignore
import html2canvas from 'html2canvas';
// @ts-ignore
import canvasToImage from 'canvas-to-image';
import { setZephyr } from '../../actions/zephyrs';

// Component Imports
import Spinner from '../shared_components/Spinner';
import Modal from '../shared_components/Modal';
import AQTemporalChart from './AQTemporalChart';

// Const Imports
import { annualAvgTab, routePaths, AQUnitContent } from '../../utils/consts';

// Util Imports
import { mappairCsvGenerator } from '../../utils/functions/csvGenerator';

// Type Imports
import {
  AnnualAvgTabs,
  DisplayConfig,
  Loading,
  ReduxState,
  Zephyr,
  PointValues,
  Species,
  AnnualAvgData,
} from '../../utils/interface';

// Component Interfaces
interface AQLatestDataProps {
  aaIsOn: AnnualAvgTabs;
  toggleAccordion: Function;
  changeMapAnnualAverageTab: Function;
  displayConfig: DisplayConfig;
  isOn: boolean;
  loading: Loading;
  tourAQDataOpen: boolean;
  tourOpen: boolean;
  zephyr: Zephyr;
  annualAvgData: AnnualAvgData | null;
  curSpecies: Species;
  points: PointValues | null;
  setZephyr: Function;
}

// Component
const AnnualAveragesAccordion = ({
  aaIsOn,
  toggleAccordion,
  changeMapAnnualAverageTab,
  displayConfig,
  curSpecies,
  isOn,
  loading,
  tourAQDataOpen,
  tourOpen,
  zephyr,
  points,
  annualAvgData,
  setZephyr,
}: AQLatestDataProps) => {
  const history = useHistory();
  const { t: translate } = useTranslation();

  const [isModalShown, setModalShow] = useState(false);

  // Effects
  useEffect(() => {
    if (!loading.zephyrLastHour) {
      changeTab('chart'); // 'list'
    }
  }, []);

  // Functions
  const changeTab = (tab: string) => {
    if (!aaIsOn[tab]) {
      changeMapAnnualAverageTab(tab);
    }
  };

  const navigateToDataTab = () => {
    setZephyr([]);
    // will pick up annual averageData from redux when we reach there
    history.push(routePaths.data);
  };

  // Functions
  const handleChartDownload = () => {
    const fileName = `mappairChartExport_${curSpecies.label}`;
    const item = document.querySelector('#aqm-modal-chart-container') as any;
    item.parentNode.style.overflow = 'visible';
    const html2canvasOptions = {
      backgroundColor: null,
      ignoreElements: () => false,
      logging: false,
    };
    html2canvas(item as HTMLElement, html2canvasOptions).then((canvas) => {
      canvasToImage(canvas, {
        name: fileName,
        type: 'png',
        quality: 1,
      });
    });
  };

  return (
    <>
      {!loading.zephyrLastHour ? (
        <li className="aql tourLatestMeasurements" data-user-tour="step-5">
          <button
            type="button"
            className={
              isOn  || tourAQDataOpen
                ? 'active'
                : ''
            }
            onClick={() => toggleAccordion('annualAvg')}
          >
            {translate('annualAvgAccordionTitle')}
          </button>

          {isOn || tourAQDataOpen ? (
            <Tabs
              isModalShown={isModalShown}
              setModalShow={setModalShow}
              points={points}
              annualAvgData={annualAvgData}
              aaIsOn={aaIsOn}
              changeTab={changeTab}
              handleChartDownload={handleChartDownload}
              curSpecies={curSpecies}
              navigateToDataTab={navigateToDataTab}
            />
          ) : null}
        </li>
      ) : null}
    </>
  );
};

const Loader = ({ points }: any) => {
  const { t: translate } = useTranslation();

  if (points && points?.hdms) {
    return (
      <div className="aqm">
        <div className="aqm-placeholder">
          <div>{translate('AQModelDataLoading')}</div>
          <Spinner
            additionalClass="header-spinner dark-spinner see-through"
            on
          />
        </div>{' '}
      </div>
    );
  }

  return (
    <div className="aqm">
      <div className="aqm-placeholder">
        {translate('AQModelDataClickPrompt')}
      </div>
    </div>
  );
};

function ListComponent(
  list: any,
):
  | string
  | number
  | boolean
  | {}
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | React.ReactNodeArray
  | React.ReactPortal
  | null
  | undefined {
  const { t: translate } = useTranslation();

  if (list?.values_timestamps[0]?.value === -999) {
    return (
      <div className="aq-sensor-data list">
        <div className="aqm-header p-0">
          <p>{translate('annualAvgNotAuthorised')}</p>
        </div>
      </div>
    );
  }

  if (list)
    return (
      <div className="aq-sensor-data list">
        <div className="aqm-header p-0">
          <h4>
            {translate('tabContentTitleForListPrefix')}
            {list.values_timestamps.length}
            {translate('tabContentTitleForListPostfix')}
          </h4>
        </div>
        <div className="species-table">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col" className="p-4">
                  {translate('tableRowforYr')}
                </th>
                <th scope="col" className="p-4">
                  {`${list.species.toLocaleUpperCase()} (${list.units})`}
                </th>
              </tr>
            </thead>
            <tbody>
              {list.values_timestamps.length > 0 ? (
                list.values_timestamps.map((valueTimestamp: any) => (
                  <tr key={`row-${valueTimestamp.timestamp}-`}>
                    <td className="p-4">{valueTimestamp.timestamp}</td>
                    <td className="p-4">{valueTimestamp.value}</td>
                  </tr>
                ))
              ) : (
                <tr>{translate('nodata')}</tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );

  return <div className="aq-sensor-data list">{translate('AQNoData')}</div>;
}

function ChartComponent(
  annualAvgData: AnnualAvgData | null,
  isModalShown: boolean,
  setModalShow: Function,
  handleChartDownload: Function,
  curSpecies: Species,
  points: PointValues,
  navigateToDataTab: Function,
):
  | string
  | number
  | boolean
  | {}
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | React.ReactNodeArray
  | React.ReactPortal
  | null
  | undefined {
  const { t: translate } = useTranslation();

  // Refs
  const compRef = React.createRef<any>();

  // Consts
  const additionalOptions = {
    zoomEnabled: true,
  };

  if (annualAvgData?.list?.values_timestamps[0].value === -999) {
    return (
      <div className="aq-sensor-data chart">
        <div className="aqm-header p-0">
          <p>{translate('annualAvgNotAuthorised')}</p>
        </div>
      </div>
    );
  }


  if (annualAvgData?.list)
    return (
      <div className="aq-sensor-data chart">
        {annualAvgData?.list ? (
          <>
            <div className="aqm-header p-0">
              <h4>
                {translate('tabContentTitleForChartPrefix')}
                {annualAvgData.list.values_timestamps.length}
                {translate('tabContentTitleForChartPostfix')}
              </h4>
              <button
                onClick={() => setModalShow(true)}
                className="aqm-expand-to-modal"
                data-html2canvas-ignore="true"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="#000"
                >
                  <path d="M8.465 16.95l2.828 3.05h-7.293v-7.293l3.051 2.829 8.484-8.486-2.828-3.05h7.293v7.292l-3.051-2.828z" />
                </svg>
              </button>
            </div>

            <AQTemporalChart
              chartDataset={annualAvgData.chart}
              annual
              intervals={1}
              intervalType="year"
            />

            <Modal
              on={isModalShown}
              modalComponent={
                <div className="modal-container temporal-modal">
                  <div ref={compRef} className="temporal-modal-content">
                    <div className="aqm-header aqm-header-modal">
                      <h4>
                        {translate('AAChartHeader')}

                        <br />
                        <span className="aq-model-unit">
                          {annualAvgData.list.values_timestamps[0].timestamp} -{' '}
                          {
                            annualAvgData.list.values_timestamps[
                              annualAvgData.list.values_timestamps.length - 1
                            ].timestamp
                          }
                          : {parse(curSpecies.HTMLLabel)}
                          {curSpecies.HTMLShortUnitLabel}
                        </span>
                      </h4>
                      <div className="aqm-modal-header-buttons">
                        <button
                          className="button close close-modal aqm-close-modal"
                          onClick={() => setModalShow(false)}
                          type="button"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            viewBox="0 0 24 24"
                            fill="#000"
                          >
                            <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div
                      className="aqm-chart-container aqm-modal-chart-container"
                      id="aqm-modal-chart-container"
                    >
                      <AQTemporalChart
                        chartDataset={annualAvgData.chart}
                        additionalOptions={additionalOptions}
                        handleChartDownload={handleChartDownload}
                        annual
                        intervals={1}
                        intervalType="year"
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        if (annualAvgData && annualAvgData.chart) {
                          mappairCsvGenerator(
                            annualAvgData.chart,
                            curSpecies,
                            points?.hdms,
                          );
                        }
                      }}
                      className="button tertiary aqm-download-data"
                      data-html2canvas-ignore="true"
                    >
                      {translate('AADownloadData')}
                    </button>

                    {points ? (
                      <button
                        type="button"
                        onClick={() => {
                          navigateToDataTab();
                        }}
                        className="button primary aqm-download-data"
                        data-html2canvas-ignore="true"
                      >
                        {translate('AASeeMore')}
                      </button>
                    ) : null}
                  </div>
                </div>
              }
            />
          </>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    );

  return <div className="aq-sensor-data chart">{translate('AQNoData')}</div>;
}

const Tabs = ({
  points,
  annualAvgData,
  aaIsOn,
  changeTab,
  isModalShown,
  setModalShow,
  handleChartDownload,
  curSpecies,
  navigateToDataTab,
}: any) => {
  if (points && points.value && annualAvgData) {
    return (
      <div className="aq-lm aqm">
        <nav className="aq-nav">
          <ul>
            {annualAvgTab.map((item, i) => {
              return (
                <li
                  role="tab"
                  key={`${item.value}-i`}
                  onClick={() => changeTab(item.value)}
                  className={aaIsOn[item.value] ? 'active' : ''}
                >
                  {item.label}
                </li>
              );
            })}
          </ul>
        </nav>

        {aaIsOn.chart && annualAvgData.chart
          ? ChartComponent(
            annualAvgData,
            isModalShown,
            setModalShow,
            handleChartDownload,
            curSpecies,
            points,
            navigateToDataTab,
          )
          : null}

        {aaIsOn.list ? ListComponent(annualAvgData.list) : null}
      </div>
    );
  }

  return <Loader points={points} />;
};

// Redux
const mapStateToProps = (state: ReduxState) => ({
  displayConfig: state.setDisplayConfig,
  loading: state.loading,
  tourAQDataOpen: state.tour.openAQIndex.openAQData,
  tourOpen: state.tour.openTour,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setZephyr,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnnualAveragesAccordion);
