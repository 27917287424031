// Package Imports
import React from 'react';
import { useTranslation } from 'react-i18next';

// Component Imports
import AQSAChart from './AQSAChart';

// Const Imports
import { AQSAContent, naeiCRLink } from '../../utils/consts';

// Type Imports
import { SAChartData } from '../../utils/interface';

// Component Interfaces
interface AQSAProps {
  isOn: boolean;
  saData: SAChartData | null;
}

// Component
const AQSA = ({ isOn, saData }: AQSAProps) => {
  const { t: translate } = useTranslation();

  if (isOn && saData) {
    return (
      <div className="aqm-container">
        <div className="aqm-header sa-header">
          <h4>
            {`${translate('AQTotal')} ${saData.meta.total.toFixed(3)}`}
            <br />
            <span className="aq-model-unit">{translate('AQUnits')}</span>
          </h4>
        </div>
        <div className="aqm-chart-container">
          <AQSAChart chartDataset={saData} />
        </div>
        <div className="aq-model-cr">
          {naeiCRLink.precedingContent}
          <a href={naeiCRLink.url} target={naeiCRLink.target}>
            {translate('naeiCRLink')}
          </a>
        </div>
      </div>
    );
  }
  return null;
};

export default AQSA;
