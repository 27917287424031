// Type Imports
import { PublicAdvice } from '../interface';

const publicAdviceFinder = (advice: PublicAdvice[][], value: number | null, species: string) => {
    let defaultAdvice = advice[0][0];
    let no_advice_found: PublicAdvice;
    no_advice_found = {
        advice: "Advice currently unavailable for this pollutant, please try switching to another.",
        advice_sensitive: "",
        behavioural_change: "",
        label: "",
        lower_limit_value: 0,
        species: ""
    }
  if (value) {
    // For now we only return AQI advice from the API - not true anymore 28092022
      const AQIAdvice = advice[0];
      let any_entries_for_species = false;
    // Use for loop to allow break
      for (let idx = 0; idx < AQIAdvice.length; idx++) {
          if (species === AQIAdvice[idx].species) {
              any_entries_for_species = true;
          }
        // handle end case for each species group (entries should be added in species groups and in ascending conc order for this to work)
          if (idx === AQIAdvice.length - 1) {
              if (any_entries_for_species) {
                  defaultAdvice = AQIAdvice[idx];
                  break;
              } else {
                  return no_advice_found;
              }
          } else if ((species === AQIAdvice[idx].species && AQIAdvice[idx + 1].species !== species) ||
              (value >= AQIAdvice[idx].lower_limit_value &&
              value < AQIAdvice[idx + 1].lower_limit_value &&
              species === AQIAdvice[idx].species)) {
              defaultAdvice = AQIAdvice[idx];
              break;
          } 
    }
  }
  return defaultAdvice;
};

export { publicAdviceFinder };
