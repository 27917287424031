// Type Imports
import { Dispatch } from 'redux';
import { SHOW_MAP_AIR_FILTERS, SET_SELECTED_FILTER } from './types';

// Action Creators
export const mappAirFilters = (payload: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SHOW_MAP_AIR_FILTERS,
    payload
  });
};

export const setSelectedFilters = (payload: string[]) => (
  dispatch: Dispatch,
) => {
  dispatch({
    type: SET_SELECTED_FILTER,
    payload,
  });
};
