// Util Imports
import { cardinalDirectionsPlus } from '../consts';

// Type Imports
import { CardinalDirections } from '../interface';

/* ===== Function =====
@DESC: cardinalDirectionFinder takes a direction and returns the bucket name for it e.g NNW
@ARGS: a direction in unit degrees
*/
const cardinalDirectionFinder = (direction: number) => {
  const directionCount = cardinalDirectionsPlus.length;
  let dirBucket: CardinalDirections =
    cardinalDirectionsPlus[directionCount - 1];
  cardinalDirectionsPlus.forEach((cD, idx) => {
    if (
      idx < directionCount - 1 &&
      direction >= cD.minDegrees &&
      direction < cardinalDirectionsPlus[idx + 1].minDegrees
    ) {
      dirBucket = cD;
    }
  });
  return dirBucket;
};

export { cardinalDirectionFinder };
