/* Function
DESC: wideScreenFinder returns true for screen sizes over a specified amoung
ARGS: screen size cut-off in pixels, anything above this, will return true
*/
export const wideScreenFinder = (pixels: number) => {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName('body')[0];
  const windowWidth = w.innerWidth || e.clientWidth || g.clientWidth;

  return windowWidth > pixels;
};
