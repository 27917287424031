// Util Imports
import { Dispatch } from 'redux';
import utilsRequest from '../utils/request';

// Type Imports
import { SET_LOCATION, SET_UNIT_LOCATION, MAPS_LOCATION_LOADED } from './types';

// Action Creators
export const checkLocation = async (location: string, searchBias = 'null') => {
  if (location) {
    const newLocation = await utilsRequest.setLocation(location, searchBias);
    const newGeometry = newLocation.results[0].geometry.location;
    return newGeometry;
  }
  return null;
};

export const setLocation = (location: string, searchBias: string) => async (
  dispatch: Dispatch,
) => {
  if (location) {
    const newLocation = await checkLocation(location, searchBias);
    dispatch({
      type: SET_LOCATION,
      payload: [newLocation.lng, newLocation.lat],
    });
  }
};

export const setUnitLocation = (lng: number, lat: number) => (
  dispatch: Dispatch,
) => {
  dispatch({
    type: SET_UNIT_LOCATION,
    payload: [lng, lat],
  });
};

export const mapsLocationLoaded = (loaded: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: MAPS_LOCATION_LOADED,
    payload: loaded,
  });
};
