// Package Imports
import React from 'react';
import CanvasJSReact from '../../canvasjs.react';

// Component Imports
import DataChartDownloadButton from './DataChartDownloadButton';

// Util Imports
import { wrangleLineChartData } from '../../utils/functions/chartHelper';

// Type Imports
import { ChartData } from '../../utils/interface';

// Component Interfaces
interface AQTemporalChartProps {
  chartDataset: ChartData | null;
  intervals?: number;
  additionalOptions?: any;
  handleChartDownload?: Function;
  annual?: boolean;
  intervalType?: string;
}

const AQTemporalChart = ({
  chartDataset,
  intervals,
  intervalType,
  additionalOptions,
  handleChartDownload,
  annual = false,
}: AQTemporalChartProps) => {
  let axisXIntervalType = intervals ? 'day' : undefined;
  if (intervalType) axisXIntervalType = intervalType;
  if (!chartDataset) return null;
  return (
    <>
      <CanvasJSReact.CanvasJSChart
        containerProps={{
          height: '250px',
        }}
        options={{
          data: wrangleLineChartData(chartDataset, () => {}),
          animationEnabled: false,
          zoomEnabled: false,
          backgroundColor: 'transparent',
          toolTip: {
            borderColor: '#222539',
            cornerRadius: 3,
            fontFamily: 'Open Sans',
            contentFormatter: (e: any) => {
              const formattedDate = CanvasJSReact.CanvasJS.formatDate(
                e.entries[0].dataPoint.x,
                e.entries[0].dataSeries.xValueFormatString,
              );
              const content = `<small>${formattedDate}</small><br/><strong>${e.entries[0].dataPoint.y}</strong> ${e.entries[0].dataSeries.label}`;
              return content;
            },
          },
          axisY: {
            gridColor: '#cbcdcf',
            gridThickness: 0,
            labelFontFamily: 'Open Sans',
            minimum: 0,
          },
          axisX: {
            labelFontFamily: 'Open Sans',
            valueFormatString: annual ? 'YYYY' : 'DD-MM-YY',
            labelAngle: 0,
            labelAutoFit: true,
            labelWrap: true,
            labelMaxWidth: 75,
            prefix: '‎‎‏‏‎ ‎  ',
            interval: intervals || undefined,
            intervalType: axisXIntervalType,
            stripLines: [
              {
                value: new Date(),
                color: '#92949a',
                label: 'Today',
                labelBackgroundColor: 'transparent',
                labelFontColor: '#252834',
              },
            ],
          },
          ...additionalOptions,
        }}
      />
      <DataChartDownloadButton
        handleChartDownload={handleChartDownload as Function}
        on={Object.keys(additionalOptions).length > 0}
      />
    </>
  );
};

// Default Props
AQTemporalChart.defaultProps = {
  additionalOptions: {},
  handleChartDownload: () => null,
};

export default AQTemporalChart;
